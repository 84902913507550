import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionContact = () => (
  <SectionCta title="Ready to learn more?" centerCTA>
    <SectionCta.Buttons>
      <Button url="/meeting-request/">Let’s chat</Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionContact
