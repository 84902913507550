import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'

const Icon = ({ xxxl, xxl, xl, l, m, s, className, children }) => {
  const classes = cx(`u-icon`, className, {
    'u-icon--xxxl': xxxl,
    'u-icon--xxl': xxl,
    'u-icon--xl': xl,
    'u-icon--l': l,
    'u-icon--m': m,
    'u-icon--s': s,
  })
  return <span className={classes}>{children}</span>
}

Icon.propTypes = {
  xxxl: PropTypes.bool,
  xxl: PropTypes.bool,
  xl: PropTypes.bool,
  l: PropTypes.bool,
  m: PropTypes.bool,
  s: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.children.isRequired,
}

// Global Icons
export const IconClose = createSvgIcon(`icon-close`)
export const IconSearch = createSvgIcon(`icon-search`)

// Header Icons
export const HeaderCloud = createSvgIcon(`header-cloud`)
export const HeaderClipboard = createSvgIcon(`header-clipboard`)
export const HeaderEmail = createSvgIcon(`header-email`)
export const HeaderLightbulb = createSvgIcon(`header-lightbulb`)
export const HeaderMachineLearning = createSvgIcon(`header-machine-learning`)
export const HeaderMessage = createSvgIcon(`header-message`)
export const HeaderMethods = createSvgIcon(`header-methods`)
export const HeaderMoney = createSvgIcon(`header-money`)
export const HeaderNetwork = createSvgIcon(`header-network`)
export const HeaderPointer = createSvgIcon(`header-pointer`)
export const HeaderProfile = createSvgIcon(`header-profile`)
export const HeaderRocket = createSvgIcon(`header-rocket`)
export const HeaderSmile = createSvgIcon(`header-smile`)
export const HeaderOrganization = createSvgIcon(`header-organization`)
export const HeaderStack = createSvgIcon(`header-stack`)
export const HeaderTranslation = createSvgIcon(`header-translation`)
export const HeaderTrophy = createSvgIcon(`header-trophy`)
export const HeaderUser = createSvgIcon(`header-user`)
export const HeaderWorld = createSvgIcon(`header-world`)
export const HeaderThumbsup = createSvgIcon(`header-thumbsup`)
export const HeaderPresentation = createSvgIcon(`header-presentation`)

// White Icons
export const IconAtSymbol = createSvgIcon(`icon-at-symbol`)
export const IconButtons = createSvgIcon(`icon-buttons`)
export const IconBarGraph = createSvgIcon(`icon-bar-graph`)
export const IconBriefcase = createSvgIcon(`icon-briefcase`)
export const IconCertifiedBadge = createSvgIcon(`icon-certified-badge`)
export const IconChatQuote = createSvgIcon(`icon-chat-quote`)
export const IconCloudSecure = createSvgIcon(`icon-cloud-secure`)
export const IconCoin = createSvgIcon(`icon-coin`)
export const IconCommandLine = createSvgIcon(`icon-command-line`)
export const IconComputerApps = createSvgIcon(`icon-computer-apps`)
export const IconComputer = createSvgIcon(`icon-computer`)
export const IconGallery = createSvgIcon(`icon-gallery`)
export const IconGlasses = createSvgIcon(`icon-glasses`)
export const IconMail = createSvgIcon(`icon-mail`)
export const IconPieChart = createSvgIcon(`icon-pie-chart`)
export const IconPostIt = createSvgIcon(`icon-post-it`)
export const IconSend = createSvgIcon(`icon-send`)
export const IconSmartphone = createSvgIcon(`icon-smartphone`)
export const IconSwatchbook = createSvgIcon(`icon-swatchbook`)
export const IconUpDownArrows = createSvgIcon(`icon-up-down-arrows`)
export const IconContent = createSvgIcon(`icon_content-whiteline`)
export const IconDocuments = createSvgIcon(`icon_documents-whiteline`)
export const IconTranslation = createSvgIcon(`icon_translation-cloud-whiteline`)
export const IconFuzzymatch = createSvgIcon(`icon_fuzzymatch-whiteline`)
export const IconDiamond = createSvgIcon(`icon_diamond-whiteline`)
export const IconCog = createSvgIcon(`icon_cog-whiteline`)
export const IconWebsite = createSvgIcon(`icon_website-light`)
export const IconContent2 = createSvgIcon(`icon_content-light`)
export const IconShopping = createSvgIcon(`icon_shopping-light`)
export const IconCogs = createSvgIcon(`icon_three-cogs-light`)
export const IconTeam = createSvgIcon(`icon_team-light`)
export const IconTeamRing = createSvgIcon(`icon_team-light-ring`)
export const IconIntegrations = createSvgIcon(`icon_integration-light-ring`)
export const IconPencil = createSvgIcon(`icon_pencil-light`)
export const IconLocation = createSvgIcon(`icon_location-light`)
export const IconGoals = createSvgIcon(`icon_goals-light-ring`)
export const IconAccuracy = createSvgIcon(`icon_accuracy-light`)
export const IconVideo = createSvgIcon(`icon_video-light-ring`)
export const IconError = createSvgIcon(`icon_error-light`)
export const IconRosette = createSvgIcon(`g2_icon_rosette-light-ring`)
export const IconErrorCircleLight = createSvgIcon(`icon_error-light-ring`)
export const IconLightbulb = createSvgIcon(`icon_lightbulb-light`)
export const IconRocketCircleLight = createSvgIcon(`icon_rocket-light-ring`)
export const IconLightbulbCircleLight = createSvgIcon(
  `icon_lightbulb-light-ring`
)
export const IconBinocularsRing = createSvgIcon(`icon_binoculars-light-ring`)
export const IconCloudLightRing = createSvgIcon(
  `icon_translation-cloud-light-ring`
)
export const IconTrophyCircleLight = createSvgIcon(`icon_trophy-light-ring`)
export const IconStar = createSvgIcon(`icon_star-light`)
export const IconStarCircleLight = createSvgIcon(`icon_star-light-ring`)
export const IconIntegrationCircleLight = createSvgIcon(
  `icon_integration-light-ring`
)

// Tab Icons
export const TabAnalytics = createSvgIcon(`tab-analytics`)
export const TabApi = createSvgIcon(`tab-api`)
export const TabHuman = createSvgIcon(`tab-human`)
export const TabIntegrations = createSvgIcon(`tab-integrations`)
export const TabInterface = createSvgIcon(`tab-interface`)
export const TabMachine = createSvgIcon(`tab-machine`)
export const TabProject = createSvgIcon(`tab-project`)
export const TabWorld = createSvgIcon(`tab-world`)

// Help Icons
export const Demo = createSvgIcon(`icon-pack_calendar-white`)
export const LearningCenter = createSvgIcon(`icon-pack_content-light`)
export const HelpCenter = createSvgIcon(`icon-pack_read-our-docs-white`)

// Misc Icons
export const Headphones = createSvgIcon(`headphones`)

export const Menu = (props) => (
  <Icon {...props} className="o-icon__menu">
    <span />
    <span />
    <span />
  </Icon>
)

export const Close = (props) => (
  <Icon {...props} className="o-icon__close">
    <span />
    <span />
  </Icon>
)

export const Facebook = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 30">
      <path d="M3.75,10H0v5H3.75V30H10V15h4.55L15,10H10V7.92c0-1.2.24-1.67,1.39-1.67H15V0H10.24C5.74,0,3.75,2,3.75,5.77Z" />
    </svg>
  </Icon>
)

export const Twitter = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 24.37">
      <path d="M26.92,6.07c0,.27,0,.54,0,.8,0,8.13-6.19,17.5-17.5,17.5A17.38,17.38,0,0,1,0,21.61a14.39,14.39,0,0,0,1.48.07,12.29,12.29,0,0,0,7.64-2.63,6.14,6.14,0,0,1-5.75-4.26,7.36,7.36,0,0,0,1.16.1,6.72,6.72,0,0,0,1.62-.21,6.15,6.15,0,0,1-4.93-6V8.57A6.31,6.31,0,0,0,4,9.35,6.18,6.18,0,0,1,2.09,1.12,17.51,17.51,0,0,0,14.77,7.56a7.42,7.42,0,0,1-.15-1.41A6.15,6.15,0,0,1,25.26,1.94,12,12,0,0,0,29.16.46a6.18,6.18,0,0,1-2.7,3.39,12.36,12.36,0,0,0,3.54-1,13.11,13.11,0,0,1-3.08,3.18Z" />
    </svg>
  </Icon>
)

export const Googleplus = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.43 30">
      <path d="M51.43,12.86v4.28H45v6.43H40.71V17.14H34.29V12.86h6.42V6.43H45v6.43ZM29.36,14.55a16,16,0,0,0-.2-1.69H15V18h8.51c-.35,2.21-2.57,6.47-8.51,6.47A9.47,9.47,0,0,1,15,5.53a8.47,8.47,0,0,1,6,2.31l4.07-3.92A14.38,14.38,0,0,0,15,0a15,15,0,0,0,0,30c8.66,0,14.4-6.09,14.4-14.66C29.4,15.05,29.38,14.81,29.36,14.55Z" />
    </svg>
  </Icon>
)

export const Youtube = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 22.5">
      <path d="M24.52.23C20-.08,10-.08,5.48.23.61.56,0,3.51,0,11.25s.61,10.69,5.48,11c4.5.31,14.53.31,19,0C29.39,21.94,30,19,30,11.25S29.39.56,24.52.23Zm-13.27,16v-10l10,5Z" />
    </svg>
  </Icon>
)

export const Linkedin = (props) => (
  <Icon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 28.75">
      <path d="M0,3.12a3.1,3.1,0,1,1,6.19,0A3.1,3.1,0,1,1,0,3.12Zm16.2,8.32V8.75H10v20h6.21V18.25c0-5.84,7.54-6.31,7.54,0v10.5H30V16.09C30,6.24,18.85,6.6,16.23,11.44ZM0,28.75H6.25v-20H0Z" />
    </svg>
  </Icon>
)

/* HELPERS */

function createSvgIcon(name) {
  const svg = require(`../images/icons/${name}.svg`)
  return (props) => (
    <Icon {...props}>
      <img loading="lazy" alt="" src={svg.default} />
    </Icon>
  )
}
