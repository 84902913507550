import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import { getPodcastPath } from '../routes'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import ShareBar from 'molecules/share-bar'
import Section from 'atoms/section'
import Card from 'molecules/card'
import PodcastSubscribe from 'atoms/podcastSubscribe'
import PardotForm from 'components/forms/pardot'
import { GatsbyImage } from 'gatsby-plugin-image'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import Layout from 'layouts'

const moment = require('../../static/vendor/moment.min.js')

class PodcastTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    function newsletterClick(e) {
      e.preventDefault()
      if (window.drift) {
        window.drift.api.startInteraction({ interactionId: 121442 })
      } else {
        console.log('drift not defined in -', window)
      }
    }

    return (
      <Layout>
        <div className={'l-page--podcast'}>
          <Metadata
            title={`${data.page.name} - Smartling`}
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={
              data.page.heroImage?.file?.url
                ? data.page.heroImage?.file?.url
                : MetaDataImage
            }
          />

          <Hero
            gradientSecondary
            className={'c-hero--podcast u-gradient--secondary'}
            title={
              data.page.seasonNumber && data.page.episodeNumber
                ? `S${data.page.seasonNumber}E${data.page.episodeNumber} - ${data.page.name}`
                : data.page.name
            }
          >
            <Hero.Kicker>
              {data.page.publishDate &&
                moment(data.page.publishDate).format('MMMM Do YYYY')}
            </Hero.Kicker>

            <Hero.Description>
              {data.page.description && (
                <div className="u-spacing o-text">
                  {/* <DangerousHTML>
                    {data.page.description.html.text}
                  </DangerousHTML> */}

                  {data.page.heroImage?.file.url && (
                    <GatsbyImage
                      image={data.page.heroImage.gatsbyImageData}
                      alt="podcast"
                    />
                  )}

                  <ShareBar
                    uri={getPodcastPath(data.page.slug)}
                    title={data.page.name}
                  ></ShareBar>
                </div>
              )}
            </Hero.Description>

            <Hero.Buttons>
              {data.page.buttonUrl && (
                <Button
                  url={data.page.buttonUrl}
                  target={data.page.openInNewWindow ? `_blank` : `_self`}
                >
                  {data.page.buttonText ? data.page.buttonText : `Learn More`}
                </Button>
              )}
            </Hero.Buttons>
          </Hero>
          <article
            className={`l-article u-spacing--sections ${
              data.category.key === 'locshow' ? 'o-locshow-bg' : ''
            }`}
          >
            <div className="l-wrap l-container--l u-spacing--double">
              <div className="l-article__body o-text o-text--secondary u-center-children">
                <div className="u-space--double--bottom u-spacing">
                  <div className="c-podcast-player">
                    {data.page.jwId ? (
                      <div
                        id={'jw-' + data.page.jwId}
                        className="jw-player"
                        data-jw-media-id={data.page.jwId}
                      ></div>
                    ) : data.page.transistorMediaId ? (
                      <iframe
                        style={{ marginBottom: '20px' }}
                        title="player"
                        width="100%"
                        height="180"
                        frameborder="no"
                        scrolling="no"
                        seamless
                        src={`https://share.transistor.fm/e/${data.page.transistorMediaId}`}
                      ></iframe>
                    ) : (
                      ''
                    )}
                  </div>

                  <PodcastSubscribe
                    subscribeLinks={data.category.subscribeLinks}
                  />
                </div>

                {data.page?.pardotFormReference &&
                  (data.page.showFormAboveBody ? (
                    <PardotForm
                      iframe={data.page?.pardotFormReference?.pardotFormLink}
                    />
                  ) : (
                    ``
                  ))}

                {data.page.body && (
                  <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                )}

                {data.page?.pardotFormReference &&
                  (!data.page.showFormAboveBody ? (
                    <div className="u-padding--double--top u-padding--double--bottom">
                      <PardotForm
                        iframe={data.page?.pardotFormReference?.pardotFormLink}
                      />
                    </div>
                  ) : (
                    ``
                  ))}

                {data.page.transcript && (
                  <DangerousHTML>
                    {data.page.transcript.html.text}
                  </DangerousHTML>
                )}
              </div>
            </div>

            {!data.page?.pardotFormReference && (
              <Section className="c-section__block-rows c-block-rows u-space--double--bottom">
                <Section.Inner l className="u-spacing--quad">
                  <Grid className="c-podcast-signup">
                    <Grid.Item>
                      <Card title="Subscribe to the Smartling newsletter">
                        <Card.Image />
                        Interested in receiving email notifications when a new
                        podcast goes live? Be sure to subscribe to our
                        newsletter!
                        <p className="u-padding--top">
                          <a
                            role="button"
                            onClick={newsletterClick}
                            href="#newslettersignup"
                            className="o-link"
                          >
                            Subscribe today
                          </a>
                        </p>
                      </Card>
                    </Grid.Item>
                  </Grid>
                </Section.Inner>
              </Section>
            )}
          </article>
        </div>
      </Layout>
    )
  }
}

export default PodcastTemplate

export const pageQuery = graphql`
  query PodcastTemplateQuery($slug: String!, $category: String!) {
    page: contentfulPodcast(slug: { eq: $slug }) {
      name
      slug
      jwId
      transistorMediaId
      publishDate
      seasonNumber
      episodeNumber
      description {
        html: childMarkdownRemark {
          text: html
        }
      }
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      transcript {
        html: childMarkdownRemark {
          text: html
        }
      }
      heroImage {
        ...ContentfulImageFragment
        gatsbyImageData(
          width: 700
          height: 394
          formats: [WEBP, AUTO]
          placeholder: BLURRED
        )
      }
      showFormAboveBody
    }
    category: contentfulPodcastCategory(key: { eq: $category }) {
      key
      subscribeLinks {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
