import React from 'react'

// Images
import imageYextLogo from '../../images/yext-logo.svg'

const YextContent = () => (
  <article className="l-article c-resource-yext">
    <section class="o-section--inner l-wrap l-container--l u-background-accent--right u-background-accent--right-less-offset c-product-intro-section">
      <div className="l-flex l-flex-gap--40">
        <div className="l-flex-item l-flex-item--60 l-flex-item--swap-mobile-order-2">
          <div class="u-spacing--and-half">
            <p>
              Yext is on a mission to put more truth online. They do that by
              helping businesses around the world deliver official answers to
              customer questions across the entire search ecosystem—starting
              with a brand’s website, then extending across search engines,
              voice assistants, maps, and more than 175 global platforms.
            </p>
            <p>
              As Yext continues to break into new international markets,
              localizing marketing and product materials are increasingly
              critical components to scaling the business globally.
            </p>

            <h2 class="u-font--l u-text-color--purple">Challenge</h2>
            <p>
              Before partnering with Smartling, Yext’s translation process was
              highly manual and difficult to scale for the translation volume
              they needed. In addition, they wanted to create an entire
              multilingual experience for customers, which meant that they
              needed to translate now only their website and product, but also
              sales decks, marketing collateral, white papers, and more. As they
              launched their product in more countries, this manual process
              would quickly become untenable.
            </p>

            <h2 class="u-font--l u-text-color--purple">Solution</h2>
            <p>
              After adopting Smartling, Yext focused on establishing a robust
              and automated process so that they could scale. Yext connected
              their tech stack to Smartling’s translation management system
              through pre-built WordPress and code repository connectors. This
              approach allowed them to greatly increase in volume and expansion
              into new languages and enabled the entire company, both existing
              and new teams, to access Smartling's cloud TMS and language
              services with minimal setup and effort.
            </p>

            <p>
              The results from Yext’s launch in Japan demonstrated how much the
              technology and collaboration impacted their outcomes. Over the
              course of the project, the correction rate for Japanese strings
              dropped to just 0.17%, an 87% decrease in words that had to be
              edited. Yext was also able to benefit from a comprehensive
              translation memory, accumulated over time, which contributed to a
              25% decrease in their effective cost per word, despite a sixfold
              increase in the number of supported languages.
            </p>

            <p>
              On top of all of the technology that automates the process
              surrounding human translation, it was the relationship facilitated
              by Smarlting’s customer engagement and translation services team
              that made it possible for Yext to translate the sheer volume of
              content into so many languages possible.
            </p>
          </div>

          <div class="u-space--top u-space--bottom">
            <p>
              <strong>
                Want to hear more about Yext’s journey with Smartling? Watch
                From Zero to Localization Hero webinar below or on{' '}
                <a href="https://vimeo.com/488629197">Vimeo</a>
              </strong>
            </p>
          </div>

          <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe
              src="https://player.vimeo.com/video/488629197?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style="position:absolute;top:0;left:0;width:100%;height:100%;margin:0;"
              title="How to Translate Yext Location Data | Smartling 101"
            ></iframe>
          </div>
        </div>
        <div className="l-flex-item l-flex-item--40 l-flex-item--swap-mobile-order-1 u-padding--top">
          <blockquote>
            <p>
              At the end of the day, Smartling’s technology is incredibly
              reliable, and the customer experience is fantastic.
            </p>
            <p>
              <strong>Jessica Birenz</strong>
              <br />
              Yext, Director of Planning and Project Management
            </p>
            <img loading="lazy" src={imageYextLogo} alt="Yext Logo" />
          </blockquote>
        </div>
      </div>
    </section>
  </article>
)

export default YextContent
