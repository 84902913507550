import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionCustomerSuccess = () => (
  <SectionCta title="Your goals. Our priority." centerCTA>
    <SectionCta.Description>
      Best practices. Digital transformation. Strategy alignment. A dedicated
      Customer Success and Account Manager are focused on achieving your
      business objectives.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/products/manage/onboarding-enablement-services">
        Learn More
      </Button>
      <Button outline url="/excellence-every-touchpoint">
        Excellence at Every Touchpoint
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionCustomerSuccess
