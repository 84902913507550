import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/google-drive.jpg'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import googledriveSupportedFiles from '../../../images/integrations/google-drive-supported-files.png'
import googledriveInfographic from '../../../images/integrations/googledrive-infographic.png'
import photoNao from '../../../images/Nao_1.png'
import { Link } from 'gatsby'
var imgStyle = {
  maxWidth: '500px',
}

class MindtouchIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Google Drive Integration - Translate with Smartling"
          description="Smartling's Google Drive connector allows customers to access their Google Drive files within the platform and automatically authorize them for translation."
          keywords="Smartling, translation management, Google Drive, Document Translation"
          image={heroImage}
          alt="Google Drive Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Smartling for Google Drive
            "
          >
            <Hero.Description>
              Access your Google Drive files from Smartling and automatically
              authorize them for translation.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Google Drive logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a meeting</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--s c-product-intro-section">
              <div class="u-spacing--and-half u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  The easiest way to translate Google Drive files
                </h3>
                <p>
                  With Smartling’s Google Drive connector, you can easily access
                  source files saved in your Drive directly from the Smartling
                  platform and automatically authorize them for translation.
                </p>
                <p>
                  Once complete, translations are delivered straight back to
                  Google Drive into the folder of your choice.
                </p>
                <p>
                  It’s great for any team that manages their content in Google
                  Drive, no matter what file types you work with. Ready to enjoy
                  fast, quality translations using professional and/or machine
                  translation?
                </p>
                <Button url="/meeting-request/">Book a Meeting</Button>
              </div>
            </section>

            <Section
              gradientSecondary
              className="c-section__grid-blocks c-grid-blocks u-padding--section"
            >
              <Section.Header>
                <img
                  loading="lazy"
                  src={googledriveInfographic}
                  alt="Improved workflow with Smartling and Google Drive"
                />
              </Section.Header>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="No More Manual Work"></Block>
              </Section.Header>
              <Section.Inner className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className="u-text-align--center u-spacing">
                    <p>
                      Smartling simplifies workflows through automation,
                      enabling content creators to easily create, translate,
                      publish, and manage their Google Drive folders and content
                      in one place.
                    </p>
                    <p>
                      Smartling’s Google Drive integration supports a wide range
                      of file types, so you don’t have to spend time converting
                      them.
                    </p>
                    <p>
                      Gone are the days of copy, paste, import, export, and
                      countless back and forth over email!
                    </p>
                    <img
                      loading="lazy"
                      src={googledriveSupportedFiles}
                      alt="Support files types"
                    />
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section className="l-wrap l-container--m ">
              <Section.Inner className="u-spacing--half u-text-align--center">
                <h2>What’s In It For You?</h2>
                <p>
                  Smartling directly connects with Google Drive, providing a
                  fully automated stream of source content and translations.
                </p>
                <p>
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Countless hours of manual work saved
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Higher quality translation
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Faster time to market
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Satisfied customers
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Greater ROI
                  <br />
                </p>
                <Button className="u-spacing-" url="/meeting-request/">
                  Book a meeting
                </Button>
              </Section.Inner>
            </Section>

            <Section id="google-drive-video" className="l-wrap l-container--l">
              <Section.Inner m>
                <div style="padding:56.25% 0 0 0;position:relative;">
                  <iframe
                    src="https://player.vimeo.com/video/764691128?h=7d7f647669&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;margin:0;"
                    title="Getting Started with Smartling&amp;#039;s Google Drive Integration"
                  ></iframe>
                </div>
              </Section.Inner>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Your One Source of Truth"></Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className="u-text-align--center u-spacing">
                    <p>
                      Smartling enables you to capture and codify brand
                      preferences to improve translation quality outcomes
                      programmatically.
                    </p>
                    <p>
                      Our AI-powered toolkit automatically generates brand
                      terminology, and you can provide guidance on content style
                      preferences, customize workflows, and configure
                      translation memory however you wish.
                    </p>
                    <h4>Translate by the file or folder</h4>
                    <p>
                      When requesting a translation job, you can conveniently
                      select one file at a time or an entire folder to be
                      translated and customize which files to sync into
                      Smartling.
                    </p>
                    <h4>
                      ↓<br />
                      Track job status
                    </h4>
                    <p>
                      Whether you translate a file or an entire folder, you can
                      track the job status on an individual page level within
                      the Smartling platform.
                    </p>
                    <h4>
                      ↓<br />
                      Automate your workflow
                    </h4>
                    <p>
                      Schedule a translation job at the time and frequency of
                      your choice.
                    </p>
                  </div>
                </div>
              </Section.Inner>
            </Section>
            <div class="o-section--inner l-wrap l-container--l ">
              <Grid twoUp>
                <Grid.Item className="u-spacing--half">
                  <h2>Smartling Language Services</h2>
                  <p>
                    Smartling Language Services customers experience faster
                    times to market, lower costs, and guaranteed quality.
                  </p>
                  <p>
                    When you combine Smartling’s translation platform features
                    with professional translators, you get:
                  </p>
                  <ul
                    className="u-spacing--quarter"
                    style={{ listStyleType: 'disc', marginLeft: '1rem' }}
                  >
                    <li>Reduced time to market by up to 90%</li>
                    <li>Reduced translation costs by up to 60%</li>
                    <li>Complete transparency of the entire process</li>
                    <li>
                      Fast issue resolution via direct communication with
                      linguists
                    </li>
                    <li>Low or no vendor management</li>
                    <li>
                      Expertise in Smartling platform features, CAT tool, and
                      integrations{' '}
                    </li>
                    <li>100% quality guarantee</li>
                  </ul>
                  <div>
                    <Link
                      className="o-link u-color--primary"
                      href="/translation-services/"
                    >
                      Learn More About Smartling Language Services
                    </Link>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <img
                    loading="lazy"
                    src={photoNao}
                    alt="many different language names displayed in different colors"
                  />
                </Grid.Item>
              </Grid>
            </div>
            <section class="o-section--inner l-wrap l-container--xs-s u-text-align--center u-spacing">
              <img
                loading="lazy"
                src={imagePuzzle}
                alt="Puzzle Pieces"
                style={{
                  maxWidth: '120px',
                  border: '7px solid #56189e',
                  borderRadius: '50%',
                }}
              />
              <h3 class="o-section-title o-section-title">
                Ready to Integrate Google Drive with Smartling?
              </h3>
              <p>
                If you’d like to learn more about the integration with Google
                Drive or try it out for yourself, reach out to us today!
              </p>

              <Button url="/meeting-request/">Book a meeting</Button>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default MindtouchIntegrationPage
