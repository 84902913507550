import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Layout from 'layouts'
import AutomateDiagram from 'atoms/automate-diagram'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

// Images
import heroImage from '../images/badge-rosettes.svg'
import heroSm from '../images/newlady-at-computer--s.jpg'
import heroMd from '../images/newlady-at-computer--m.jpg'
import heroLg from '../images/newlady-at-computer--l.jpg'
import sectionImage2 from '../images/translators-rockstar.jpg'
import visualContext from '../images/homepage-svgs_visual-context-white-bg.svg'
import localization from '../images/homepage-svgs_localization-maturity-model.svg'
import customerSuccess from '../images/icons/casestudy-adroll-laptop.svg'
import itsTrue from '../images/its-true-csa-graphic.png'
import translationBlind from '../images/Smartling_TranslationIsntBlind_Logo_RGB_Color.svg'
import twbLogos from '../images/twb-Logos.png'
import developerAPI from '../images/icons/icon-pack_code-white.svg'
import transProxy from '../images/icons/icon-pack-translation-proxy-white.svg'
import integrations from '../images/icons/icon-pack-access-white.svg'

/*
 * This is the homepage of the site - behind the scenes, Gatsby will render this
 * component inside the layouts/index.js layout to create the static HTML content
 * for the homepage. By convention, Gatsby uses the default export from this file
 * as the component to render, and also accepts a `query` export to load data.
 */

class IndexBakPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="l-home">
          <Metadata
            title="Language Translation and Content Localization Solutions - Smartling"
            description="Smartling connects your brand with customers around the globe. Translate, automate and manage your content across platforms with real-time analytics and reporting."
            keywords=""
          />

          <div className="l-home-hero-wrap">
            <Hero
              curveLeft
              gradientPrimary
              largeLandingImage
              center
              className="l-home-hero"
              title={
                <span>
                  <strong>Move the World</strong> with Words
                </span>
              }
            >
              <Hero.Description>
                <div className="u-spacing--half">
                  <div className="c-hero__description">
                    Translate the words on your website, mobile app, product,
                    and more into any language.
                  </div>
                </div>
                <div className="c-hero__button-container">
                  <a href="/meeting-request/" class="o-button o-button--fancy">
                    Get a Demo
                  </a>
                </div>
                <div className="l-home-hero__ctas">
                  <div class="c-hero__cta-text">
                    <a
                      href="/translators"
                      class="o-cta-arrow o-cta-arrow--white"
                    >
                      <strong>Meet your translators</strong>
                    </a>
                  </div>
                  <div class="c-hero__cta-text">
                    <a href="/software" class="o-cta-arrow o-cta-arrow--white">
                      <strong>Discover new technology</strong>
                    </a>
                  </div>
                  <div className="l-home-hero__badges">
                    <img
                      loading="lazy"
                      className="lazyload"
                      data-src={heroImage}
                      alt="Cards"
                    />
                  </div>
                </div>
              </Hero.Description>
              <Hero.Image />
            </Hero>
            <div className="l-home-hero__bottom">
              <div className="l-home-hero__bottom-image">
                <picture class="js-box">
                  <source srcset={heroLg} media="(min-width: 1100px)" />
                  <source srcset={heroMd} media="(min-width: 570px)" />
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={heroSm}
                    alt="Meet your translators"
                  />
                </picture>
              </div>
            </div>
          </div>

          <article className="l-article u-spacing--sections c-home-sections">
            <div className="c-logo-strip"></div>
            <Section centerCTA className="c-section__left c-how-works">
              <Section.Header>
                <Block title="How Smartling Works">
                  <Block.Image />
                  Deploy your multilingual content 50% faster.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="c-how-works__content u-spacing">
                  <div className="c-how-works__image">
                    <AutomateDiagram />
                  </div>
                  <div className="c-how-works__ctas">
                    <div class="c-hero__cta-text">
                      <a href="/software" class="o-cta-arrow">
                        <strong>Explore Technology</strong>
                      </a>
                    </div>
                    <div class="c-hero__cta-text">
                      <a href="/products/translate" class="o-cta-arrow">
                        <strong>Explore Translation Services</strong>
                      </a>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <div className="u-spacing c-g2-market-leader" id="g2" name="g2">
              <Section>
                <Section.Header>
                  <Block title="#1 Market Leader by Customers on G2" />
                </Section.Header>
              </Section>
              <div className="o-section--inner l-wrap l-container--m">
                <div className="o-cta-group">
                  <div class="o-cta-group__cta-text">
                    <a href="/resources/case-study" class="o-cta-arrow">
                      <strong>Success Stories</strong>
                    </a>
                  </div>
                  <div class="o-cta-group__cta-text">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.g2.com/products/smartling/reviews?focus=&order=most_helpful"
                      class="o-cta-arrow"
                    >
                      <strong>Read Reviews on G2</strong>
                    </a>
                  </div>
                  <div class="o-cta-group__cta-text">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.g2.com/gated_content/tokens/9dc22982-c5f2-4425-b39c-d2734045a1bf"
                      class="o-cta-arrow"
                    >
                      <strong>View the Full Report</strong>
                    </a>
                  </div>
                </div>
                <div className="o-iframe-grid">
                  <iframe
                    title="G2 Grid"
                    src="https://www.g2.com/categories/translation-management/grids.embed?wid=1554838455"
                    width="550px"
                    height="550px"
                    frameborder="0"
                    scrolling="no"
                  />
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.g2.com/categories/translation-management"
                  >
                    G2 Grid<sup>®</sup> for Translation Management Software
                  </a>
                </div>
              </div>
            </div>

            <Section centerCTA className="c-section__grid-blocks c-grid-blocks">
              <Section.Header>
                <Block title="The world moves on words.">
                  <Block.Image />
                  Translating 150+ languages across 50+ specialized industries,
                  we’re the one-stop solution for any translation you need. What
                  type of content are you translating?
                </Block>
              </Section.Header>
              <Section.Inner l>
                <Grid threeUp className="has-icon-bulbs">
                  <Grid.Item>
                    <Block
                      title="Websites"
                      link="/software/"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconComputer />
                      </Block.Image>
                      Your website is your business. Be open: in any market, on
                      any platform, and wherever your customers find you.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block
                      title="Mobile Apps"
                      link="/software/"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconSmartphone />
                      </Block.Image>
                      When the world downloads your mobile app, be confident
                      that it’s global-ready.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block
                      title="Web Apps"
                      link="/software/"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconComputerApps />
                      </Block.Image>
                      Web Apps that are internationalized - and those that
                      aren't - can be translated with minimal effort.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block
                      title="Marketing Emails"
                      link="/software/integrations#marketing-automation"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconAtSymbol />
                      </Block.Image>
                      Localize emails, landing pages and forms with ease. Get
                      the best translation for your customer communication.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block
                      title="Product Documentation"
                      link="/software/"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconPostIt />
                      </Block.Image>
                      Expert and efficient translation to help your global users
                      self-serve and reduce support requests.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block
                      title="Video Subtitles"
                      link="/products/automate/integrations#application-support"
                      linkText="Learn More"
                    >
                      <Block.Image>
                        <Icons.IconChatQuote />
                      </Block.Image>
                      Bring culture, language, and creativity together quickly
                      and with precision.
                    </Block>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>

            <Section
              centerCTA
              className="c-section__right c-integrate-platform"
            >
              <Section.Header>
                <Block title="Integrate Any Platform">
                  <Block.Image />
                  Your content. Translated Faster.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="c-integrate-platform__content">
                  <div className="l-grid l-grid--3up">
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="l-grid__item-image">
                        <img
                          loading="lazy"
                          className="lazyload"
                          data-src={developerAPI}
                          alt="Developer API"
                        />
                      </div>
                      <h3 className="u-color--primary">Developer API</h3>
                      <a href="/software/api/" class="o-link">
                        Learn More
                      </a>
                    </div>
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="l-grid__item-image">
                        <img
                          loading="lazy"
                          className="lazyload"
                          data-src={transProxy}
                          alt="Translation Proxy"
                        />
                      </div>
                      <h3 className="u-color--primary">Translation Proxy</h3>
                      <a
                        href="/software/global-delivery-network/"
                        class="o-link"
                      >
                        Learn More
                      </a>
                    </div>
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="l-grid__item-image">
                        <img
                          loading="lazy"
                          className="lazyload"
                          data-src={integrations}
                          alt="Integrations"
                        />
                      </div>
                      <h3 className="u-color--primary">Integrations</h3>
                      <a href="/software/integrations/" class="o-link">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <div className="c-meet-translators">
              <SectionCta title="Rockstar. Translator." centerCTA>
                <SectionCta.Description>
                  <div className="u-spacing">
                    <p className="u-font--m">
                      Create electric content with Flavio, a translator by day
                      and rockstar by night.
                    </p>
                  </div>
                  <div className="o-cta-group">
                    <div class="o-cta-group__cta-text">
                      <a
                        href="/translators"
                        class="o-cta-arrow o-cta-arrow--white"
                      >
                        <strong>Meet our translators</strong>
                      </a>
                    </div>
                    <div class="o-cta-group__cta-text">
                      <a
                        href="/translation-services"
                        class="o-cta-arrow o-cta-arrow--white"
                      >
                        <strong>100% translation quality guarantee</strong>
                      </a>
                    </div>
                  </div>
                </SectionCta.Description>
                <SectionCta.ImageLarge>
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={sectionImage2}
                    alt="Rockstar. Translator."
                  />
                </SectionCta.ImageLarge>
                <SectionCta.Buttons />
              </SectionCta>
            </div>

            <div className="l-grid l-grid--2up c-collection c-collection--home">
              <div className="c-collection__item">
                <h3>Visual Context</h3>
                <p>Improve translation quality 33%.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a href="/software/CAT-tool/" class="o-cta-arrow">
                      <strong>Learn More</strong>
                    </a>
                  </div>
                  <div class="c-collection__cta-text">
                    <a href="/meeting-request/" class="o-cta-arrow">
                      <strong>Demo Smartling</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image">
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={visualContext}
                    alt=""
                  />
                </div>
              </div>

              <div className="c-collection__item">
                <h3>It's true.</h3>
                <p>CSA Research ranked us #1</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a
                      href="/resources/101/csa-research-marketflex-for-language-oriented-tms/"
                      class="o-cta-arrow"
                    >
                      <strong>Download the Report</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image c-collection__item-image--lg">
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={itsTrue}
                    alt=""
                  />
                </div>
              </div>

              <div className="c-collection__item c-collection__item--with-bg c-collection__item--book">
                <h3>
                  Move the World <span>with Words</span>
                </h3>
                <p>A transformative story about translators.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a href="/book" class="o-cta-arrow o-cta-arrow--white">
                      <strong>Purchase the Book</strong>
                    </a>
                  </div>
                </div>
              </div>

              <div className="c-collection__item c-collection__item--with-bg c-collection__item--dark-bg c-collection__item--globalready">
                <h3>Translation Isn't Blind</h3>
                <p>The webinar series bringing translation into focus.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a
                      href="/globalready"
                      class="o-cta-arrow o-cta-arrow--white"
                    >
                      <strong>Learn More</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image">
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={translationBlind}
                    alt=""
                  />
                </div>
              </div>

              <div className="c-collection__item">
                <h3>Localization 101</h3>
                <p>Thought leadership, articles, eBooks and more.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a href="/resources" class="o-cta-arrow">
                      <strong>Explore Our Content</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image">
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={localization}
                    alt=""
                  />
                </div>
              </div>

              <div className="c-collection__item c-collection__item--with-bg c-collection__item--purple-bg">
                <h3>Customer Success</h3>
                <p>Hundreds of brands trust Smartling.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a
                      href="/resources/case-study/"
                      class="o-cta-arrow o-cta-arrow--white"
                    >
                      <strong>Hear Their Stories</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image">
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={customerSuccess}
                    alt=""
                  />
                </div>
              </div>

              <div className="c-collection__item c-collection__item--with-bg c-collection__item--cloud">
                <h3>Smartling Translation Cloud</h3>
                <p>
                  With 20 deployments a day, it’s no wonder we’re voted #1 in
                  our space.
                </p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a
                      href="/why-smartling"
                      class="o-cta-arrow o-cta-arrow--white"
                    >
                      <strong>Discover Our Solution</strong>
                    </a>
                  </div>
                </div>
              </div>

              <div className="c-collection__item c-collection__item--with-bg c-collection__item--our-story">
                <h3>Smartling for Translators Without Borders</h3>
                <p>Dollar for dollar gifting to get through difficult times.</p>
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://translatorswithoutborders.z2systems.com/np/clients/translatorswithoutborders/donation.jsp?campaign=51"
                      class="o-cta-arrow o-cta-arrow--white"
                    >
                      <strong>Learn More</strong>
                    </a>
                  </div>
                </div>
                <div className="c-collection__item-image c-collection__item-image--sm">
                  <img
                    loading="lazy"
                    className="lazyload u-space--double--top"
                    data-src={twbLogos}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <SectionCta
              title="Anywhere, Today"
              centerCTA
              className="c-anywhere-video"
            >
              <SectionCta.Description>
                Pick a playlist. Cars on demand. Book a flight. Swipe left - or
                right.
              </SectionCta.Description>
              <SectionCta.Video>
                <iframe
                  title="Video"
                  src="https://player.vimeo.com/video/260429276?title=0&byline=0&portrait=0"
                  frameborder="0"
                  width="100%"
                  height="auto"
                />
              </SectionCta.Video>
            </SectionCta>

            <SectionCta
              centerCTA
              className="c-ready-to-start"
              title="Ready to get started?"
            >
              <SectionCta.Description>
                The best way to understand our product is to see it for
                yourself. Request a demo or reach out to chat.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="/meeting-request/">Request Demo</Button>
              </SectionCta.Buttons>
            </SectionCta>
          </article>
        </div>
      </Layout>
    )
  }
}

export default IndexBakPage
