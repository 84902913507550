import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'
import Section from 'atoms/section'
import { oneByType } from '../util/react'

const SectionCta = ({
  title,
  gradientPrimary,
  gradientSecondary,
  gradientTertiary,
  curveTop,
  curveTopLeft,
  curveTopRight,
  curveBottom,
  centerCTA,
  reverseCTA,
  curveBottomCenter,
  children,
  checkmark,
  className,
  backgroundImage,
  backgroundPosition,
  id,
}) => {
  const description = oneByType(children, SectionCta.Description)
  const subtext = oneByType(children, SectionCta.Subtext)
  const body = oneByType(children, SectionCta.Body)
  const buttons = oneByType(children, SectionCta.Buttons)
  const video = oneByType(children, SectionCta.Video)
  const image = oneByType(children, SectionCta.Image)
  const colImage = oneByType(children, SectionCta.ColImage)
  const imageLarge = oneByType(children, SectionCta.ImageLarge)

  const classes = cx({
    'u-gradient--primary': gradientPrimary,
    'u-gradient--secondary': gradientSecondary,
    'u-gradient--tertiary': gradientTertiary,
    'u-curve--top': curveTop,
    'u-curve--top--left': curveTopLeft,
    'u-curve--top--right': curveTopRight,
    'u-curve--bottom': curveBottom,
    'u-curve--bottom--center': curveBottomCenter,
    'c-cta--center': centerCTA,
    'c-cta--reverse': reverseCTA,
    'u-checkmark': checkmark,
    'c-cta--cols': colImage,
  })

  return (
    <PatternSectionCta
      className={className}
      classes={classes}
      title={title}
      description={description && description.props.children}
      subtext={subtext && subtext.props.children}
      body={body && body.props.children}
      buttons={buttons && buttons.props.children}
      video={video && video.props.children}
      image={image && image.props.children}
      colImage={colImage && colImage.props.children}
      imageLarge={imageLarge && imageLarge.props.children}
      backgroundImage={backgroundImage}
      backgroundPosition={backgroundPosition}
      id={id}
    />
  )
}

SectionCta.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  gradientPrimary: PropTypes.bool,
  gradientSecondary: PropTypes.bool,
  curveTopLeft: PropTypes.bool,
  curveTop: PropTypes.bool,
  curveBottom: PropTypes.bool,
  centerCTA: PropTypes.bool,
  reverseCTA: PropTypes.bool,
  curveBottomCenter: PropTypes.bool,
  checkmark: PropTypes.bool,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  id: PropTypes.string,
}

export default SectionCta

SectionCta.Image = ({ children }) => <React.Fragment />

SectionCta.ColImage = ({ children }) => <React.Fragment />

SectionCta.ImageLarge = ({ children }) => <React.Fragment />

SectionCta.Video = ({ children }) => <React.Fragment />

SectionCta.Description = ({ children }) => <React.Fragment />

SectionCta.Subtext = ({ children }) => <React.Fragment />

SectionCta.Body = ({ children }) => <React.Fragment />

SectionCta.Buttons = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternSectionCta = ({
  className,
  classes,
  title,
  image,
  colImage,
  imageLarge,
  description,
  subtext,
  body,
  buttons,
  video,
  backgroundImage,
  backgroundPosition,
  id,
}) => {
  if (backgroundImage) {
    var style = {}
    if (backgroundPosition) {
      style.backgroundPosition = backgroundPosition
    }
    var backgroundClassName = 'has-background-image u-overlay'
  }

  return (
    <Section
      style={style}
      databgset={backgroundImage ? backgroundImage : ''}
      className={`c-section__cta c-cta ${classes} ${className} ${backgroundClassName} ${
        backgroundImage ? 'lazyload' : ''
      }`}
      id={id}
    >
      <Section.Inner l>
        <div className="c-cta__content u-spacing">
          {image && <div className="c-cta__image">{image}</div>}
          {title && (
            <h2
              className="c-cta__title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {description && (
            <div className="c-cta__description">{description}</div>
          )}
          {subtext && <div className="c-cta__subtext">{subtext}</div>}
        </div>
        {imageLarge && (
          <div className="c-cta__image--large u-space--double--top l-wrap l-container--l">
            {imageLarge}
          </div>
        )}
        {video && <div className="c-cta__video">{video}</div>}
        {body && (
          <div className="c-cta__body l-article__body l-wrap l-container--m u-space--double--top">
            <div className="o-text u-spacing u-text-align--left">{body}</div>
          </div>
        )}
        {buttons && <div className="c-cta__buttons">{buttons}</div>}
        {colImage && <div className="c-cta__image-right">{colImage}</div>}
      </Section.Inner>
    </Section>
  )
}

PatternSectionCta.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  subtext: PropTypes.string,
  body: PropTypes.string,
  buttons: PropTypes.children,
  video: PropTypes.children,
  image: PropTypes.node,
  colImage: PropTypes.node,
  imageLarge: PropTypes.node,
  id: PropTypes.string,
}
