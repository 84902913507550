import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionLogoBanner from 'organisms/logo-banner'
import SectionTwoColumn from 'organisms/two-column'

import Layout from 'layouts'

// Images
import metaImage from '../images/smartling-logo.png'
import heroImage from '../images/illustrations/devices-03.svg'
import caseStudyImage1 from '../images/illustrations/case-study-hootsuite.png'
import caseStudyLogo1 from '../images/logo-hootsuite.png'
import caseStudyImage2 from '../images/illustrations/case-study-adroll.png'
import caseStudyLogo2 from '../images/logo-adroll.png'

class B2bPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    return (
      <Layout>
        <div className="l-page--b2b">
          <Metadata
            title="B2B Translation - Smartling"
            description="Smartling enables businesses to effectively translate their business experience to professionals worldwide."
            keywords=""
            image={metaImage}
          />
          <Hero
            curveLeft
            gradientPrimary
            largeLandingImage
            title="👋 B2B SaaS, say hello to Cloud Translation."
          >
            <Hero.Description>
              Smartling enables businesses to effectively translate their
              marketing and product experience to professionals worldwide.
            </Hero.Description>
            <Hero.Image>
              <img loading="lazy" src={heroImage} alt="B2B Translation" />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="#b2bdemo">Get a Demo</Button>
              <Button
                url="/resources/101/global-ready-success-kit-2019/"
                outline
              >
                Free Success Kit
              </Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <div className="c-intro-logos l-container--m">
              <SectionCta centerCTA>
                <SectionCta.Description>
                  <div className="o-text u-spacing">
                    <p className="u-text-align--left">
                      <strong>Smartling invented Cloud Translation</strong> to
                      address the increasing complexities that are inherent with
                      global content management. Today, hundreds of B2B SaaS
                      companies rely on Smartling Cloud Translation to convert
                      websites, complex web applications, marketing automation,
                      and product documentation from one language into any.
                    </p>
                  </div>
                </SectionCta.Description>
              </SectionCta>

              <SectionLogoBanner>
                {data.logoOrdering.logos.map((logo) => (
                  <SectionLogoBanner.Item
                    url={logo?.url}
                    src={logo.image?.file.url}
                    alt={logo.name}
                  />
                ))}
              </SectionLogoBanner>
            </div>

            <SectionCta
              centerCTA
              gradientTertiary
              curveTop
              title="How it works"
            >
              <SectionCta.Description>
                <div className="u-spacing u-text-align--left">
                  <p>
                    Smartling connects to your existing tools and content
                    infrastructure, enabling you to easily manage language
                    translation at scale. There are multiple integration types
                    that simplify this process, including:
                  </p>
                  <ul className="o-list--checked">
                    <li>
                      Pre-built{' '}
                      <a
                        href="/software/integrations/"
                        title="Smartling Integrations"
                      >
                        integrations
                      </a>{' '}
                      with popular Content Management Systems
                    </li>
                    <li>
                      An https{' '}
                      <a
                        href="/software/global-delivery-network/"
                        title="Smartling Web Proxy"
                      >
                        web proxy
                      </a>
                    </li>
                    <li>
                      Hundreds of{' '}
                      <a href="/software/api/" title="Smartling APIs">
                        API endpoints
                      </a>
                    </li>
                  </ul>
                  <p>
                    These integrations connect you to the most powerful Cloud
                    Translation Management System on the market. It’s the
                    simplest place to configure automation rules, establish
                    workflow preferences, maintain style guides, and more.
                  </p>
                </div>
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="#b2bdemo" center primary>
                  Get a Demo
                </Button>
                <Button
                  url="/resources/101/global-ready-success-kit-2019/"
                  outline
                >
                  Free Success Kit
                </Button>
              </SectionCta.Buttons>
            </SectionCta>

            <SectionCta
              centerCTA
              gradientPrimary
              curveTop
              curveBottomCenter
              title="Have a strategy"
            >
              <SectionCta.Description>
                Localizing content for worldwide markets introduces complex
                challenges. With "Localization: A Strategy for B-to-B Marketers"
                by SiriusDecisions, you can chart your path to success.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="/resources/101/localization-a-strategy-for-b-to-b-marketers/">
                  Access Free Report
                </Button>
              </SectionCta.Buttons>
            </SectionCta>

            <SectionTwoColumn title="Hootsuite">
              <SectionTwoColumn.Logo>
                <img loading="lazy" src={caseStudyLogo1} alt="Hootsuite Logo" />
              </SectionTwoColumn.Logo>
              <SectionTwoColumn.Description>
                <p>
                  See how the social media management company built a
                  sustainable translation process while growing its global user
                  base.
                </p>
              </SectionTwoColumn.Description>
              <SectionTwoColumn.Buttons>
                <Button
                  target="_blank"
                  url="https://info.smartling.com/hootsuite-case-study"
                >
                  View Case Study
                </Button>
              </SectionTwoColumn.Buttons>
              <SectionTwoColumn.Image>
                <img loading="lazy" src={caseStudyImage1} alt="IGH Website" />
              </SectionTwoColumn.Image>
            </SectionTwoColumn>

            <SectionCta
              gradientSecondary
              centerCTA
              curveTop
              curveBottom
              title="What you can do with Smartling"
            >
              <SectionCta.Description>
                <div className="u-spacing u-text-align--left">
                  <p>
                    Software companies enable people to be more efficient. It
                    has never been easier for software companies to easily grow
                    their marketing and product experience in any language.
                    Smartling’s robust network of{' '}
                    <a
                      href="/translation-services"
                      title="Smartling translation services"
                    >
                      professional translators
                    </a>{' '}
                    are versed in 50 business verticals. Our translators deliver
                    transformative content that gets your businesses&apos;
                    message across. What’s more? With Smartling, you have
                    unparalleled visibility into the entire translation process.
                  </p>
                  <ul className="o-list--checked">
                    <li>
                      Evaluate individual translator performance to manage
                      quality
                    </li>
                    <li>
                      Data-driven reports to manage on-time delivery and
                      translation quality
                    </li>
                    <li>Enterprise-grade SLAs with money back guarantees</li>
                  </ul>
                </div>
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="#b2bdemo">Get a Demo</Button>
                <Button
                  url="/resources/101/global-ready-success-kit-2019/"
                  outline
                >
                  Free Success Kit
                </Button>
              </SectionCta.Buttons>
            </SectionCta>

            <SectionCta centerCTA title="Data enables your efficiency">
              <SectionCta.Description>
                <div className="o-text u-spacing">
                  <p>
                    With every action in Smartling being tracked, you can depend
                    on real-time analytics to make decisions. There are a bevy
                    of reports that can be called up at any time to achieve
                    unparalleled visibility into your translation process. We
                    also{' '}
                    <a
                      href="/software/translation-analytics/"
                      title="Smartling's Quality Confidence Score"
                    >
                      built an algorithm
                    </a>{' '}
                    that identifies hundreds of drivers that impact translation
                    quality. We turned these into a single score that you can
                    measure, trust, and control.
                  </p>
                </div>
              </SectionCta.Description>
            </SectionCta>

            <SectionCta
              centerCTA
              gradientPrimary
              curveTop
              curveBottomCenter
              title="From one language into any, for the first time"
            >
              <SectionCta.Description>
                <div className="o-text u-spacing u-text-align--left u-color--white">
                  <p>
                    If you aren't translating already, the most important thing
                    is to get started now. Language translation is now table
                    stakes. 75% of buyers prefer to buy products in their native
                    language. Smartling simplifies the process of accessing
                    these consumers with a strong translation and localization
                    strategy.{' '}
                  </p>
                  <p>
                    It starts with a robust set of{' '}
                    <a href="/software/" title="Smarting - Automate">
                      technologies that enable you to easily integrate your
                      content repository with our translation management system
                    </a>
                    . You can scale into new markets without increasing the
                    investment in your existing infrastructure. The most obvious
                    benefit is that your engineers or marketing team can then
                    focus their time on product development and key marketing
                    activities. Leave the heavy lifting to our platform.
                  </p>
                </div>
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="#b2bdemo">Get a Demo</Button>
              </SectionCta.Buttons>
            </SectionCta>

            <Section>
              <Section.Header>
                <Block title="Why Smartling?">
                  <Block.Image />
                  Before Brian was Smartling's VP of Sales, he was a customer.
                  Learn why AdRoll bought Smartling (and{' '}
                  <a href="https://info.smartling.com/success-story-adroll?__hstc=131131536.06ae70b95bbb979e97be8644f57ac8f6.1546548493559.1546639103041.1546708105699.5&__hssc=131131536.1.1546708105699&__hsfp=3079700181">
                    check out the case study
                  </a>
                  ).
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="o-video">
                  <iframe
                    title="video 1"
                    src="https://player.vimeo.com/video/309642140?title=0&byline=0&portrait=0"
                    frameborder="0"
                    width="560px"
                    height="315px"
                    allow="autoplay;
                    encrypted-media"
                    allowfullscreen
                  />
                </div>
              </Section.Inner>
            </Section>

            <SectionCta
              centerCTA
              gradientSecondary
              curveTop
              curveBottomCenter
              title="Optimizing translation management has never been easier."
            >
              <SectionCta.Description>
                <div className="u-text-align--left u-spacing">
                  <p>
                    We firmly believe that the most valuable use of your time is
                    to focus on activities that have the highest impact on the
                    widest number of people, both internally and externally.
                    With time:
                  </p>
                  <ul className="o-list--checked">
                    <li>
                      Developers can focus on the roadmap - week over week,
                      month over month - to improve the end-user experience
                    </li>
                    <li>
                      Marketers (and localization managers!) can realize
                      creative projects, tweak messaging, organize events, and
                      reinforce your brand promise to drive pipeline value
                    </li>
                    <li>
                      Leaders can position teams for success. Every individual
                      has extraordinary talents, and how leaders work with their
                      people to maximize these talents can give companies a
                      competitive edge.
                    </li>
                  </ul>
                  <p>
                    Smartling saves you time. And{' '}
                    <a href="/why-smartling/" title="Smarting - Why Smartling">
                      time is priceless
                    </a>
                    .
                  </p>
                </div>
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="#b2bdemo">Get a Demo</Button>
                <Button
                  url="/resources/101/global-ready-success-kit-2019/"
                  outline
                >
                  Free Success Kit
                </Button>
              </SectionCta.Buttons>
            </SectionCta>

            <SectionTwoColumn flip title="Adroll">
              <SectionTwoColumn.Logo>
                <img loading="lazy" src={caseStudyLogo2} alt="Adroll Logo" />
              </SectionTwoColumn.Logo>
              <SectionTwoColumn.Description>
                <p>
                  AdRoll launched 7 languages within two weeks with Smartling's
                  Global Delivery Network and Managed Services.
                </p>
              </SectionTwoColumn.Description>
              <SectionTwoColumn.Buttons>
                <Button
                  target="_blank"
                  url="https://info.smartling.com/success-story-adroll"
                >
                  View Case Study
                </Button>
              </SectionTwoColumn.Buttons>
              <SectionTwoColumn.Image>
                <img
                  loading="lazy"
                  src={caseStudyImage2}
                  alt="Adroll Website"
                />
              </SectionTwoColumn.Image>
            </SectionTwoColumn>

            <div className="c-five-reasons">
              <SectionCta
                centerCTA
                gradientPrimary
                curveTop
                curveBottomCenter
                title="Five reasons why B2B SaaS customers have switched to Smartling"
              >
                <SectionCta.Description>
                  <div className="c-big-number-list u-spacing u-text-align--left">
                    <ol>
                      <li>
                        <div className="c-big-number">1</div>
                        <div className="c-number-content">
                          <h3>Scale</h3>
                          <p>Add languages with zero developer involvement</p>
                        </div>
                      </li>
                      <li>
                        <div className="c-big-number">2</div>
                        <div className="c-number-content">
                          <h3>Automate</h3>
                          <p>Replace human input with pre-configured rules</p>
                        </div>
                      </li>
                      <li>
                        <div className="c-big-number">3</div>
                        <div className="c-number-content">
                          <h3>Measure</h3>
                          <p>
                            Quantify translation quality results and make
                            improvements over time
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="c-big-number">4</div>
                        <div className="c-number-content">
                          <h3>Easy to implement</h3>
                          <p>
                            Launch multilingual websites, complex web apps, and
                            engaging customer experiences with minimal developer
                            involvement
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="c-big-number">5</div>
                        <div className="c-number-content">
                          <h3>Savings</h3>
                          <p>
                            Teams that invest in Smartling reduce their overall
                            translation spend by minimizing the number of people
                            managing translation, and by leveraging previously
                            translated content across devices and platforms
                          </p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </SectionCta.Description>
              </SectionCta>
            </div>

            <Section>
              <Section.Header>
                <Block title="Anywhere, Today">
                  <Block.Image />
                  <em>
                    Pick a playlist. Cars on demand. Book a flight. Swipe left -
                    or right.
                  </em>
                   We exist because our customers are the fastest growing, most
                  innovative brands of our time. Our value proposition hinges on
                  you being able to pre-configure, control, and adjust Smartling
                  so that translation just happens in the background while you
                  remain focused on building the best in class product in your
                  category.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="o-video">
                  <iframe
                    title="video 2"
                    src="https://player.vimeo.com/video/260429276?title=0&byline=0&portrait=0"
                    frameborder="0"
                    width="560px"
                    height="315px"
                    allow="autoplay;
                    encrypted-media"
                    allowfullscreen
                  />
                </div>
                <div class="l-container--xs u-space--double--top u-align--center">
                  <Button url="/resources/101/global-ready-success-kit-2019/">
                    Free Success Kit
                  </Button>
                </div>
              </Section.Inner>
            </Section>
          </article>
        </div>
        <script
          async
          charset="utf-8"
          type="text/javascript"
          src="//js.hsforms.net/forms/v2.js"
        />
      </Layout>
    )
  }
}

export default B2bPage

export const query = graphql`
  query B2bPageQuery {
    logoOrdering: contentfulLogoCarousel(title: { eq: "b2b-logos" }) {
      title
      logos: sliderItems {
        url
        name
        image {
          gatsbyImageData(
            formats: [WEBP, AUTO]
            placeholder: BLURRED
            width: 148
            height: 42
          )
          file {
            url
          }
        }
      }
    }
  }
`
