import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import EventBlockPost from 'molecules/eventblock-post'
import BlockPost from 'molecules/block-post'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'

import videoIcon from '../images/icons/icon_video-light-ring.png'
import globalReadyImage from '../images/globalready-image-2023.jpg'
import globalReadyConference from '../images/global-ready-conference.jpg'

const moment = require('../../static/vendor/moment.min.js')

class EventsPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    return (
      <Layout>
        <div className="u-spacing--sections">
          <Metadata
            title="Upcoming Events | Smartling"
            description="Join us virtually for online events. Access on-demand localization webinars, events and workshops."
            keywords="localization webinars, thought leadership, translation industry events"
          />
          {data.featured.edges &&
            data.featured.edges.map(
              ({ node }) =>
                node.featuredEvent && (
                  <Hero
                    centerCTA
                    disableShadow
                    className="c-event-hero c-hero__resource"
                    title={node.featuredEvent.name}
                    flip
                  >
                    <Hero.Kicker className="u-color--primary">
                      Featured Event
                    </Hero.Kicker>
                    <Hero.Description>
                      <span
                        className="o-event-date o-event-date--purple"
                        data-date={node.featuredEvent.startDate}
                      >
                        {node.featuredEvent.startDate &&
                          moment
                            .parseZone(node.featuredEvent.startDate)
                            .format('MMMM D, YYYY')}
                        {node.featuredEvent.eventLocation?.city &&
                          ` | ${node.featuredEvent.eventLocation.city}`}
                        {node.featuredEvent.eventLocation?.state &&
                          ` ${node.featuredEvent.eventLocation.state}`}
                      </span>
                      {node.featuredEvent.shortDescription && (
                        <span>{node.featuredEvent.shortDescription}</span>
                      )}
                    </Hero.Description>
                    <Hero.Buttons>
                      <Button url="https://www.smartling.com/globalready/webinars/the-importance-of-multilingual-email-marketing-and-localization/">
                        Learn More
                      </Button>
                    </Hero.Buttons>
                    {node.featuredEvent.heroImage && (
                      <Hero.Image>
                        <img
                          loading="lazy"
                          src={`${node.featuredEvent.heroImage.file.url}?fit=fill&w=750&h=420`}
                          alt={node.featuredEvent.heroImage.title}
                          className="c-gallery-block--review hover-up"
                        />
                      </Hero.Image>
                    )}
                  </Hero>
                )
            )}
          {data.events.edges && (
            <div id="events">
              <div className="c-section__block-rows c-block-rows">
                <section class="o-section c-section__hero c-hero c-hero--center u-gradient--secondary-top">
                  <div class="o-section--inner l-wrap l-container--xl">
                    <div class="c-hero--left">
                      <div class="c-hero__content u-spacing--double">
                        <h1 class="c-hero__title o-section-title--heavy">
                          Upcoming Events
                        </h1>
                        <p class="c-hero__description">
                          Stay informed of global trends, localization best
                          practices, product enablement and more! Join us
                          virtually for webinars, coaching events, workshops and
                          thought leadership panels.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <Section.Inner xl className="u-spacing--triple">
                  <Grid twoUp className="c-events">
                    {data.events.edges.map(({ node }) => (
                      <Grid.Item>
                        <EventBlockPost imageHeight={345} {...node} />
                      </Grid.Item>
                    ))}
                  </Grid>
                </Section.Inner>
              </div>
            </div>
          )}

          {/* <div id="globalreadyconference" className="c-gloabl-ready-section u-background-color--primary">
            <Section>
              <Section.Inner xl className="u-spacing--triple u-padding--section">
                <div className="l-grid l-grid--align-top l-grid--2up">
                  <div className="l-grid-item">
                    <a href="/conference">
                      <img src={globalReadyImage} alt="Global Ready Conference" />
                    </a>
                  </div>
                  <div className="l-grid-item">
                    <div className="c-cta__content u-spacing--double">
                      <h2 className="c-cta__title">
                        Smartling's annual Global Ready Conference will be <br/>
                        <span className="u-text-gradient--underline">streaming live</span> on June 14th.
                      </h2>
                      <a href="/conference" role="button" className="o-button o-button--white">Register Now</a>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>
          </div> */}

          {/* <div id="global-ready-text">
            <Section>
              <Section.Inner xl className="u-spacing--triple u-padding--section">
                <p class="c-cta__description">Global Ready Conference is an interactive, 100% virtual event designed to help you learn about the latest translation and localization innovations, meet our team and other industry experts, network with peers, and get tips for getting the most out of your localization investments and strategy.</p>
              </Section.Inner>
            </Section>
          </div> */}
          
          {/* <Section>
            <Section.Inner xl className="u-spacing--triple">
              <a href="/conference">
                <img src={globalReadyConference} alt="Global Ready Conference" />
              </a>
            </Section.Inner>
          </Section> */}

          {data.pastEvents.edges && (
            <div id="events">
              <Section>
                <Section.Inner xl className="u-spacing--triple">
                  <div className="l-grid__header">
                    <h2>Watch On Demand</h2>
                  </div>
                  <Grid threeUp className="c-events">
                    {data.pastEvents.edges.map(({ node }) => (
                      <Grid.Item>
                        <BlockPost {...node} />
                      </Grid.Item>
                    ))}
                    <Grid.Item>
                      <div
                        className="c-block c-block__space-between u-background-color--primary"
                        style={{
                          borderRadius: 15,
                          height: '100%',
                          padding: '70px 50px',
                        }}
                      >
                        <img
                          loading="lazy"
                          style={{ width: '70%' }}
                          src={videoIcon}
                          alt="Video"
                        />
                        <div className="c-block">
                          <h3 className=" u-color--white">Looking for more?</h3>
                          <div className="u-color--white">
                            Access on-demand webinars, events and workshops
                          </div>
                        </div>
                        <div className="c-hero__button-container">
                          <a
                            className="o-button o-button--white"
                            href="/resources/webinar/"
                            role="button"
                          >
                            View All
                          </a>
                        </div>
                      </div>
                    </Grid.Item>
                  </Grid>
                </Section.Inner>
              </Section>
            </div>
          )}

          {/* <article className="l-article u-space--zero--bottom">
            <section className="c-product-intro-section u-gradient--senery-alt u-padding--section">
              <div className="u-spacing--and-half l-container l-wrap">
                <div className="u-spacing--double">
                  <h3 className="c-block__title o-section-title o-section-title--heavy u-color--yellow--light">
                    2022 Global Ready Conference On-Demand
                  </h3>
                  <p className="c-cta__description">
                    Global Ready Conference is the flagship conference designed
                    by Smartling for anyone who touches the translation and
                    localization process. Attended by hundreds of global brands,
                    the event provides a deep dive into the future of the
                    translation industry as well as exciting and unique
                    sessions, expert thought leaders and speakers, live
                    entertainment and your playbook for globalization
                  </p>
                  <a name="o-pardot-form" class="u-block">
                    <div class="o-pardot-form">
                      <iframe
                        src="https://resource.smartling.com/l/703963/2022-04-07/2tf3ls"
                        id="pardotForm"
                        className="pardot-responsive"
                      ></iframe>
                    </div>
                  </a>
                </div>
              </div>
            </section>
          </article> */}
        </div>
      </Layout>
    )
  }
}

export default EventsPage

export const query = graphql`
  query EventsPageQuery {
    page: contentfulEventCategory(key: { eq: "global-ready-events" }) {
      name
      key
      description {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      metadataImage {
        ...ContentfulImageFragment
      }
      featuredEvent {
        name
        slug
        startDate
        eventLocation {
          city
          state
        }
        heroImage {
          ...ContentfulImageFragment
        }
        shortDescription
        buttonText
        buttonUrl
        event_category {
          key
          name
        }
      }
    }
    globalReadyEvents: allContentfulEventCategory(
      limit: 1
      filter: { key: { eq: "global-ready-events" } }
    ) {
      ...ContentfulEventCategoryFragment
    }
    featured: allContentfulEventCategory(
      limit: 1
      filter: { key: { eq: "global-ready-events" } }
    ) {
      ...ContentfulEventCategoryFragment
    }
    events: allContentfulEvent(
      limit: 999
      sort: { fields: [startDate, name], order: ASC }
      filter: {
        slug: { ne: "test-event" }
        removeFromMainListing: { ne: true }
        startDatePassed: { eq: false }
      }
    ) {
      ...ContentfulEventFragment
    }
    pastEvents: allContentfulResource(
      limit: 2
      sort: { fields: [publishDate, title], order: DESC }
      filter: { tags: { elemMatch: { key: { eq: "webinar" } } } }
    ) {
      ...ContentfulResourceFragment
    }
  }
`
