import React from 'react'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Grid from 'atoms/grid'
import * as Icons from 'atoms/icons'
import Block from 'molecules/block'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

// Images
import metaImage from '../images/grc-meta-image.png'

class OndemandPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="l-grc-ondemand">
          <Metadata
            title="Global Ready Conference On Demand - Smartling"
            description="Language Translation and Content Localization Solutions - Smartling"
            keywords=""
            image={metaImage}
          />
          <Hero
            gradientPrimary
            center
            title="Global Ready Conference On-Demand"
            className="c-hero-bg-ondemand"
          >
            <Hero.Description></Hero.Description>
            <Hero.Image />
          </Hero>
          <article className="l-article u-spacing--sections">
            <Section>
              <Section.Inner l>
                <div className="u-spacing u-text-align--center u-padding--double--top l-container l-wrap">
                  <h2 className="o-heading--xxl">
                    Watch all 2024 sessions now!
                  </h2>
                </div>
                <Grid
                  twoUp
                  className="u-padding--top c-integrate-platform u-align--top"
                >
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943396232?h=0c3048686a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="Leadership Keynote | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        Leadership keynote
                      </span>
                      <br />
                      <p>
                        Smartling’s CEO, Bryan Murphy kicks of Global Ready Conference with an insightful discussion around the impact of new AI technologies on the translation industry, plus delivers some exciting news about Smartling and where we're headed next!
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/944237410?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="The Power Behind the Proxy | Global Ready Conference 2022"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        Future proofing your career in the age of AI
                      </span>
                      <br />
                      <p>
                        In this keynote, award-winning author and former tech executive Minette Norman shares insights for taking ownership of our careers, building resilience, and embracing change.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943340126?h=adb225d4c2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="SAS and FedEx on why the time is now for (AI in) translation | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        SAS and FedEx on why the time is now for (AI in) translation
                      </span>
                      <br />
                      <p>
                        From breakthroughs in machine learning algorithms to the ethical implications of automated translation, join us as we discuss the opportunities and challenges of harnessing AI to bridge linguistic divides.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943355344?h=784f2b95fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="How Fresno Unified and Tompkins County drive accessibility by removing the linguistic divide"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        How Fresno Unified and Tompkins County drive accessibility by removing the linguistic divide
                      </span>
                      <br />
                      <p>
                        Learn how translation is a catalyst for fostering diversity and inclusion within a community. Our panelists dig into the ways language translation promotes understanding and empowers individuals from all backgrounds to fully participate in shared experiences, like going to school or living in the same city.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943387864?h=61ce852a59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="How Vimeo and Bluebeam are bridging the gap between old and new processes | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        How Vimeo and Bluebeam are bridging the gap between old and new processes
                      </span>
                      <br />
                      <p>
                        Learn how localization leaders from Vimeo and Bluebeam are transitioning their processes towards AI-powered human translation, what influences their mix of human and machine translation, and how leadership and budget constraints influence decisions.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/942022353?h=16e01a597c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="Budgeting for translation: Quality without compromise | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        Budgeting for translation: Quality without compromise
                      </span>
                      <br />
                      <p>
                        This session will address a variety of solutions and strategies that you can utilize to make your budget work for you to yield higher returns on your localization investments.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/942013112?h=7c38f4683c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="Research revealed: The impact of AI on translation | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        Research revealed: The impact of AI on translation
                      </span>
                      <br />
                      <p>
                        Learn research and discoveries Smartling's team is making about Large Language Models (LLMs) and Generative AI for translation and localization— how do they really shine for translation? We'll share insights about benchmarking for use cases such as fuzzy match repair, glossary insertion and effort estimation with foundational and fine tuned models.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943397107?h=96b475719f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="How do we scale up quality management in this AI world? | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        How do we scale up quality management in this AI world?
                      </span>
                      <br />
                      <p>
                        This panel discussion will explore the challenges companies face with the explosion of localized content, as well as building new frameworks around quality measurement and management, while examining the hurdles AI is already posing (such as new error types and predicting quality levels).
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943398022?h=78eeb587dc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="Volvo Cars and Middlebury Institute on disrupting the translation industry with AI | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        Volvo Cars and Middlebury Institute on disrupting the translation industry with AI
                      </span>
                      <br />
                      <p>
                        In this conversation, experts will analyze the current AI disruptions and offer insights on how to embrace these advancements in translation. This panel will speak objectively about the biggest challenges and the biggest wins we've seen from AI in translation, and what we hope to see in 2025, 2026, and beyond.
                      </p>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="l-grid__item u-spacing">
                    <div className="l-grid__item-video">
                      <div className="o-video">
                        <iframe
                          src="https://player.vimeo.com/video/943398081?h=9a7482227c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                          frameborder="0"
                          allow="autoplay; encrypted-media"
                          allowfullscreen
                          width="560px"
                          height="315px"
                          title="How Affirm scales localization despite macroeconomic constraints | 2024 Global Ready Conference by Smartling"
                        ></iframe>
                      </div>
                    </div>
                    <div>
                      <span className="o-heading--l u-color--primary">
                        How Affirm scales localization despite macroeconomic constraints
                      </span>
                      <br />
                      <p>
                        In this session we’ll learn how Affirm has scaled its localization program despite macroeconomic constraints and company restructuring. Hear their challenges around budgeting and bandwidth and strategies and tools they’ve leveraged along the way to keep Affirm’s expansion plan on schedule.
                      </p>
                    </div>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default OndemandPage
