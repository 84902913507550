import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionPlatinumSupport = () => (
  <SectionCta title="Managed Services: a white glove experience.">
    <SectionCta.Description>
      Vendor management. Personalized services. Ongoing training. With Managed
      Services, we take care of everything for you.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/contact-us">Learn More</Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionPlatinumSupport
