import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/salesforce-cc-hero.jpg'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import salesforceWorkflowImage from '../../../images/Salesforce-Workflow.svg'

import photoNao from '../../../images/Nao_1.png'
import { Link } from 'gatsby'
var imgStyle = {
  maxWidth: '500px',
}

class SalesforceCommerceCloudPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Salesforce Commerce Cloud Translation Integration"
          description=" Easily connect Smartling's Salesforce Commerce Cloud Connector to automate your content for translation."
          keywords="Smartling, translation management, Salesforce Commerce Cloud, Document Translation"
          image={heroImage}
          alt="Salesforce Commerce Cloud Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Smartling for Salesforce Commerce Cloud"
          >
            <Hero.Description>
              Automatically access your Salesforce Commerce Cloud files from
              Smartling and authorize them for translation. 
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Salesforce Commerce Cloud logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a meeting</Button>
              <Button url="#view-webinar">View Webinar</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--s c-product-intro-section">
              <div class="u-spacing--and-half u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  The easiest way to translate Salesforce Commerce Cloud files
                </h3>
                <p>
                  With Smartling’s Salesforce Commerce Cloud (SFCC) connector,
                  you can easily search and locate content you want to translate
                  in SFCC directly from Smartling and automatically authorize
                  them for translation.
                </p>
                <p>
                  Once complete, translations are automatically delivered back
                  to SFCC. 
                </p>
                <p>
                  It’s great for any team that manages their eCommerce content
                  in SFCC. Ready to enjoy fast, quality translations using
                  professional and/or machine translation?
                </p>
                <div
                  style="padding:56.25% 0 0 0;position:relative;"
                  id="view-webinar"
                >
                  <iframe
                    src="https://player.vimeo.com/video/788789226?h=5e0018e299&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    title="Smartling&amp;#039;s Salesforce Commerce Cloud Integration.mov"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <Button url="/meeting-request/">Book a Meeting</Button>
              </div>
            </section>

            <Section
              gradientSecondary
              className="c-section__grid-blocks c-grid-blocks u-padding--section"
            >
              <Section.Header>
                <img
                  loading="lazy"
                  src={salesforceWorkflowImage}
                  alt="Improved workflow with Smartling and Salesforce Cloud"
                />
              </Section.Header>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="No more manual work"></Block>
              </Section.Header>
              <Section.Inner className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className="u-text-align--center u-spacing">
                    <p>
                      Smartling simplifies workflows through automation,
                      enabling content creators to easily create, translate,
                      publish, and manage their SFCC files in one place.
                    </p>
                    <p>
                      Smartling’s SFCC integration supports the major file
                      types, so you don’t have to spend time converting them. 
                    </p>
                    <p>
                      Gone are the days of copy, paste, import, export, and
                      countless back and forth over email!
                    </p>
                  </div>
                </div>
              </Section.Inner>
            </Section>
            <Section centerCTA>
              <Section.Header>
                <h2>Supported content types</h2>
              </Section.Header>
              <Section.Inner s>
                <Grid twoUp className="l-grid--align-top">
                  <Grid.Item className="u-spacing">
                    <div className="o-list o-list--checked o-list--checked--center u-spacing--double">
                      <ul>
                        <li className="u-space--and-half--bottom">
                          <p className="u-color--primary u-font--m u-font-weight--strong u-text--line-height--tight">
                            Products
                          </p>
                        </li>
                        <li className="u-space--and-half--bottom">
                          <p className="u-color--primary u-font--m u-font-weight--strong u-text--line-height--tight">
                            Categories
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Grid.Item>
                  <Grid.Item className="u-spacing">
                    <div className="o-list o-list--checked ">
                      <ul>
                        <li className="u-space--and-half--bottom">
                          <p className="u-color--primary u-font--m u-font-weight--strong u-text--line-height--tight">
                            Variants
                          </p>
                        </li>
                        <li className="u-space--and-half--bottom">
                          <p className="u-color--primary u-font--m u-font-weight--strong u-text--line-height--tight">
                            Library Pages
                          </p>
                        </li>
                      </ul>
                    </div>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="l-wrap l-container--m ">
              <Section.Inner className="u-spacing--half u-text-align--center">
                <h2>What’s in it for you?</h2>
                <p>
                  Smartling directly connects with Salesforce Commerce Cloud,
                  providing a fully automated stream of source content and
                  translations.
                </p>
                <p>
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Countless hours of manual work saved
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Higher quality translation
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Faster time to market
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Satisfied customers
                  <br />
                  <span
                    role="img"
                    aria-label="checkmark"
                    className="o-heading--m u-color--primary"
                  >
                    ✓
                  </span>{' '}
                  Greater ROI
                  <br />
                </p>
                <Button className="u-spacing-" url="/meeting-request/">
                  Book a meeting
                </Button>
              </Section.Inner>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Your one source of truth"></Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className="u-text-align--center u-spacing">
                    <p>
                      Smartling enables you to capture and codify brand
                      preferences to improve translation quality outcomes
                      programmatically.
                    </p>
                    <p>
                      Our AI-powered toolkit automatically generates brand
                      terminology, and you can provide guidance on content style
                      preferences, customize workflows, and configure
                      translation memory however you wish. 
                    </p>
                    <h4>Translate Source Files</h4>
                    <p>
                      When requesting a translation job, you can conveniently
                      search and locate source files within Salesforce Commerce
                      Cloud to be translated and customize which files to sync
                      into Smartling. 
                    </p>
                    <h4>Automate your workflow</h4>
                    <p>
                      Schedule a translation job at the time and frequency of
                      your choice. 
                    </p>
                  </div>
                </div>
              </Section.Inner>
            </Section>
            <div class="o-section--inner l-wrap l-container--l ">
              <Grid twoUp>
                <Grid.Item className="u-spacing--half">
                  <h2>Smartling Language Services</h2>
                  <p>
                    Smartling Language Services customers experience faster
                    times to market, lower costs, and guaranteed quality.
                  </p>
                  <p>
                    When you combine Smartling’s translation platform features
                    with professional translators, you get:
                  </p>
                  <ul
                    className="u-spacing--quarter"
                    style={{ listStyleType: 'disc', marginLeft: '1rem' }}
                  >
                    <li>Reduced time to market by up to 90%</li>
                    <li>Reduced translation costs by up to 60%</li>
                    <li>Complete transparency of the entire process</li>
                    <li>
                      Fast issue resolution via direct communication with
                      linguists
                    </li>
                    <li>Low or no vendor management</li>
                    <li>
                      Expertise in Smartling platform features, CAT tool, and
                      integrations{' '}
                    </li>
                    <li>100% quality guarantee</li>
                  </ul>
                  <div>
                    <Link
                      className="o-link u-color--primary"
                      href="/translation-services/"
                    >
                      Learn More About Smartling Language Services
                    </Link>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <img
                    loading="lazy"
                    src={photoNao}
                    alt="many different language names displayed in different colors"
                  />
                </Grid.Item>
              </Grid>
            </div>
            <section class="o-section--inner l-wrap l-container--xs-s u-text-align--center u-spacing">
              <img
                loading="lazy"
                src={imagePuzzle}
                alt="Puzzle Pieces"
                style={{
                  maxWidth: '120px',
                  border: '7px solid #56189e',
                  borderRadius: '50%',
                }}
              />
              <h3 class="o-section-title o-section-title">
                Ready to integrate Salesforce Commerce Cloud with Smartling?
              </h3>
              <p>
                If you’d like to learn more about the integration with SFCC or
                try it out for yourself, reach out to us today!
              </p>

              <Button url="/meeting-request/">Book a meeting</Button>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default SalesforceCommerceCloudPage
