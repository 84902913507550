import React from 'react'
import PropTypes from '../prop-types'
import { oneByType, withoutTypes } from '../util/react'

const BlockRow = ({ title, link, linkText, children }) => {
  const image = oneByType(children, BlockRow.Image)
  const description = withoutTypes(children, BlockRow.Image)

  return (
    <PatternBlockRow
      title={title}
      description={description}
      image={image.props.children}
      link={{ url: link, text: linkText }}
    />
  )
}

BlockRow.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  children: PropTypes.children,
}

export default BlockRow

BlockRow.Image = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternBlockRow = ({ title, description, image, link }) => {
  return (
    <React.Fragment>
      <div className="c-block-row">
        {title && <h4 className="c-block-row__title">{title}</h4>}
        {description && (
          <div className="c-block-row__description u-spacing">
            {description}
            {link.url && (
              <a
                role="button"
                href={link.url}
                className="c-block-row__link o-link"
              >
                {link.text}
              </a>
            )}
          </div>
        )}
        {image && <div className="c-block-row__image">{image}</div>}
      </div>
    </React.Fragment>
  )
}

PatternBlockRow.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  image: PropTypes.node,
}
