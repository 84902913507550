import React from 'react'
import Metadata from '../../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Layout from 'layouts'

// Images
import heroImage from '../../images/transcreation-hero.png'
import imageAssets from '../../images/illustrations/illustration_linguistic-assets.png'
import iconSignpost from '../../images/icons/icon-signpost-cropped.svg'
import imageProduct from '../../images/transcreation-product-features.gif'
import imageDashboard from '../../images/transcreation-dashboard.png'
import imageChat from '../../images/transcreation-product-chat.gif'
import imageWhale from '../../images/transcreation-whale.png'
import imageCake from '../../images/transcreation-cake.gif'
import imageGabriela from '../../images/gabriela-circle.png'

class TranscreationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Smartling Transcreation Tool: What it is and how to use it | Smartling"
          description="The first of its kind, Smartling’s Transcreation Tool helps you take your standard translations to the next level by translating the meaning, style, and tone of the source text."
          keywords="Translation management system, Translation management, Translation platform"
          image={heroImage}
        />
        <div className="c-page-manage">
          <Hero
            curveLeftReverse
            gradientPrimary
            title="Smartling Transcreation Tool"
          >
            <Hero.Description>
              <div className="u-spacing--and-half">
                <div className="c-hero__cta-text">
                  Say hello to a new way to do transcreation. Smartling’s new
                  Transcreation Tool gives you a single touchpoint to manage
                  every aspect of the process without the need for spreadsheets.
                </div>
              </div>
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                src={heroImage}
                alt="Whale Hellooo There! Poster"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Get a Demo</Button>
            </Hero.Buttons>
          </Hero>

          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      What is transcreation?
                    </h3>
                    <p>
                      Transcreation is the translation process of adapting the
                      meaning, intent, style, and tone of the source text,
                      rather than the actual words. It is a more creative form
                      of translations, and therefore requires a creative
                      environment conducive to collaboration.
                    </p>
                    <p>
                      This is important because content is becoming more
                      creative. Headlines, buttons, advertisements and more are
                      becoming more witty. Words are being used in puns for
                      marketing purposes to the consumers’ delight, but “how the
                      sausage is made” (to use a turn of phrase in and of itself
                      that would need to be transcreated) requires a more
                      sophisticated technology to scale and optimize the
                      process.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageAssets}
                      width={412}
                      height={218}
                      alt="Linguistic Assets Illustration"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageProduct}
                      alt="Transcreation Product"
                      height={325}
                      width={500}
                    />
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Introducing Smartling’s Transcreation Tool
                    </h3>
                    <p>
                      The only tool on the market specifically designed for
                      transcreating content at scale. With the new Transcreation
                      Tool you can:
                      <ul className="o-list--checked o-list--checked--left">
                        <li>Create multiple transcreation options</li>
                        <li>Add back translations</li>
                        <li>Select your favorite transcreation options</li>
                        <li>
                          Say goodbye to spreadsheets{' '}
                          <span role="img" aria-label="waving hand emoji">
                            👋
                          </span>
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block>
                  <Block.Image>
                    <Icons.IconRocketCircleLight xxxl />
                  </Block.Image>
                  <h3 class="c-block__title u-padding--double--bottom">
                    Out with the old.
                    <br />
                    In with the new.
                  </h3>
                  Smartling’s new Transcreation Tool replaces the decades-old
                  process of transcreating content in antiquated documentation
                  tools that are offline, email-based, and not easily scaled.
                  It’s a time consuming, expensive process laden with
                  spreadsheets and inefficiencies. That’s why we created the new
                  Transcreation Tool, the first tool specifically designed to
                  help you with transcreating content at scale.
                </Block>
              </Section.Header>

              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      What the Transcreation Tool Looks Like
                    </h3>
                    <p>
                      Translators see the source string, and then have the
                      ability to add multiple translations and back-translations
                      so the project owner can understand the meaning of each
                      option. Each string is automatically configured within
                      Smartling’s WYSIWYG visual context editor, enabling the
                      translator and content owner to collaborate on the content
                      and see how the transcreations appear before merging
                      transcreations with the code.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDashboard}
                      alt="Transcreation Dashboard"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="c-product-intro-image u-text-align--center">
                    <img
                      loading="lazy"
                      src={imageChat}
                      alt="Transcreation Product Chat"
                      style={{ maxWidth: 250 + 'px' }}
                    />
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Getting Transcreations Right the First Time
                    </h3>
                    <p>
                      Getting translations and transcreation right before
                      merging the target content with the code base eliminates
                      the back-and-forth typically associated with editing that
                      takes place after issues are identified in the quality
                      assurance phase. The experience of working in Smartling’s
                      Transcreation Tool is similar to collaborative writing
                      technologies that enable you to communicate easily with
                      contributors and access linguistic assets.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      How The Transcreation Tool is Collaborative
                    </h3>
                    <p>
                      When the translator submits transcreation options in
                      context, you’re also presented with back translations. So
                      even if you aren’t multilingual, you can get an idea for
                      what the target transcreation means. Transcreating “Whale
                      hellooo there” into Spanish for Latin America yields three
                      options.
                      <ul className="o-list--checked o-list--checked--left">
                        <li className="no">Bye fish!</li>
                        <li>Have a whale of a time!</li>
                        <li className="no">Like a fish in water.</li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageWhale}
                      alt="Bye fish! Have a whale of a time! Like a fish in water."
                    />
                  </div>
                </div>
              </div>
            </section>
            <div className="u-gradient--tertiary u-curve--top u-padding--section">
              <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
                <div class="c-section__header">
                  <div class="c-block u-spacing">
                    <div class="c-block__text u-spacing">
                      <h3 class="c-block__title">
                        Smartling on Transcreation Services
                      </h3>
                      <div class="c-block__description u-spacing--double">
                        <div>
                          Watch the{' '}
                          <a href="/resources/101/smartling-language-services-and-transcreation/">
                            transcreation workshop
                          </a>{' '}
                          featuring Smartling’s transcreators and Language
                          Services Team.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="c-cta__video">
                  <iframe
                    title="video 1"
                    src={`https://player.vimeo.com/video/443167529?title=0&byline=0&portrait=0`}
                    frameborder="0"
                    width="100%"
                    height="auto"
                    allow="autoplay"
                    encrypted-media
                    allowfullscreen
                  />
                </div>
              </section>
            </div>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      Separate your Transcreation Memory from your Translation
                      Memory
                    </h3>
                    <p>
                      Smartling is known for its ability to centralize all
                      things translation into one place. Like storing all of
                      your translations into one, or group of translation
                      memories. With transcreation, it’s important to isolate
                      terminology so that it doesn’t get linked to the
                      translation memory. We’ve thought about details like this
                      so you don’t have to: your transcreation memory will be
                      separate from your translation memory.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageCake}
                      alt="Transcreation Cake"
                      style={{ maxWidth: 330 + 'px' }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-text-align--center">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={iconSignpost}
                      alt="Signpost icon"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Translation vs. Transcreation
                    </h3>
                    <p>
                      <a href="/resources/101/six-ways-transcreation-differs-from-translation/">
                        Translation is verbatim
                      </a>
                      . It’s a direct recreation of the source text into a new
                      language. Transcreation, on the other hand, is taking the
                      meaning, style, tone, and intent of the original source
                      and creating a new asset in a new language.
                    </p>
                    <p>
                      The emotional impact and outcome is the same, but the road
                      you take to get there is different.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Five Features Customers Love with the Transcreation Tool">
                  <Block.Image>
                    <Icons.IconStarCircleLight xxxl />
                  </Block.Image>
                  <div>
                    Along with being able to set up a smoother process, the
                    Transcreation Tool gives you the ability to supercharge all
                    aspects of your transcreation workflow.
                  </div>
                </Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--left">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="1" class="u-font--l">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            Get a complete string history that shows you who
                            likes what, or who made changes during the editing
                            process
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="2" class="u-font--l">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            Build a transcreation-specific translation memory
                            that you can use to both work faster and optimize
                            for cost
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="3" class="u-font--l">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            Leverage the built-in glossary so unique terms and
                            phrases are always considered throughout the content
                            creation process
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span
                              role="img"
                              aria-label="4"
                              class="u-font--l"
                              and-half--
                            >
                              4️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            Add comments, make selections, and make (kind
                            hearted) rejections for a more collaborative and
                            optimized process
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="5" class="u-font--l">
                              5️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            See what it will cost <em>before you start</em> with
                            integrated budgeting tools
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <div className="u-gradient--secondary u-curve--top--left u-curve--bottom--center u-padding--section">
              <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
                <div class="c-section__header">
                  <div class="c-block u-spacing">
                    <div class="c-block__text u-spacing">
                      <div class="u-spacing">
                        <h3 class="o-section-title o-section-title--heavy">
                          Smartling developed the Transcreation Tool with the
                          advice and council of translators and customers who
                          have been using a beta version of the product for
                          months.
                        </h3>
                        <p class="u-padding--bottom">
                          Smartling developed the Transcreation Tool with the
                          advice and council of translators and customers who
                          have been used a beta version of the product for
                          months.
                        </p>
                        <div class="c-block__description u-spacing--double">
                          <div class="u-text-align--left u-font-weight--bold">
                            “I absolutely love working on creative projects with
                            our customers, and Smartling’s new Transcreation
                            Tool makes it substantially more efficient to
                            produce quality results. I no longer have to stare
                            into the cells of a spreadsheet and circulate
                            transcreations by email. Instead, I log into
                            Smartling, write three options with back
                            translations, and then communicate directly with the
                            customer through the platform to ensure the work
                            meets their expectations."
                          </div>
                          <div class="u-text-align--left">
                            <div class="u-flex">
                              <img
                                loading="lazy"
                                src={imageGabriela}
                                alt="Gabriela, a translator"
                                style={{
                                  width: '120px',
                                  height: '120px',
                                  marginRight: '20px',
                                }}
                              />
                              <div class="u-spacing--half">
                                <div>
                                  Gabriela O., a professional Smartling
                                  translator in Argentina.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      The Transcreation Tool Explained
                    </h3>
                    <p>
                      Smartling’s new Transcreation Tool gives you a single
                      touchpoint to manage every aspect of the transcreation
                      process without the need for spreadsheets.
                    </p>
                  </div>
                  <div class="u-spacing--double">
                    <div></div>
                    <a
                      class="o-button"
                      href="/resources/101/the-transcreation-tool-explained/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      view on demand
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div className="o-video">
                    <iframe
                      title="video 1"
                      src="https://player.vimeo.com/video/492149005?title=0&byline=0&portrait=0"
                      frameborder="0"
                      width="560px"
                      height="315px"
                      allow="autoplay;
                      encrypted-media"
                      allowfullscreen
                    />
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Transcreate Tomorrow Today">
                  <Block.Image>
                    <Icons.IconCloudLightRing xxxl />
                  </Block.Image>
                  Leapfrog your content into the future with Smartling’s new
                  Transcreation Tool. You’ll be able to ditch spreadsheets and
                  an email-based workflow behind and you won’t look back.
                </Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default TranscreationPage
