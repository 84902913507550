import React from 'react'
import Metadata from '../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Card from 'molecules/card'
import Section from 'atoms/section'
import SectionTwoColumn from 'organisms/two-column'

import Layout from 'layouts'

// Images
import imageG2a from '../images/g2/Enterprise TMS.svg'
import imageG2b from '../images/g2/Mid-Market TMS.svg'
import imageG2c from '../images/g2/Small Business TMS.svg'
import imageG2d from '../images/g2/Europe.svg'
import imageG2e from '../images/g2/Americas.svg'
import imageG2f from '../images/g2/Highest Adoption.svg'

class ComparePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Compare - Smartling"
          description="See how Smartling, the #1 rated translation management system (TMS) by G2 and CSA Research, compares to other TMSes"
          keywords=""
        />
        <div className="c-page--compare">
          <div className="c-compare-section u-gradient--tertiary u-curve--bottom--center u-spacing--sections">
            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Smartling vs.">
                  <Block.Image>
                    <Icons.HeaderPresentation xxl />
                  </Block.Image>
                  <div className="u-spacing">
                    <p>
                      When done right, localization enables businesses to
                      connect with customers worldwide and grow their business.
                      But these projects can get complicated fast, making it
                      challenging to scale, especially on a tight budget or
                      timeline. A competent language service provider and a
                      robust translation management system can help you become Smartling Global Ready.
                    </p>

                    <p>
                      If you're in the market for either or both of these, you
                      have probably realized that with so many options, it can
                      be tricky to settle on the right solution for you. This
                      page will help you understand how Smartling compares to
                      the other players in the space.
                    </p>
                    <Button url="/meeting-request/">Book a Meeting</Button>
                  </div>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Block>
                  <div className="u-spacing">
                    <Section>
                      <Section.Header>
                        <h4 className="u-font--xl">Market Leaders by Customers on G2</h4>
                      </Section.Header>
                    </Section>
                    <SectionTwoColumn title="G2 Grid for Translation Management Software">
                      <SectionTwoColumn.Description>
                        <div>
                          <a
                            title="Smartling is a leader in Translation Management on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in Translation Management on G2"
                              src={imageG2a}
                            />
                          </a>
                          <a
                            title="Smartling is a leader in Mid-Market Translation Management on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in Mid-Market Translation Management on G2"
                              src={imageG2b}
                            />
                          </a>
                          <a
                            title="Smartling is a leader in Small-Business Localization on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in Small-Business Localization on G2"
                              src={imageG2c}
                            />
                          </a>
                          <a
                            title="Smartling is a leader in Europe for Translation Management on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in Europe for Translation Management on G2"
                              src={imageG2d}
                            />
                          </a>
                          <a
                            title="Smartling is a leader in America for Translation Management on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in America for Translation Management on G2"
                              src={imageG2e}
                            />
                          </a>
                          <a
                            title="Smartling is a leader in Highest Adoption for Translation Management on G2"
                            href="https://www.g2.com/products/smartling/reviews?utm_source=rewards-badge"
                          >
                            <img
                              loading="lazy"
                              style="width: 125px;"
                              alt="Smartling is a leader in Highest Adoption for Translation Management on G2"
                              src={imageG2f}
                            />
                          </a>
                        </div>
                      </SectionTwoColumn.Description>

                      <SectionTwoColumn.Image>
                        <div className="o-iframe-grid">
                          <div style="width: 500px; ">
                            <iframe
                              src="https://www.g2.com/categories/translation-management/grids.embed?filters%5Bcompany_segment%5D=181&wid=1679421403"
                              id="g2-crowd-widget-default"
                              width="500px"
                              height="500px"
                              frameborder="0"
                              scrolling="no"
                              style="border: solid 1px #eaeaea;  width:100%;min-height:100%;overflow:hidden;"
                            >
                              {' '}
                            </iframe>{' '}
                            <a
                              href="https://www.g2.com/categories/translation-management"
                              style="font-size: 12px; color: #515159; font-weight: 600"
                            >
                              {' '}
                              Enterprise G2 Grid®️ for Translation Management
                              Software{' '}
                            </a>{' '}
                          </div>
                          {/* <iframe
                            title="G2 Grid"
                            src="https://www.g2.com/categories/translation-management/grids.embed?wid=1554838455"
                            width="550px"
                            height="550px"
                            frameborder="0"
                            scrolling="no"
                          />
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.g2.com/categories/translation-management"
                          >
                            G2 Grid<sup>®</sup> for Translation Management
                            Software
                          </a> */}
                        </div>
                      </SectionTwoColumn.Image>
                    </SectionTwoColumn>
                  </div>
                </Block>
                <Grid twoUp className="c-cards">
                  <Grid.Item>
                    <Card title="Lingotek" titleLink="/compare/lingotek">
                      <Card.Image />
                      <p>
                        Lingotek offers translation services and a cloud-based
                        TMS. But the pared-down software provides for a less
                        customizable and less seamless user experience than
                        Smartling. On the other hand, Smartling's TMS has many
                        customizable features like dynamic workflows and allows
                        for a seamless flow of content between sources. Further,
                        Smartling's world-class language services include a
                        carefully vetted pool of expert translators.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Lokalise" titleLink="/compare/lokalise">
                      <Card.Image />
                      <p>
                        Lokalise's TMS has some automation features, offers
                        machine translation, and can connect companies with
                        human translators. But companies may find Lokalise's
                        offering too limited. Smartling's TMS, built on
                        enterprise-grade security infrastructure, combines
                        powerful project management features and MT options with
                        a robust, translator-friendly CAT tool. Smartling also
                        gives companies direct access to world-class translation
                        services.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Memsource" titleLink="/compare/memsource">
                      <Card.Image />
                      <p>
                        Memsource is good for users looking for basic
                        integrations, some project management features, and MT
                        options. With Smartling's TMS, on the other hand, users
                        can send content for translation via a wide range of
                        integrations, increase efficiency with many different
                        project management tools, and leverage both human
                        translators and various MT engines to localize their
                        content.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Lilt" titleLink="/compare/lilt">
                      <Card.Image />
                      <p>
                        Users of Lilt have access to a cloud-based TMS that has
                        some helpful project management features and an adaptive
                        MT tool that can increase efficiency. Smartling's TMS
                        takes things a bit further: within its TMS, it offers a
                        feature-rich CAT tool, so translators have everything
                        they need for high-quality translations in one place,
                        customizable workflows, reporting tools, and a
                        translation proxy that makes website translation
                        seamless.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Phrase" titleLink="/compare/phrase">
                      <Card.Image />
                      <p>
                        Phrase is best for companies requiring only software
                        localization: it offers several features and
                        integrations with developers in mind. Smartling,
                        however, is the most scalable solution for translating
                        enterprise-wide content. From website and software
                        localization to translating marketing content and even
                        subtitling videos, Smartling's TMS can handle it all.
                        Plus, Smartling offers world-class language services,
                        putting customers in touch with professional translators
                        who have extensive industry expertise.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card
                      title="Translations.com (TransPerfect)"
                      titleLink="/compare/transperfect"
                    >
                      <Card.Image />
                      <p>
                        While TDC remains a language services provider first and
                        a tech company second, Smartling has been a tech-first
                        company from the start. Smartling utilizes AI, machine
                        learning, and analytics throughout the product to create
                        the most robust and streamlined user experience
                        possible. Smartling also incorporates user feedback and
                        feature requests into product updates. Plus,
                        thoughtfully chosen translators across numerous language
                        pairs are available to work on any or all of a
                        customer's content - there are no translation minimums.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="XTM" titleLink="/compare/xtm">
                      <Card.Image />
                      <p>
                        XTM's platform helps automate workflows and encourages
                        collaboration. But when it comes to complex or varied
                        translation needs, Smartling shines. Smartling has
                        dozens of integrations, including APIs and a translation
                        proxy that simplifies website translation (and requires
                        almost no IT involvement). It also has a CAT tool
                        equipped with visual context, TM leverage, and quality
                        checks.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Lionbridge" titleLink="/compare/lionbridge">
                      <Card.Image />
                      <p>
                        While Lionbridge offers a few different software
                        solutions for customers looking for localization project
                        management tools, it is still primarily a language
                        services company. Smartling offers a feature-rich,
                        cloud-based TMS that streamlines all tasks related to
                        translation, charges no translation minimums, and
                        provides access to experienced translators with
                        extensive industry expertise and white-glove project
                        management support.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Transifex" titleLink="/compare/transifex">
                      <Card.Image />
                      <p>
                        While Transifex mainly supports mobile app, software,
                        and website translation, Smartling supports
                        enterprise-wide translation needs - from apps, software,
                        and websites to marketing materials, video subtitling,
                        and beyond. In addition to offering many APIs and
                        integration options, Smartling provides customers with a
                        powerful translation proxy, which makes website
                        translation simple - almost no IT expertise is needed.
                        Highly customizable workflows and reporting capabilities
                        also help streamline translation processes.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Crowdin" titleLink="/compare/crowdin">
                      <Card.Image />
                      <p>
                        Crowdin's TMS automates specific translation tasks and
                        has some helpful project management features. It also
                        can connect companies with third-party translation
                        vendors. Smartling, however, offers an end-to-end
                        cloud-based solution that centralizes all source and
                        translated content and easily scales to support all
                        content types and volumes. In addition, users can engage
                        with localization experts and thoroughly vetted
                        translators should they choose to do so.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="MemoQ" titleLink="/compare/memoq">
                      <Card.Image />
                      <p>
                        While memoQ was developed originally as a tool for
                        translators, Smartling was built for both translators
                        and customer-side project managers. The TMS has an
                        easy-to-use UI, encourages collaboration, and gives
                        project managers visibility into their translators'
                        work. Translators have access to a CAT tool with visual
                        context, QA checks, and more. Additionally, Smartling
                        offers project management and localization support and
                        is a full-service LSP with access to expert translators.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="MotionPoint" titleLink="/compare/motionpoint">
                      <Card.Image />
                      <p>
                        While MotionPoint supports the translation of other
                        digital content, its flagship product remains its web
                        proxy. Smartling offers an end-to-end translation
                        management solution with enterprise-grade security
                        software that provides visibility into the entire
                        process and numerous project management and reporting
                        features. Smartling's web proxy is also the best in the
                        market, with a 99.99% uptime and localized websites that
                        load within milliseconds.
                      </p>
                    </Card>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ComparePage
