import React from 'react'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionSmartlingTranslationServices from 'components/sections/smartling-translation-services'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionTranslationInterface from 'components/sections/translation-interface'
import SectionThirdPartyTranslators from 'components/sections/third-party-translators'
import SectionPlatinumSupport from 'components/sections/platinum-support'
import SectionSoftwareAutomation from 'components/sections/software-automation'

// Images
import heroImage from '../../../images/illustrations/tablet-gopro.svg'

import Layout from 'layouts'

const heroStyle = {
  maxHeight: '550px',
}

class TranslationServicesPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Language Translation Services - Smartling"
          description="Smartling combines human and machine language translation services customized to your project goals and budget. Meet quick deadlines with our automation tools."
          keywords=""
        />
        <Hero
          flip
          curveRight
          gradientSecondary
          title="Any assignment. Any budget."
        >
          <Hero.Description>
            Smartling has a methodology to deliver 100% of your content within
            budget for any language.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} style={heroStyle} alt="Cards" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Book a demo</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <SectionCta title="We value <em>value</em>." centerCTA>
            <SectionCta.Description>
              Every word you translate carries economic utility. You share the
              goals, we provide a plan. From transcreation to machine
              translation, Smartling strikes the right balance between speed,
              quality, and cost.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="/why-smartling">
                Which method is right for me?
              </Button>
            </SectionCta.Buttons>
          </SectionCta>

          <div className="u-gradient--tertiary u-curve--top--left">
            <Section className="c-section__grid-blocks c-grid-blocks">
              <Section.Header>
                <Block title="We find words in all great things.">
                  <Block.Image>
                    <Icons.HeaderRocket xxl />
                  </Block.Image>
                  Human translators provide the human touch. With experience in
                  50 business verticals and over 150 languages, you can count on
                  Smartling to deliver quality work.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <Grid threeUp className="has-icon-bulbs">
                  <Grid.Item>
                    <Block title="Transcreation">
                      <Block.Image>
                        <Icons.IconButtons />
                      </Block.Image>
                      Creative translation to ensure cultural relevance
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Translation and editing">
                      <Block.Image>
                        <Icons.IconCoin />
                      </Block.Image>
                      To ensure quality and consistency
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Single Pass Translation">
                      <Block.Image>
                        <Icons.IconSend />
                      </Block.Image>
                      For efficiency - in cost and time
                    </Block>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
          </div>

          <SectionCta
            title="We support machine translation, too."
            gradientPrimary
            curveTop
            centerCTA
            curveBottomCenter
          >
            <SectionCta.Description>
              Machine translation is the least expensive and fastest option for
              converting words at scale. You can re-use previously translated
              words before they reach a machine.
            </SectionCta.Description>
            <SectionCta.subText>
              CrossLang • DeepL • Google Translate • Lilt • Microsoft Translator
              • Unbabel • Watson Language Translator
            </SectionCta.subText>
            <SectionCta.Buttons>
              <Button
                target="_blank"
                url="https://help.smartling.com/hc/en-us/articles/115003829113-Choose-a-Translation-Method"
              >
                Integrate in minutes
              </Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionSmartlingTranslationServices />
          <div className="u-gradient--secondary u-curve--top--left u-curve--bottom--center u-padding--section">
            <SectionTranslationInterface />
          </div>
          <SectionThirdPartyTranslators />
          <div className="u-gradient--primary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionSoftwareAutomation />
          </div>
          <SectionCustomerSuccess />
          <div className="u-gradient--tertiary u-curve--top">
            <SectionPlatinumSupport />
          </div>
        </article>
      </Layout>
    )
  }
}

export default TranslationServicesPage
