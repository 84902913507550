exports.components = {
  "component---src-pages-3-quick-wins-translation-js": () => import("./../../../src/pages/3-quick-wins-translation.js" /* webpackChunkName: "component---src-pages-3-quick-wins-translation-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-advantages-to-using-a-translation-management-system-js": () => import("./../../../src/pages/5-advantages-to-using-a-translation-management-system.js" /* webpackChunkName: "component---src-pages-5-advantages-to-using-a-translation-management-system-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-b-2-b-js": () => import("./../../../src/pages/b2b.js" /* webpackChunkName: "component---src-pages-b-2-b-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-compare-js": () => import("./../../../src/pages/compare.js" /* webpackChunkName: "component---src-pages-compare-js" */),
  "component---src-pages-conference-2020-js": () => import("./../../../src/pages/conference2020.js" /* webpackChunkName: "component---src-pages-conference-2020-js" */),
  "component---src-pages-conference-2021-js": () => import("./../../../src/pages/conference-2021.js" /* webpackChunkName: "component---src-pages-conference-2021-js" */),
  "component---src-pages-conference-js": () => import("./../../../src/pages/conference.js" /* webpackChunkName: "component---src-pages-conference-js" */),
  "component---src-pages-conference-on-demand-js": () => import("./../../../src/pages/conference-on-demand.js" /* webpackChunkName: "component---src-pages-conference-on-demand-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-diversity-js": () => import("./../../../src/pages/diversity.js" /* webpackChunkName: "component---src-pages-diversity-js" */),
  "component---src-pages-ecommerce-localization-js": () => import("./../../../src/pages/ecommerce-localization.js" /* webpackChunkName: "component---src-pages-ecommerce-localization-js" */),
  "component---src-pages-excellence-every-touchpoint-js": () => import("./../../../src/pages/excellence-every-touchpoint.js" /* webpackChunkName: "component---src-pages-excellence-every-touchpoint-js" */),
  "component---src-pages-globalready-js": () => import("./../../../src/pages/globalready.js" /* webpackChunkName: "component---src-pages-globalready-js" */),
  "component---src-pages-guaranteed-quality-js": () => import("./../../../src/pages/guaranteed-quality.js" /* webpackChunkName: "component---src-pages-guaranteed-quality-js" */),
  "component---src-pages-how-to-choose-a-translation-management-system-js": () => import("./../../../src/pages/how-to-choose-a-translation-management-system.js" /* webpackChunkName: "component---src-pages-how-to-choose-a-translation-management-system-js" */),
  "component---src-pages-index-bak-js": () => import("./../../../src/pages/index-bak.js" /* webpackChunkName: "component---src-pages-index-bak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-fintech-js": () => import("./../../../src/pages/industries/fintech.js" /* webpackChunkName: "component---src-pages-industries-fintech-js" */),
  "component---src-pages-industries-government-agencies-js": () => import("./../../../src/pages/industries/government-agencies.js" /* webpackChunkName: "component---src-pages-industries-government-agencies-js" */),
  "component---src-pages-industries-healthcare-js": () => import("./../../../src/pages/industries/healthcare.js" /* webpackChunkName: "component---src-pages-industries-healthcare-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-meeting-request-js": () => import("./../../../src/pages/meeting-request.js" /* webpackChunkName: "component---src-pages-meeting-request-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-plans-2021-js": () => import("./../../../src/pages/plans-2021.js" /* webpackChunkName: "component---src-pages-plans-2021-js" */),
  "component---src-pages-plans-js": () => import("./../../../src/pages/plans.js" /* webpackChunkName: "component---src-pages-plans-js" */),
  "component---src-pages-podcasts-locshow-js": () => import("./../../../src/pages/podcasts/locshow.js" /* webpackChunkName: "component---src-pages-podcasts-locshow-js" */),
  "component---src-pages-ppc-demo-js": () => import("./../../../src/pages/ppc-demo.js" /* webpackChunkName: "component---src-pages-ppc-demo-js" */),
  "component---src-pages-pressplay-js": () => import("./../../../src/pages/pressplay.js" /* webpackChunkName: "component---src-pages-pressplay-js" */),
  "component---src-pages-products-translate-translation-services-archive-js": () => import("./../../../src/pages/products/translate/translation-services-archive.js" /* webpackChunkName: "component---src-pages-products-translate-translation-services-archive-js" */),
  "component---src-pages-professional-services-js": () => import("./../../../src/pages/professional-services.js" /* webpackChunkName: "component---src-pages-professional-services-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sample-translation-rfp-js": () => import("./../../../src/pages/sample-translation-rfp.js" /* webpackChunkName: "component---src-pages-sample-translation-rfp-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sf-login-js": () => import("./../../../src/pages/sf-login.js" /* webpackChunkName: "component---src-pages-sf-login-js" */),
  "component---src-pages-software-ai-translation-toolkit-js": () => import("./../../../src/pages/software/ai-translation-toolkit.js" /* webpackChunkName: "component---src-pages-software-ai-translation-toolkit-js" */),
  "component---src-pages-software-api-js": () => import("./../../../src/pages/software/api.js" /* webpackChunkName: "component---src-pages-software-api-js" */),
  "component---src-pages-software-cat-tool-js": () => import("./../../../src/pages/software/CAT-tool.js" /* webpackChunkName: "component---src-pages-software-cat-tool-js" */),
  "component---src-pages-software-global-delivery-network-js": () => import("./../../../src/pages/software/global-delivery-network.js" /* webpackChunkName: "component---src-pages-software-global-delivery-network-js" */),
  "component---src-pages-software-integrations-adobe-experience-manager-js": () => import("./../../../src/pages/software/integrations/adobe-experience-manager.js" /* webpackChunkName: "component---src-pages-software-integrations-adobe-experience-manager-js" */),
  "component---src-pages-software-integrations-akeneo-js": () => import("./../../../src/pages/software/integrations/akeneo.js" /* webpackChunkName: "component---src-pages-software-integrations-akeneo-js" */),
  "component---src-pages-software-integrations-archive-js": () => import("./../../../src/pages/software/integrations-archive.js" /* webpackChunkName: "component---src-pages-software-integrations-archive-js" */),
  "component---src-pages-software-integrations-braze-js": () => import("./../../../src/pages/software/integrations/braze.js" /* webpackChunkName: "component---src-pages-software-integrations-braze-js" */),
  "component---src-pages-software-integrations-contentstack-js": () => import("./../../../src/pages/software/integrations/contentstack.js" /* webpackChunkName: "component---src-pages-software-integrations-contentstack-js" */),
  "component---src-pages-software-integrations-custom-mt-js": () => import("./../../../src/pages/software/integrations/custom-mt.js" /* webpackChunkName: "component---src-pages-software-integrations-custom-mt-js" */),
  "component---src-pages-software-integrations-drupal-js": () => import("./../../../src/pages/software/integrations/drupal.js" /* webpackChunkName: "component---src-pages-software-integrations-drupal-js" */),
  "component---src-pages-software-integrations-figma-js": () => import("./../../../src/pages/software/integrations/figma.js" /* webpackChunkName: "component---src-pages-software-integrations-figma-js" */),
  "component---src-pages-software-integrations-google-drive-js": () => import("./../../../src/pages/software/integrations/google-drive.js" /* webpackChunkName: "component---src-pages-software-integrations-google-drive-js" */),
  "component---src-pages-software-integrations-mindtouch-js": () => import("./../../../src/pages/software/integrations/mindtouch.js" /* webpackChunkName: "component---src-pages-software-integrations-mindtouch-js" */),
  "component---src-pages-software-integrations-optimizely-js": () => import("./../../../src/pages/software/integrations/optimizely.js" /* webpackChunkName: "component---src-pages-software-integrations-optimizely-js" */),
  "component---src-pages-software-integrations-salesforce-commerce-cloud-js": () => import("./../../../src/pages/software/integrations/salesforce-commerce-cloud.js" /* webpackChunkName: "component---src-pages-software-integrations-salesforce-commerce-cloud-js" */),
  "component---src-pages-software-integrations-sanity-js": () => import("./../../../src/pages/software/integrations/sanity.js" /* webpackChunkName: "component---src-pages-software-integrations-sanity-js" */),
  "component---src-pages-software-integrations-yext-js": () => import("./../../../src/pages/software/integrations/yext.js" /* webpackChunkName: "component---src-pages-software-integrations-yext-js" */),
  "component---src-pages-software-integrations-zendesk-js": () => import("./../../../src/pages/software/integrations/zendesk.js" /* webpackChunkName: "component---src-pages-software-integrations-zendesk-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-software-transcreation-tool-js": () => import("./../../../src/pages/software/transcreation-tool.js" /* webpackChunkName: "component---src-pages-software-transcreation-tool-js" */),
  "component---src-pages-software-translation-analytics-js": () => import("./../../../src/pages/software/translation-analytics.js" /* webpackChunkName: "component---src-pages-software-translation-analytics-js" */),
  "component---src-pages-software-translation-management-system-js": () => import("./../../../src/pages/software/translation-management-system.js" /* webpackChunkName: "component---src-pages-software-translation-management-system-js" */),
  "component---src-pages-software-translation-management-system-onboarding-enablement-services-js": () => import("./../../../src/pages/software/translation-management-system/onboarding-enablement-services.js" /* webpackChunkName: "component---src-pages-software-translation-management-system-onboarding-enablement-services-js" */),
  "component---src-pages-software-translation-management-system-project-management-js": () => import("./../../../src/pages/software/translation-management-system/project-management.js" /* webpackChunkName: "component---src-pages-software-translation-management-system-project-management-js" */),
  "component---src-pages-summer-js": () => import("./../../../src/pages/summer.js" /* webpackChunkName: "component---src-pages-summer-js" */),
  "component---src-pages-supergrowth-js": () => import("./../../../src/pages/supergrowth.js" /* webpackChunkName: "component---src-pages-supergrowth-js" */),
  "component---src-pages-teams-customer-support-js": () => import("./../../../src/pages/teams/customer-support.js" /* webpackChunkName: "component---src-pages-teams-customer-support-js" */),
  "component---src-pages-teams-marketing-js": () => import("./../../../src/pages/teams/marketing.js" /* webpackChunkName: "component---src-pages-teams-marketing-js" */),
  "component---src-pages-teams-product-js": () => import("./../../../src/pages/teams/product.js" /* webpackChunkName: "component---src-pages-teams-product-js" */),
  "component---src-pages-translation-services-js": () => import("./../../../src/pages/translation-services.js" /* webpackChunkName: "component---src-pages-translation-services-js" */),
  "component---src-pages-translation-services-linguistic-asset-management-js": () => import("./../../../src/pages/translation-services/linguistic-asset-management.js" /* webpackChunkName: "component---src-pages-translation-services-linguistic-asset-management-js" */),
  "component---src-pages-translation-services-meet-our-translators-js": () => import("./../../../src/pages/translation-services/meet-our-translators.js" /* webpackChunkName: "component---src-pages-translation-services-meet-our-translators-js" */),
  "component---src-pages-translation-services-sls-lsp-industry-analysis-js": () => import("./../../../src/pages/translation-services/sls/lsp-industry-analysis.js" /* webpackChunkName: "component---src-pages-translation-services-sls-lsp-industry-analysis-js" */),
  "component---src-pages-translation-services-sls-smartling-experience-js": () => import("./../../../src/pages/translation-services/sls/smartling-experience.js" /* webpackChunkName: "component---src-pages-translation-services-sls-smartling-experience-js" */),
  "component---src-pages-translation-services-translators-js": () => import("./../../../src/pages/translation-services/translators.js" /* webpackChunkName: "component---src-pages-translation-services-translators-js" */),
  "component---src-pages-translation-test-js": () => import("./../../../src/pages/translation-test.js" /* webpackChunkName: "component---src-pages-translation-test-js" */),
  "component---src-pages-verbalizeit-js": () => import("./../../../src/pages/verbalizeit.js" /* webpackChunkName: "component---src-pages-verbalizeit-js" */),
  "component---src-pages-why-managed-services-js": () => import("./../../../src/pages/why-managed-services.js" /* webpackChunkName: "component---src-pages-why-managed-services-js" */),
  "component---src-pages-why-smartling-js": () => import("./../../../src/pages/why-smartling.js" /* webpackChunkName: "component---src-pages-why-smartling-js" */),
  "component---src-templates-basic-page-js": () => import("./../../../src/templates/basic-page.js" /* webpackChunkName: "component---src-templates-basic-page-js" */),
  "component---src-templates-competitors-js": () => import("./../../../src/templates/competitors.js" /* webpackChunkName: "component---src-templates-competitors-js" */),
  "component---src-templates-event-category-js": () => import("./../../../src/templates/event-category.js" /* webpackChunkName: "component---src-templates-event-category-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-learning-hub-page-js": () => import("./../../../src/templates/learning-hub-page.js" /* webpackChunkName: "component---src-templates-learning-hub-page-js" */),
  "component---src-templates-paid-ads-js": () => import("./../../../src/templates/paid-ads.js" /* webpackChunkName: "component---src-templates-paid-ads-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-podcast-category-archive-js": () => import("./../../../src/templates/podcast-category-archive.js" /* webpackChunkName: "component---src-templates-podcast-category-archive-js" */),
  "component---src-templates-podcast-category-js": () => import("./../../../src/templates/podcast-category.js" /* webpackChunkName: "component---src-templates-podcast-category-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */),
  "component---src-templates-ppc-js": () => import("./../../../src/templates/ppc.js" /* webpackChunkName: "component---src-templates-ppc-js" */),
  "component---src-templates-resource-category-js": () => import("./../../../src/templates/resource-category.js" /* webpackChunkName: "component---src-templates-resource-category-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-resource-tag-js": () => import("./../../../src/templates/resource-tag.js" /* webpackChunkName: "component---src-templates-resource-tag-js" */),
  "component---src-templates-translator-js": () => import("./../../../src/templates/translator.js" /* webpackChunkName: "component---src-templates-translator-js" */)
}

