import React from 'react'
import Metadata from '../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import Card from 'molecules/card'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import SectionSmartlingTranslationServices from 'components/sections/smartling-translation-services'
import SectionManage from 'components/sections/manage'
import SectionSoftwareAutomation from 'components/sections/software-automation'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionPlatinumSupport from 'components/sections/platinum-support'

// Images
import heroImage from '../../images/illustrations/cat-tool.svg'

import Layout from 'layouts'

class TranslationInterfacePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Language Translation Interface (CAT Tool) - Smartling"
          description="With Smartling’s language translation interface (CAT tool) and real-time translation memory, you gain high-quality translations that you can access at any time."
          keywords=""
        />
        <Hero
          flip
          curveRight
          gradientSecondary
          title="You need to see it to believe it."
        >
          <Hero.Description>
            Finally. A CAT Tool that lets you actually see what you’re
            translating.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="CAT Tool" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">See it for yourself</Button>
          </Hero.Buttons>
        </Hero>
        <article className="l-article u-spacing--sections">
          <SectionCta
            title="Say goodbye to spreadsheets, outdated translation memories and email-based workflow."
            centerCTA
          >
            <SectionCta.Description />
            <SectionCta.Buttons />
          </SectionCta>

          <div className="u-background-accent--left">
            <Section className="c-section__grid-blocks c-grid-blocks">
              <Section.Header>
                <Block title="Cloud Translation.">
                  <Block.Image>
                    <Icons.HeaderCloud xxl />
                  </Block.Image>
                  An experience engineered for today and tomorrow. Wherever life
                  takes you, Smartling is ready.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <Grid threeUp className="has-icon-bulbs">
                  <Grid.Item>
                    <Block title="Always communicating.">
                      <Block.Image>
                        <Icons.IconUpDownArrows />
                      </Block.Image>
                      It’s your turn. Smartling lets you know when it’s time to
                      translate or review work.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Always saving.">
                      <Block.Image>
                        <Icons.IconUpDownArrows />
                      </Block.Image>
                      Never lose your work again. Translations are automatically
                      saved in the cloud.
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Always deploying.">
                      <Block.Image>
                        <Icons.IconUpDownArrows />
                      </Block.Image>
                      New features are made available weekly and no installation
                      is required.
                    </Block>
                  </Grid.Item>
                </Grid>
                <div className="u-text-align--center u-space--triple--top">
                  <h4 className="u-color--primary">
                    You bring the skills. We bring the best workbench in the
                    biz.
                  </h4>
                </div>
              </Section.Inner>
            </Section>
          </div>

          <div className="u-gradient--tertiary u-curve--top">
            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Rich with features so you can be rich in meaning.">
                  <Block.Image>
                    <Icons.HeaderMoney xxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  <Grid.Item>
                    <Card title="Visual context">
                      <Card.Image />
                      <p>
                        Preview translations on the web page, mobile app, or
                        wherever they will live before submitting content. The
                        language translation interface captures the exact page
                        layout so you can{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115003080374#visual-context-view"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          translate in context
                        </a>
                        .
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Real-time, active translation memory">
                      <Card.Image />
                      <p>
                        Sync and share across all your vendors in real-time. No
                        more sharing outdated TMs every quarter.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Version control">
                      <Card.Image />
                      <p>
                        When a new file is uploaded, only the new content is
                        surfaced for translation. Smartling correctly
                        pre-populates translation matches for everything else.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Brand assets">
                      <Card.Image />
                      <p>
                        Know the tone and brand guidelines before diving head
                        first into translations. The term base and{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115003144713-Create-a-Style-Guide"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          style guide
                        </a>{' '}
                        is always available.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Integrated quality checks">
                      <Card.Image />
                      <p>
                        Real-time quality checks save time on proofreading.
                        Catch errors in spacing, number formatting, tags,
                        spelling,{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115003151014-Configure-Quality-Checks"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          and more
                        </a>{' '}
                        as you work.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="50% faster with shortcuts">
                      <Card.Image />
                      <p>
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115003085554-Customize-CAT-Tool-Behavior"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Keyboard shortcuts
                        </a>{' '}
                        for every action. Select a profile from other CAT tools.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Merge strings">
                      <Card.Image />
                      <p>
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/360000103153-Content-Parsing"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Consolidate multiple segments
                        </a>{' '}
                        with a keystroke. Visualize, translate, and submit with
                        fewer clicks.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Flexible tag handling">
                      <Card.Image />
                      <p>
                        Automatic{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115003080374#tags"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          tag handling
                        </a>
                        . Uses machine learning to accurately place tags - no
                        more tag soup. Focus on translation, not tags.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Auto-save">
                      <Card.Image />
                      <p>
                        Smartling continuously saves your work so you don’t have
                        to, even if the internet connect is lost, or if the
                        browser is closed.
                      </p>
                    </Card>
                  </Grid.Item>
                  <Grid.Item>
                    <Card title="Automatic Routing">
                      <Card.Image />
                      <p>
                        Click submit when you’re done. Smartling automatically
                        routes content to the next step in the workflow.
                      </p>
                    </Card>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
          </div>

          <SectionCta
            title="Pre-Programmed Quality Checks"
            gradientPrimary
            curveTop
            centerCTA
            curveBottom
          >
            <SectionCta.Description>
              <h5>Configured to reduce work and improve velocity</h5>
              <p>
                In effort to continously improve translation quality, Smartling
                measures every input and automatically surfaces errors when they
                are introduced. Customers can interpret historical data and
                tweak specific settings to achieve better outcomes over time.
              </p>
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>

          <Section className="c-section__grid-blocks c-grid-blocks">
            <Section.Header>
              <Block title="Easily measured. Easily fixed.">
                <Block.Image>
                  <Icons.HeaderClipboard xxl />
                </Block.Image>
              </Block>
            </Section.Header>
            <Section.Inner l>
              <Grid twoUp>
                <Grid.Item>
                  <Block title="Consistency">
                    <Block.Image />
                    Tags, placeholders, insertables, even emoji’s. Surface
                    errors before they break a build.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Overwrite">
                    <Block.Image />
                    Typos, extra spaces, repeated words. Errors happen.
                    Smartling recommends action as the errors are introduced.
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <SectionCta gradientPrimary curveTop centerCTA curveBottom>
            <SectionCta.Description>
              <h3 className="u-font--m">
                “I can use Smartling at any time, at any moment. I only need a
                good Internet connection, no need to use a PC and to install any
                software. That provides me with a lot of flexibility and
                improves my internal organization.”
              </h3>
              <h5>– Translation and Localization User</h5>
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button
                target="_blank"
                rel="noopener noreferrer"
                url="https://www.g2crowd.com/products/smartling/reviews/smartling-review-725219"
              >
                Read Reviews
              </Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionSmartlingTranslationServices />
          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionManage />
          </div>
          <SectionSoftwareAutomation />
          <div className="u-gradient--primary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionCustomerSuccess />
          </div>
          <SectionPlatinumSupport />
        </article>
      </Layout>
    )
  }
}

export default TranslationInterfacePage
