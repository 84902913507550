import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/company-logos/logo-sanity.jpg'
import imageDiscover from '../../../images/illustrations/2021 Product Launch - TMS - Sanity@3x.png'
import imageWorkflow from '../../../images/DA20210701 - Sanity - Product Launch - 1920x1080 - Capture and codify brand preferences@2x.jpg'
import imageRocket from '../../../images/icons/rocket-no-shadow.svg'
import sanityTranslators from '../../../images/sanity-translators-boxed.jpg'
import imageDemo1 from '../../../images/DA20210701 - Sanity - Product Launch - 1920x1080 - Product Features.gif'
import imageDemo2 from '../../../images/episerver-context.gif'

var imgStyle = {
  maxWidth: '500px',
}

class EpiserverPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Sanity Translation Integration | Smartling"
          description="Localize your content in Sanity with Smartling’s translation integration. Get a demo today."
          keywords=""
          image={heroImage}
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Translate Sanity’s structured content with Smartling"
          >
            <Hero.Description>
              Enable developers and content owners the flexibility to flow
              translated content over APIs and reuse it across channels,
              devices, and products to build the best experiences possible.
            </Hero.Description>
            <Hero.Image>
              <img loading="lazy" style={imgStyle} src={heroImage} alt="" />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Sanity Translation Connector
                    </h3>
                    <p>
                      Global content management is increasingly important as the
                      need to engage worldwide users has become a priority
                      across the enterprise.
                    </p>
                    <p>
                      Personalization is a key way to maximize engagement, but
                      it’s hard to do since there are so many languages and
                      channels. Smartling's Sanity Connector gives customers the
                      ability to easily scale across languages and digital
                      channels to increase content velocity, and access to fully
                      integrated professional language services that customers
                      can leverage to get their product into the market without
                      the overhead of people and time.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDiscover}
                      alt="Sanity TMS"
                      style={{ maxHeight: '667px' }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageRocket}
                      alt="Rocket"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Accelerate time to market
                    </h3>
                    <p>
                      Smartling’s translation integration for Sanity provides a
                      solution to create and maintain a global website
                      architecture, and simplifies the process of continuously
                      translating content into any language by automatically
                      retrieving new content and pushing translated content back
                      to Sanity. No funky workarounds. No copy paste.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Sanity Platform Integration
                    </h3>
                    <p>
                      Smartling intuitively connects with your Sanity Content
                      Lake, and offers a glimpse into your Sanity Studio from
                      Smartling. Sanity Studio Documents can be searched and
                      sorted before being authorized for the translation
                      process. From here, you can easily check on the status of
                      translations so that when you’re asked if the translations
                      are ready yet, you’ll have an answer at your fingertips.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <img loading="lazy" src={imageDemo1} alt="" />
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageDemo2} alt="Context Demo" />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Visual Context Improves Translation Quality
                    </h3>
                    <p>
                      Smartling automatically ingests screenshots of the digital
                      experience managed in Sanity so human translators can
                      actually see the content like an end user. The WYSIWYG
                      interface ensures translation accuracy and eliminates
                      mistakes that are commonplace with translating nuanced
                      brand copy.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Comprehensive Language Services
                    </h3>
                    <p>
                      Integrating with Smartling connects you with its
                      comprehensive language services. We match professional
                      translators with your content who are experienced with
                      your industry. The language services team will manage the
                      translators and ensure the quality and timeliness of
                      delivery always meets your expectations.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <img loading="lazy" src={sanityTranslators} alt="" />
                </div>
              </div>
              <div class="u-spacing u-text-align--left">
                <a
                  class="o-button u-space--double--top"
                  href="/translation-services"
                  role="button"
                >
                  Learn More
                </a>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageWorkflow} alt="Workflow" />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      All of Your Brand Assets In One Place
                    </h3>
                    <p>
                      Your content is the lifeblood of your brand. That’s why
                      we’ve made it possible for you to easily capture and
                      codify brand preferences in Smartling. Our AI-powered
                      toolkit automatically generates brand terminology, and you
                      can provide guidance on content style preferences,
                      customize workflows, and configure translation memory
                      however you wish.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Like what you see?">
                  <Block.Image>
                    <Icons.IconBinocularsRing xxxl />
                  </Block.Image>
                  Say goodbye to dated workflows. And hello to automated
                  translation.
                </Block>
              </Section.Header>

              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Top 5 Reasons Why Sanity customers choose Smartling">
                  <Block.Image>
                    <Icons.IconStarCircleLight xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--left">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="1" class="u-font--l">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Onboard in 30 days or less.</b>
                            <br />
                            We make your success our priority. Our solution
                            engineers and developers have a robust understanding
                            of localization and Sanity’s content management
                            framework. We deploy these skills to your account so
                            that you can deploy content and realize value
                            quickly.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="2" class="u-font--l">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Context matters.</b>
                            <br />
                            Smartling’s WYSIWYG translation editor statistically
                            improves translation quality outcomes for linguists
                            and reviewers. This is the most effective way to
                            manage your brand at scale without having to take
                            screenshots and coordinate efforts with translators
                            because it’s done automatically.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="3" class="u-font--l">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Data-driven localization.</b>
                            <br />
                            Every single activity related to translating Sanity
                            content is logged and seamlessly surfaced so you
                            know exactly what’s going on within your translation
                            program. Customers regularly learn how to drive down
                            cost and increase efficiency on a quarterly basis
                            with these insights and guidance from our customer
                            success team.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span
                              role="img"
                              aria-label="4"
                              class="u-font--l"
                              and-half--
                            >
                              4️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>The best translators.</b>
                            <br />
                            Odds are you don’t know the language you’re trying
                            to support, which is why it is so critically
                            important to know the people translating your
                            content. Smartling assigned highly qualified
                            professionals who are vetted, certified and
                            centrally managed - but not kept secret. You will
                            know your translator. We will introduce you to them,
                            and you will come to appreciate the value of this
                            relationship because it is unmatched by our
                            competition.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="5" class="u-font--l">
                              5️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Managed Services.</b>
                            <br />
                            If your team is overwhelmed with development
                            activities and doesn’t have the bandwidth or
                            headcount for a full time localization or developer
                            resource, Smartling serves as an extension to your
                            team to offset any work related to day-to-day
                            management. Our team of support experts stands ready
                            to assist you. In the meantime, we have
                            comprehensive documentation on our Sanity
                            integration and translation management platform.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Create Global Digital Experiences with Sanity and Smartling">
                  <Block.Image>
                    <Icons.IconIntegrations xxxl />
                  </Block.Image>
                  Join the ranks of Smartling’s world-class customers and
                  discover how Smartling’s Sanity integration can scale your
                  digital experience into any language today.
                </Block>
              </Section.Header>

              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default EpiserverPage
