import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
// TODO: Replace with actual image
import heroImage from '../../../images/integrations/akeneo.jpg'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import photoNao from '../../../images/Nao_1.png'
import { Link } from 'gatsby'
var imgStyle = {
  maxWidth: '500px',
}

class AkeneoIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Akeneo PIM Translation | Translate with Smartling"
          description="Elevate customer experiences with AI-powered localization"
          keywords="akeneo connector, PIM translation, smartling, translation software, machine translation software, product experience"
          image={heroImage}
          alt="Akeneo Translation Integration for Smartling"
        />

        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Smartling for Akeneo
            "
          >
            <Hero.Description>
              Elevate customer experiences with AI-powered localization.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Akeneo logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a meeting</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--s c-product-intro-section">
              <div class="u-spacing--and-half u-align--center">
                {/* <h3 class="o-section-title o-section-title--heavy u-align--center">
                  Main Value Prop
                </h3> */}
                <p>
                  The combination of Akeneo's PIM and Smartling’s translation
                  management system provides a comprehensive solution for
                  managing and delivering localized product content. You can
                  seamlessly synchronize and publish translated products,
                  product models, categories and more. Smartling helps you
                  maintain a consistent customer experience across all
                  touchpoints.
                </p>
                <Button url="/meeting-request/">Book a Meeting</Button>
              </div>
            </section>

            <Section id="braze-video" className="l-wrap l-container--l">
              <Section.Inner m>
                <div style="padding:56.25% 0 0 0;position:relative;">
                  <iframe
                    src="https://www.youtube.com/embed/v62tuH0sneY"
                    frameborder="0"
                    width="560"
                    height="315"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;margin:0;"
                    title="YouTube video player"
                  ></iframe>
                </div>
              </Section.Inner>
            </Section>

            <section class="o-section--inner l-wrap l-container--s">
              <div class="u-spacing--and-half u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  Get to market faster
                </h3>
                <p>
                  With Smartling’s Akeneo connector, users significantly
                  accelerate time-to-market for global product launches.
                  Translations are efficiently managed, updated, and
                  synchronized with product information, enabling you to launch
                  localized versions faster and reach global markets more
                  effectively.
                </p>
                <p>
                  Workflows are automated and linguistic assets such as
                  glossaries and style guides are applied centrally, so that
                  translation is managed consistently, and adheres to brand
                  guidelines, voice, and preferences. The Smartling platform
                  provides a contextual experience for translators and
                  reviewers, reducing errors, and increasing quality. This
                  results in a reduced time to market of over 50% and increased
                  conversions by 70%. Quality is 100% guaranteed.
                </p>
              </div>
            </section>

            <div class="o-section--inner l-wrap l-container--l ">
              <Grid twoUp>
                <Grid.Item className="u-spacing--half">
                  <h2>Smartling Language Services</h2>
                  <p>
                    Smartling Language Services customers experience faster time
                    to market, lower costs, and guaranteed quality.
                  </p>
                  <p>
                    When you combine Smartling’s translation platform features
                    with professional translators, you get:
                  </p>
                  <ul
                    className="u-spacing--quarter"
                    style={{ listStyleType: 'disc', marginLeft: '1rem' }}
                  >
                    <li>Reduced time to market by up to 90%</li>
                    <li>Reduced translation costs by up to 60%</li>
                    <li>Complete transparency of the entire process</li>
                    <li>
                      Fast issue resolution via direct communication with
                      linguists
                    </li>
                    <li>Low or no vendor management</li>
                    <li>
                      Expertise in Smartling platform features, CAT tool, and
                      integrations{' '}
                    </li>
                    <li>100% quality guarantee</li>
                  </ul>
                  <div>
                    <Link
                      className="o-link u-color--primary"
                      href="/translation-services/"
                    >
                      Learn more about Smartling Language Services
                    </Link>
                  </div>
                </Grid.Item>
                <Grid.Item>
                  <img
                    loading="lazy"
                    src={photoNao}
                    alt="many different language names displayed in different colors"
                  />
                </Grid.Item>
              </Grid>
            </div>
            <section class="o-section--inner l-wrap l-container--xs-s u-text-align--center u-spacing">
              <img
                loading="lazy"
                src={imagePuzzle}
                alt="Puzzle Pieces"
                style={{
                  maxWidth: '120px',
                  border: '7px solid #56189e',
                  borderRadius: '50%',
                }}
              />
              <h3 class="o-section-title o-section-title">
                Ready to integrate Akeneo with Smartling?
              </h3>
              <p>
                If you’d like to learn more about the integration with Akeneo or
                try it out for yourself, reach out to us today!
              </p>

              <Button url="/meeting-request/">Book a meeting</Button>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default AkeneoIntegrationPage
