import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/yext-integration-logo.png'
import imageIntegration from '../../../images/smartling-yext-integration.png'
import imageDemo1 from '../../../images/yext-managed-in-smartling.gif'
import imageDemo2 from '../../../images/yext-app-translation.gif'
import imageDemo3 from '../../../images/yext-translation-memory-workbench.gif'
import imageDemo4 from '../../../images/yext-translation-status.gif'
var imgStyle = {
  maxWidth: '500px',
}

class YextIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Yext Translation Integration | Smartling"
          description="Power your global content strategy with Smartling and the Yext translation integration. Discover the powerful Yext integration for translation."
          keywords=""
          image={heroImage}
          alt="Yext Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Translate Yext Location Data"
          >
            <Hero.Description>
              Smartling’s Yext translation integration is the first and only
              plugin listed on the Yext App Directory that automatically scales
              location data into any language.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Yext logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Translation for the entire search ecosystem
                    </h3>
                    <p>
                      Yext is the tool of choice for managing business
                      information across websites, search engines, voice
                      assistants, chatbots, apps, and maps. But translating the
                      descriptions, hours, locations and relevant business data
                      into multiple languages can quickly turn into a
                      time-consuming headache. As the business grows, so too
                      does this challenge. And the more information changes -
                      which is common today - the more time you have to spend
                      managing location data translation.
                    </p>
                    <p>
                      Smartling’s Yext integration allows users to
                      professionally translate location data stored in the
                      Yext Content with near-zero developer involvement, zero
                      copy and paste, and the ability to update content without
                      friction.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageIntegration}
                      alt="Yext integration"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDemo1}
                      alt="Yext Managed in Smartling"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Yext Content Managed in Smartling
                    </h3>
                    <p>
                      Smartling’s Yext translation integration automatically
                      pulls location data into Smartling’s translation
                      management platform for easy translation management. The
                      content is organized as you would see in Yext, which makes
                      it turnkey to make decisions about how to translate
                      different content fields within the Yext Content.
                      What’s more? Filters established in Yext are carried over
                      to further simplify this process.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Translate and auto-export translations
                    </h3>
                    <p>
                      Smartling built a simple Yext translation process so that
                      you can use human or machine translation. You choose based
                      on your content, budget, and desired process. Once
                      translations are complete, Smartling’s API-driven Yext
                      integration automatically delivers content back into Yext.
                      No more copy and pasting translated content into Yext one
                      string at a time. Smartling really makes it that simple.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDemo2}
                      alt="Translation in Yext"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDemo3}
                      alt="Yext Translation Memory Workbench"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Easily update content
                    </h3>
                    <p>
                      Listing details and questions to frequently answered
                      questions are regularly updated as products and services
                      change over time. Smartling’s translation integration
                      listed on the Yext App Directory makes it possible to
                      continuously optimize content, leveraging a dedicated or
                      shared translation memory to reduce cost exposure and
                      increase the speed of delivery.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Maintain full visibility of the process at all times
                    </h3>
                    <p>
                      Smartling’s Yext integration gives you the ability to
                      check translation status at any point in time. Get live,
                      real-time status updates at every step of the translation
                      process so you can get translations as quickly as possible
                      — without having to ever email, “Is it done yet?”
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDemo4}
                      alt="Yext translation status"
                    />
                  </div>
                </div>
              </div>
            </section>
            <Section className="l-wrap l-container--l">
              <Section.Header>
                <h2 class="u-text-align--center">
                  How A Relationship with Smartling Helped Yext Translate 12
                  Million Words to Support Teams Around the World
                </h2>
                <a
                  class="o-button  u-space"
                  href="/resources/case-study/yext/"
                  role="button"
                >
                  View the Case Study
                </a>
              </Section.Header>
            </Section>
            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="The leader in end-to-end translation management">
                  <Block.Image>
                    <Icons.IconRosette xxxl />
                  </Block.Image>
                  Deliver culturally relevant content that drives conversion and
                  fuels global growth. Smartling is the #1 rated translation
                  management system (TMS) by{' '}
                  <a
                    href="https://www.g2.com/products/smartling/reviews"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    users on G2
                  </a>
                  . Smartling’s cloud-based TMS is accessible anywhere there is
                  an internet connection, and our network of professional
                  translators are assigned to your account based on the domain
                  of your business.
                </Block>
              </Section.Header>
            </Section>
            <div className="u-gradient--secondary u-curve--top--left u-curve--bottom--center u-padding--section">
              <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
                <div class="c-section__header">
                  <div class="c-block u-spacing">
                    <div class="c-block__text u-spacing">
                      <div class="u-spacing">
                        <div class="c-block__description u-spacing--double">
                          <div class="u-text-align--left u-font-weight--bold">
                            “In our global economy, it’s critical for businesses
                            to provide official answers to all of their
                            customers, wherever they are in the world and
                            whichever language they speak. We’re proud to
                            introduce an integration with Smartling, the first
                            scalable translation platform in our App Directory,
                            to enable businesses to reach their customers at
                            scale, in any language.”
                          </div>
                          <div class="u-text-align--left">
                            <div class="u-flex">
                              <div>
                                <div>
                                  Tyler Donahue, Senior Director of Strategic
                                  Alliances at Yext.
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Section centerCTA>
              <Section.Header>
                <Block title="Be Discovered in Any Language">
                  <Block.Image>
                    <Icons.IconBinocularsRing xxxl />
                  </Block.Image>
                  Translate Yext content with Smartling. It’s a simple, scalable
                  solution to enable your discovery in any language.
                </Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default YextIntegrationPage
