import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
// TODO: Replace with actual image
import heroImage from '../../../images/integrations/customMT.jpg'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import photoNao from '../../../images/Nao_1.png'
import { Link } from 'gatsby'
var imgStyle = {
  maxWidth: '500px',
}

class CustomMTIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Custom.MT Machine Translation Integration | Translate with Smartling"
          description="Connect to more machine translation engines with greater language coverage within your translation management system."
          keywords="machine translation, custom.mt, translation, smartling, translation software, machine translation software"
          image={heroImage}
          alt="Custom.MT Translation Integration for Smartling"
        />

        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Smartling & Custom.MT Integration
"
          >
            <Hero.Description>
              Connect to more machine translation engines with access to greater
              language coverage within your translation management system.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Custom.MT logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a meeting</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--s c-product-intro-section">
              <div class="u-spacing--and-half u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  Multiple machine translation options that fit your TMS
                </h3>
                <p>
                  Augmenting the power of Smartling machine translation
                  solutions with CustomMT integration platform, you can automate
                  the translation of your content faster and more efficiently,
                  while feeling confident about quality. Custom.MT extends the
                  range of machine translation models available in the platform,
                  and customers can now use the TAUS Quality Estimation and
                  ModelFront quality evaluation frameworks through the
                  integration.
                </p>
                <Button url="/meeting-request/">Book a Meeting</Button>
              </div>
            </section>

            <Section id="braze-video" className="l-wrap l-container--l">
              <Section.Inner m>
                <div style="padding:56.25% 0 0 0;position:relative;">
                  <iframe
                    style="position:absolute;top:0;left:0;width:100%;height:100%;margin:0;"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Y-l_o0zaYs8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </Section.Inner>
            </Section>

            <section class="o-section--inner l-wrap l-container--s">
              <div class="u-spacing--and-half u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  Seamless integration and automation
                </h3>
                <p>
                  With Smartling’s Custom.MT connector, users gain access to
                  more engines and two state-of-the-art 3rd party quality
                  frameworks.
                </p>
                <p>
                  Within Smartling, machine translation workflows are automated
                  and linguistic assets, like glossaries and style guides, are
                  applied centrally so that translation is managed consistently
                  and adheres to brand guidelines, voice, and preferences. Plus,
                  machine learning accounts for spelling and inclusive language.
                </p>
                <p>
                  This all results in a reduced time to market of over 50% and
                  increased conversions by 70%, while quality is 100%
                  guaranteed.
                </p>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--xs-s u-text-align--center u-spacing">
              <img
                loading="lazy"
                src={imagePuzzle}
                alt="Puzzle Pieces"
                style={{
                  maxWidth: '120px',
                  border: '7px solid #56189e',
                  borderRadius: '50%',
                }}
              />
              <h3 class="o-section-title o-section-title">
                Ready to integrate Custom.MT with Smartling?
              </h3>
              <p>
                If you’d like to learn more about the integration with Custom.MT
                or try it out for yourself, reach out to us today!
              </p>

              <Button url="/meeting-request/">Book a meeting</Button>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default CustomMTIntegrationPage
