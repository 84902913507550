import React from 'react'
import Button from 'atoms/button'
import Metadata from '../layouts/metadata'
import Helmet from 'react-helmet'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import Section from 'atoms/section'

// Sections
import StateFarm from '../images/company-logos/statefarm-white.png'
import NewYork from '../images/company-logos/newyork-white.png'
import CloudFlare from '../images/company-logos/cloudflare-white.png'
import Tinder from '../images/company-logos/tinder-white.png'
import Lyft from '../images/company-logos/lyft-white.png'
import MasterCard from '../images/company-logos/mastercard-white.png'
import IconIntegration from '../images/icons/icon_integration-dark.png'
import IconRocket from '../images/icons/icon_rocket-dark.png'
import IconTranslationCloud from '../images/icons/icon_translation-cloud-dark.png'
import IconTranslator from '../images/icons/icon_translator-dark.png'

import Layout from 'layouts'
import SmartlingDefault from '../images/SmartlingDefault.jpeg'
const PpcDemo = () => {
  return (
    <Layout>
      <Metadata
        title="Smartling Translation Management | Demo"
        description="Share your info and someone from Smartling will be in touch ASAP, so you can get translating!"
        keywords="Smartling, translation management, localization, localization tool"
        image={SmartlingDefault}
      />
      <Section className="u-background--primary c-section__hero c-hero u-padding--bottom">
        <Section.Inner xl>
          <Grid
            twoUp
            className="l-grid--align-top u-space--quad--top u-space--and-half--bottom"
          >
            <Grid.Item>
              <h1 className="c-hero__title o-section-title--heavy">
                The fastest way to publish translations
              </h1>

              <p className="c-hero__description u-space--and-half">
                Scale your translation program with Smartling technology and
                services.
              </p>
              <p className="c-hero__description u-space--and-half">
                <span
                  role="img"
                  aria-label="checkmark"
                  className="u-color--quaternary u-space--left u-font--l"
                >
                  ✓
                </span>{' '}
                Faster time to market
                <br />
                <span
                  role="img"
                  aria-label="checkmark"
                  className="u-color--quaternary u-space--left u-font--l"
                >
                  ✓
                </span>{' '}
                Increased translation volumes
                <br />
                <span
                  role="img"
                  aria-label="checkmark"
                  className="u-color--quaternary u-space--left u-font--l"
                >
                  ✓
                </span>{' '}
                Reduced cost
              </p>
              <h3>Used by:</h3>
              <div className="l-grid l-grid--3x2 u-space--top u-space--triple--right">
                <div className="l-grid-area l-grid-area--a1">
                  <img loading="lazy" src={StateFarm} alt="State Farm" />
                </div>
                <div className="l-grid-area l-grid-area--a2">
                  <img loading="lazy" src={NewYork} alt="New York State" />
                </div>
                <div className="l-grid-area l-grid-area--a3">
                  <img loading="lazy" src={CloudFlare} alt="CloudFlare" />
                </div>
                <div className="l-grid-area l-grid-area--b1">
                  <img loading="lazy" src={Tinder} alt="Tinder" />
                </div>
                <div className="l-grid-area l-grid-area--b2">
                  <img loading="lazy" src={Lyft} alt="Lyft" />
                </div>
                <div className="l-grid-area l-grid-area--b3">
                  <img loading="lazy" src={MasterCard} alt="MasterCard" />
                </div>
              </div>
            </Grid.Item>
            <Grid.Item>
              <h3 className="u-align--center" id="bookademo">
                Book a 15-Min Demo
              </h3>
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <iframe
                  src="https://resource.smartling.com/l/703963/2022-02-03/2q1qpr"
                  width="90%"
                  height="780"
                  type="text/html"
                  frameborder="0"
                  style={{ marginTop: '5%', border: '0' }}
                  allowTransparency="true"
                ></iframe>
              </div>
            </Grid.Item>
          </Grid>
        </Section.Inner>
      </Section>

      <Section>
        <Section.Inner xl>
          <Grid fourUp className="l-grid--align-top u-space">
            <Grid.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  minHeight: '300px',
                }}
              >
                <img
                  loading="lazy"
                  src={IconIntegration}
                  alt="Integration"
                  style={{ maxWidth: '100px' }}
                />
                <h4>Easy to Connect</h4>
                <p className="u-align--center u-space--top">
                  Utilize Smartling’s powerful global proxy or pre-built
                  connectors to integrate directly into existing workflows.{' '}
                </p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  minHeight: '300px',
                }}
              >
                <img
                  loading="lazy"
                  src={IconTranslationCloud}
                  alt="TranslationCloud"
                  style={{ maxWidth: '100px' }}
                />
                <h4>Centralized Workflows</h4>
                <p className="u-align--center u-space--top">
                  Equipped with visual context, quality checks and productivity
                  tools to help streamline and accelerate time to market.
                </p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  minHeight: '300px',
                }}
              >
                <img
                  loading="lazy"
                  src={IconTranslator}
                  alt="Translator"
                  style={{ maxWidth: '100px' }}
                />
                <h4>Professional Translation</h4>
                <p className="u-align--center u-space--top">
                  Communicate directly with professional translators to create
                  localized experience for your end users
                </p>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  minHeight: '300px',
                }}
              >
                <img
                  loading="lazy"
                  src={IconRocket}
                  alt="Rocket"
                  style={{ maxWidth: '100px' }}
                />
                <h4>Translate at Scale</h4>
                <p className="u-align--center u-space--top">
                  Smartling makes it easy to bring content to users anywhere, on
                  any device - no matter what they speak.
                </p>
              </div>
            </Grid.Item>
          </Grid>
        </Section.Inner>
      </Section>
      {/* NOTE: adding body class must happen after Hero renders 
              because Hero component adds its own class  */}
      <section className="c-product-intro-section u-gradient--senery-alt u-padding--section c-section__bg--signup">
        <div className="u-spacing--and-half l-container l-wrap">
          <div className="u-spacing--double u-max-width--66--dt">
            <h3 className="c-block__title o-section-title o-section-title--heavy u-color--yellow--light">
              The fastest way to publish translations.
            </h3>
            <p className="c-cta__description">
              Personalize your content into any language with Smartling’s
              full-service translation solution.
            </p>
            <div
              class="c-cta__buttons"
              style={{ justifyContent: 'flex-start', marginLeft: '0px' }}
            >
              <a href="#bookademo" role="button" class="o-button  ">
                Get a Demo
              </a>
            </div>
          </div>
        </div>
      </section>
      <Helmet>
        <body className="page-ppcdemo u-header--white" />
      </Helmet>
    </Layout>
  )
}

export default PpcDemo
