exports.getBasicPath = function (path) {
  return `/${path}/`
}

exports.getIntegrationPath = function (slug) {
  return `/software/integrations/${slug}/`
}

exports.getTranslatorPath = function (slug) {
  return `/translation-services/translators/${slug}/`
}

exports.getCategoryPath = function (key) {
  return `/resources/${key}/`
}

exports.getResourcePath = function (slug) {
  return `/resources/${slug}/`
}

exports.getPodcastPath = function (slug, category) {
  return category ? `/podcasts/${category}/${slug}/` : `/podcasts/${slug}/`
}

exports.getPodcastCategoryPath = function (key) {
  return `/podcasts/${key}/`
}

exports.getPodcastCategoryArchivePath = function (key) {
  return `/podcast/archive/${key}`
}

exports.getTagPath = function (tag) {
  return `/resources/${tag}/`
}

exports.getPpcPath = function (slug) {
  return `/${slug}/`
}

exports.getPaidAdsPath = function (slug) {
  return `/${slug}/`
}
  
exports.getCompetitorsPath = function (slug) {
  return `/${slug}/`
}

exports.getEventCategoryPath = function (key) {
  return `/globalready/${key}/`
}

exports.getEventPath = function (slug, category) {
  return category
    ? `/globalready/${category.toLowerCase().replace(' ', '-')}/${slug}/`
    : `/globalready/${slug}/`
}

exports.getPersonPath = function (slug) {
  return `/${slug}/`
}

exports.getLearningHubPath = function (path) {
  return `/${path}/`
}
