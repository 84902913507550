import React from 'react'
import Metadata from '../../../layouts/metadata'
import Button from 'atoms/button'
import { StaticImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/jeff-left.png'

class TranslatePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="The Smartling Language Services Experience | Smartling"
          description="Not all language services providers are created equal. Smartling takes a different approach to language services, by helping word-class brands professionally translate their content on their terms."
          keywords="Translation agency, Professional translators, Professional translation services, Professional language services"
          image={heroImage}
        />
        <Hero
          curveLeftReverse
          gradientPrimary
          className="u-padding--hero-primary"
          title="The Smartling Experience"
        >
          <Hero.Description>
            <div className="u-spacing--and-half">
              <div className="c-hero__cta-text">
                Not all language services providers are created equal. See what
                sets us apart.
              </div>
            </div>
          </Hero.Description>
          <Hero.Image>
            <StaticImage
              src="../../../images/jeff-left.png"
              alt="Translation Partner"
              width={750}
              height={562}
              objectFit="scale-down"
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#language-services-consultation">
              Schedule a Consultation
            </Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Say Hello to your Translator
                  </h3>
                  <p>
                    Smartling’s{' '}
                    <a href="/translation-services/sls">Language Services</a>{' '}
                    offer the most direct relationship between customers and
                    translators of any provider on the market. You will not only
                    have access to great translators, but you will also have the
                    ability to communicate with them directly. And, you get to
                    do this without having to think about or manage any part of
                    the translation process.
                  </p>
                  <p>
                    <a href="/translation-services/translators/" class="o-link">
                      Meet Our Translators
                    </a>
                  </p>
                </div>
              </div>
              <div className="l-grid-item">
                <div class="c-product-intro-image">
                  <StaticImage
                    src="../../../images/nao-left.png"
                    alt="Translator Nao"
                    width={500}
                    height={375}
                    objectFit="scale-down"
                  />
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-grid--reverse--sm">
              <div className="l-grid-item">
                <div class="c-product-intro-image">
                  <StaticImage
                    src="../../../images/maria-right.png"
                    alt="Translator Maria"
                    width={500}
                    height={375}
                    objectFit="scale-down"
                  />
                </div>
              </div>
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Translation from the Source
                  </h3>
                  <p>
                    Smartling’s translators are 100% native speakers and almost
                    exclusively live in-market. They are professionally trained
                    in the disciplines of translation and frequently have
                    achieved advanced certifications or degrees in a specific
                    field. It's not uncommon for customers whose content
                    requires highly skilled writers in fields of law, medicine,
                    or other similarly critical fields to be matched with
                    experts in the respective domain. We do this every day
                    without breaking a sweat.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item u-space--and-half--bottom">
                <div class="u-spacing--and-half o-divider-gradient">
                  <h3 class="o-section-title o-section-title--heavy">
                    A partner that goes the extra mile.
                  </h3>
                </div>
              </div>
              <div className="l-grid-item u-spacing--and-half">
                <div className="c-icon-text-lockup">
                  <div class="c-icon-text-lockup__icon">
                    <span role="img" class="o-emoji" aria-label="clock emoji">
                      ⏰
                    </span>
                  </div>
                  <div class="u-spacing--half c-icon-text-lockup__text">
                    <h3>Managing language-specific deadlines</h3>
                    <p>
                      Once you submit a translation job and assign a deadline
                      for the project, the language services team takes the lead
                      on assigning the content to your specific translators, and
                      ensuring their time is properly balanced to meet the
                      delivery date of your project. For Smartling, it’s
                      incredibly simple to get a bird's eye view of a single
                      translator’s workload across every customer and adjust
                      priorities accordingly. Other service providers don’t have
                      this level of intelligence into their workforce, and the
                      result is missed deadlines and an unnecessary headache.
                    </p>
                  </div>
                </div>
                <div className="c-icon-text-lockup">
                  <div class="c-icon-text-lockup__icon">
                    <span
                      role="img"
                      class="o-emoji"
                      aria-label="cat with heart eyes emoji"
                    >
                      😻
                    </span>
                  </div>
                  <div class="u-spacing--half c-icon-text-lockup__text">
                    <h3>
                      Training translators on our CAT tool & the continuous
                      feedback loop
                    </h3>
                    <p>
                      Smartling translators are required to use{' '}
                      <a href="/software/translation-management-system/">
                        the platform
                      </a>{' '}
                      for 100% of the translation work. Because they are part of
                      our team, we take the time to fully train them on every{' '}
                      <a href="/software/CAT-tool/">CAT Tool</a> feature, and
                      how to leverage its communication tools so that you can
                      easily discuss string-level inputs. What’s more is that
                      because the translators work in the platform, you have the
                      ability to see exactly where they are in the process at
                      any given time, and we regularly run reports to ensure
                      each translator assigned to your account is completing
                      work within the benchmarks with a low correction rate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item u-space--and-half--bottom">
                <div class="u-spacing--and-half o-divider-gradient">
                  <h3 class="o-section-title o-section-title--heavy">
                    Language services that have your back.
                  </h3>
                </div>
              </div>
              <div className="l-grid-item u-spacing--and-half">
                <div className="c-icon-text-lockup">
                  <div class="c-icon-text-lockup__icon">
                    <span
                      role="img"
                      class="o-emoji"
                      aria-label="woman working on laptop emoji"
                    >
                      👩‍💻
                    </span>
                  </div>
                  <div class="u-spacing--half c-icon-text-lockup__text">
                    <h3>
                      Quality guarantee and adherence to linguistic assets
                    </h3>
                    <p>
                      At your direction and with our guidance, Smartling
                      configures a series of up to 27{' '}
                      <a href="https://help.smartling.com/hc/en-us/articles/360041858653-Work-with-Quality-Checks">
                        automated quality checks
                      </a>
                      . These quality checks notify the translator and Smartling
                      project manager (PM) when specific rules aren’t followed
                      in the translated content. The Smartling PM works
                      independently with the translator to resolve open issues
                      unless it requires the input of the customer.
                    </p>
                  </div>
                </div>
                <div className="c-icon-text-lockup">
                  <div class="c-icon-text-lockup__icon">
                    <span role="img" class="o-emoji" aria-label="rocket emoji">
                      🚀
                    </span>
                  </div>
                  <div class="u-spacing--half c-icon-text-lockup__text">
                    <h3>Get to market on your time</h3>
                    <p>
                      Translators take time off, countries shut down for
                      holidays, and people get sick. These are realities that
                      you don’t have to think about when you work with
                      Smartling’s language services. Our PMs work directly with
                      you to ensure project deadlines are met no matter the
                      circumstance.
                    </p>
                  </div>
                </div>
                <div className="c-icon-text-lockup">
                  <div class="c-icon-text-lockup__icon">
                    <span
                      role="img"
                      class="o-emoji"
                      aria-label="party celebrate emoji"
                    >
                      🥳
                    </span>
                  </div>
                  <div class="u-spacing--half c-icon-text-lockup__text">
                    <h3>Competitive rates & no project minimums</h3>
                    <p>
                      Smartling’s rates are competitive with{' '}
                      <a href="/translation-services/sls/lsp-industry-analysis/">
                        the industry
                      </a>{' '}
                      and we never require a minimum word count (so now you can
                      translate that one-word CTA change without paying excess
                      fees).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="o-section c-section__cta c-cta c-cta--center c-ready-to-start c-ready-to-start--zero-margin">
            <div className="o-section--inner l-wrap l-container--l">
              <div className="c-cta__content u-spacing u-text-align--center">
                <StaticImage
                  src="../../../images/gabriela-flag.png"
                  alt="Gabriela, a translator"
                  width={296}
                  height={222}
                  objectFit="scale-down"
                />
                <h2 className="c-cta__title">
                  Ready to translate on your terms?
                </h2>
                <div className="c-cta__description">
                  If the answer is yes, let’s connect!
                </div>
              </div>
              <div className="c-cta__buttons">
                <a
                  className="o-button  "
                  href="#language-services-consultation"
                  role="button"
                >
                  Schedule a Consultation
                </a>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default TranslatePage
