import React from 'react'
import { graphql } from 'gatsby'
import { getPersonPath } from '../routes'
import { DangerousHTML } from 'atoms/contentful/html'
import Block from 'molecules/block'

const BlockPerson = ({
  name,
  slug,
  headshot,
  positionTitle,
  company,
  companyLink,
  about,
  toggleAbout,
}) => {
  return (
    <React.Fragment>
      <Block
        title={name}
        className="c-block--person"
        link={getPersonPath(slug)}
      >
        {headshot && (
          <Block.Image>
            <img
              loading="lazy"
              src={`${headshot.file.url}?fit=fill&w=150&h=150`}
              alt={name}
            />
          </Block.Image>
        )}
        <div className="u-spacing--half">
          <h4 className="c-block__subtitle">
            {positionTitle && <span>{positionTitle}</span>}
            {company && <a href={companyLink}>{`${company}`}</a>}
          </h4>
          {toggleAbout && about && (
            <React.Fragment>
              <input
                type="checkbox"
                id={'o-toggle--' + slug}
                className="o-toggle__checkbox"
              />
              <label className="o-toggle__label" htmlFor={'o-toggle--' + slug}>
                View Session Description
              </label>

              <div className="o-toggle__item u-spacing--half">
                <DangerousHTML>{about.html.text}</DangerousHTML>
              </div>
            </React.Fragment>
          )}
        </div>
      </Block>
    </React.Fragment>
  )
}

export default BlockPerson

export const query = graphql`
  fragment ContentfulPersonFragment on ContentfulPersonConnection {
    totalCount
    edges {
      node {
        slug
        name
        headshot {
          ...ContentfulImageFragment
        }
        positionTitle
        company
        about {
          html: childMarkdownRemark {
            text: html
          }
        }
        metadataTitle
        metadataDescription
        metadataKeywords
        metadataImage {
          ...ContentfulImageFragment
        }
      }
    }
  }
`
