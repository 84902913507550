import React from 'react'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Button from 'atoms/button'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

// Images
import iconSubtitles from '../images/icons/icon_subtitles-light-ring.svg'

class verbalizeitPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    return (
      <Layout>
        <div>
          <Metadata
            title="VerbalizeIt subtitle translation - Smartling"
            description="Smartling acquired VerbalizeIt to professionally translates video subtitles into 150 languages."
            keywords="Language translation company, Translation company"
          />

          <Hero gradientPrimary curveBottom center title="VerbalizeIt">
            <Hero.Description>
              Video subtitles seamlessly translated by professionals
            </Hero.Description>
          </Hero>

          <Section className="u-padding--quad--bottom">
            <Section.Inner m>
              <Grid twoUp>
                <Grid.Item className="l-grid__item-image u-icon--250 u-text-align--center">
                  <img
                    loading="lazy"
                    src={iconSubtitles}
                    alt="Subtitles Icon"
                  />
                </Grid.Item>
                <Grid.Item>
                  <p className="u-space--double--bottom">
                    Smartling Acquired VerbalizeIt in 2016 to expand our video,
                    audio and multimedia content capabilities.
                  </p>
                  <Button
                    url="https://customers.verbalizeit.com/sign-in"
                    target="_blank"
                    className="u-spacing"
                  >
                    Log Into VerbalizeIt
                  </Button>
                  <a
                    href="https://www.smartling.com/resources/today/smartling-acquires-shark-tank-alumnus-verbalizeit-expand-multimedia-translation-capabilities/"
                    className="o-link u-space--double--top"
                  >
                    Read the News Release
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default verbalizeitPage
