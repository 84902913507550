import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'
import Button from 'atoms/button'
import DisplayButton from 'atoms/display-button'
import * as Icons from 'atoms/icons'
import BlueYellowLogo from '../images/logoblueyellow.svg'
import LanguageSwitcher from '../02-organisms/language-switcher'
import IconRight from '../images/icons/icon-gt.svg'

const Header = () => {
  const [active, setActive] = useState(false)
  const [btnTitle, setBtnTitle] = useState('EN')
  const [iconFlip, setIconFlip] = useState(false)
  const [language, setLanguage] = useState(false)

  const handleBtnClick = (e) => {
    setLanguage(!language)
    setIconFlip(!iconFlip)
  }

  const handleTitleClick = (e) => {
    setBtnTitle(e.target.innerHTML)
  }

  const handleMobileMenuClick = (e) => {
    setActive(true)
    setLanguage(false)
  }

  const classes = cx('c-header', 'l-wrap', 'l-container--xl', {
    'this-is-active': active,
  })

  return (
    <header className="c-header__wrap">
      <div className={classes} role="banner" id="header">
        <div className="c-header__logo">
          <div className="c-logo">
            <a href="/" className="c-logo__link" aria-label="Smartling Logo">
              <img
                src={BlueYellowLogo}
                width={200}
                height={38}
                style={{ height: 'auto', maxWidth: '200px' }}
                alt="Smartling Logo"
              />
            </a>
          </div>
        </div>
        <div className="c-header__group">
          <div className="c-header__nav">
            <Nav prefix="c-primary-nav" onClose={() => setActive(false)}>
              <Nav.SubMenu
                prefix="c-primary-subnav"
                title="Software"
                subClasses="c-primary-subnav--products c-primary-subnav--software is-expanded"
              >
                <div class="c-primary-subnav--block">
                  <h4 class="c-primary--subnav--block-heading">Automate</h4>
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Integrations"
                    to="/software/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Translation Proxy"
                    to="/software/global-delivery-network/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Smartling Translate"
                    to="/software/smartling-translate/"
                  />
                </div>

                <div class="c-primary-subnav--block">
                  <h4 class="c-primary--subnav--block-heading">Manage</h4>
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Translation Management System"
                    to="/software/translation-management-system/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="CAT Tool"
                    to="/software/CAT-tool/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Transcreation Tool"
                    to="/software/transcreation-tool/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Translation Analytics"
                    to="/software/translation-analytics/"
                  />
                </div>

                <div class="c-primary-subnav--block">
                  <h4 class="c-primary--subnav--block-heading">Translate</h4>
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="AI-Powered Human Translation"
                    to="/software/ai-powered-human-translation/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Machine Translation"
                    to="/software/neural-machine-translation-hub/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Language Services"
                    to="/translation-services/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="AI Translation Toolkit"
                    to="/software/ai-translation-toolkit/"
                  />
                </div>
              </Nav.SubMenu>

              <Nav.SubMenu
                prefix="c-primary-subnav"
                title="Services"
                subClasses="c-primary-subnav--products"
              >
                <h4 class="c-primary--subnav--block-heading">
                  Language Services
                </h4>

                <div class="c-primary-subnav--block">
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Translation Services"
                    to="/translation-services/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Managed Services"
                    to="/why-managed-services/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Professional Services"
                    to="/professional-services/"
                  />
                  <Nav.SubMenu
                    prefix="c-primary-subsubnav"
                    title="Our Translators"
                    to="/translation-services/translators/"
                  />
                </div>
              </Nav.SubMenu>

              <Nav.Item title="Plans" to="/plans" />

              <Nav.SubMenu
                prefix="c-primary-subnav"
                title="Use Cases"
                subClasses="c-primary-subnav--products"
              >
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Why Smartling"
                  to="/why-smartling/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Case Studies"
                  to="/resources/case-study/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="For Marketing"
                  to="/teams/marketing/"
                  subClasses="u-sub-menu--spacing"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="For Product"
                  to="/teams/product/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="For Customer Support"
                  to="/teams/customer-support/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="For Legal"
                  to="/teams/legal/"
                />
              </Nav.SubMenu>

              <Nav.SubMenu
                prefix="c-primary-subnav"
                title="Company"
                subClasses="c-primary-subnav--products"
              >
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="About"
                  to="/about-us/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Contact Us"
                  to="/contact-us/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Partners"
                  to="/partner-ecosystem/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Careers"
                  to="/careers/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="News"
                  to="/resources/press-release/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Resources and Blog"
                  to="/resources/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Events"
                  to="/globalready/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Global Ready Conference"
                  to="/conference/"
                />
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Podcast"
                  to="/podcasts/locshow/"
                />
              </Nav.SubMenu>
              <Nav.SubMenu
                prefix="c-primary-subnav"
                title="Demo"
                subClasses="c-primary-subnav--products mobile-only"
              >
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="Book a Meeting"
                  to="/meeting-request"
                ></Nav.SubMenu>
                <Nav.SubMenu
                  prefix="c-primary-subsubnav"
                  title="5-Minute Demo"
                  to="/demo"
                ></Nav.SubMenu>
              </Nav.SubMenu>
              <div className="mobile-only mobile-search">
                <a
                  class="o-button"
                  href="https://dashboard.smartling.com/"
                  role="button"
                  style={{
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    maxHeight: '2rem',
                    marginTop: '0.6rem',
                  }}
                >
                  Login
                </a>
              </div>
              <Nav.Item
                languageSwitcher={
                  <LanguageSwitcher
                    title={btnTitle}
                    clickBtn={handleBtnClick}
                    clickTitle={handleTitleClick}
                    language={language}
                    iconFlip={iconFlip}
                  />
                }
              />
            </Nav>
          </div>
        </div>
        <div className="u-flex" style={{ gap: '20px' }}>
          <div className="mobile-only">
            <LanguageSwitcher
              title={btnTitle}
              clickBtn={handleBtnClick}
              clickTitle={handleTitleClick}
              language={language}
              iconFlip={iconFlip}
            />
          </div>
          <div className="c-header__demo">
            <div className="u-demo-button">
              <div className="u-demo-button--nav">
                <Nav prefix="u-demo-nav" onClose={() => setActive(false)}>
                  <Nav.SubMenu
                    prefix="c-primary-subnav"
                    title="demo"
                    subClasses="c-primary-subnav--demo"
                    displayButton
                  >
                    <Nav.SubMenu
                      prefix="c-primary-subsubnav"
                      title="Book a Meeting"
                      to="/meeting-request"
                    >
                      <p>
                        Schedule a personalized introduction call with our team.
                      </p>
                    </Nav.SubMenu>
                    <Nav.SubMenu
                      prefix="c-primary-subsubnav"
                      title="5-Minute Demo"
                      to="/demo"
                    >
                      <p>Watch an on-demand walkthrough of Smartling</p>
                    </Nav.SubMenu>
                  </Nav.SubMenu>
                </Nav>
              </div>
              <div className="u-demo-button--link">
                <DisplayButton size="sm" secondary outline url="">
                  Demo
                </DisplayButton>
              </div>
            </div>
          </div>
          <div className="c-header__demo">
            <Button
              className="o-button--outline--white"
              outlineWhite
              url="https://dashboard.smartling.com/"
            >
              Login
            </Button>
          </div>
          <div className="c-header__toggle">
            {/* eslint-disable-next-line */}
            <div
              role="navigation"
              aria-label="Menu"
              className="c-header__toggle-menu"
              onClick={handleMobileMenuClick}
            >
              <Icons.Menu m />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

const PrefixContext = React.createContext(`nav`)

const Nav = ({ prefix, onClose, children }) => {
  return (
    <PrefixContext.Provider value={prefix}>
      <nav className={prefix} role="navigation">
        {/* eslint-disable-next-line */}
        <div
          aria-label="Close"
          className={`${prefix}__close`}
          onClick={onClose}
        >
          <Icons.Close m />
        </div>
        <ul className={`${prefix}__list`}>{children}</ul>
      </nav>
    </PrefixContext.Provider>
  )
}

Nav.propTypes = {
  prefix: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.children.isRequired,
}

Nav.Item = ({ to, title, languageSwitcher, displayButton }) => (
  <PrefixContext.Consumer>
    {(prefix) => (
      <>
        {!languageSwitcher && !displayButton && (
          <li
            className={`${prefix}__item has-subnav ${prefix}__item--products mobile-no-subnav`}
          >
            <a href={to} className={`${prefix}__link`}>
              <span>{title}</span>
            </a>
          </li>
        )}
        {languageSwitcher && languageSwitcher}
        {displayButton && displayButton}
      </>
    )}
  </PrefixContext.Consumer>
)

Nav.Item.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

Nav.SubMenu = ({ prefix, to, title, children, subClasses, displayButton }) => {
  const handClick = (t) => {
    const list = document.querySelector(
      `#mobile-nav-${title.replace(/\s+/g, '')}`
    )
    const button = document.querySelector(
      `#mobile-button-${title.replace(/\s+/g, '')}`
    )
    button.classList.toggle('is-expanded')
    list.classList.toggle('is-expanded')
  }

  return (
    <PrefixContext.Consumer>
      {(parentPrefix) => (
        <li className={`${parentPrefix}__item has-subnav ${subClasses || ''}`}>
          {/* eslint-disable-next-line no-script-url */}
          <a href={to || `#`} className={`${parentPrefix}__link`}>
            {title}
          </a>
          <button
            id={`mobile-button-${title.replace(/\s+/g, '')}`}
            className="mobile-expand"
            onClick={handClick}
          >
            <img aria-hidden src={IconRight} />
          </button>
          <ul
            id={`mobile-nav-${title.replace(/\s+/g, '')}`}
            className={`${prefix}__list u-spacing--quarter`}
          >
            <PrefixContext.Provider value={prefix}>
              {children}
            </PrefixContext.Provider>
          </ul>
        </li>
      )}
    </PrefixContext.Consumer>
  )
}

Nav.SubMenu.propTypes = {
  prefix: PropTypes.string.isRequired,
  to: PropTypes.string,
  subClasses: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.children.isRequired,
}
