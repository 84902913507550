import React from 'react'
import { graphql } from 'gatsby'
import Button from 'atoms/button'
import Metadata from '../layouts/metadata'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionLogoBanner from 'organisms/logo-banner'

import Layout from 'layouts'

class ExcellenceEveryTouchpointPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    return (
      <Layout>
        <Metadata
          title="Excellence at Every Touchpoint - Smartling"
          description=""
          keywords=""
          image=""
        />
        <Hero
          center
          curveBottom
          gradientPrimary
          title="Excellence at Every Touchpoint"
        >
          <Hero.Description>
            It’s how we deliver world-class customer service.
          </Hero.Description>
        </Hero>
        <article className="l-article u-spacing--sections u-padding--double--top">
          <SectionCta title="Five-to-One" centerCTA>
            <SectionCta.Description>
              Customer success is a shared responsibility. Make customers feel
              like there are five of us for every one of them.
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>
          <SectionCta
            title="Timeliness"
            centerCTA
            gradientSecondary
            curveTop
            curveBottomCenter
          >
            <SectionCta.Description>
              Always be on time. Immediacy speaks volumes; resolve or
              acknowledge customer requests with urgency.
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>
          <SectionCta title="Expertise" centerCTA>
            <SectionCta.Description>
              Train and retrain. Constantly ask: “Is this my best work?” Be
              radically candid with yourself and with each other.
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>
          <SectionCta
            title="Attentiveness"
            centerCTA
            gradientPrimary
            curveTop
            curveBottomCenter
          >
            <SectionCta.Description>
              Our customers aren’t businesses; they’re people. Have a relentless
              focus on their personal preferences.
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>
          <SectionCta title="Gratitude" centerCTA>
            <SectionCta.Description>
              Everything we do is for our customers. Show them how much we value
              their business by thanking them regularly.
            </SectionCta.Description>
            <SectionCta.Buttons />
          </SectionCta>
          <SectionCta
            title="Smartling’s people deliver excellence at every touchpoint for the most innovative businesses."
            centerCTA
            gradientSecondary
            curveTop
            curveBottomCenter
          >
            <SectionCta.Description />
            <SectionCta.Buttons />
          </SectionCta>
          <SectionLogoBanner>
            {data.logoOrdering.logos.map((logo) => (
              <SectionLogoBanner.Item
                url={logo.url}
                src={logo.image.file.url}
                alt={logo.name}
              />
            ))}
          </SectionLogoBanner>
          <SectionCta
            title="Do you deliver Excellence at Every Touchpoint, too?"
            centerCTA
          >
            <SectionCta.Description />
            <SectionCta.Buttons>
              <Button url="/contact-us">Contact Us</Button>
              <Button url="/careers">Join our team</Button>
            </SectionCta.Buttons>
          </SectionCta>
        </article>
      </Layout>
    )
  }
}

export default ExcellenceEveryTouchpointPage

export const query = graphql`
  query ExcellenceEveryTouchpointPageQuery {
    logoOrdering: contentfulLogoCarousel(title: { eq: "logo-banner" }) {
      title
      logos: sliderItems {
        url
        name
        image {
          gatsbyImageData(
            formats: [WEBP, AUTO]
            placeholder: BLURRED
            width: 148
            height: 42
          )
          file {
            url
          }
        }
      }
    }
  }
`
