import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionOnboarding = () => (
  <SectionCta title="Onboard in 30 days or less." centerCTA>
    <SectionCta.Description>
      Ready, set, translate. Our onboarding team will help you to realize value
      quickly.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/products/manage/onboarding-enablement-services">
        Learn More
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionOnboarding
