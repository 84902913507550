import React from 'react'
import Metadata from '../layouts/metadata'
import * as Icons from 'atoms/icons'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'
import SectionContact from 'components/sections/contact-us'

import Layout from 'layouts'

// Images
import headshotWelde from '../images/headshot-jack-welde.jpg'
import headshotSaxe from '../images/headshot-andrew-saxe.jpg'
import headshotMiele from '../images/headshot-frank-miele.jpg'
import headshotSogin from '../images/headshot-max-sogin.jpg'
import headshotGrimes from '../images/headshot-gavin-grimes.jpg'
import headshotNolan from '../images/headshot-a-nolan.png'
import headshotOlga from '../images/headshot-olga.png'
import headshotMurphy from '../images/headshot-b-murphy.jpeg'
import headshotKirby from '../images/headshot-tim-kirby.jpg'

class AboutUsPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    return (
      <Layout>
        <Metadata
          title="About Our Global Translation Services - Smartling "
          description="Smartling’s professional translation services are trusted by international brands to translate content for a global audience. With our efficient language translation and software machine learning, you can get to market faster."
          keywords="about us, team, smartling"
        />
        <Hero center curveBottom gradientPrimary title="About Us">
          <Hero.Description>
            Smartling was founded to make localization and translation seamless.
            Our LanguageAI™️ enables global businesses to reach customers in
            their native languages, across any channel.
            <br />
            <br />
            As an integrated platform built on artificial intelligence (AI),
            Smartling’s LanguageAI generates human-quality translations, faster,
            more reliably, and at a significantly lower cost when compared
            to traditional approaches. 
            <br />
            <br />
            Smartling customers span all industries and include British Airways,
            Canva, IHG, New York City Mayor’s Office, Volkswagen, PayPal, and
            more.. Each year Smartling translates billions of words into more
            than 450 languages and locales. We are SMARTLING GLOBAL READY – truly a global company with
            employees representing 17 countries and offices in New York City and
            Dublin.
          </Hero.Description>
        </Hero>
        <article className="l-article u-spacing--sections">
          <div className="u-background-accent--left u-background-accent--right">
            <Section className="c-section__grid-blocks c-grid-blocks c-grid-blocks__leadership-team">
              <Section.Header>
                <Block title="Meet the Leadership Team">
                  <Block.Image>
                    <Icons.HeaderUser xxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l>
                <Grid threeUp>
                  <Grid.Item>
                    <Block title="Bryan Murphy">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotMurphy}
                          alt="Bryan Murphy"
                        />
                      </Block.Image>
                      Chief Executive Officer
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Jack Welde">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotWelde}
                          alt="Jack Welde"
                        />
                      </Block.Image>
                      Founder & President
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Olga Beregovaya">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotOlga}
                          alt="Olga Beregovaya"
                        />
                      </Block.Image>
                      Vice President, Machine Translations and AI
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Gavin Grimes">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotGrimes}
                          alt="Gavin Grimes"
                        />
                      </Block.Image>
                      Vice President, Language Services
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Tim Kirby">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotKirby}
                          alt="Tim Kirby"
                        />
                      </Block.Image>
                      Chief Revenue Officer
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Frank Miele">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotMiele}
                          alt="Frank Miele"
                        />
                      </Block.Image>
                      Chief Financial Officer
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Aisling Nolan">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotNolan}
                          alt="Aisling Nolan"
                        />
                      </Block.Image>
                      Vice President, Customer Success
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Andrew Saxe">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotSaxe}
                          alt="Andrew Saxe"
                        />
                      </Block.Image>
                      Vice President, Product
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block title="Max Sogin">
                      <Block.Image>
                        <img
                          loading="lazy"
                          src={headshotSogin}
                          alt="Max Sogin"
                        />
                      </Block.Image>
                      Vice President, Technology
                    </Block>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>
          </div>

          <div className="u-background-accent--left">
            <SectionContact />
          </div>
        </article>
      </Layout>
    )
  }
}

export default AboutUsPage
