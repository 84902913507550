import React from 'react'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

class PartnersPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Become A Partner - Smartling"
          description="By partnering with Smartling, you receive custom native integrations and translation management solutions for your business. Let’s build something together."
          keywords=""
        />
        <Hero center curveBottom gradientPrimary title="Partner with Us">
          <Hero.Description>
            <h5>Let’s build something together.</h5>
            <p>
              Building strong relationships is at the core of what we do. We’re
              here to ensure that our partnership provides your customers with
              the best possible solution.{' '}
            </p>
            <p>
              Within our constantly growing partner ecosystem, we work with the
              industry’s leading software platforms, systems integrators,
              marketing firms, digital agencies, and language service providers.
              Together, we enable brands to expand their reach and drive
              efficiency.
            </p>
            <Button url="mailto:hi@smartling.com">Get in Touch</Button>
          </Hero.Description>
        </Hero>
        <article className="l-article u-spacing--sections">
          <div className="l-article__body">
            <div className="o-text u-spacing--double" />
          </div>

          <SectionCta title="Referral Program" centerCTA checkmark>
            <SectionCta.Description>
              <p>
                Your customers trust you to make technology recommendations so
                they can extend their use of your software, solution or service.
              </p>
              <h5>Referral Program</h5>
              <ul>
                <li>Receive guidance to identify customer fit</li>
                <li>Make technology recommendations to your clients</li>
                <li>Take advantage of referral commission</li>
              </ul>
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="mailto:hi@smartling.com ">Learn More</Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionCta
            title="Integration Program"
            gradientSecondary
            curveTop
            centerCTA
            checkmark
            curveBottomCenter
          >
            <SectionCta.Description>
              <p>
                Enhance the functionality of your software platform with a
                seamless custom integration, extending the power of your current
                technology.
              </p>
              <ul>
                <li>Receive a custom integration plan</li>
                <li>
                  Get access to our translation software API and developer
                  documentation
                </li>
                <li>Get developer support to build a connector</li>
              </ul>
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="mailto:hi@smartling.com">Learn More</Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionCta title="Affiliate Program" centerCTA checkmark>
            <SectionCta.Description>
              <p>
                You need the best possible translation platform to fulfill your
                customer’s translation needs.{' '}
              </p>
              <ul className="o-bullet-list o-bullet-list--less-pad u-padding u-space--left u-space--right">
                <li>Integrate / plug-in your system</li>
                <li>Translate your content</li>
                <li>Launch translations & grow your customers’ business</li>
              </ul>
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="mailto:hi@smartling.com">Learn More</Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionCta
            gradientSecondary
            centerCTA
            checkmark
            title="Affiliate Program"
          >
            <SectionCta.Description />
            <SectionCta.Buttons>
              <Button url="mailto:hi@smartling.com">Get in Touch</Button>
            </SectionCta.Buttons>
          </SectionCta>
        </article>
      </Layout>
    )
  }
}

export default PartnersPage
