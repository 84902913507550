import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'
import { oneByType, withoutTypes } from '../util/react'

const Section = ({
  className,
  style,
  children,
  gradientPrimary,
  gradientPrimaryAlt,
  gradientSecondary,
  gradientTertiary,
  gradientSenery,
  gradientQuaternary,
  gradientAccent,
  gradientAccentAlt,
  curveTop,
  curveTopRight,
  curveTopLeft,
  curveBottom,
  curveBottomRight,
  curveBottomLeft,
  databgset,
  id,
}) => {
  const classes = cx(className, {
    'u-gradient--primary': gradientPrimary,
    'u-gradient--primary-alt': gradientPrimaryAlt,
    'u-gradient--secondary': gradientSecondary,
    'u-gradient--tertiary': gradientTertiary,
    'u-gradient--senery': gradientSenery,
    'u-gradient--quaternary': gradientQuaternary,
    'u-gradient--accent': gradientAccent,
    'u-gradient--accent-alt': gradientAccentAlt,
    'u-curve--top': curveTop,
    'u-curve--top--right': curveTopRight,
    'u-curve--top--left': curveTopLeft,
    'u-curve--bottom--right': curveBottomRight,
    'u-curve--bottom--left': curveBottomLeft,
    'u-curve--bottom': curveBottom,
    lazyload: databgset,
  })

  return (
    <section
      style={style ? style : null}
      className={`o-section ${classes}`}
      data-bgset={databgset ? databgset : ''}
      id={id}
    >
      {oneByType(children, Section.Header)}
      {oneByType(children, Section.Inner)}
      {withoutTypes(children, Section.Header, Section.Inner)}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
}

Section.Header = ({ children }) => (
  <div className="c-section__header">{children}</div>
)

Section.Inner = ({ className, children, s, m, l, xl, full }) => {
  const classes = cx(className, {
    'l-container--s': s,
    'l-container--m': m,
    'l-container--l': l,
    'l-container--xl': xl,
    'l-container--full': full,
  })

  return <div className={`o-section--inner l-wrap ${classes}`}>{children}</div>
}

export default Section
