import React, {useState} from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Hero from 'organisms/hero'
import Grid from 'atoms/grid'
import Button from 'atoms/button'
import Layout from 'layouts/index-ppc'
import PardotForm from 'components/forms/pardot'
import LandingPageSection from 'components/sections/landing-page-section'
import SectionLogoBanner from 'organisms/logo-banner'
import SectionCta from 'organisms/cta'
import { GatsbyImage } from 'gatsby-plugin-image'
import Section from 'atoms/section'
import { Link } from 'gatsby'

import { getIntegrationPath } from '../routes'

import MetaDataImage from '../images/meta-image.png'

const IntegrationEntry = ({ name, description, logo, slug, integrationsPageLink }) => (
  <div className="c-card--integration">
    <div className="c-card--integration__top">
      {logo && (
        <img
          loading="lazy"
          style={{ height: '120px' }}
          alt=""
          src={logo ? logo.file.url : ``}
        />
      )}
      <div className="u-space--top u-spacing">
        <h4 className="c-card--integration__name">
          {name && <DangerousHTML>{name}</DangerousHTML>}
        </h4>
        <p className="c-card--integration__description">
          {description && <DangerousHTML>{description}</DangerousHTML>}
        </p>
      </div>
    </div>
    <div className="c-card--integration__bottom">
      <div>
        <a
          role="button"
          href={integrationsPageLink ? integrationsPageLink : getIntegrationPath(slug)}
          className="o-link u-color--primary"
        >
          Learn more
        </a>
      </div>
    </div>
  </div>
)

const PpcTemplate = ({ data }) => {
  const heroImage = data.page.heroImage
  console.log(data.page.description)

  const [selectedCategory, setSelectedCategory] = useState("all");
  const [firstLoad, setFirstLoad] = useState(true);
  const [selectWidth, setSelectWidth] = useState('auto');

  const categories = {
    contmanagement: { data: data.cmsIntegrations, name: "Content Management Systems" },
    markautomation: { data: data.marketingIntegrations, name: "Marketing Automation" },
    ecommerce: { data: data.ecommerceIntegrations, name: "eCommerce" },
    cloudstorage: { data: data.cloudStorageIntegrations, name: "Cloud Storage" },
    custsupport: { data: data.customerSupportIntegrations, name: "Customer Support" },
    design: { data: data.appSupportIntegrations, name: "Design" },
    coderep: { data: data.codeRepositoryIntegrations, name: "Code Repository & Developer Tools" },
    pim: { data: data.pimIntegrations, name: "PIM" },
    projectmanagement: { data: data.projectManagmentIntegrations, name: "Project Management" },
    machinetranslation: { data: data.machineTranslationIntegrations, name: "Machine Translation" },
    subtitling: { data: data.subtitlingIntegrations, name: "Subtitling" },
    customerengagement: { data: data.customerEngagementIntegrations, name: "Customer Engagement" },
    terminologymanagement: { data: data.terminologyManagamentIntegrations, name: "Terminology Management" },
    collaboration: { data: data.collaborationIntegrations, name: "Collaboration" },
  };

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
    setFirstLoad(false);  // After the first selection, set firstLoad to false
  };

  return (
    <Layout>
      <div className="c-ppc-detail c-ppc-software">
        <Metadata
          title={data.page?.metadataTitle || data.page.title}
          description={data.page?.metadataDescription}
          keywords={data.page?.metadataKeywords?.join(`, `)}
          image={MetaDataImage}
        />
        <Hero
          className="c-hero__resource o-hero--custom"
          curveBottom
          backgroundPrimary
          title={data.page.heroTitle}
        >
          <Hero.Description>
            {data.page.heroDescription && (
              <div className="c-hero__description--wrap">
                <DangerousHTML>
                  {data.page.heroDescription.html.text}
                </DangerousHTML>
              </div>
            )}
            {data.page.description && (
              <p className="c-hero__sub-description">{data.page.description}</p>
            )}
          </Hero.Description>
          <Hero.Image>
            {data.page.heroImage && (
              <>
                {heroImage.gatsbyImageData ? (
                  <GatsbyImage
                    image={heroImage.gatsbyImageData}
                    alt={heroImage.title}
                    objectFit="contain"
                    width="1476"
                    height="852"
                    // title={heroDescription.description}
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={heroImage.file.url}
                    alt={heroImage.title}
                    width="1476"
                    height="852"
                  />
                )}
              </>
            )}
          </Hero.Image>

          {/* {data.page?.pardotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'In Hero' ? (
                <div className="c-ppc__hero--pardot">
                  <h3 className="c-ppc__hero--pardot__heading">
                    Please provide your information below
                  </h3>
                  <PardotForm
                    iframe={data.page?.pardotFormReference?.pardotFormLink}
                  />
                  <p>
                    For translators 
                    interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              ) : (
                ``
              ))} */}

          <Hero.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn more`}
                  </Button>
                )
              })}
          </Hero.Buttons>
        </Hero>
        <Section className="c-section__integrations">
          {data.page.introSection.id && (
            <Section className="c-section__integrations__intro u-space--quad--bottom">
              <Section.Inner m className="u-spacing--triple">
                {data.page.introSection.headline && (
                  <h2>
                    {data.page.introSection.headline}
                  </h2>
                )}
                {data.page?.introSection?.description?.description && (
                  <p>
                    {data.page.introSection.description.description}
                  </p>
                )}
              </Section.Inner>
            </Section>
          )}
          <Section className="c-section__integrations__dropdown-container">
            <Section.Inner xl className="u-spacing">
              <Section className="c-section__integrations__dropdown">
                <select value={selectedCategory} onChange={handleSelectChange}>
                  <option value="all">{firstLoad ? "Select Integration" : "All"}</option>
                  {Object.entries(categories).map(([key, value]) => {
                    if (value.data !== undefined && value?.data?.edges.length !== 0) {
                      return <option value={key}>{value.name}</option>
                    }
                  })}
                </select>
              </Section>
            </Section.Inner>
          </Section>

          {selectedCategory === "all" ?
            Object.keys(categories).map(key => {
              const integrationData = categories[key].data;
              const categoryName = categories[key].name;

              if (typeof integrationData !== 'undefined' && integrationData.edges.length !== 0) {
                return (
                  <Section id={key} className="c-section__block-rows c-block-rows c-section__block-integrations">
                    <Section.Inner xl className="u-spacing">
                      <h3>{categoryName}</h3>
                      <Grid threeUp className="l-grid--tighter">
                        {integrationData &&
                          integrationData.edges.map(({ node }) => (
                            <Grid.Item>
                              <IntegrationEntry {...node} />
                            </Grid.Item>
                          ))}
                      </Grid>
                    </Section.Inner>
                  </Section>
                )
              };
            }) :
            <Section id={selectedCategory} className="c-section__block-rows c-block-rows c-section__block-integrations">
              <Section.Inner xl className="u-spacing">
                <h3>{categories[selectedCategory].name}</h3>
                <Grid threeUp className="l-grid--tighter">
                  {categories[selectedCategory].data &&
                    categories[selectedCategory].data.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>
          }
          {data.page.integrationsButton.buttonLink && (
            <Section className="u-align--center">
              <Section.Inner xl className="u-spacing">
                <div className="u-space--quad--top">
                  <Button url={data.page.integrationsButton.buttonLink} target="_blank">
                    {data.page.integrationsButton.buttonText ? data.page.integrationsButton.buttonText : `Learn more`}
                  </Button>
                </div>
              </Section.Inner>
            </Section>
          )}
        </Section>

        <Section className="c-section--testimonial o-section u-padding--sext--top u-padding--quint--bottom">
          <Section.Inner m>
            <div className="o-testimonial--block">
              <div className="u-space--quad--bottom">
                <h3 className="o-testimonial--quote u-text--line-height--tight ">
                  {data.page.testimonial.description.description}
                </h3>
              </div>
              <div className="o-testimonial--block--content">
                {data.page.testimonial.image.gatsbyImageData ? (
                  <GatsbyImage
                    image={data.page.testimonial.image.gatsbyImageData}
                    alt={data.page.testimonial.image.title}
                    objectFit="contain"
                  />
                ) : (
                  <img
                    src={data.page.testimonial.image.file.url}
                    alt={data.page.testimonial.image.file.title}
                    width="209"
                    height="48"
                    loading="lazy"
                  />
                )}
                <div className="o-testimonial--block__author">
                  <p className="u-font--m u-font-weight--bold o-testimonial--block__author--tile">
                    {data.page.testimonial.secondaryTitle}
                  </p>
                  <p className="u-font--m o-testimonial--block__author--tile">
                    {data.page.testimonial.secondaryDescription}
                  </p>
                </div>
                <Link
                  to={data.page.testimonial?.button.buttonLink}
                  class="o-link u-color--primary o-testimonial--block__link"
                >
                  {data.page.testimonial?.button.buttonText}
                </Link>
              </div>
            </div>
          </Section.Inner>
        </Section>
        {data.page.pageSections &&
          data.page.pageSections.map((section) =>
            section.internal.type === 'ContentfulLogoCarousel' ? (
              <div className="c-logo-banner__book-meeting">
                <SectionLogoBanner title={section.title}>
                  {section.sliderItems.map((logo) => (
                    <SectionLogoBanner.Item
                      url={logo.image?.url}
                      src={logo.image?.file.url}
                      alt={logo.image.title}
                    />
                  ))}
                </SectionLogoBanner>
              </div>
            ) : (
              <LandingPageSection {...section} />
            )
          )}
        {data.page?.pardotFormReference &&
          (data.page?.pardotFormReferencePlacement === 'Below Body' ||
          data.page?.pardotFormReferencePlacement === 'Hero and Below Body' ? (
            <div
              className={`l-wrap ${
                data.page?.pardotFormReference.name ===
                'Email Capture - Viewed How Lyft Scaled Content for 8 Languages PPC Webinar'
                  ? 'l-container--s'
                  : 'l-container--xs'
              }`}
            >
              <PardotForm
                iframe={data.page?.pardotFormReference?.pardotFormLink}
              />
            </div>
          ) : (
            ``
          ))}
      </div>
    </Layout>
  )
}

export default PpcTemplate

export const pageQuery = graphql`
  fragment ContentfulIntegrationFragment on ContentfulIntegrationConnection {
    edges {
      node {
        name
        description
        logo {
          ...ContentfulImageFragment
        }
        integrationsPageLink
        slug
        integrationsPageLink
      }
    }
  }
  query SoftwareQuery {
    cmsIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: {
        type: { eq: "CMS Integrations" }
        slug: { ne: "test-integration-for-gatsby-bug-do-no-delete" }
      }
    ) {
      ...ContentfulIntegrationFragment
    }
    marketingIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Marketing Automation" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    ecommerceIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "eCommerce" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    cloudStorageIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Cloud Storage" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    codeRepositoryIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Code Repository" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    customerSupportIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Customer Support" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    appSupportIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Application Support" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    pimIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "PIM" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    projectManagmentIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Project Management" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    machineTranslationIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Machine Translation" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    subtitlingIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Subtitling" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    customerEngagementIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Customer Engagement" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    terminologyManagamentIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Terminology Management" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    collaborationIntegrations: allContentfulIntegration(
      sort: { fields: [name], order: ASC }
      filter: { type: { eq: "Collaboration" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    page: contentfulPpc(slug: { eq: "software" }) {
      name
      slug
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      description
      heroImage {
        # ...ContentfulImageFragment
        file {
          url
        }
        gatsbyImageData(
          width: 720
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          height: 426
        )
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      introSection {
        id
        headline
        description {
          description
        }
      }
      integrationsButton {
        id
        buttonLink
        buttonText
        newWindow
      }
      testimonial {
        headline
        description {
          description
        }
        image {
          title
          gatsbyImageData(
            width: 209
            height: 48
            quality: 100
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
          file {
            url
          }
        }
        button {
          buttonLink
          buttonText
          newWindow
        }
        secondaryTitle
        secondaryDescription
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
        ... on ContentfulLogoCarousel {
          internal {
            type
          }
          title
          sliderItems {
            image {
              file {
                url
              }
              description
              title
            }
          }
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`