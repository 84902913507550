import React from 'react'

const JsonLd = ({ title, url, type }) => (
  <script
    type="application/ld+json"
    dangerouslySetInnerHTML={{
      __html: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: 'Smartling',
            item: 'https://smartling.com',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: 'Learning Center',
            item: 'https://smartling.com/resources',
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `${title}`,
            item: `https://smartling.com/${url}`,
          },
        ],
      }),
    }}
  />
)
export default JsonLd
