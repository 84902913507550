import React from 'react'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

// Images
import ebook from '../images/DA20210811 - 4 Step Guide to Bringing on a TMS - 750x420 - Website – 4@2x.png'

class onboardTMSPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    return (
      <Layout>
        <div>
          <Metadata
            title="How to Choose a Translation Management System - Smartling"
            description="Maximize your time, money and quality of translations with a comprehensive TMS partner."
            keywords=""
            image={ebook}
          />
          <Hero
            gradientPrimary
            curveBottom
            center
            title="How to Choose a Translation Management System"
          >
            <Hero.Description>
              Get started with Smartling to maximize your time, improve
              translation quality and reduce the overall cost of localization.
            </Hero.Description>
          </Hero>

          <Section className="u-padding--section">
            <Section.Inner l>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={ebook} alt="ebook" />
                </Grid.Item>
                <Grid.Item>
                  <h3 className="u-space--bottom">
                    Integrations, onboarding time, hidden fees - you know the
                    drill...{' '}
                    <span role="img" aria-label="facepalm">
                      🤦‍♂️
                    </span>
                  </h3>
                  <p className="u-space--bottom">
                    Check out our 4-Step guide for a quick look at what you need
                    to know as you start evaluating TMS partners.
                  </p>
                  <a href="#guide" className="o-link">
                    Get the Guide
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section">
            <Section.Header>
              <h2 className="u-color--primary u-space--bottom">
                Your Content. Any Language.
              </h2>
              <p>
                The type of translation management software you choose depends
                on a few things:
              </p>
            </Section.Header>
            <Section.Inner s>
              <div className="u-flex u-justify-content--center">
                <ul className="o-list--checked u-space--zero--right">
                  <li>The volume of content that requires translation</li>
                  <li>
                    The number of languages and regions that need to be
                    represented
                  </li>
                  <li>
                    Automation objectives - typically dictated by the number of
                    content integrations required
                  </li>
                  <li>Complexity of content, brand tone and style</li>
                  <li>
                    The number of{' '}
                    <span role="img" aria-label="chef">
                      🧑‍🍳
                    </span>
                    s in the kitchen{' '}
                    <span role="img" aria-label="winking">
                      😉
                    </span>
                  </li>
                </ul>
              </div>
              <p className="u-space--double--top">
                Smartling's platform supports automation at all levels, and has
                different solutions and price points for customers looking to
                get started, scale or optimize their early stage or enterprise
                translation programs.
              </p>
            </Section.Inner>
          </Section>

          <Section
            gradientSecondary
            className="c-section__grid-blocks c-grid-blocks u-padding--section"
          >
            <Section.Header>
              <h2>Smartling’s guide includes:</h2>
            </Section.Header>
            <Section.Inner l className="u-padding--top">
              <Grid threeUp>
                <Grid.Item>
                  <Block title="Gaining Cross-Functional Internal Buy-in">
                    If you want to be a global company, you need to be a global
                    company at every level.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Most Important Questions to Ask a LSP">
                    Drill down to the finer details to make sure your provider
                    can meet your expectations.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Getting Started In 30 Days Or Less">
                    Onboarding, training and preparing your team to start
                    realizing value immediately.
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section" id="guide">
            <Section.Inner l>
              <h2 className="u-text-align--center u-space--double--bottom">
                Your Framework for Evaluating a TMS
              </h2>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={ebook} alt="ebook" />
                </Grid.Item>
                <Grid.Item>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a name="o-pardot-form">
                    <div className="o-pardot-form">
                      <iframe
                        title="Pardot Form"
                        id="pardotiframe"
                        width="100%"
                        height="200"
                        type="text/html"
                        allowTransparency={true}
                        scrolling="no"
                        className="lazyload u-center-block pardot-responsive"
                        data-src="https://resource.smartling.com/l/703963/2021-08-24/2dhlb1"
                      />
                    </div>
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <div className="u-background-accent--right u-background-accent--right-small u-background-accent--left u-background-accent--left-small u-padding--section">
            <SectionCta centerCTA className="c-cta--why-smartling">
              <SectionCta.Description>
                <div className="u-spacing">
                  <h2>
                    Ready to swipe right?{' '}
                    <span role="img" aria-label="fire">
                      🔥
                    </span>
                  </h2>
                  <div>
                    Businesses depend on technology to drive efficiency and cost
                    savings. At Smartling, we take this to heart and are
                    equipped to scale alongside your ever-changing business.
                  </div>
                  <div>Book a meeting with our team to get started.</div>
                  <Button url="/meeting-request/" className="u-spacing">
                    REQUEST A DEMO
                  </Button>
                </div>
              </SectionCta.Description>
            </SectionCta>
          </div>
        </div>
      </Layout>
    )
  }
}

export default onboardTMSPage
