import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionTranslationInterface = () => (
  <SectionCta title="Hello > Hola > Salut > 你好 > مرحبا > Ciao" centerCTA>
    <SectionCta.Description>
      Our translator workbench is rich with features so you can be rich in
      meaning. Say hello to visual context, real-time translation memory and a
      modern workspace.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/products/translate/translation-interface/">
        Learn more
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionTranslationInterface
