import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionProfessionalServices = () => (
  <SectionCta title="An extra hand goes a long way." centerCTA>
    <SectionCta.Description>
      Solutions consulting and advanced technical support to advance
      integrations.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/meeting-request/">Learn More</Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionProfessionalServices
