import React from 'react'
import { Link, graphql } from 'gatsby'
import Metadata from '../../layouts/metadata'
import PropTypes from '../../prop-types'
import { getIntegrationPath } from '../../routes'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import Image from 'atoms/contentful/image'
import { DangerousHTML } from 'atoms/contentful/html'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Card from 'molecules/card'

import Layout from 'layouts'

// Images
import heroImage from '../../images/illustrations/integration.svg'
import teamManagers from '../../images/illustrations/Teams_Translation_Managers.png'
import iconBinoculars from '../../images/icons/icon_binoculars-light-crop.png'
import sectionImage2 from '../../images/Patrick-At-Work_01.jpg'

class IntegrationsPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    return (
      <Layout>
        <Metadata
          title="Smartling Platform Technology Integrations | Smartling"
          description="Smartling’s Translation Management System integrates with Content Management Systems (CMS) , Marketing Automation platforms, eCommerce product information management solutions, Code Repositories, Customer Support software, and Design Applications, making it easy for you to connect your entire technology stack. Check out the full integration list."
          keywords="smartling, platform, technology, integrations"
        />
        <Hero
          curveLeftReverse
          gradientPrimary
          title="Easy integrations. Effortless translation."
        >
          <Hero.Description>
            We made it easy to connect your tech stack with Smartling so you can
            translate website pages, landing pages, help centers and more
            without leaving the tools you already know. The result? Spend less
            time managing translation.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Analyze" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Get a Demo</Button>
          </Hero.Buttons>
        </Hero>

        <div className="l-article u-spacing--sections">
          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    So long, copy and paste.{' '}
                    <span role="img" aria-label="waving hand">
                      👋
                    </span>
                  </h3>
                  <p>
                    The dog days of managing translation via email and
                    spreadsheets are over. Software automation is “in.” By
                    leveraging our industry-leading translation proxy,
                    customizing an integration with our translation API, or
                    using one of our connectors, you can automate the entire
                    process surrounding translation without having to worry
                    about version control, file management, emailing with
                    vendors, and much more (like automated visual context
                    capture).
                  </p>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img
                    loading="lazy"
                    src={teamManagers}
                    alt="Teams Translate Manager"
                  />
                </div>
              </div>
            </div>
          </section>

          <div className="c-integration-blocks u-background-accent--left u-background-accent--right u-spacing--sections">
            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Content Management System Integrations">
                  <Block.Image>
                    <Icons.IconWebsite xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.cmsIntegrations &&
                    data.cmsIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block
                  title="Marketing Automation Integrations"
                  anchor="marketing-automation"
                >
                  <Block.Image>
                    <Icons.IconContent2 xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.marketingIntegrations &&
                    data.marketingIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="eCommerce Integrations">
                  <Block.Image>
                    <Icons.IconShopping xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.ecommerceIntegrations &&
                    data.ecommerceIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Code Repository Integrations and Developer Tools">
                  <Block.Image>
                    <Icons.IconCogs xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.codeRepositoryIntegrations &&
                    data.codeRepositoryIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Customer Support Integrations">
                  <Block.Image>
                    <Icons.IconTeam xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.customerSupportIntegrations &&
                    data.customerSupportIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>

            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block
                  title="Design Application Support Integrations"
                  anchor="application-support"
                >
                  <Block.Image>
                    <Icons.IconPencil xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--quad">
                <Grid twoUp className="c-cards">
                  {data.appSupportIntegrations &&
                    data.appSupportIntegrations.edges.map(({ node }) => (
                      <Grid.Item>
                        <IntegrationEntry {...node} />
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>
          </div>
          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Onboarding, enablement, and professional services.
                  </h3>
                  <p>
                    Our professional services team is here to help you. We
                    partner with our customers to accelerate implementations and
                    onboarding, manage product migrations, maintain custom
                    integrations, and provide custom training packages for new
                    team members. Think of us as an extension of your team to
                    help you meet your demanding business goals, every time.
                  </p>
                  <p>
                    <a
                      role="button"
                      href="/products/manage/onboarding-enablement-services/"
                      class="o-link u-spacing--right"
                    >
                      Onboarding & Enablement Services
                    </a>
                    <a
                      role="button"
                      href="/professional-services/"
                      class="o-link"
                    >
                      Professional Services
                    </a>
                  </p>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img
                    loading="lazy"
                    src={sectionImage2}
                    alt="Man in office working"
                  />
                </div>
              </div>
            </div>
          </section>
          <section className="o-section c-section__cta c-cta c-cta--center c-ready-to-start c-ready-to-start--nb">
            <div className="o-section--inner l-wrap l-container--l">
              <div className="c-cta__content u-spacing u-text-align--center">
                <img
                  loading="lazy"
                  className="c-cta__header-img"
                  src={iconBinoculars}
                  alt="Binoculars Icon"
                />
                <h2 className="c-cta__title">
                  Ready to automate translation with Smartling?
                </h2>
                <div className="c-cta__description">
                  Say goodbye to dated workflows. And hello to automated
                  translation.
                </div>
              </div>
              <div className="c-cta__buttons">
                <a
                  className="o-button  "
                  href="/meeting-request/"
                  role="button"
                >
                  Book a Demo
                </a>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

const IntegrationEntry = ({ name, description, logo, slug }) => (
  <Card link={getIntegrationPath(slug)} linkText="Learn More">
    <Card.Image>
      <Link to={getIntegrationPath(slug)}>
        {logo && <img loading="lazy" alt="" src={logo ? logo.file.url : ``} />}
      </Link>
    </Card.Image>
    {description && <DangerousHTML>{description}</DangerousHTML>}
  </Card>
)

IntegrationEntry.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.shape(Image.propTypes),
  slug: PropTypes.string.isRequired,
  integrationsPageLink: PropTypes.string
}

export default IntegrationsPage

export const query = graphql`
  fragment ContentfulIntegrationFragment on ContentfulIntegrationConnection {
    edges {
      node {
        name
        description
        logo {
          ...ContentfulImageFragment
        }
        integrationsPageLink
        slug
        integrationsPageLink
      }
    }
  }

  query IntegrationsPageQuery {
    cmsIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: {
        type: { eq: "CMS Integrations" }
        slug: { ne: "test-integration-for-gatsby-bug-do-no-delete" }
      }
    ) {
      ...ContentfulIntegrationFragment
    }
    marketingIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: { type: { eq: "Marketing Automation" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    ecommerceIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: { type: { eq: "eCommerce" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    codeRepositoryIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: { type: { eq: "Code Repository" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    customerSupportIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: { type: { eq: "Customer Support" } }
    ) {
      ...ContentfulIntegrationFragment
    }
    appSupportIntegrations: allContentfulIntegration(
      sort: { fields: [createdAt], order: ASC }
      filter: { type: { eq: "Application Support" } }
    ) {
      ...ContentfulIntegrationFragment
    }
  }
`
