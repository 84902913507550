import React from 'react'
import {
  InstantSearch,
  Hits,
  SearchBox,
  Highlight,
} from 'react-instantsearch-dom'
import Metadata from '../layouts/metadata'

// Sections
import Hero from 'organisms/hero'

import Layout from 'layouts'

import 'instantsearch.css/themes/algolia.css'

class SearchPage extends React.Component {
  state = { inBrowser: false }

  componentDidMount() {
    this.setState({ inBrowser: true })
  }

  render() {
    const { inBrowser } = this.state

    return (
      <Layout>
        <Metadata title="Search - Smartling" />
        {inBrowser && (
          <InstantSearch
            appId={__ALGOLIA_APP_ID__} // eslint-disable-line no-undef
            apiKey={__ALGOLIA_API_KEY__} // eslint-disable-line no-undef
            indexName={__ALGOLIA_INDEX__} // eslint-disable-line no-undef
          >
            <Hero gradientSecondary center curveBottom title="Search">
              <Hero.Description>
                <div className="c-search__input">
                  <SearchBox />
                </div>
              </Hero.Description>
            </Hero>
            <article className="l-article u-spacing--sections">
              <div className="l-article__body l-wrap l-container--s">
                <div className="o-text u-spacing c-search__results">
                  <Hits hitComponent={Result} />
                </div>
              </div>
            </article>
          </InstantSearch>
        )}
      </Layout>
    )
  }
}

export default SearchPage

const Result = ({ hit }) => (
  <div className="c-search__results-item">
    <div className="c-search__results-content">
      <a className="c-search__results-link" href={hit.permalink}>
        <strong>
          <Highlight attribute="permalink" hit={hit} />
        </strong>
        <br />
        <Highlight attribute="content" hit={hit} />
      </a>
    </div>
  </div>
)
