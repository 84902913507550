import React from 'react'
import Image from 'atoms/contentful/image'
import ClickableTags from './clickable-tags'

const BlockFeaturedResource = ({
  image,
  categories,
  slug,
  title,
  tags,
}) => {
  return (
    <div
      style={{
        width: '600px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <a href={`/resources/${categories.key}/${slug}`}>
        <Image {...image} />
      </a>
      <h5 className="o-kicker o-kicker__article u-space--half--top">
        <a className="o-kicker" href={`resources/${categories.key}`}>
          {categories.name}
        </a>
      </h5>
      <a href={`/resources/${categories.key}/${slug}`}>
        <h3 className="u-color--black">{title}</h3>
      </a>
      {tags && tags.length > 0 && (
        <p>
          <strong>Tags: </strong>
          <ClickableTags tags={tags} />
        </p>
      )}
    </div>
  )
}

export default BlockFeaturedResource
