import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Block from 'molecules/block'
import GalleryBlock from 'molecules/block-gallery'
import Slider from 'react-slick'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

// Images
import heroImage from '../images/Smartling-eCommerce-Localization-Platform-for-Translation-Management.jpg'
import metaImage from '../images/Smartling-eCommerce-Localization-Platform-for-Translation-Management.jpg'
import iconApi from '../images/icons/icon_code-dark-ring.svg'
import iconNative from '../images/icons/icon_cloud-data-dark-ring.svg'
import iconGlobe from '../images/icons/icon_website-translation-dark-ring.svg'
import iconTranslator from '../images/icons/icon_translator-light-ring.svg'
import iconContext from '../images/icons/icon_visual-context-light-ring.svg'
import iconQuality from '../images/icons/icon_quality-confidence-score-light-ring.svg'
import iconSmartling from '../images/icons/icon_smartling-light-ring.svg'
import iconCloud from '../images/icons/icon_translation-cloud-light-ring.svg'
import iconWhiteglove from '../images/icons/icon_whiteglove-light-ring.svg'
import videoEcommerce from '../images/video/ecommerce-video-rev.mp4'
import iconPi from '../images/icons/icon_pi-light-ring.svg'
import iconEcommerce from '../images/icons/icon_ecommerce-light-ring.svg'

// Reviews
import review from '../images/g2-review.png'
import review2 from '../images/g2-review2.png'
import review3 from '../images/g2-review3.png'
import review4 from '../images/g2-review4.png'
import review5 from '../images/g2-review5.png'

import Layout from 'layouts'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const galleryImages = [review, review2, review3, review4, review5]

const EcommerceLocalizationTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title="Translate Your eCommerce Store Into Any Language | Smartling"
      description="Smartling’s eCommerce localization platform takes the guesswork out of translating your ecommerce content, creating consistent customer experiences throughout the world."
      keywords="eCommerce Translation, eCommerce Localization"
      image={metaImage}
    />
    <div className="c-page-ecommerce-localization">
      <Hero
        curveBottom
        gradientPrimary
        title="eCommerce Localization and Translation"
        className="c-hero__resource"
      >
        <Hero.Description>
          <div className="u-spacing--and-half">
            <div className="c-hero__cta-text">
              Smartling’s eCommerce localization and translation management
              platform helps retailers deliver a 360 degree experience for
              customers in any language.
            </div>
          </div>
        </Hero.Description>
        <Hero.Image>
          <img
            loading="lazy"
            src={heroImage}
            alt="eCommerce Localization Platform"
          />
        </Hero.Image>
        <Hero.Buttons>
          <Button url="/meeting-request/">Book a Demo</Button>
        </Hero.Buttons>
      </Hero>

      <article className="l-article u-spacing--sections">
        <section class="o-section">
          <div class="c-section__header">
            <div class="c-block undefined u-spacing">
              <div class="c-block__text u-spacing">
                <div class="u-spacing">
                  <h3 class="c-block__title">From Cart to Casa</h3>
                  <div class="c-block__description">
                    How can retailers support consumers, from around the globe,
                    throughout their buying journey?
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="o-section--inner l-wrap l-container--l">
            <div class="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div class="l-grid-item">
                <div class="u-spacing--and-half">
                  <p>
                    Let’s quickly go on a journey… a customer journey through an
                    eCommerce website.
                  </p>
                  <p>
                    Consider the buyer who visits your website or mobile
                    application. They browse hundreds (or thousands!) of SKUs,
                    use filters to narrow down their selections, and are swept
                    off their feet by thoughtful imagery and romance copy. The
                    product name, description, and instructions for care are
                    considered; then the consumer makes a choice. Add to basket
                    and purchase?{' '}
                    <span class="u-nowrap">
                      Check.{' '}
                      <span role="img" aria-label="checkmark">
                        ✔️
                      </span>
                    </span>
                  </p>
                  <p>
                    Now it's time to fulfill the order, starting with an email
                    confirmation, and enrollment into drip marketing campaigns.
                  </p>
                  <p>
                    The customer journey, from intent to purchase, isn't as
                    pretty behind the scenes. And adding localization into the
                    mix adds a whole new layer of complexity to this process. It
                    begs the question: how do you scale your entire eCommerce
                    website or mobile application from one language to another
                    without adding delays to market, additional people to manage
                    the process, and at a rate of return that doesn’t exceed the
                    initial investment?
                  </p>
                </div>
              </div>
              <div class="l-grid-item">
                <div class="c-product-intro-image">
                  <video autoPlay muted loop>
                    <source type="video/mp4" src={videoEcommerce} />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Section>
          <Section.Header>
            <Block title="Why localization for eCommerce is important - and challenging.">
              <Block.Image>
                <span class="u-icon u-icon--xxl">
                  <img
                    loading="lazy"
                    src={iconPi}
                    className=""
                    alt="Pie Chart"
                  />
                </span>
              </Block.Image>
              Localizing content is obviously important: a person has to
              understand what they're buying, and can only do so if the content
              is in their native language. But how consumers make decisions, and
              the access they have to products and services is digital-first.
              We've seen:
            </Block>
          </Section.Header>
          <div class="l-wrap l-container--l">
            <div class="l-grid c-content-complexity l-container--xs-s  o-padding--zero--bottom">
              <div class="l-grid-item">
                <div class="c-block undefined u-spacing">
                  <div class="c-block__text u-spacing">
                    <div class="u-spacing">
                      <div class="c-block__description">
                        <p>Increased competition for market share</p>
                        <p>
                          Multiplying consumer touchpoints, distribution options
                          and support requirements
                        </p>
                        <p>Fast-changing product inventory</p>
                        <p>
                          The demand for each product is different, but there’s
                          no way to easily adjust translation cost for each SKU
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        <Section>
          <Section.Header>
            <Block title="Streamline End-to-End eCommerce Localization">
              <Block.Image>
                <span class="u-icon u-icon--xxl">
                  <img
                    loading="lazy"
                    src={iconEcommerce}
                    className=""
                    alt="eCommerce"
                  />
                </span>
              </Block.Image>
              Each of these challenges presents a unique translation complexity
              that Smartling addresses with the best-in-class translation
              management system and professional translators. With Smartling,
              you can:
            </Block>
          </Section.Header>
          <div class="l-wrap l-container--l">
            <div class="l-grid c-content-complexity l-container--xs-s o-padding--zero--bottom">
              <div class="l-grid-item">
                <div class="c-block undefined u-spacing">
                  <div class="c-block__text u-spacing">
                    <div class="u-spacing">
                      <div class="c-block__description">
                        <p>
                          Build and maintain global brand equity by supporting
                          any language
                        </p>
                        <p>
                          Eliminate English bleed-through across all of your
                          content types
                        </p>
                        <p>
                          Centralize translations to one platform and
                          translation vendor to translate new products faster
                        </p>
                        <p>
                          One-stop shop for translation across every team
                          (marketing, apps, checkout, packaging, legal)
                        </p>
                        <p>
                          Intelligently manage translation costs across your
                          primary and secondary markets with dynamic workflows
                        </p>
                        <p>
                          Share and utilize translation memories across
                          departments and content types
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>

        <Section>
          <Section.Header>
            <Block title="Connecting Your eCommerce Technology & Content with Smartling">
              <Block.Image />
              Smartling offers a number of ways to integrate, making it simple
              to centralize eCommerce translation for your digital storefront,
              across devices and platforms.
            </Block>
          </Section.Header>
          <Section.Inner l>
            <div className="l-grid l-grid--3x4">
              {/* one */}
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img loading="lazy" src={iconGlobe} alt="Languages Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1 u-text-align--left">
                <h3 class="u-color--primary">Web Proxy</h3>
              </div>
              <div className="l-grid-area l-grid-area--c1 u-text-align--left">
                <p>
                  The fastest option for launching your web app in new
                  languages. Smartling’s web proxy can translate any website
                  into any language, with little-to-no developer involvement.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d1 u-text-align--left">
                <a
                  role="button"
                  href="/software/global-delivery-network/"
                  class="o-link"
                >
                  Discover our web proxy
                </a>
              </div>

              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img loading="lazy" src={iconNative} alt="Words Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2 u-text-align--left">
                <h3 class="u-color--primary">Integrations</h3>
              </div>
              <div className="l-grid-area l-grid-area--c2 u-text-align--left">
                <p>
                  With our native integrations built to connect with your tech
                  stack, we make it easy to manage translations using the
                  applications you work with every day.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d2 u-text-align--left">
                <a role="button" href="/software/integrations/" class="o-link">
                  See our integrations
                </a>
              </div>

              {/* three */}
              <div className="l-grid-area l-grid-area--a3">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img loading="lazy" src={iconApi} alt="Integrations Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b3 u-text-align--left">
                <h3 class="u-color--primary">API Endpoints</h3>
              </div>
              <div className="l-grid-area l-grid-area--c3 u-text-align--left">
                <p>
                  With hundreds of API endpoints at your disposal, Smartling’s
                  eCommerce localization software makes custom functionality
                  possible, and won’t disrupt your current operational
                  structure.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d3 u-text-align--left">
                <a role="button" href="/software/api/" class="o-link">
                  Check out our APIs
                </a>
              </div>
            </div>
          </Section.Inner>
        </Section>

        <div className="u-gradient--primary u-curve--top--right u-curve--bottom--center u-padding--section">
          <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
            <Section.Header>
              <div class="c-block undefined u-spacing">
                <div class="c-block__text u-spacing">
                  <div class="u-spacing">
                    <h3 class="c-block__title u-text-align--left">
                      eCommerce Localization Built to Attract Global Buyers
                    </h3>
                    <div class="c-block__description u-text-align--left">
                      Top retailers partner with Smartling to deliver
                      high-quality, eCommerce experiences for their customers
                      around the world. Take{' '}
                      <a href="/resources/case-study/canary/">
                        <span class="u-color--white u-text-decoration--underline">
                          Canary
                        </span>
                      </a>{' '}
                      for example, a leading B2C home security brand. They
                      entered 15 new countries in 15 months with the help of
                      Smartling’s translation management platform. We offer a
                      host of services and technology to help you do more, with
                      less.
                    </div>
                  </div>
                </div>
              </div>
            </Section.Header>
          </section>
        </div>

        <Section>
          <Section.Header>
            <Block title="Mi Casa Es Su Casa">
              <Block.Image />
              Smartling is home to a plethora of localization tools and services
              to take your eCommerce brand global.
            </Block>
          </Section.Header>
          <Section.Inner l className="u-spacing--quad l-container--xl">
            <div className="l-grid l-grid--3x4">
              {/* one */}
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    src={iconTranslator}
                    alt="Translator Icon"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1 u-text-align--left">
                <h3 class="u-color--primary">Professional Language Services</h3>
              </div>
              <div className="l-grid-area l-grid-area--c1 u-text-align--left">
                <p>
                  Smartling is a full-service translation agency employing
                  top-level translators who deliver more than 30 million words
                  monthly. Our translators care about getting the job done
                  right, no matter what it takes. And with our eCommerce
                  localization platform, translators have access to your brand
                  guidelines and glossary to ensure you are accurately
                  represented everywhere.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d1 u-text-align--left">
                <a role="button" href="/professional-services/" class="o-link">
                  Learn more
                </a>
              </div>

              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    src={iconSmartling}
                    alt="Smartling Icon"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2 u-text-align--left">
                <h3 class="u-color--primary">
                  Unified platform for all translations
                </h3>
              </div>
              <div className="l-grid-area l-grid-area--c2 u-text-align--left">
                <p>
                  Smartling’s eCommerce translation platform prevents the
                  breakdown of a fractured translation process, providing a
                  single source of truth for every channel and content type.
                  From your website to marketing emails to your customer support
                  content, your translated content lives on a single platform,
                  giving centralized access to translation memory and branding
                  guidelines.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d2 u-text-align--left">
                <a
                  role="button"
                  href="/resources/101/how-to-leverage-smartling-for-a-unified-strategy/"
                  class="o-link"
                >
                  Learn more
                </a>
              </div>

              {/* three */}
              <div className="l-grid-area l-grid-area--a3">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img loading="lazy" src={iconQuality} alt="Quality Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b3 u-text-align--left">
                <h3 class="u-color--primary">Data-driven Translation</h3>
              </div>
              <div className="l-grid-area l-grid-area--c3 u-text-align--left">
                <p>
                  Smartling provides you with superior analytics and insights to
                  help optimize your translation quality for available budget.
                  Our Quality Confidence Score<sup>TM</sup>, the industry’s
                  first predictive learning-powered measure of translation
                  quality, empowers localization teams to achieve greater
                  business results by continuously tracking costs savings, cost
                  estimates, and translation velocity driving down overall cost
                  over time.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d3 u-text-align--left">
                <a
                  role="button"
                  href="/software/translation-analytics/"
                  class="o-link"
                >
                  Learn more
                </a>
              </div>
            </div>

            <div className="l-grid l-grid--3x4">
              {/* one */}
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    src={iconContext}
                    alt="Visual Context Icon"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1 u-text-align--left">
                <h3 class="u-color--primary">Visual Context</h3>
              </div>
              <div className="l-grid-area l-grid-area--c1 u-text-align--left">
                <p>
                  Smartling technology captures snapshots of your website and
                  product so that translators can actually see what the content
                  looks like in context. And your team can use the same software
                  to preview translations the same way your end user will
                  experience the content before its published.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d1 u-text-align--left">
                <a
                  role="button"
                  href="/resources/101/visual-context-a-translator-tool-that-helps-the-whole-team/"
                  class="o-link"
                >
                  Learn more
                </a>
              </div>

              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    src={iconCloud}
                    alt="Translator Cloud Icon"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2 u-text-align--left">
                <h3 class="u-color--primary">Machine Translation</h3>
              </div>
              <div className="l-grid-area l-grid-area--c2 u-text-align--left">
                <p>
                  Seamlessly connect with neural machine translation engines,
                  and configure Smartling to programmatically recommend which
                  service to use based on the content type and language.
                  Leverage the right translation resource for the job for the
                  best outcome every time.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d2 u-text-align--left">
                <a
                  role="button"
                  href="/resources/101/machine-translation-vs-human-translation/"
                  class="o-link"
                >
                  Learn more
                </a>
              </div>

              {/* three */}
              <div className="l-grid-area l-grid-area--a3">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    src={iconWhiteglove}
                    alt="White Glove Icon"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b3 u-text-align--left">
                <h3 class="u-color--primary">Managed Services</h3>
              </div>
              <div className="l-grid-area l-grid-area--c3 u-text-align--left">
                <p>
                  Your company has identified a need for localization, but don’t
                  have the internal resources to support a translation project?
                  Not to worry. We offer dedicated account support, acting as an
                  extension of your team and supporting your localization
                  efforts with expertise.
                </p>
              </div>
              <div className="l-grid-area l-grid-area--d3 u-text-align--left">
                <a role="button" href="/why-platinum-support/" class="o-link">
                  Learn more
                </a>
              </div>
            </div>
          </Section.Inner>
        </Section>

        <div>
          <Section className="c-section__block-rows c-block-rows">
            <Section.Header>
              <Block title="Hundreds of Customers Love Smartling">
                Whether they're selling a hotel room, a train ticket, or a
                business communication platform, our customers rely on us to
                make it easier for their buyers to make a purchase online.
              </Block>
            </Section.Header>
            <div className="c-logo-strip"></div>
          </Section>
        </div>

        <Section className="c-section__block-rows c-block-rows">
          <Section.Header>
            <Block title="Unlock Global Revenue">
              Smartling's localization and translation management product is
              rated #1 by users on G2.
            </Block>
          </Section.Header>
          <Section.Inner l className="u-spacing">
            <Slider {...settings} className="u-padding--top">
              {galleryImages.map((image) => (
                <GalleryBlock
                  className="c-gallery-block--small c-gallery-block--review"
                  src={`${image}?fit=fill&w=460`}
                />
              ))}
            </Slider>
            <div className="u-text-align--center">
              <Button
                url="https://www.g2.com/products/smartling/reviews/"
                addClasses="u-space"
                target="_blank"
              >
                Read Reviews
              </Button>
            </div>
          </Section.Inner>
        </Section>

        <SectionCta
          centerCTA
          className="c-ready-to-start"
          title="Sell Products Anywhere"
        >
          <SectionCta.Description>
            The best way to learn about Smartling's eCommerce translation
            management software is to see if for yourself.
          </SectionCta.Description>
          <SectionCta.Buttons>
            <Button url="/meeting-request/">Book a Meeting</Button>
          </SectionCta.Buttons>
        </SectionCta>
      </article>
    </div>
  </Layout>
)

export default EcommerceLocalizationTemplate

export const EcommerceLocalizationQuery = graphql`
  query EcommerceLocalizationTemplateQuery {
    page: contentfulBasicPage(path: { eq: "ecommerce-localization" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      metadataImage {
        ...ContentfulImageFragment
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
