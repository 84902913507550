import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Layout from 'layouts/index-ppc'
import PardotForm from 'components/forms/pardot'
import LandingPageSection from 'components/sections/landing-page-section'
import SectionLogoBanner from 'organisms/logo-banner'
import SectionCta from 'organisms/cta'
import { GatsbyImage } from 'gatsby-plugin-image'

import MetaDataImage from '../images/meta-image.png'

const PpcTemplate = ({ data }) => {
  const heroImage = data.page.heroImage
  console.log(data.page.description)

  return (
    <Layout>
      <div className="c-ppc-detail c-ppc-why-managed-services">
        <Metadata
          title={data.page?.metadataTitle || data.page.title}
          description={data.page?.metadataDescription}
          keywords={data.page?.metadataKeywords?.join(`, `)}
          image={MetaDataImage}
        />
        <Hero
          className="c-hero__resource o-hero--custom"
          curveBottom
          backgroundPrimary
          title={data.page.heroTitle}
        >
          <Hero.Description>
            {data.page.heroDescription && (
              <div className="c-hero__description--wrap">
                <DangerousHTML>
                  {data.page.heroDescription.html.text}
                </DangerousHTML>
              </div>
            )}
            {data.page.description && (
              <p className="c-hero__sub-description">{data.page.description}</p>
            )}
          </Hero.Description>
          <Hero.Image>
            {data.page.heroImage && (
              <>
                {heroImage.gatsbyImageData ? (
                  <GatsbyImage
                    image={heroImage.gatsbyImageData}
                    alt={heroImage.title}
                    objectFit="contain"
                    width="622"
                    height="425"
                    // title={heroDescription.description}
                  />
                ) : (
                  <img
                    loading="lazy"
                    src={heroImage.file.url}
                    alt={heroImage.title}
                    width="622"
                    height="425"
                  />
                )}
              </>
            )}
          </Hero.Image>

          {/* {data.page?.pardotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'In Hero' ? (
                <div className="c-ppc__hero--pardot">
                  <h3 className="c-ppc__hero--pardot__heading">
                    Please provide your information below
                  </h3>
                  <PardotForm
                    iframe={data.page?.pardotFormReference?.pardotFormLink}
                  />
                  <p>
                     
                    For translators 
                    interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              ) : (
                ``
              ))} */}

          <Hero.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </Hero.Buttons>
        </Hero>
        {data.page.pageSections &&
          data.page.pageSections.map((section) =>
            section.internal.type === 'ContentfulLogoCarousel' ? (
              <div className="c-logo-banner__book-meeting">
                <SectionLogoBanner title={section.title}>
                  {section.sliderItems.map((logo) => (
                    <SectionLogoBanner.Item
                      url={logo.image?.url}
                      src={logo.image?.file.url}
                      alt={logo.image.title}
                    />
                  ))}
                </SectionLogoBanner>
              </div>
            ) : (
              <LandingPageSection {...section} />
            )
          )}
        {data.page?.pardotFormReference &&
          (data.page?.pardotFormReferencePlacement === 'Below Body' ||
          data.page?.pardotFormReferencePlacement === 'Hero and Below Body' ? (
            <div
              className={`l-wrap ${
                data.page?.pardotFormReference.name ===
                'Email Capture - Viewed How Lyft Scaled Content for 8 Languages PPC Webinar'
                  ? 'l-container--s'
                  : 'l-container--xs'
              }`}
            >
              <PardotForm
                iframe={data.page?.pardotFormReference?.pardotFormLink}
              />
            </div>
          ) : (
            ``
          ))}
      </div>
    </Layout>
  )
}

export default PpcTemplate

export const pageQuery = graphql`
  query WhyManagedServicesQuery {
    page: contentfulPpc(slug: { eq: "why-managed-services" }) {
      name
      slug
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      description
      heroImage {
        # ...ContentfulImageFragment
        file {
          url
        }
        gatsbyImageData(
          width: 610
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          height: 343
        )
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
        ... on ContentfulLogoCarousel {
          internal {
            type
          }
          title
          sliderItems {
            image {
              file {
                url
              }
              description
              title
            }
          }
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`
