import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from '../../prop-types'
import Img from 'gatsby-image'

/*
 * we have to be careful with the file prop because  when media items in contentful
 * are deleted, their references aren't cleared - given that, it's possible to
 * receive an empty object from contentful when you are using a media reference.
 */
const Image = ({ title, description, file, fluid, height, ...props }) => (
  <img
    loading="lazy"
    {...props}
    title={title}
    alt={description}
    height={height}
    src={file ? file.url : ``}
    fluid={fluid}
  />
)

Image.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  height: PropTypes.number,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  fluid: PropTypes.string.isRequired,
}

export default Image

export const contentfulImageFragment = graphql`
  fragment ContentfulImageFragment on ContentfulAsset {
    title
    description
    file {
      url
    }
    fluid(maxWidth: 900) {
      ...GatsbyContentfulFluid_noBase64
    }
  }
`
