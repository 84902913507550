import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import SectionCta from 'organisms/cta'
import Grid from 'atoms/grid'
import { Link } from 'gatsby'

import ThreeColumnCta from 'organisms/ThreeColumnCta'

import MetadataImg from '../../images/use-cases/marketing/metadata-img.jpg'
import MarketingHero from '../../images/use-cases/marketing/marketing-hero.png'
import Logos from '../../images/company-logos/marketing-logos.jpg'
import TranslationServicesBook from '../../images/fintech-book.png'
import Multilingual from '../../images/use-cases/marketing/multilingual.jpg'
import Email from '../../images/use-cases/marketing/email.jpg'
import SeoImage from '../../images/use-cases/marketing/seo.jpg'
import gdnIcon from '../../images/use-cases/marketing/gdn-icon.png'
import integrationsIcon from '../../images/use-cases/marketing/integration-icon.png'
import translatorIcon from '../../images/use-cases/marketing/translator-icon.png'
import CaseStudyLogoSrc from '../../images/use-cases/marketing/adroll-logo.svg'
import CaseStudyPersonSrc from '../../images/use-cases/marketing/gavid-flood.jpg'

import PardotResponsive from '../../02-organisms/pardot-responsive'
import HubSpotForm from 'components/forms/HubSpotForm';

const LogosAlt =
  'Samsung logo, gofundme logo, IGH Hotel and Resorts logo, Pintrest logo, Mastercard logo'
const CaseStudyLogoAlt = 'AdRoll Logo'
const CaseStudyPersonAlt = 'Gavid Flood'

const FinTech = () => {
  return (
    <Layout>
      <Metadata
        title="Smartling for Marketing | Translate Website, Blog Posts, Content"
        description="Localize your emails, websites, blog posts, and marketing materials across every language"
        keywords="marketing localization, website translation, website localization, content translation, email translation, global marketing"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="The easiest way to manage content translation"
        >
          <Hero.Description>
            Instantly translate all of your websites, emails, and other global
            marketing materials into your customers languages with Smartling.
            It’s how you increase trust, brand awareness, traffic, and sales
            with your global audience. 
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              src={MarketingHero}
              alt="Stylized image"
              aria-hidden={true}
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#schedule-meeting">Book a Meeting</Button>
          </Hero.Buttons>
        </Hero>

        <Section className="l-wrap l-container--xl">
          {/* <Section.Header></Section.Header> */}
          <Section.Inner xl>
            <img loading="lazy" src={Logos} alt={LogosAlt} />
            <Block className="u-padding--bottom">
              <h4>Powering the Top Global Brands</h4>
            </Block>
          </Section.Inner>
        </Section>

        <Section l className="l-wrap u-space--double--top">
          <Section.Header>
            <Block>
              <h2 className="o-heading--l">
                Multilingual Content. Global Recognition.
              </h2>
            </Block>
          </Section.Header>

          <Section.Inner
            s
            className="u-space--triple--bottom u-margin--zero--top"
          >
            <p className="u-align--center">How?</p>
            <ul className="o-list u-space--quad--left u-space--bottom">
              <li>
                With translation management software that allows you to automate
                your translation strategy while maintaining quality, brand
                voice, and style.
              </li>
              <li>
                With our professional linguists who are local experts and native
                speakers.
              </li>
              <li>
                With integrations ready to be connected to your existing content
                management systems and marketing automation tools.
              </li>
            </ul>

            <div className="u-align--center u-spacing">
              <p>This results in:</p>
            </div>

            <div>
              <ol className="o-list u-space--quad--left u-space--top u-space--bottom">
                <li>Tens of thousands of dollars saved</li>
                <li>Hundreds of thousands of human hours saved</li>
                <li>New languages launched instantly</li>
              </ol>

              <div className="u-align--center">
                <a
                  role="button"
                  href="#schedule-meeting"
                  class="o-link u-color--primary"
                >
                  Book a Meeting
                </a>
              </div>
            </div>
          </Section.Inner>
        </Section>

        {/* Iframe */}
        <Section className="u-space--bottom">
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h2 class="o-heading--l u-text-align--center">
                  How Vitamix Sold Out of a New Product in 24 Hours
                </h2>
                <div style="padding:62.21% 0 0 0;position:relative;">
                  <iframe
                    src="https://player.vimeo.com/video/480434786?h=59d3aede98&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    title="How Vitamix Sold Out of a New Product in 24 Hours with Smartling | Smartling Customer Success Stories"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>

        <ThreeColumnCta
          title="Bring translation into your existing workflow"
          leftImgSrc={gdnIcon}
          leftAlt="Global Distribution Network Icon"
          leftLink="/software/global-delivery-network/"
          leftLinkText={
            <>
              Instant Website <br /> Localization
            </>
          }
          middleImgSrc={integrationsIcon}
          middleAlt="Two Puzzle Pieces"
          middleLink="/software/"
          middleLinkText={
            <>
              See Our Pre-Built <br /> Integrations
            </>
          }
          rightImgSrc={translatorIcon}
          rightAlt="Letter A and a Chinese character with a woman in the middle"
          rightLink="/translation-services/"
          rightLinkText={
            <>
              Professional <br />
              Translation Services
            </>
          }
        />

        <Section>
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>Reach More Customers Online</h3>

                <p>
                  40% of customers won’t buy from websites that are not in their
                  language.  Here’s how to build a multilingual website to
                  increase brand awareness, web traffic, and sales.
                </p>

                <a
                  role="button"
                  href="/resources/101/10-tips-for-building-a-multilingual-website-examples/"
                  class="o-link u-color--primary"
                >
                  Check out our Article with 10 Tips
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={Multilingual}
                  alt="Letter A being translated into a Chinese character"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={Email}
                  alt="Envolope with at symbol"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                <h3>Marketing Emails Translated</h3>
                <p>
                  Use built-in integrations to translate your emails and keep
                  everything organized in one place. It’s time to make your
                  email translation work simpler than ever.
                </p>
                <a
                  role="button"
                  href="/resources/101/the-best-ways-to-address-email-localization-challenges/"
                  class="o-link u-color--primary"
                >
                  Take me there
                </a>
              </Grid.Item>
            </Grid>
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>Multilingual SEO Best Practices</h3>
                <p>
                  People from different regions use different search terms.
                  Customers can’t find you if you don’t optimize your website’s
                  content for specific locales.  Here's how to localize search
                  engine opitmization, so your website brings  in more traffic
                  and better leads in  your target markets.
                </p>
                <a
                  role="button"
                  href="/resources/101/seo-and-localization-a-few-best-practices"
                  class="o-link u-color--primary"
                >
                  Learn Best Practices
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={SeoImage}
                  alt="Acronym SEO and map marker pin"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <SectionCta centerCTA>
              <SectionCta.Buttons>
                <Button url="#schedule-meeting">
                  Schedule a 30-Min Introduction Call
                </Button>
              </SectionCta.Buttons>
            </SectionCta>
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item>
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  alt="Book with text on cover: The Complete Guide to Translation Services"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                <h3>The Complete Guide to Translation Services</h3>
                <p>
                  When language services and translation management software
                  work together, they can significantly improve your marketing
                  localization results – online or via mobile application.
                </p>
                <a
                  role="button"
                  href="/resources/101/the-complete-guide-to-translation-services/"
                  class="o-link u-color--primary"
                >
                  Take Me There
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section class="o-section--inner l-wrap l-container--xl">
              <div id="sumup-case-study" className="u-space--bottom">
                <div class="c-quote-box">
                  <div class="u-spacing--double">
                    <div class="u-flex c-quote-top">
                      <img
                        loading="lazy"
                        src={CaseStudyPersonSrc}
                        alt={CaseStudyPersonAlt}
                        className="u-round"
                      />
                      <div class="c-quote-attribute">
                        <img
                          loading="lazy"
                          src={CaseStudyLogoSrc}
                          style={{ maxWidth: '120px' }}
                          alt={CaseStudyLogoAlt}
                        />
                        <div>
                          <strong>Gavid Flood</strong>
                        </div>
                        <div>Senior Director of Marketing, APAC and EMEA</div>
                      </div>
                    </div>
                    <div class="o-section-title o-section-title--small c-quote o-section-title--medium">
                      We were able to launch our new AdRoll website in 7
                      languages within just 2 weeks!
                    </div>
                    <a
                      href="/resources/case-study/adroll/"
                      class="o-button o-button--wide o-button--no-shadow"
                    >
                      Read the Case Study
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="u-align--center u-spacing">
                <h2>Book a 20-Minute Demo</h2>
                <iframe
                  src="https://resource.smartling.com/l/703963/2022-01-06/2p6w73"
                  type="text/html"
                  frameborder="0"
                  title="pardot form"
                  allowtransparency="true"
                  scrolling="no"
                  style="margin: 15px auto; border: 0px; height: 170px; min-height: 100%; width: auto; min-width: 50%; overflow: hidden;"
                  id="pardotForm"
                ></iframe>
              </div> */}
            </section>

            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="schedule-meeting">
                    Book a 30-Minute Demo
                  </h2>
                  <div id="hubspot-form-container" className="hubspot-form-container">
                    <HubSpotForm portalId='40052555' formId='cf139b2b-683e-453b-86b4-05dcd4dc2bfb' />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FinTech
