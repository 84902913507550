import React from 'react'

const AccordionSection = ({ children }) => {
  return (
    <div
      style={{
        padding: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {children}
    </div>
  )
}

export default AccordionSection;