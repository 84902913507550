import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'
import Section from 'atoms/section'
import { oneByType } from '../util/react'

const SectionTwoColumn = ({
  className,
  title,
  flip,
  white,
  mobile,
  bulb,
  children,
}) => {
  const classes = cx(className, {
    'c-two-column--reverse': flip,
    'c-two-column--secondary': white,
    'has-mobile': mobile,
    'has-bulb': bulb,
  })

  const description = oneByType(children, SectionTwoColumn.Description)
  const buttons = oneByType(children, SectionTwoColumn.Buttons)
  const image = oneByType(children, SectionTwoColumn.Image)
  const logo = oneByType(children, SectionTwoColumn.Logo)

  return (
    <PatternSectionTwoColumn
      className={classes}
      title={title}
      description={description?.props.children}
      image={image?.props.children}
      logo={logo?.props.children}
      buttons={buttons?.props.children}
    />
  )
}

SectionTwoColumn.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

export default SectionTwoColumn

SectionTwoColumn.Description = ({ children }) => <React.Fragment />

SectionTwoColumn.Image = ({ children }) => <React.Fragment />

SectionTwoColumn.Logo = ({ children }) => <React.Fragment />

SectionTwoColumn.Buttons = ({ children }) => <React.Fragment />

SectionTwoColumn.Actions = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternSectionTwoColumn = ({
  className,
  title,
  description,
  buttons,
  image,
  logo,
}) => {
  return (
    <Section className={`c-section__two-column c-two-column ${className}`}>
      <Section.Inner l>
        <div className="c-two-column__content u-spacing">
          {logo && <div className="c-two-column__logo">{logo}</div>}
          {title && (
            <div className="u-spacing--half">
              <h3 className="c-two-column__title">{title}</h3>
              <span className="c-two-column__hr" />
            </div>
          )}
          {description && (
            <div className="c-two-column__description u-spacing">
              {description}
            </div>
          )}
          {buttons && <div className="c-two-column__buttons">{buttons}</div>}
        </div>
        {image && <div className="c-two-column__image">{image}</div>}
      </Section.Inner>
    </Section>
  )
}

PatternSectionTwoColumn.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.node,
  logo: PropTypes.node,
}
