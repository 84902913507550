import React from 'react'

const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1313.33 280">
    <path
      d="M365.52,74.65c14,0,28.16,3.37,38.29,9.11v23.61c-10-7.09-23.28-10.63-36.44-10.46-12.81.17-19.22,3.71-19.22,11,0,20.41,64.59,12.82,64.59,54.81,0,26.48-21.25,34.74-46,34.74-16.36,0-31.54-3.87-43.68-10.62V162.68a75.59,75.59,0,0,0,41.83,12.48c13.66,0,22.09-2.86,22.09-11.3,0-22.09-64.59-13.82-64.59-55.65C322.35,85.44,342.25,74.65,365.52,74.65Z"
      className="o-path"
    />
    <path
      d="M554.07,77V195.06h-25.3V122.38l-31.2,47.39H482.9l-31.2-47.56v72.85H426.41V77h23.44l40.47,61.39L530.63,77Z"
      className="o-path"
    />
    <path
      d="M565,195.06,613.2,77h27.32l48.06,118.05H662.44L653.84,173h-54l-8.77,22.09Zm42.84-43h38.11l-16.52-42.33a51.79,51.79,0,0,1-2.54-9.28,39.23,39.23,0,0,1-2.69,9.28Z"
      className="o-path"
    />
    <path
      d="M699.47,195.06V77H748c22.94,0,46.89,8.6,46.89,38.45,0,20.24-11.47,30.53-25.81,35.08l35.25,44.52H775.2l-31.88-41.32H724.77v41.32Zm25.3-62.4h24.45c10.63,0,19.9-4.72,19.9-16.69,0-12.14-9.27-16.7-19.9-16.7H724.77Z"
      className="o-path"
    />
    <path
      d="M902.49,77V99.78H866.23v95.28H841.1V99.78H804.84V77Z"
      className="o-path"
    />
    <path d="M939.38,77V172.3H996v22.76H914.09V77Z" className="o-path" />
    <path d="M1038.05,77V195.06h-25.3V77Z" className="o-path" />
    <path
      d="M1058.69,195.06V77h23.78l55,77.07c-.33-4.89-.5-10.79-.5-16.19V77h25.29V195.06h-23.77l-55-77.07c.34,4.89.5,10.8.5,16.19v60.88Z"
      className="o-path"
    />
    <path
      d="M1241.21,74.65a90,90,0,0,1,36.25,7.59v24.12c-8.93-5.91-20.91-9.11-32.88-9.11-21.08,0-41.83,11.13-41.83,38.79,0,31.37,23.11,39.29,40.48,39.29a36.57,36.57,0,0,0,13-2.19V131h24.12V189.5a99.23,99.23,0,0,1-38.45,7.92c-33.05,0-64.93-18-64.93-61.38C1177,93.2,1208.83,74.65,1241.21,74.65Z"
      className="o-path"
    />
    <circle cx="120.54" cy="157.82" r="11.91" fill="#f26c5d" />
    <path
      d="M240.37,224a7.6,7.6,0,0,1-6.79-11A125.53,125.53,0,0,0,64.91,45,7.59,7.59,0,1,1,58.1,31.41,140.7,140.7,0,0,1,247.17,219.82,7.57,7.57,0,0,1,240.37,224Z"
      fill="#f26c5d"
    />
    <path
      d="M120.56,201.11A43.46,43.46,0,1,1,164,157.65,43.51,43.51,0,0,1,120.56,201.11Zm0-71.73a28.28,28.28,0,1,0,28.28,28.27A28.3,28.3,0,0,0,120.56,129.38Z"
      fill="#f26c5d"
    />
    <path
      d="M120.56,233.75a76,76,0,0,1-22.71-3.45,7.67,7.67,0,0,1,4.57-14.64,60.68,60.68,0,1,0-39.86-39.84,7.67,7.67,0,1,1-14.63,4.58,76.11,76.11,0,1,1,72.63,53.35Z"
      fill="#f26c5d"
    />
    <path
      d="M166.88,255.66a7.67,7.67,0,0,1-3.51-14.49A93.84,93.84,0,1,0,36.93,115.07a7.67,7.67,0,0,1-13.66-7A109.18,109.18,0,1,1,170.38,254.81,7.75,7.75,0,0,1,166.88,255.66Z"
      fill="#f26c5d"
    />
  </svg>
)

export default Logo