import React from 'react'
import { graphql } from 'gatsby'
import { getTranslatorPath } from '../../routes'
import Metadata from '../../layouts/metadata'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import { StaticImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'
import GalleryBlock from 'molecules/block-gallery'

import Layout from 'layouts'

/*
 * This is the homepage of the site - behind the scenes, Gatsby will render this
 * component inside the layouts/index.js layout to create the static HTML content
 * for the homepage. By convention, Gatsby uses the default export from this file
 * as the component to render, and also accepts a `query` export to load data.
 */

class TranslatorsPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props

    return (
      <Layout>
        <div className="l-page--translators">
          <Metadata
            title="Meet Our Translators | Smartling Language Services"
            description="Learn a bit more about the everyday lives of Smartling translators around the world."
            keywords=""
          />
          <Hero curveBottom gradientPrimary>
            <Hero.Image>
              <h1>
                A profile on the lives of Smartling translators around the
                world.
              </h1>
            </Hero.Image>
            <Hero.Description>
              <StaticImage
                src="../../images/mtwww-WHT-lockup.svg"
                alt="Move the world with words"
                width={400}
                height={294}
                objectFit="scale-down"
              />
            </Hero.Description>
          </Hero>
          <article className="l-article u-spacing--double">
            <div className="u-spacing--sections">
              <div className="u-background-accent--right">
                <Section className="c-section__gallery">
                  <Section.Inner xl>
                    <Grid twoUp>
                      <Grid.Item>
                        <div className="u-spacing">
                          <p>
                            Move the World with Words. What does this phrase
                            mean to you, and how is it accomplished?
                          </p>
                          <p>
                            In 2019, I set off to create a photo series about
                            Smartling’s translators all over the world. The goal
                            was simple: if we could put a spotlight on the human
                            element, which is so often overlooked in the digital
                            age, perhaps we could realize how words with meaning
                            come to life.
                          </p>
                          <p>
                            From the rural state of Asturias, Spain to the
                            bustling coastal city of Bari, Italy, I’ve been all
                            over Europe, Asia and South America for the last
                            five weeks getting to know a handful of talented
                            translators. From exploring their backyards to
                            hearing fresh perspectives on communication, we’re
                            giving you a glimpse into their daily lives — and
                            how their passion for language is connecting people
                            across the globe.
                          </p>
                          <p>#movetheworldwithwords</p>
                          <p>
                            Photos and stories by Elisabeth Brentano (
                            <a
                              href="https://www.instagram.com/elisabethontheroad/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              @elisabethontheroad
                            </a>
                            )
                          </p>
                        </div>
                      </Grid.Item>
                      {data.galleries.edges.map(
                        ({ node }) =>
                          node.galleryImages[0] && (
                            <Grid.Item>
                              <GalleryBlock
                                title={node.title}
                                src={`${node.galleryImages[0].file.url}?fit=fill&w=700&h=700`}
                                slug={getTranslatorPath(node.slug)}
                              />
                            </Grid.Item>
                          )
                      )}
                    </Grid>
                  </Section.Inner>
                </Section>
              </div>
              <SectionCta
                gradientSecondary
                curveTop
                curveBottom
                centerCTA
                title="Rockstar. Translator."
              >
                <SectionCta.Description>
                  <div className="u-spacing">
                    <p className="u-font--m">
                      Create electric content with Flavio, a translator by day
                      and rockstar by night.
                    </p>
                  </div>
                </SectionCta.Description>
                <SectionCta.ImageLarge>
                  <StaticImage
                    src="../../images/translators-rockstar.jpg"
                    alt="Rockstar. Translator."
                    width={630}
                    height={630}
                    objectFit="scale-down"
                  />
                </SectionCta.ImageLarge>
              </SectionCta>
              <Section className="c-section__grid-blocks c-grid-blocks c-grid-blocks--featured">
                <Section.Inner xl className="u-spacing--double">
                  <div className="u-text-align--center">
                    <h2 className="c-cta__title">We love our translators.</h2>
                    <p className="c-cta__description u-font--m">
                      And our customers do, too. Here are three reasons why
                      customers choose Smartling for translation.
                    </p>
                  </div>
                  <Grid threeUp>
                    <Grid.Item>
                      <Block title="50%">
                        <h3>Faster Time to Market</h3>
                        <p className="u-space--top">
                          Achieve predictable translation results and timelines.
                        </p>
                      </Block>
                    </Grid.Item>
                    <Grid.Item>
                      <Block title="100%">
                        <h3>Quality Guarantee</h3>
                        <p className="u-space--top">
                          Measure your quality, and guarantee results.
                        </p>
                      </Block>
                    </Grid.Item>
                    <Grid.Item>
                      <Block title="30%">
                        <h3>Cost Savings</h3>
                        <p className="u-space--top">
                          One word minimum. In-platform communication with
                          translators.
                        </p>
                      </Block>
                    </Grid.Item>
                  </Grid>
                  <div className="u-display--block u-text-align--center">
                    <Button url="/products/translate">Learn More</Button>
                    <Button url="/become-a-translator" outline>
                      Become a translator
                    </Button>
                  </div>
                </Section.Inner>
              </Section>
              <SectionCta
                gradientPrimary
                curveTop
                curveBottom
                centerCTA
                title="How One Translator Uses Technology to Travel Back in Time"
              >
                <SectionCta.Description>
                  <div className="u-spacing">
                    <p className="u-font--m">
                      An inside look at the life of Daniel, a freelance
                      translator who lives in a rural village in Asturias, Spain
                    </p>
                  </div>
                </SectionCta.Description>
                <SectionCta.ImageLarge>
                  <StaticImage
                    src="../../images/translators-daniel.jpg"
                    alt="An inside look at the life of Daniel"
                    width={940}
                    height={656}
                    objectFit="scale-down"
                  />
                </SectionCta.ImageLarge>
                <SectionCta.Buttons>
                  <Button url="/resources/101/how-one-translator-uses-technology-to-travel-back-in-time">
                    Read Daniel's story
                  </Button>
                </SectionCta.Buttons>
              </SectionCta>
              <SectionCta
                centerCTA
                title="How Passion and Perspective Bring Language to Life"
              >
                <SectionCta.Description>
                  <div className="u-spacing">
                    <p className="u-font--m">
                      An interview with Oana, a freelance translator who lives
                      in a small town near Toulouse, France.
                    </p>
                  </div>
                </SectionCta.Description>
                <SectionCta.ImageLarge>
                  <StaticImage
                    src="../../images/translators-oana.jpg"
                    alt="An interview with Oana"
                    width={940}
                    height={688}
                    objectFit="scale-down"
                  />
                </SectionCta.ImageLarge>
                <SectionCta.Buttons>
                  <Button url="/resources/101/how-passion-and-perspective-bring-language-to-life">
                    Read Oana's story
                  </Button>
                </SectionCta.Buttons>
              </SectionCta>
              <SectionCta title="Home" centerCTA gradientTertiary curveTop>
                <SectionCta.Description>
                  <p className="u-font--m">
                    Let's bring home meaning and work together to move the world
                    with words.
                  </p>
                </SectionCta.Description>
                <SectionCta.Video>
                  <iframe
                    title="Video"
                    src="https://player.vimeo.com/video/336098356?title=0&byline=0&portrait=0"
                    frameborder="0"
                    width="100%"
                    height="auto"
                  />
                </SectionCta.Video>
                <SectionCta.Buttons>
                  <Button url="/meeting-request/">Let's work together</Button>
                </SectionCta.Buttons>
              </SectionCta>
            </div>
          </article>
        </div>
      </Layout>
    )
  }
}

export default TranslatorsPage

export const query = graphql`
  query TranslatorsPageQuery {
    galleries: allContentfulGallery(sort: { fields: [order], order: ASC }) {
      ...ContentfulGalleryFragment
    }
  }
`
