import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

const SmartlingCalculator = () => {
  const [words, setWords] = useState(350000)
  const [languages, setLanguages] = useState(1)
  const [savings, setSavings] = useState(7560000)

  useEffect(() => {
    calculateSavings()
  }, [words, languages])

  const calculateSavings = () => {
    const totalWords = words * languages
    const beforeCost = totalWords * 0.2
    const afterCost =
      totalWords * 0.2 * 0.07 +
      totalWords * 0.12 * 0.2 +
      totalWords * 0.12 * 0.2 +
      totalWords * 0.005 * 0.6
    const calculatedSavings = beforeCost - afterCost
    setSavings(Math.round(calculatedSavings))
  }

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleWordsChange = (e) => {
    const value = e.target.value.replace(/,/g, '')
    setWords(Number(value))
  }

  // Create the URL with query parameters
  const meetingUrl = `/meeting-request?words=${words}&languages=${languages}&savings=${savings}`

  return (
    <div className="l-container--l c-smartlng-calculator">
      <div className="l-flex mx-auto">
        <div className="l-flex-item--40 c-smartlng-calculator__left">
          <h1 className="u-space--double--bottom">
            See how much you can save by switching to Smartling
          </h1>
          <div className="u-background-color--white">
            <h2 className="u-space--bottom">Cost savings calculator</h2>
            <div className="u-space--bottom">
              <label
                htmlFor="words"
                className="u-display--block u-space--half--bottom"
              >
                Number of words needed to translate:
              </label>
              <input
                id="words"
                type="text"
                value={formatNumber(words)}
                onChange={handleWordsChange}
                className="u-display--block c-smartlng-calculator__input"
              />
            </div>
            <div className="u-space--bottom">
              <label
                htmlFor="languages"
                className="u-display--block u-space--half--bottom"
              >
                Into how many languages:
              </label>
              <div className="c-smartlng-calculator__input-wrapper">
                <input
                  id="languages"
                  type="number"
                  value={languages}
                  onChange={(e) => setLanguages(Number(e.target.value))}
                  className="u-display--block c-smartlng-calculator__input"
                  min="1"
                />
                <div className="c-smartlng-calculator__input-arrows">
                  <button onClick={() => setLanguages(languages + 1)}>▲</button>
                  <button
                    onClick={() => setLanguages(Math.max(1, languages - 1))}
                  >
                    ▼
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="l-flex-item--40 c-smartlng-calculator__right">
          <div className="u-background-color--primary u-color--white c-smartlng-calculator__right__inner">
            <h2 className="u-space--double--bottom">
              <span>▲</span>
              Your potential savings
            </h2>
            <h3 className="u-space--bottom">${formatNumber(savings)}</h3>
            <p className="u-space--double--bottom">
              Talk to someone on the Smartling team to see how we can help you
              save money and improve the quality of your translations
            </p>
            <button className="u-background-color--white u-color--primary u-padding--half">
              <Link to={meetingUrl}>Book a meeting</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartlingCalculator
