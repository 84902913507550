import React from 'react'
import Metadata from '../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import BlockRow from 'molecules/block-row'
import Card from 'molecules/card'

// Sections
import Hero from 'organisms/hero'
import SectionTwoColumn from 'organisms/two-column'
import Layout from 'layouts'

// Images
import heroImage from '../../images/illustrations/gdnimg.png'
import adrollLogo from '../../images/illustrations/adroll-logo-2018.png'
import adrollImage from '../../images/illustrations/adroll-04.png'
import twoColumnImage1 from '../../images/illustrations/withgdn.png'
import caseStudyImage1 from '../../images/illustrations/case-study-ihg.png'
import caseStudyLogo1 from '../../images/logo-igh.png'
import gdnDiagram from '../../images/illustrations/gdndiag.png'
import gdnCrawlerImage from '../../images/illustrations/GDN_crawler_hero.png'

class GlobalDeliveryNetworkPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Global Delivery Network (GDN) Translation Proxy - Smartling"
          description="Smartling’s Global Delivery Network translation proxy offers your business a cloud-based translation solution that integrates seamlessly with any CMS."
          keywords=""
        />
        <Hero
          curveCenterAlt
          flip
          gradientPrimary
          title="Deliver web experiences in any language"
        >
          <Hero.Description>
            Websites, Web Apps, and Landing pages, The Global Delivery Network
            (GDN) is the fastest, most cost effective way to deploy any web
            experience, everywhere!
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              style={{ minWidth: 'unset' }}
              src={heroImage}
              alt="Global Delivery Network"
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Book a Demo</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <Section className="u-space--double--bottom">
            <Section.Header>
              <Block title="How it Works"></Block>
            </Section.Header>
          </Section>

          <SectionTwoColumn white bulb title="Capture, Translate, Deploy">
            <SectionTwoColumn.Image>
              <img
                loading="lazy"
                src={twoColumnImage1}
                alt="Translation Automated"
              />
            </SectionTwoColumn.Image>
            <SectionTwoColumn.Logo />
            <SectionTwoColumn.Description>
              <p>
                Once Smartling’s Global Delivery Network is configured, content
                can flow seamlessly from your website into Smartling for
                translation. After the content is translated, any request for a
                localized version of your website will deliver instant results
                to your customer in their native language.
              </p>
              <p>
                Maintaining your translation quality with the GDN is a breeze.
                We guarantee a 99.99% uptime, so you don’t have to worry about
                bleedthrough. Changes to website copy in your source language
                are automatically detected and surfaced instantly for
                translation.
              </p>
              <p>
                Not only is the Global Delivery Network SEO compatible, it
                detects and rewrites links so that the user stays on a localized
                version of your website for a seamless customer experience.
              </p>
            </SectionTwoColumn.Description>
            <SectionTwoColumn.Buttons />
          </SectionTwoColumn>

          <Section className="u-space--double--bottom">
            <Section.Header>
              <Block title="Smartling's GDN Crawler"></Block>
            </Section.Header>
            <div className="o-section--inner l-wrap l-container--l">
              <div className="l-grid l-grid--2up u-padding--quad--bottom">
                <div className="l-grid-item">
                  <div className="c-block u-spacing">
                    <div className="c-block__text u-spacing">
                      <div className="u-spacing">
                        <div className="c-block__description u-align--left">
                          <div className="u-spacing">
                            <p>
                              Smartling’s GDN Crawler automatically scans
                              websites to detect new content for translation,
                              thus reducing source language bleed through. Gone
                              are the days of partially-localized experiences
                              for your customers.
                            </p>
                            <p>
                              Pairing the GDN Crawler with Smartling’s
                              automation tools eliminates the need to manage
                              your content approval queue. Simply schedule when
                              and where the Crawler should ingest content, and
                              let it work its magic.
                            </p>
                            <p>
                              Translations will always be current, even for
                              dynamic or interactive content. The Crawler loads
                              pages like your customers' browsers, capturing
                              changes in text, including those powered by
                              Javascript.
                            </p>
                            <p>
                              With support for flexible schedules, filters to
                              narrow scope, and advanced features like custom
                              connection headers, the GDN Crawler quickly adapts
                              to various deployment environments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="l-grid-item">
                  <div className="c-block u-spacing">
                    <div className="c-block__image">
                      <img
                        loading="lazy"
                        style={{ minWidth: 'unset' }}
                        src={gdnCrawlerImage}
                        alt="GDN Crawler"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Section>

          <Section>
            <Section.Header>
              <Block title="The Power Behind the Proxy"></Block>
            </Section.Header>
            <Section.Inner s>
              <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe
                  src="https://player.vimeo.com/video/697533448?h=68b3b13612&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="position:absolute;top:0;left:0;width:100%;height:100%;"
                  title="The Power Behind the Proxy | Global Ready Conference 2022"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </Section.Inner>
          </Section>

          <Section>
            <Section.Inner l>
              <h3 className="u-space--double--bottom u-align--center u-max-width--70 u-margin-x--auto">
                Watch this webinar on how Vitamix sold out of a new product in
                24 hours with Smartling's GDN.
              </h3>
              <Section.Inner s>
                <div style="padding:62.21% 0 0 0;position:relative;">
                  <iframe
                    src="https://player.vimeo.com/video/480434786?h=59d3aede98&title=0&byline=0&portrait=0"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </Section.Inner>
              {/* <PardotResponsive
                height={650}
                url="https://resource.smartling.com/l/703963/2022-03-07/2rl679"
              /> */}
            </Section.Inner>
          </Section>

          <div
            className="u-spacing--half u-gradient--tertiary u-curve--top--right"
            style={{ marginTop: '0' }}
          >
            <Section>
              <Section.Header>
                <Block title="Customers Using Our Proxy">
                  <Block.Image>
                    <Icons.HeaderTrophy xxl />
                  </Block.Image>
                </Block>
              </Section.Header>
            </Section>

            <div className="u-spacing--sections">
              <SectionTwoColumn mobile title="5,200+ Hotel Websites">
                <SectionTwoColumn.Logo>
                  <img loading="lazy" src={caseStudyLogo1} alt="IHG Logo" />
                </SectionTwoColumn.Logo>
                <SectionTwoColumn.Description>
                  <p>
                    IHG continuously translates its digital properties using
                    Smartling's website proxy and Adobe Experience Manager
                    connector.
                  </p>
                </SectionTwoColumn.Description>
                <SectionTwoColumn.Buttons>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    url="https://info.smartling.com/ihg-success-story"
                  >
                    View Case Study
                  </Button>
                </SectionTwoColumn.Buttons>
                <SectionTwoColumn.Image>
                  <img loading="lazy" src={caseStudyImage1} alt="IGH Website" />
                </SectionTwoColumn.Image>
              </SectionTwoColumn>

              <SectionTwoColumn
                flip
                mobile
                title="Cut Translation Time by More Than Half"
              >
                <SectionTwoColumn.Logo>
                  <img loading="lazy" src={adrollLogo} alt="Adroll Logo" />
                </SectionTwoColumn.Logo>
                <SectionTwoColumn.Description>
                  <p>
                    Using the Global Delivery Network, AdRoll has decreased time
                    to market, reduced cost and built a scalable localization
                    infrastructure without a full time developer.
                  </p>
                </SectionTwoColumn.Description>
                <SectionTwoColumn.Buttons>
                  <Button
                    target="_blank"
                    rel="noopener noreferrer"
                    url="https://info.smartling.com/success-story-adroll"
                  >
                    View Case Study
                  </Button>
                </SectionTwoColumn.Buttons>
                <SectionTwoColumn.Image>
                  <img loading="lazy" src={adrollImage} alt="Adroll" />
                </SectionTwoColumn.Image>
              </SectionTwoColumn>
            </div>
          </div>

          {/* <SectionCta
            title="Fast and Cost-effective."
            gradientPrimary
            curveTop
            centerCTA
            checkmark
            curveBottomCenter
          >
            <SectionCta.Description>
              <p>
                Smartling is the fastest, most cost-effective way to deploy
                multilingual websites and web apps securely and reliably, with
                almost no coding.
              </p>
              <ul>
                <li>Minimal Coding Required</li>
                <li>Works with any web application</li>
                <li>Easy to customize and manage</li>
              </ul>
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="/meeting-request/">Get Started</Button>
            </SectionCta.Buttons>
          </SectionCta> */}

          <Section
            gradientSecondary
            curveTop
            curveBottom
            className="c-section__block-rows c-block-rows"
          >
            <Section.Header>
              <Block title="A Modern Proxy Solution">
                <Block.Image>
                  <Icons.HeaderTranslation xxl />
                </Block.Image>
                Effortlessly transform your marketing website, product pages,
                and thousands of SKUs into any language with minimal developer
                engagement
                <ul className="o-list--checked u-padding--and-half--top u-block">
                  <li>Works seamlessly with your CMS or web application</li>
                  <li>Easy to manage translation and customize experiences</li>
                  <li>Fully SEO-compatible</li>
                  <li>Always working: 99.99% uptime</li>
                </ul>
              </Block>
            </Section.Header>
            <Section.Inner m className="u-spacing--quad">
              <BlockRow title="Launch in a flash">
                <BlockRow.Image />
                <h5>The fastest way to deliver websites in any language</h5>
                <p>
                  Moving at the speed of business. The GDN delivers content
                  faster than your competition can, putting your content where
                  you need it, when you need it most.
                </p>
                <img loading="lazy" src={gdnDiagram} alt="GDN" />
              </BlockRow>
              <BlockRow title="No code. No drama.">
                <BlockRow.Image />
                <h5>
                  Finally, there’s a hassle-free solution for localizing
                  websites.
                </h5>
                <p>
                  We work with your team to set up the GDN with minimal
                  interference on your day-to-day. As for your developers, they
                  can keep their eyes on the product roadmap.
                </p>
              </BlockRow>
              <BlockRow title="Works hard. Plays well.">
                <BlockRow.Image />
                <h5>You can pair the GDN with any CMS or tech stack.</h5>
                <p>
                  From WordPress to your home-grown web service. You name it,
                  the GDN simply layers on top and is easy to deploy.
                </p>
              </BlockRow>
              {/* <BlockRow title="A 21st Century Proxy">
                <BlockRow.Image />
                <h5>Built for modern web experiences</h5>
                <p>
                  Customize the end user experience without compromising your
                  time. Manage image swaps, and easily convert date, time,
                  measurement, and currencies.
                </p>
              </BlockRow>
              <BlockRow title="How it works">
                <BlockRow.Image />
                <p>
                  Make a simple DNS change to add your multilingual domains, and
                  then watch magic of Smartling unfold.
                </p>
                <ul className="o-bullet-list">
                  <li>
                    Your website receives a request in a different language, our
                    GDN responds immediately.
                  </li>
                  <li>
                    The GDN instantly exchanges the existing English content
                    with professionally translated content.
                  </li>
                  <li>
                    Finally, the GDN delivers the translated version of the
                    website to the end user.
                  </li>
                </ul>
                <p>99.99% Uptime. Guaranteed.</p>
                <p>
                  The GDN enables a dynamic and SEO-compatible multilingual
                  experience for end users everywhere within milliseconds.
                </p>
              </BlockRow> */}
            </Section.Inner>
          </Section>

          <Section>
            <Section.Header>
              <Block title="Why our translation proxy rocks">
                <Block.Image>
                  <Icons.HeaderRocket xxl />
                </Block.Image>
              </Block>
            </Section.Header>
            <Section.Inner l>
              <Grid threeUp className="c-cards">
                <Grid.Item>
                  <Card title="Speedy">
                    <Card.Image />
                    <ul>
                      <li>Pages load within hundredths of a second</li>
                      <li>
                        Powered by a streaming server rather than a buffering
                        server
                      </li>
                    </ul>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Secure">
                    <Card.Image />
                    <ul>
                      <li>Meets the highest security standards</li>
                      <li>PCI Level 1, SOC 2, and HIPAA compliant</li>
                      <li>Routine security audit and system maintenance</li>
                    </ul>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card
                    title="100% SEO-compatible"
                    link="https://help.smartling.com/hc/en-us/articles/360000309453-Translate-SEO-Content"
                    linkText="Learn More"
                  >
                    <Card.Image />
                    <ul>
                      <li>Translates URLs and keywords</li>
                      <li>
                        Localized pages displayed on subdomains, subdirectories,
                        ccTLDs, or using cookies or HTTP headers
                      </li>
                    </ul>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Flexible">
                    <Card.Image />
                    <ul>
                      <li>
                        Includes server-side headers for developers to truly
                        customize a site for a particular language
                      </li>
                      <li>Adapts to website redesigns</li>
                    </ul>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="No source content bleed-through">
                    <Card.Image />
                    <ul>
                      <li>
                        Always display in-language content on your website by
                        configuring static cache
                      </li>
                      <li>
                        Prevent bleed-through by configuring a{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/360000309473-Using-a-Staging-Site"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          staging server
                        </a>
                        , leveraging machine translation, or{' '}
                        <a
                          href="https://help.smartling.com/hc/en-us/articles/115004496414-Setup-Translation-Cache"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          caching
                        </a>
                      </li>
                    </ul>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Powerful">
                    <Card.Image />
                    <ul>
                      <li>
                        Deployed on some of the world’s largest, dynamic,
                        personalized sites, including eCommerce, airline, and
                        hotel websites.
                      </li>
                    </ul>
                  </Card>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>
        </article>
      </Layout>
    )
  }
}

export default GlobalDeliveryNetworkPage
