import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Button from 'atoms/button'
import { DangerousHTML } from 'atoms/contentful/html'
import AccordionTable from '../02-organisms/AccordionTable'

// Sections
import Section from 'atoms/section'

// Images
import logosSecurityPCI from '../images/logos-security-pci.jpg'
import logosSecuritySOC from '../images/logos-security-soc.jpg'
import logosSecurityHIPAA from '../images/logos-security-hipaa.jpg'
import logosSecurityISO from '../images/logos-security-iso17100.png'
import supportImage from '../images/support.svg'

const plansTableHeadContent = [
  {
    title: 'Core',
    details:
      'Best fit for teams looking to translate small-scale basic projects such as business documents, etc.',
    // price: 'Starting at $0/mo',
    ctaText: 'Get a Demo',
    ctaLink: '/meeting-request/',
  },
  {
    title: 'Growth',
    details:
      'Best fit for mid-sized teams looking to localize software and/or mobile applications',
    // price: 'Starting at $1,500/mo',
    ctaText: 'Get a Demo',
    ctaLink: '/meeting-request/',
  },
  {
    title: 'Enterprise',
    details:
      'Best fit for large teams looking to optimize, customize and automate their localization process',
    // price: 'Starting at $3,000/mo',
    ctaText: 'Get a Demo',
    ctaLink: '/meeting-request/',
  },
]

const certCards = [
  {
    title: 'PCI',
    imgSrc: logosSecurityPCI,
    imgAlt: 'PCI logo',
    description:
      'Certifies presence of best security practices for secure processing and transmission of credit card data.',
    slug: '/security/ ',
  },
  {
    title: 'SOC 2',
    imgSrc: logosSecuritySOC,
    imgAlt: 'Secruity SOC logo',
    description:
      'Certifies a third-party vendor’s controls over security, availability, processing integrity, and confidentiality or privacy.',
    slug: '/security/ ',
  },
  {
    title: 'HIPAA',
    imgSrc: logosSecurityHIPAA,
    imgAlt: 'HIPPA logo',
    description:
      'Certifies an external vendor’s controls over privacy and security of certain health information covered by the law.',
    slug: '/security/ ',
  },
  {
    title: 'ISO 13485',
    imgSrc: logosSecurityISO,
    imgAlt: 'ISO logo',
    description:
      'Certifies the ability to provide medical devices and related services meet customer regulatory requirements.',
    slug: '/security/ ',
  },
  {
    title: 'ISO 17100',
    imgSrc: logosSecurityISO,
    imgAlt: 'ISO logo',
    description:
      'Indicates presence and capability of standard processes to deliver a quality translation service to meet the client’s specifications.',
    slug: '/security/ ',
  },
  {
    title: 'ISO 9001',
    imgSrc: logosSecurityISO,
    imgAlt: 'ISO logo',
    description:
      'Ensures that customers get consistent, good-quality products and services.',
    slug: '/security/ ',
  },
]

function PlansPage() {
  const { data } = this.props

  return (
    <Layout>
      <div className="l-page--plans">
        <Metadata
          title="Smartling Pricing: Plans Tailored To How Much You Translate"
          description="Smartling’s pricing is based on the plan you need and the translations you create. Software subscriptions and translation services are tailored to each business and scale with the number of languages you translate and the number of integrations you use."
          keywords="Smartling price, Smartling plans, How much is Smartling, Translation software, Translation Management System, translation platform, localization platform"
          image="http://images.ctfassets.net/a8pkciehvm1g/7BF3nz4CAIFOUiiVm1t45M/2d1ca4b00142fd12a0c2c1e48590a998/meta-image.png"
        />

        <section
          class="o-section c-section__hero c-hero c-hero--center u-gradient--septenary"
          data-bgset=""
        >
          <div class="o-section--inner l-wrap l-container--xl">
            <div class="c-hero--left">
              <div class="c-hero__content">
                {data.allContentfulPlansAndFeaturePage &&
                  data.allContentfulPlansAndFeaturePage.edges.map(
                    ({ node }) => (
                      <>
                        <h1 class="c-hero__title o-section-title--heavy u-font--xxxl-alt">
                          <DangerousHTML>
                            {node.pageHeader.html.text}
                          </DangerousHTML>
                        </h1>
                        <p class="c-hero__description u-space--half--top u-font--m-alt">
                          {node.subHeading.childMarkdownRemark.internal.content}
                        </p>
                        {node.headingButton && (
                          <div class="c-hero__buttons u-space--triple--top">
                            <a
                              className="o-button  "
                              href={node.headingButton.buttonLink}
                            >
                              {node.headingButton.buttonText}
                            </a>
                          </div>
                        )}
                      </>
                    )
                  )}
              </div>
            </div>
          </div>
        </section>
        <Section className="section-plans--plans-table">
          <div className="o-section--inner  l-wrap l-container--xl">
            {data.allContentfulPlansAndFeaturePage &&
              data.allContentfulPlansAndFeaturePage.edges.map(({ node }) => (
                <div className={`o-table c-plans-table`}>
                  <div className="c-plans-table__table">
                    <table className="c-plans-table__header">
                      <tbody>
                        <tr>
                          <td>
                            <h3 className="c-plans-table__title u-font--xxl-alt">
                              Our <br />
                              Plans
                            </h3>{' '}
                            <p className="u-text-color--accent u-padding--half--top">
                              and key features
                            </p>
                          </td>
                          {plansTableHeadContent.map((item) => {
                            return (
                              <td>
                                <h4 className="u-font--l-alt">{item.title}</h4>{' '}
                                <p className="u-padding--top u-text-color--accent ">
                                  {item.details}
                                </p>
                                {item.price && (
                                  <p className="u-font-weight--strong u-padding--top">
                                    {item.price}
                                  </p>
                                )}
                                <div class="c-hero__buttons--center u-space--triple--top">
                                  <a
                                    class="o-button"
                                    href={item.ctaLink}
                                    size="sm"
                                  >
                                    {item.ctaText}
                                  </a>
                                </div>
                              </td>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <DangerousHTML>{node.plans.body.html.text}</DangerousHTML>
                  </div>
                </div>
              ))}
          </div>
        </Section>

        <Section id="feature-tables" className="section-plans--feature-tables">
          <div className="o-section--inner l-wrap l-container--xl">
            <h2 class="c-hero__title o-section-title--heavy u-text-align--center u-padding--triple--bottom">
              Compare All Features
            </h2>
            {data.allContentfulPlansAndFeaturePage &&
              data.allContentfulPlansAndFeaturePage.edges.map(({ node }) => (
                <>
                  {node.featureTables.map((featureTable) => (
                    <>
                      <AccordionTable data={featureTable} />
                    </>
                  ))}
                </>
              ))}
          </div>
        </Section>

        <article
          className="l-article u-spacing--sections"
          style={{ marginBottom: '0' }}
        >
          <Section className="o-section c-section__cta c-cta c-cta--center u-space--quint--top">
            <div className="u-section-plans--background--primary o-section--inner l-wrap l-container--l  u-padding--quad--top u-padding--triple--bottom">
              {data.allContentfulPlansAndFeaturePage &&
                data.allContentfulPlansAndFeaturePage.edges.map(({ node }) => (
                  <>
                    <div className="c-cta__content u-spacing u-text-align--center">
                      <h2 className="c-cta__title u-font--xxl-alt">
                        Start Translating{' '}
                        <span className="u-text-gradient--underline">
                          Today
                        </span>
                      </h2>
                      <div className="c-cta__description u-font--m-alt">
                        {node.firstCta.description.description}
                      </div>
                    </div>
                    {node.firstCta.button?.buttonLink && (
                      <div className="c-cta__buttons">
                        <a
                          className="o-button  "
                          href={node.firstCta.button?.buttonLink}
                        >
                          {node.firstCta.button.buttonText}
                        </a>
                      </div>
                    )}
                  </>
                ))}
            </div>
          </Section>

          <Section className="o-section u-section-plans--background-secondary u-padding--quint--bottom">
            <div class="o-section--inner l-wrap l-container--xl">
              <div class="u-spacing">
                {data.allContentfulPlansAndFeaturePage &&
                  data.allContentfulPlansAndFeaturePage.edges.map(
                    ({ node }) => (
                      <>
                        <div className="section-plans--header--secondary u-space--quint--bottom">
                          <h2 className="section-plans--header--secondary--title u-font--xxl-alt u-text--line-height--tight">
                            {node.cardSectionTitle}
                          </h2>
                          <div className="section-plans--header--secondary--tagline u-font--m-alt u-text-color--accent">
                            <DangerousHTML>
                              {node.cardSectionDescription.html.text}
                            </DangerousHTML>
                          </div>
                        </div>
                        {node?.cardsSection && (
                          <div className="cards-list--cards">
                            {node.cardsSection.map((card) => {
                              return (
                                <div className="c-card c-card--bare">
                                  {card.headline && (
                                    <p className="u-font-weight--bold u-font--s u-padding--quarter--bottom">
                                      {card.headline}
                                    </p>
                                  )}
                                  {card.description.description && (
                                    <p className="c-card--description u-text-align--left u-font--s">
                                      {card.description.description}
                                    </p>
                                  )}
                                  {card.button?.buttonLink && (
                                    <div className="u-space--top u-text-align--left">
                                      <a
                                        href={card.button?.buttonLink}
                                        target={
                                          card.button?.newWindow
                                            ? '_blank'
                                            : '_self'
                                        }
                                        rel="noopener noreferrer"
                                        class="o-link u-color--primary-alt u-font--s"
                                      >
                                        {card.button?.buttonText}
                                      </a>
                                    </div>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </>
                    )
                  )}
              </div>
            </div>
          </Section>

          <Section
            centerCTA
            className="c-section__right section-plans--metrics"
          >
            <Section.Inner xl>
              <div className="section-plans--header--secondary u-space--quint--bottom">
                <h2 className="section-plans--header--secondary--title u-font--xxl-alt u-text--line-height--tight">
                  Why Smartling Language Services
                </h2>
              </div>
              <div className="section-plans--metrics">
                <div className="l-grid">
                  <h4 className="l-grid__item u-font--xxl-alt">
                    <span className=" o-heading--xxl u-text-align--center">
                      27%
                    </span>
                    <span className="u-font-weight--light">
                      Better on time delivery
                    </span>
                  </h4>
                  <h4 className="l-grid__item u-font--xxl-alt">
                    <span className=" o-heading--xxl u-text-align--center">
                      42%
                    </span>
                    <span className="u-font-weight--light">
                      Faster time to market
                    </span>
                  </h4>
                  <h4 className="l-grid__item u-font--xxl-alt">
                    <span className=" o-heading--xxl u-text-align--center">
                      48%
                    </span>
                    <span className="u-font-weight--light">
                      Less changes requested
                    </span>
                  </h4>
                  <h4 className="l-grid__item u-font--xxl-alt">
                    <span className=" o-heading--xxl u-text-align--center">
                      14%
                    </span>
                    <span className="u-font-weight--light">
                      Decrease on translation cost
                    </span>
                  </h4>
                </div>
              </div>
            </Section.Inner>
          </Section>

          <Section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right">
            <Section.Inner xl>
              {data.allContentfulPlansAndFeaturePage &&
                data.allContentfulPlansAndFeaturePage.edges.map(({ node }) => (
                  <div className="l-grid l-grid--2up l-grid--align-center l-grid--100-column-gap  l-grid--reverse--sm">
                    <div className="l-grid-item">
                      <div>
                        <h3 class="o-section-title o-section-title--heavy">
                          {node.supportSectionTitle}
                        </h3>
                        <p className="u-font--m-alt u-text-color--accent u-space--top">
                          {node.supportSectionDescription.internal.content}
                        </p>
                        <div className="u-space--and-half--top u-spacing--double">
                          {node.supportSectionContent.map((item) => {
                            if (item.internal.type === 'ContentfulBasicCard') {
                              return (
                                <div className="">
                                  {item.headline && (
                                    <div className="u-text-align--left u-padding--quarter--bottom">
                                      <p className="u-font-weight--bold">
                                        {item.headline}
                                      </p>
                                    </div>
                                  )}
                                  {item.description.description && (
                                    <p className="u-text-align--left u-font--s">
                                      {item.description.description}
                                    </p>
                                  )}
                                  <div className="u-space--top u-text-align--left">
                                    <a
                                      href={item.button?.buttonLink}
                                      target={
                                        item.button.newWindow
                                          ? '_blank'
                                          : '_self'
                                      }
                                      rel="noopener noreferrer"
                                      class="o-link u-color--primary-alt u-font--s"
                                    >
                                      {item.button.buttonText}
                                    </a>
                                  </div>
                                </div>
                              )
                            } else {
                              return (
                                <div class="c-hero__buttons u-space--double--top">
                                  <a
                                    class="o-button"
                                    href={item.buttonLink}
                                    size="sm"
                                    target={item.newWindow ? '_blank' : '_self'}
                                  >
                                    {item.buttonText}
                                  </a>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    {node?.supportImage && (
                      <div className="l-grid-item">
                        <div class="c-product-intro-image">
                          <img
                            loading="lazy"
                            src={node?.supportImage.file.url}
                            aria-hidden={true}
                            alt={node?.supportImage.description}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </Section.Inner>
          </Section>

          <Section>
            <Section.Inner xl>
              <hr />
            </Section.Inner>
          </Section>

          <Section className="o-section section-plans--security u-space--triple--bottom">
            <Section.Inner xl>
              <div className="section-plans--header--secondary u-space--quint--bottom">
                <h2 className="section-plans--header--secondary--title u-font--xxl-alt u-text--line-height--tight">
                  Smartling Leads the Way in Enterprise Security
                </h2>
              </div>
              <div class="l-grid l-grid--3up">
                {certCards.map((card) => {
                  return (
                    <div class="l-grid-item">
                      <div class="c-card c-card--bare u-spacing">
                        <div class="c-block__image u-text-align--center">
                          <img
                            loading="lazy"
                            class="c-security-logo"
                            src={card.imgSrc}
                            alt={card.imgAlt}
                          />
                        </div>
                        <h3 class="u-text-align--center">{card.title}</h3>
                        {card.description ? (
                          <div class="u-text-align--center c-card--description">
                            <p className="u-font--s">{card.description}</p>
                          </div>
                        ) : null}
                        <div className="u-space--top u-text-align--center">
                          <a
                            href={card.slug}
                            class="o-link u-color--primary u-font--s"
                          >
                            View Certification
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Section.Inner>
          </Section>
        </article>
      </div>
    </Layout>
  )
}

export default PlansPage

export const query = graphql`
  query PlansPageQuery {
    allContentfulPlansAndFeaturePage(
      filter: { id: { eq: "c5cd6c17-7382-59ae-80ba-0d6c9e36fb92" } }
    ) {
      edges {
        node {
          pageHeader {
            html: childMarkdownRemark {
              text: html
            }
          }
          subHeading {
            childMarkdownRemark {
              internal {
                content
              }
            }
          }
          headingButton {
            buttonLink
            buttonText
            newWindow
          }
          plans {
            body {
              html: childMarkdownRemark {
                text: html
              }
            }
          }
          featureTables {
            title
            body {
              html: childMarkdownRemark {
                text: html
              }
            }
          }
          firstCta {
            button {
              buttonLink
              buttonText
            }
            headline
            description {
              description
            }
          }
          # cardsSection {
          #   headline
          #   description {
          #     description
          #   }
          #   button {
          #     buttonLink
          #     buttonText
          #     newWindow
          #   }
          # }
          cardSectionTitle
          cardSectionDescription {
            html: childMarkdownRemark {
              text: html
            }
          }
          supportSectionTitle
          supportSectionDescription {
            internal {
              content
            }
          }
          supportSectionContent {
            ... on ContentfulBasicCard {
              id
              headline
              description {
                description
              }
              button {
                buttonText
                buttonLink
                newWindow
              }
              internal {
                type
              }
            }
            ... on ContentfulButton {
              id
              buttonLink
              buttonText
              newWindow
              internal {
                type
              }
            }
          }
          supportImage {
            description
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
