import React from 'react'
import PropTypes from '../prop-types'
import { oneByType, withoutTypes } from '../util/react'

const Block = ({
  title,
  kicker,
  kickerLink,
  anchor,
  link,
  linkText,
  linkWindow,
  children,
  className,
}) => {
  const image = oneByType(children, Block.Image)
  const description = withoutTypes(children, Block.Image)
  const tags = oneByType(children, Block.Tags)

  return (
    <PatternBlock
      title={title}
      kicker={kicker}
      kickerLink={kickerLink}
      anchor={anchor}
      description={description}
      image={image?.props.children}
      link={{ url: link, text: linkText, window: linkWindow }}
      tags={tags?.props.children}
      className={className}
    />
  )
}

Block.propTypes = {
  title: PropTypes.string,
  kicker: PropTypes.string,
  kickerLink: PropTypes.string,
  anchor: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkWindow: PropTypes.string,
  children: PropTypes.children,
  className: PropTypes.string,
}

export default Block

Block.Image = ({ children }) => <React.Fragment />

Block.Tags = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternBlock = ({
  title,
  kicker,
  kickerLink,
  anchor,
  description,
  linkWindow,
  tags,
  image,
  link,
  className,
}) => {
  if (kickerLink) {
    kicker = (
      <span className="c-block__kicker o-kicker">
        <a href={kickerLink}>{kicker}</a>
      </span>
    )
  } else if (kicker) {
    kicker = <span className="c-block__kicker o-kicker">{kicker}</span>
  } else {
    kicker = ''
  }

  if (link.url) {
    if (image) {
      image = (
        <div className="c-block__image">
          <a
            href={link.url}
            target={
              link.url ===
              '/globalready/how-to-talk-to-your-global-customers-in-their-native-language/'
                ? '_blank'
                : '_self' || link.linkWindow
                ? '_blank'
                : '_self'
            }
            rel="noreferrer"
          >
            {image}
          </a>
        </div>
      )
    }
    title = (
      <h3 className="c-block__title">
        <a
          href={link.url}
          target={
            link.url ===
            '/globalready/how-to-talk-to-your-global-customers-in-their-native-language/'
              ? '_blank'
              : '_self' || link.linkWindow
              ? '_blank'
              : '_self'
          }
          rel="noreferrer"
        >
          {title}
        </a>
      </h3>
    )
  } else {
    if (image) {
      image = <div className="c-block__image">{image}</div>
    }
    title = <h3 className="c-block__title">{title}</h3>
  }
  return (
    <React.Fragment>
      <div className={`c-block ${className} u-spacing`}>
        {anchor ? <div id={anchor} /> : ''}
        {image}
        <div className="c-block__text u-spacing">
          <div className="u-spacing">
            {kicker}
            {title}
            {description && (
              <div className="c-block__description">{description}</div>
            )}
            {tags && <div className="c-block__tags">{tags}</div>}
          </div>
          {link.url && (
            <a
              role="button"
              href={link.url}
              className="o-link"
              target={link.window ? '_blank' : '_self'}
            >
              {link.text}
            </a>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

PatternBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  tags: PropTypes.node,
  image: PropTypes.node,
}
