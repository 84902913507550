import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/mindtouch.jpg'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import imageIntegration from '../../../images/integrations/smartling-mindtouch-integration.jpg'
import mindtouchInfographic from '../../../images/integrations/mindtouch-infographic.png'
import { Link } from 'gatsby'
var imgStyle = {
  maxWidth: '500px',
}

class MindtouchIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Mindtouch Translation Integration | Smartling"
          description="Manage self-support content and save hours of manual work through automated translation workflows using Smartling’s Mindtouch connector."
          keywords=""
          image={heroImage}
          alt="Mindtouch Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Mindtouch Translation Integration"
          >
            <Hero.Description>
              Manage self-support content and save hours of manual work through
              automated translation workflows using Smartling’s Mindtouch
              connector.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Mindtouch logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--s c-product-intro-section">
              <div class="u-spacing--and-half  u-align--center">
                <h3 class="o-section-title o-section-title--heavy u-align--center">
                  Accurate & quick support in native language
                  <br />↓<br />
                  happy customers
                </h3>
                <p>
                  Did you know? Customer satisfaction and retention are heavily
                  influenced by consumers’ ability to quickly and easily access
                  accurate support content in their preferred language.
                </p>
                <p>
                  With this integration, Smartling powers Mindtouch to help
                  brands meet multilingual consumers at their point of need and
                  makes the right self-service answers easy to find in their
                  native language.
                </p>
                <Button url="/meeting-request/">
                  Schedule a 15-min meeting
                </Button>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title--heavy">
                      Countless hours of manual work saved
                    </h3>
                    <p>
                      Smartling simplifies this process through fully automated
                      workflows, enabling content creators to easily create,
                      translate, publish, and manage the knowledge base content
                      in one place.
                    </p>
                    <p>
                      No more copying and pasting, exporting and importing of
                      spreadsheets, or back and forth of emails!
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half u-text-align--right">
                    <h3 class="o-section-title--heavy">
                      What’s in it for you?
                    </h3>
                    <p>
                      Smartling intuitively connects directly with Mintouch
                      within the Smartling platform, enabling you to fully
                      automate the stream of source content and translations.
                    </p>
                    <p>
                      <span
                        role="img"
                        aria-label="checkmark"
                        className="o-heading--m u-color--primary"
                      >
                        ✓
                      </span>{' '}
                      Countless hours of manual work saved
                      <br />
                      <span
                        role="img"
                        aria-label="checkmark"
                        className="o-heading--m u-color--primary"
                      >
                        ✓
                      </span>{' '}
                      Higher quality translation
                      <br />
                      <span
                        role="img"
                        aria-label="checkmark"
                        className="o-heading--m u-color--primary"
                      >
                        ✓
                      </span>{' '}
                      Faster time to market
                      <br />
                      <span
                        role="img"
                        aria-label="checkmark"
                        className="o-heading--m u-color--primary"
                      >
                        ✓
                      </span>{' '}
                      Satisfied customers
                      <br />
                      <span
                        role="img"
                        aria-label="checkmark"
                        className="o-heading--m u-color--primary"
                      >
                        ✓
                      </span>{' '}
                      Stronger ROI
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <Section
              gradientSecondary
              className="c-section__grid-blocks c-grid-blocks u-padding--section"
            >
              <Section.Header>
                <img
                  loading="lazy"
                  src={mindtouchInfographic}
                  alt="Benefits mindtouch vs without mindtouch "
                />
              </Section.Header>
            </Section>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Your One Source of Truth"></Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--center u-spacing">
                    <p>
                      Smartling enables you to capture and codify brand
                      preferences to improve translation quality outcomes
                      programmatically.
                    </p>
                    <p>
                      Our AI-powered toolkit automatically generates brand
                      terminology, and you can provide guidance on content style
                      preferences, customize workflows, and configure
                      translation memory however you wish. And you can manage
                      all of your brand preferences within the Smartling
                      platform.
                    </p>
                    <h4>
                      <a href="https://www.smartling.com/software/CAT-tool/">
                        Learn More About Smartling CAT Tool
                      </a>
                    </h4>
                    <h3 style={{ paddingTop: '100px' }}>
                      Translate by the page or groups of pages
                    </h3>
                    <p>
                      When requesting a translation job, you can conveniently
                      select one page at a time or choose an entire category of
                      content to be translated.
                    </p>
                    <h3>Track job status</h3>
                    <p>
                      Whether you translate a page or an entire category of
                      pages, you can track the job status on an individual page
                      level within the Smartling platform.
                    </p>
                    <h3>Automate your workflow</h3>
                    <p>
                      Schedule a translation job at the time and frequency of
                      your choice.
                    </p>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <div class="o-section--inner l-wrap l-container--l ">
              <Grid twoUp>
                <Grid.Item className="u-spacing--half">
                  <h2>Comprehensive Smartling Language Services</h2>
                  <p>
                    Integrating with Smartling connects you with comprehensive
                    language services. We match your content with professional
                    translators with industry experience.
                  </p>
                  <p>
                    Smartling’s language services team will manage the
                    translators and ensure quality, timeliness, and delivery of
                    translations always meets your expectations.
                  </p>
                  <p>
                    One more reason to love Smartling Language Services: You get
                    to communicate directly with the translators for questions
                    and feedback and have complete visibility into the
                    translation team!
                  </p>
                  <p>
                    <Link href="/translation-services/">
                      Learn More About Smartling Language Services
                    </Link>
                  </p>
                </Grid.Item>
                <Grid.Item>
                  <img
                    loading="lazy"
                    src={imageIntegration}
                    alt="many different language names displayed in different colors"
                  />
                </Grid.Item>
              </Grid>
            </div>

            <section class="o-section--inner l-wrap l-container--xs-s u-text-align--center u-spacing">
              <img
                loading="lazy"
                src={imagePuzzle}
                alt="Puzzle Pieces"
                style={{
                  maxWidth: '120px',
                  border: '7px solid #56189e',
                  borderRadius: '50%',
                }}
              />

              <h3 class="o-section-title o-section-title">
                Ready to integrate Mindtouch with Smartling?
              </h3>

              <p>
                Translation for self-support content has never been easier. Join
                the ranks of Smartling’s world-class customers today!
              </p>

              <Button url="/meeting-request/">Book a demo</Button>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default MindtouchIntegrationPage
