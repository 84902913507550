import React from 'react'
import PropTypes from '../prop-types'
import Section from 'atoms/section'
import { allByType } from '../util/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const SectionLogoBanner = ({ title, children }) => (
  <Section title={title} className="c-section__logo-banner c-logo-banner">
    {title && (
      <Section.Header>
        <h2 class="c-cta__title">{title}</h2>
      </Section.Header>
    )}
    <Section.Inner xl>
      {allByType(children, SectionLogoBanner.Item)}
    </Section.Inner>
  </Section>
)

export default SectionLogoBanner

SectionLogoBanner.Item = ({ url, src, alt, gatsbyImageData }) => {
  const content = gatsbyImageData ? (
    <GatsbyImage
      image={gatsbyImageData}
      alt={alt}
      objectFit="contain"
      objectPosition="center"
    />
  ) : (
    <img loading="lazy" className="lazyload" data-src={src} alt={alt} />
  )

  return url ? (
    <a
      href={url}
      aria-label={alt}
      className={`c-logo-banner__link c-logo--${alt
        .toLowerCase()
        .replace(' ', '-')}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {content}
    </a>
  ) : (
    <div
      className={`c-logo-banner__link c-logo--${alt
        ?.toLowerCase()
        .replace(' ', '-')}`}
    >
      {content}
    </div>
  )
}

SectionLogoBanner.Item.propTypes = {
  url: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
}
