import React from 'react'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'

import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'
import SectionTwoColumn from 'organisms/two-column'
import SectionCta from 'organisms/cta'

import SectionContact from 'components/sections/contact'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionSoftwareAutomation from 'components/sections/software-automation'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/illustrations/cost-estimates.svg'
import twoColumnImage1 from '../../../images/illustrations/cat-tool-cropped.png'
import twoColumnImage2 from '../../../images/illustrations/global-delivery-network-cropped.png'

class OnboardingEnablementServicesPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Onboarding and Translation Enablement Services - Smartling "
          description="Getting your translation project started with Smartling is simplified by our people. Learn about Managed Services, Professional Services and enablement with our help center."
          keywords=""
        />
        <Hero curveLeft gradientPrimary title="Your success is our priority">
          <Hero.Description>
            Our people deliver world-class support and services so that you can
            focus on everything else.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Project Management" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/excellence-every-touchpoint">
              Excellence at Every Touchpoint
            </Button>
          </Hero.Buttons>
        </Hero>
        <article className="l-article u-spacing--sections">
          <Section className="u-space--double--bottom">
            <Section.Header>
              <Block title="Managed Services">
                <Block.Image>
                  <Icons.HeaderMachineLearning xxl />
                </Block.Image>
                We do the work so you don’t have to
              </Block>
            </Section.Header>
          </Section>
          <SectionTwoColumn
            white
            bulb
            title="No localization manager? No problem."
          >
            <SectionTwoColumn.Logo />
            <SectionTwoColumn.Description>
              <p>Managed Services serves as an extension to your team.</p>
              <ul className="o-list--checked">
                <li>Project management</li>
                <li>Vendor management</li>
                <li>Custom training</li>
                <li>Budget management</li>
                <li>Issues routing</li>
              </ul>
            </SectionTwoColumn.Description>
            <SectionTwoColumn.Buttons />
            <SectionTwoColumn.Image>
              <img loading="lazy" src={twoColumnImage1} alt="CAT Tool" />
            </SectionTwoColumn.Image>
          </SectionTwoColumn>

          <SectionCta
            title="“We are in great hands with Smartling and can easily delegate tasks knowing they would take care of it.”"
            centerCTA
            gradientPrimary
            curveTop
            curveBottomCenter
          >
            <SectionCta.Description>
              <h5>– VP of Marketing</h5>
            </SectionCta.Description>
          </SectionCta>

          <Section className="u-space--double--bottom">
            <Section.Header>
              <Block title="Professional Services">
                <Block.Image>
                  <Icons.HeaderMessage xxl />
                </Block.Image>
              </Block>
            </Section.Header>
          </Section>
          <SectionTwoColumn
            flip
            white
            bulb
            title="De-risk integrations and maximize ROI"
          >
            <SectionTwoColumn.Logo />
            <SectionTwoColumn.Description>
              <p>
                Our solutions architects know everything about Smartling so you
                don’t need developers to start translating.
              </p>
              <ul className="o-list--checked">
                <li>Website proxy configuration</li>
                <li>Technical solutioning for every integration type</li>
                <li>Advanced configurations for complex environments</li>
                <li>Custom scripts, rules and re-writes</li>
                <li>CMS migration support</li>
                <li>Health check</li>
              </ul>
            </SectionTwoColumn.Description>
            <SectionTwoColumn.Buttons />
            <SectionTwoColumn.Image>
              <img
                loading="lazy"
                src={twoColumnImage2}
                alt="Global Delivery Network"
              />
            </SectionTwoColumn.Image>
          </SectionTwoColumn>

          <SectionCta
            title="“Smartling delivered on every promise from Day One and we realized value in this investment immediately.”"
            centerCTA
            gradientPrimary
            curveTop
            curveBottomCenter
          >
            <SectionCta.Description>
              <h5>– Marketing Manager</h5>
            </SectionCta.Description>
          </SectionCta>

          <Section>
            <Section.Header>
              <Block title="You’ve got questions. We’ve got answers.">
                <Block.Image>
                  <Icons.HeaderMessage xxl />
                </Block.Image>
                Smartling support is an email away. We have SLAs for emergencies
                (first response within 15 minutes!) and general questions.
              </Block>
            </Section.Header>
          </Section>

          <SectionCta
            title="How can we help?"
            centerCTA
            gradientSecondary
            curveTop
            curveBottom
          >
            <SectionCta.Description>
              Comprehensive documentation to help you use Smartling, integrate
              your tech stack, and get the most out of your investment.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button
                rel="noopener noreferrer"
                target="_blank"
                url="https://help.smartling.com/hc/en-us"
              >
                Explore our docs
              </Button>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionCustomerSuccess />
          <div className="u-gradient--primary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionSoftwareAutomation />
          </div>
          <SectionContact />
        </article>
      </Layout>
    )
  }
}

export default OnboardingEnablementServicesPage
