import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'

const DisplayButton = ({
  outline,
  outlineWhite,
  secondary,
  children,
  size,
  ...patternProps
}) => {
  const classes = cx({
    'o-button': true,
    'o-button--outline': outline,
    'o-button--small': size === 'sm',
    'o-button--outline-white': outlineWhite,
    'o-button--secondary': secondary,
  })
  return (
    <div {...patternProps} className={classes}>
      {children}
    </div>
  )
}

DisplayButton.propTypes = {
  outline: PropTypes.bool,
}

export default DisplayButton
