import React from 'react'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Section from 'atoms/section'
import Card from 'molecules/card'

class SectionContactUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section className="c-section__grid-blocks c-grid-blocks">
          <Section.Header>
            <Block title="We’d Love to Hear from You!">
              <Block.Image>
                <Icons.HeaderEmail xxl />
              </Block.Image>
            </Block>
          </Section.Header>
          <Section.Inner xl>
            <Grid twoUp className="c-cards">
              <Grid.Item>
                <Card title="New York (HQ)">
                  <Card.Image />
                  <div className="u-spacing">
                    <div>
                      <h5>Email:</h5>
                      <p>
                        <a href="mailto:hi@Smartling.com">hi@Smartling.com</a>
                      </p>
                    </div>
                    <div>
                      <h5>Address:</h5>
                      <p>
                        1375 Broadway 14th Floor
                        <br />
                        New York, NY 10018
                      </p>
                    </div>
                    <div>
                      <h5>Phone Number:</h5>
                      <p>
                        <a href="tel:1-866-707-6278">1 (866) 707-6278</a>
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid.Item>
              <Grid.Item>
                <Card title="Dublin">
                  <Card.Image />
                  <div className="u-spacing">
                    <div>
                      <h5>Email:</h5>
                      <p>
                        <a href="mailto:hi@Smartling.com">hi@Smartling.com</a>
                      </p>
                    </div>
                    <div>
                      <h5>Address:</h5>
                      <p>
                        Smartling Ireland Limited
                        <br />
                        Unit 4B Fourth Floor
                        <br />
                        7-9 Dame Court
                        <br />
                        Exchequer Street
                        <br />
                        D02 NH31, Ireland
                      </p>
                    </div>
                  </div>
                </Card>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <div className="u-center-block u-text-align--center u-space--double--top">
          <Button url="/careers">Join us</Button>
        </div>
      </React.Fragment>
    )
  }
}

export default SectionContactUs
