import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Helmet from 'react-helmet'

// Sections
import Hero from 'organisms/hero'
import LandingPageSection from 'components/sections/landing-page-section'

import Layout from 'layouts'

import MetaDataImage from '../images/meta-image.png'

class ConferencePage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL.
    const { data } = this.props
    return (
      <Layout>
        <div className={'l-page--grc'}>
          <Helmet>
            {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
            <html className="page--grc" />
          </Helmet>
          <Metadata
            title={`${data.page.metadataTitle || data.page.title} - Smartling`}
            description={data.page.metadataDescription}
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={MetaDataImage}
          />

          <Hero
            gradientPrimary
            title={data.page.title}
            backgroundImage={data.page.heroImage?.file.url}
            center
            curveLeft
            disableLazy
          >
            <Hero.Description>
              {data.page.body && (
                <div className="u-spacing">
                  <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                  <div>
                    {data.page.heroButtons &&
                      data.page.heroButtons.map((button) => {
                        return (
                          <a
                            href={button.buttonLink}
                            target={button.newWindow ? `_blank` : `_self`}
                            className="o-anchor c-hero__link"
                          >
                            {button.buttonText
                              ? button.buttonText
                              : `Learn More`}
                          </a>
                        )
                      })}
                  </div>
                </div>
              )}
            </Hero.Description>
          </Hero>

          {data.page.pageSections &&
            data.page.pageSections.map((section, index) => (
              <LandingPageSection
                {...section}
                disableLazy={index === 0 ? true : false}
              />
            ))}
        </div>
      </Layout>
    )
  }
}

export default ConferencePage

export const query = graphql`
  query ConferencePageQuery {
    page: contentfulBasicPage(path: { eq: "conference2020" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      heroImage {
        ...ContentfulImageFragment
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
      }
    }
  }
`
