import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Layout from 'layouts/index-ppc'
import PardotForm from 'components/forms/pardot'
import HubSpotForm from 'components/forms/HubSpotForm'
import LandingPageSection from 'components/sections/landing-page-section'
import SectionLogoBanner from 'organisms/logo-banner'
import SectionCta from 'organisms/cta'
import { GatsbyImage } from 'gatsby-plugin-image'

import MetaDataImage from '../images/meta-image.png'

const PpcTemplate = ({ data }) => {
  const heroImage = data.page.heroImage
  console.log(data.page.description)
  const hubspotFormReference = data.page.hubspotFormReference || {}
  const { portalid, formid, campaignId } = hubspotFormReference

  return (
    <Layout>
      <div className="c-ppc-detail c-ppc-book-meeting">
        <Metadata
          title={data.page?.metadataTitle || data.page.title}
          description={data.page?.metadataDescription}
          keywords={data.page?.metadataKeywords?.join(`, `)}
          image={MetaDataImage}
        />
        <Hero
          className="c-hero__resource o-hero--custom"
          curveBottom
          gradientSenery
          title={data.page.heroTitle}
          backgroundImage={heroImage && heroImage.file.url}
        >
          <Hero.Description>
            {data.page.heroDescription && (
              <div className="c-hero__description--wrap">
                <DangerousHTML>
                  {data.page.heroDescription.html.text}
                </DangerousHTML>
              </div>
            )}
            {data.page.description && (
              <p className="c-hero__sub-description">{data.page.description}</p>
            )}
          </Hero.Description>
          {data.page.heroImage && (
            <div className="c-ppc__hero-background">
              {heroImage.gatsbyImageData ? (
                <GatsbyImage
                  image={heroImage.gatsbyImageData}
                  alt={heroImage.title}
                  objectFit="contain"
                  // title={heroDescription.description}
                />
              ) : (
                <img
                  loading="lazy"
                  src={heroImage.file.url}
                  alt={heroImage.title}
                />
              )}
            </div>
          )}
          <Hero.Image>
            {data.page?.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'In Hero' ? (
                <div className="c-ppc__hero--pardot">
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                  <p>
                    For translators interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              ) : (
                ``
              ))}
          </Hero.Image>
          <Hero.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </Hero.Buttons>
        </Hero>
        {data.page.pageSections &&
          data.page.pageSections.map((section) =>
            section.internal.type === 'ContentfulLogoCarousel' ? (
              <div className="c-logo-banner__book-meeting">
                <SectionLogoBanner title={section.title}>
                  {section.sliderItems.map((logo) => (
                    <SectionLogoBanner.Item
                      url={logo.image?.url}
                      src={logo.image?.file.url}
                      alt={logo.image.title}
                    />
                  ))}
                </SectionLogoBanner>
              </div>
            ) : (
              <LandingPageSection {...section} />
            )
          )}
        {data.page?.hubspotFormReference &&
          (data.page?.pardotFormReferencePlacement === 'Below Body' ||
          data.page?.pardotFormReferencePlacement === 'Hero and Below Body' ? (
            <div
              className={`l-wrap ${
                data.page?.pardotFormReference.name ===
                'Email Capture - Viewed How Lyft Scaled Content for 8 Languages PPC Webinar'
                  ? 'l-container--s'
                  : 'l-container--xs'
              }`}
            >
              <div
                id="hubspot-form-container"
                className="hubspot-form-container"
              >
                <HubSpotForm
                  portalId={portalid}
                  formId={formid}
                  campaignId={campaignId || ''}
                />
              </div>
            </div>
          ) : (
            ``
          ))}
      </div>
    </Layout>
  )
}

export default PpcTemplate

export const pageQuery = graphql`
  query MeetingRequestQuery {
    page: contentfulPpc(slug: { eq: "meeting-request" }) {
      name
      slug
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      description
      heroImage {
        # ...ContentfulImageFragment
        file {
          url
        }
        gatsbyImageData(
          width: 610
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          height: 343
        )
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
        ... on ContentfulLogoCarousel {
          internal {
            type
          }
          title
          sliderItems {
            image {
              file {
                url
              }
              description
              title
            }
          }
        }
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`
