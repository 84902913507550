import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from '../../prop-types'
import { getPersonPath } from '../../routes'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import PardotForm from 'components/forms/pardot'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import BlockRow from 'molecules/block-row'
import Card from 'molecules/card'
import Section from 'atoms/section'
import SectionTwoColumn from 'organisms/two-column'
import SectionCta from 'organisms/cta'
import SectionLogoBanner from 'organisms/logo-banner'
import Hero from 'organisms/hero'
import AccordionTable from 'organisms/AccordionTable'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const LandingPageSection = ({
  internal,
  name,
  headline,
  dropdownsHeadline,
  description,
  featuredImage,
  featuredVideo,
  featureTables,
  body,
  linkOpensInNewWindow,
  accordionItems,
  pardotFormReference,
  pardotFormReferencePlacement,
  buttons,
  backgroundColor,
  backgroundTopCurve,
  backgroundBottomCurve,
  content,
  contentBlockType,
  customBackground,
  customBackgroundPosition,
  gridLayout,
  rowLayout,
  disableLazy,
  showExpanded,
  secondaryTitle,
  descriptionText,
  image,
  secondaryImage,
  secondaryDescription,
  button,
}) => {
  function sectionName(n) {
    if (!n) {
      return ''
    }
    return n
      .toLowerCase()
      .replace(/\s|'|"|\n|\r/gi, '-')
      .replace(/[^a-z0-9-]/gi, '')
  }
  function gradientClass() {
    if (backgroundColor === 'Pink/Purple Gradient') {
      return `${'c-section--' + sectionName(name)} u-gradient--primary`
    } else if (backgroundColor === 'Blue Gradient') {
      return `${'c-section--' + sectionName(name)} u-gradient--secondary`
    } else if (backgroundColor === 'Blue/White Gradient') {
      return `${'c-section--' + sectionName(name)} u-gradient--tertiary`
    } else if (backgroundColor === 'White/Purple Gradient') {
      return `${'c-section--' + sectionName(name)} u-gradient--accent-alt`
    } else {
      return `${'c-section--' + sectionName(name)} `
    }
  }
  function curveTopClass() {
    if (backgroundTopCurve === 'Center') {
      return `u-curve--top`
    } else if (backgroundTopCurve === 'Left') {
      return `u-curve--top--left`
    } else if (backgroundTopCurve === 'Right') {
      return `u-curve--top--right`
    } else {
      return ``
    }
  }
  function curveBottomClass() {
    if (backgroundBottomCurve === 'Center') {
      return `u-curve--bottom--center`
    } else if (backgroundBottomCurve === 'Left') {
      return `u-curve--bottom`
    } else if (backgroundBottomCurve === 'Right') {
      return `u-curve--bottom`
    } else {
      return ``
    }
  }
  function paddingClass() {
    if (
      internal?.type === 'ContentfulLandingPageSectionRow' ||
      contentBlockType === 'Card 2 Column'
    ) {
      return ``
    } else if (backgroundBottomCurve || backgroundTopCurve || backgroundColor) {
      return `u-padding--section`
    } else {
      return ``
    }
  }
  function gridClass() {
    if (gridLayout === '1 column') {
      return `l-grid--1up`
    } else if (
      gridLayout === '2 column' ||
      gridLayout === 'Alternating Nested'
    ) {
      return `l-grid--2up`
    } else if (gridLayout === '3 column') {
      return `l-grid--3up`
    } else if (gridLayout === '4 column') {
      return `l-grid--4up`
    } else {
      return ``
    }
  }
  function collectionItemClass(i) {
    if (gridLayout === '1 column') {
      return `c-collection__item--1up ${
        content.length === i + 1 ? 'c-collection__item--last-row' : ''
      } ${content.length === i + 1 ? 'c-collection__item--last-item' : ''}`
    } else if (
      gridLayout === '2 column' ||
      gridLayout === 'Alternating Nested'
    ) {
      return `c-collection__item--2up ${
        content.length <= i + 2 ? 'c-collection__item--last-row' : ''
      } ${content.length === i + 1 ? 'c-collection__item--last-item' : ''}`
    } else if (gridLayout === '3 column') {
      return `c-collection__item--3up ${
        content.length <= i + 3 ? 'c-collection__item--last-row' : ''
      } ${content.length === i + 1 ? 'c-collection__item--last-item' : ''}`
    } else if (gridLayout === '4 column') {
      return `c-collection__item--4up ${
        content.length <= i + 4 ? 'c-collection__item--last-row' : ''
      } ${content.length === i + 1 ? 'c-collection__item--last-item' : ''}`
    } else {
      return ``
    }
  }
  function gridItemBgImage(item) {
    return item.image
      ? `${item.image.file.url}?fit=fill&w=${
          gridLayout === '1 column' ? '1358' : '905'
        }&h=${gridLayout === '1 column' ? '975' : '650'}`
      : item.person?.headshot
      ? `${item.person.headshot.file.url}?fit=fill&w=${
          gridLayout === '1 column' ? '1358' : '905'
        }&h=${gridLayout === '1 column' ? '975' : '650'}`
      : ''
  }
  function gridItem(item, i) {
    return (
      <Grid.Item>
        <div
          className={`c-collection__item ${
            item.image || item.person?.headshot
              ? `c-collection__item--with-bg ${disableLazy ? '' : 'lazyload'}`
              : ''
          } ${
            ((i + 1) & 1
              ? 'c-collection__item--odd '
              : 'c-collection__item--even ') + collectionItemClass(i)
          } c-collection__item--alt`}
          data-bgset={disableLazy ? null : gridItemBgImage(item)}
          style={
            disableLazy
              ? { backgroundImage: `url(${gridItemBgImage(item)})` }
              : null
          }
        >
          {(item.title || item.person?.name || item.body || item.linkText) && (
            <div className={`c-collection__content`}>
              {(item.title || item.person?.name) && (
                <h3 className="c-collection__title">
                  {item.linkUrl || item.person ? (
                    <a
                      href={
                        item.linkUrl
                          ? item.linkUrl
                          : getPersonPath(item.person.slug)
                      }
                      target={item.linkOpensInNewWindow ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      {item.person?.name || item.title}
                    </a>
                  ) : (
                    `${
                      item.title
                        ? item.title
                        : item.person.name
                        ? item.person.name
                        : ''
                    }`
                  )}
                </h3>
              )}
              {item.body && (
                <DangerousHTML>{item.body.html.text}</DangerousHTML>
              )}
              {item.linkUrl && gridLayout !== 'Alternating Nested' && (
                <div className="c-collection__ctas">
                  <div class="c-collection__cta-text"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </Grid.Item>
    )
  }

  return (
    <React.Fragment>
      {internal?.type === 'ContentfulLandingPageSectionDropdowns' ? (
        <section className="o-section--inner l-wrap l-container--xl">
          {dropdownsHeadline ? (
            <h2 className="u-text-align--center u-padding--double--bottom">
              {dropdownsHeadline}
            </h2>
          ) : null}

          {featureTables?.map((featureTable) => (
            <AccordionTable data={featureTable} />
          ))}
        </section>
      ) : internal?.type === 'ContentfulLandingPageSectionAccordion' ? (
        <Section
          className={`c-agenda ${gradientClass()} ${curveTopClass()} ${curveBottomClass()} ${paddingClass()} ${
            customBackground ? 'has-background-image' : ''
          }`}
          style={
            customBackgroundPosition
              ? { backgroundPosition: customBackgroundPosition }
              : null
          }
          databgset={customBackground?.file.url}
          id={`${sectionName(name)}`}
        >
          <Section.Header>
            <Block title={headline ? headline : ''} />
            {description && (
              <div className="c-block__description u-spacing">
                {description}
              </div>
            )}
          </Section.Header>
          <Section.Inner l>
            <div className="c-agenda__content u-spacing">
              {accordionItems?.map((agenda, index) => {
                return (
                  <div
                    className={
                      'c-agenda__item ' +
                      (agenda.hasConcurrentSession
                        ? 'c-agenda__item--concurrent'
                        : '')
                    }
                  >
                    {agenda.sessionDescription && !showExpanded && (
                      <React.Fragment>
                        <input
                          type="checkbox"
                          id={'c-agenda-toggle--' + index}
                        />
                        <label
                          className="c-agenda-toggle"
                          htmlFor={'c-agenda-toggle--' + index}
                        >
                          View Session Description
                        </label>
                      </React.Fragment>
                    )}

                    <p className="c-agenda__item--time">{agenda.time}</p>

                    <div className="c-agenda__item-copy">
                      <h4 className="c-agenda__item--title">
                        {agenda.sessionTitle}
                      </h4>
                      <div
                        className={
                          showExpanded
                            ? 'c-agenda__item--desc-open'
                            : 'c-agenda__item--desc'
                        }
                      >
                        {agenda.speaker && (
                          <div className="c-agenda__item--speaker">
                            {agenda.speaker}{' '}
                          </div>
                        )}

                        {agenda.sessionDescription && (
                          <div className="c-agenda__item--text u-spacing--half">
                            <DangerousHTML>
                              {agenda.sessionDescription.html.text}
                            </DangerousHTML>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Section.Inner>
        </Section>
      ) : internal?.type === 'ContentfulLandingPageSectionRow' ? (
        rowLayout === 'Hero Section' ? (
          <Hero
            backgroundImage={customBackground?.file.url}
            center
            backgroundPosition={
              customBackgroundPosition ? customBackgroundPosition : null
            }
            className={`${gradientClass()} ${curveTopClass()} ${curveBottomClass()} c-hero--inline`}
            id={`${sectionName(name)}`}
          >
            <Hero.Description>
              {(description || body) && (
                <div className="u-spacing">
                  {pardotFormReference &&
                    (pardotFormReferencePlacement === 'Sidebar' ? (
                      <aside className="embedly-card">
                        <PardotForm
                          iframe={pardotFormReference.pardotFormLink}
                        />
                      </aside>
                    ) : (
                      ``
                    ))}
                  {headline && <h2 className="c-hero__title">{headline}</h2>}
                  <DangerousHTML>{body.html.text}</DangerousHTML>
                  {pardotFormReference &&
                    (pardotFormReferencePlacement === 'Below Body' ? (
                      <div className="l-container--xs">
                        <PardotForm
                          iframe={pardotFormReference.pardotFormLink}
                        />
                      </div>
                    ) : (
                      ``
                    ))}
                </div>
              )}
            </Hero.Description>
            {featuredImage && (
              <Hero.Image>
                {disableLazy ? (
                  <img
                    loading="lazy"
                    src={featuredImage?.file.url}
                    alt={featuredImage?.title}
                  />
                ) : (
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={featuredImage?.file.url}
                    alt={featuredImage?.title}
                  />
                )}
              </Hero.Image>
            )}
            <Hero.Buttons>
              {buttons &&
                buttons.map((button) => {
                  return (
                    <a
                      href={button.buttonLink}
                      target={button.newWindow ? `_blank` : `_self`}
                      className="o-anchor"
                    >
                      {button.buttonText ? button.buttonText : `Learn More`}
                    </a>
                  )
                })}
            </Hero.Buttons>
          </Hero>
        ) : (
          <SectionCta
            title={headline}
            centerCTA
            backgroundImage={customBackground?.file.url}
            backgroundPosition={
              customBackgroundPosition ? customBackgroundPosition : null
            }
            className={`${gradientClass()} ${curveTopClass()} ${curveBottomClass()}`}
            id={`${sectionName(name)}`}
          >
            {description && (
              <SectionCta.Description>
                <DangerousHTML>{description}</DangerousHTML>
              </SectionCta.Description>
            )}

            {featuredImage && (
              <SectionCta.ImageLarge>
                {disableLazy ? (
                  <img
                    loading="lazy"
                    src={featuredImage?.file.url}
                    alt={featuredImage?.title}
                  />
                ) : (
                  <img
                    loading="lazy"
                    className="lazyload"
                    data-src={featuredImage?.file.url}
                    alt={featuredImage?.title}
                  />
                )}
              </SectionCta.ImageLarge>
            )}
            {(body || pardotFormReference) && (
              <SectionCta.Body>
                {pardotFormReference &&
                  (pardotFormReferencePlacement === 'Sidebar' ? (
                    <aside className="embedly-card">
                      <PardotForm iframe={pardotFormReference.pardotFormLink} />
                    </aside>
                  ) : (
                    ``
                  ))}
                {body && <DangerousHTML>{body.html.text}</DangerousHTML>}
                {pardotFormReference &&
                  (pardotFormReferencePlacement === 'Below Body' ? (
                    <div className="l-container--xs">
                      <PardotForm iframe={pardotFormReference.pardotFormLink} />
                    </div>
                  ) : (
                    ``
                  ))}
              </SectionCta.Body>
            )}
            {featuredVideo && (
              <SectionCta.Video>
                <iframe
                  title="Video"
                  src={featuredVideo}
                  frameborder="0"
                  width="100%"
                  height="auto"
                />
              </SectionCta.Video>
            )}
            {buttons && (
              <SectionCta.Buttons>
                {buttons.map((button) => {
                  return (
                    <Button
                      url={button.buttonLink}
                      target={button.newWindow ? `_blank` : `_self`}
                    >
                      {button.buttonText ? button.buttonText : `Learn More`}
                    </Button>
                  )
                })}
              </SectionCta.Buttons>
            )}
          </SectionCta>
        )
      ) : contentBlockType === 'Logos' ? (
        <Section
          className={` ${gradientClass()} ${curveTopClass()} ${curveBottomClass()} ${paddingClass()} ${
            customBackground ? 'has-background-image' : ''
          }`}
          style={
            customBackgroundPosition
              ? { backgroundPosition: customBackgroundPosition }
              : null
          }
          databgset={customBackground?.file.url}
          id={`${sectionName(name)}`}
        >
          {(headline || body) && (
            <Section.Header>
              <Block title={headline}>
                {body && (
                  <div className="u-spacing">
                    <DangerousHTML>{body.html.text}</DangerousHTML>
                  </div>
                )}
              </Block>
            </Section.Header>
          )}
          <SectionLogoBanner>
            <h2>logos</h2>
            {content.map((item) => {
              return (
                <SectionLogoBanner.Item
                  src={item.image?.file.url}
                  gatsbyImageData={item.image?.gatsbyImageData}
                  alt={item.title}
                ></SectionLogoBanner.Item>
              )
            })}
          </SectionLogoBanner>
        </Section>
      ) : internal?.type === 'ContentfulBasicCard' ? (
        <Section className="o-section u-padding--sext--top u-padding--quint--bottom ">
          <Section.Inner m>
            <div className="o-testimonial--block landing-page-section">
              <div className="u-space--double--bottom">
                {descriptionText && (
                  <h3 className="o-testimonial--quote u-text--line-height--tight ">
                    {descriptionText?.description}
                  </h3>
                )}
              </div>
              <div className="o-testimonial--block--content">
                <img
                  src={image?.file?.url}
                  alt={image?.file?.url}
                  width="300"
                  height="60"
                  loading="lazy"
                />
                <div className="o-testimonial--block__author">
                  <p className="u-font--m u-font-weight--bold o-testimonial--block__author--tile">
                    {secondaryTitle}
                  </p>
                  <p className="u-font--m o-testimonial--block__author--tile">
                    {secondaryDescription}
                  </p>
                </div>
                {linkOpensInNewWindow ? (
                  <a
                    href={button?.buttonLink}
                    className="o-link u-color--primary o-testimonial--block__link"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                ) : (
                  <Link
                    to={button?.buttonLink}
                    className="o-link u-color--primary o-testimonial--block__link"
                  >
                    {button?.buttonText}
                  </Link>
                )}
              </div>
            </div>
          </Section.Inner>
        </Section>
      ) : (
        <Section
          className={`${gradientClass()} ${curveTopClass()} ${curveBottomClass()} ${paddingClass()} ${
            customBackground ? 'has-background-image' : ''
          }
          ${
            content && contentBlockType === 'Card 2 Column'
              ? 'c-section__2-col'
              : ''
          }`}
          style={
            customBackgroundPosition
              ? { backgroundPosition: customBackgroundPosition }
              : null
          }
          databgset={customBackground?.file.url}
          id={`${sectionName(name)}`}
        >
          {(headline || body) && (
            <Section.Header>
              <Block title={headline}>
                {body && (
                  <div className="u-spacing">
                    <DangerousHTML>{body.html.text}</DangerousHTML>
                  </div>
                )}
              </Block>
            </Section.Header>
          )}
          {content &&
            (contentBlockType === 'Card' ||
            contentBlockType === 'Card 2 Column' ||
            contentBlockType === 'Card title over image' ? (
              <Section.Inner
                className={
                  contentBlockType === 'Card title over image'
                    ? 'l-container--flex'
                    : ''
                }
                {...(contentBlockType === 'Card title over image'
                  ? { m: true }
                  : { xl: true })}
              >
                <Grid className={`${gridClass()}`}>
                  {content.map((item) => {
                    return (
                      <Grid.Item>
                        <Card
                          title={item.title}
                          link={item.linkUrl ? item.linkUrl : ``}
                          linkText={
                            item.linkText ? item.linkText : `Learn More`
                          }
                          className={
                            contentBlockType === 'Card title over image'
                              ? 'c-card__image--wrapper'
                              : ''
                          }
                          {...(contentBlockType === 'Card title over image' &&
                          item.image
                            ? {
                                backgroundHeader: `${item.image.file.url}?fit=fill&w=300&h=225`,
                              }
                            : {})}
                        >
                          <Card.Image>
                            {item.image && (
                              <img
                                loading="lazy"
                                src={`${item.image.file.url}`}
                                alt={item.image.title}
                              />
                            )}
                          </Card.Image>
                          {item.body && (
                            <div className="u-spacing">
                              <DangerousHTML>
                                {item.body.html.text}
                              </DangerousHTML>
                            </div>
                          )}
                        </Card>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Collection' ? (
              <Section.Inner xl>
                <Grid className={`${gridClass()} c-collection`}>
                  {content.map((item) => {
                    return (
                      <Grid.Item>
                        <div
                          className={`c-collection__item ${
                            item.image
                              ? `c-collection__item--with-bg u-overlay`
                              : ''
                          }`}
                          style={
                            item.image
                              ? {
                                  backgroundImage: `url(${item.image.file.url}?fit=fill&w=905&h=650)`,
                                }
                              : ''
                          }
                        >
                          {item.title && (
                            <h3 className="c-collection__title">
                              {item.title}
                            </h3>
                          )}
                          {item.body && (
                            <DangerousHTML>{item.body.html.text}</DangerousHTML>
                          )}
                          {item.linkUrl && (
                            <div className="c-collection__ctas">
                              <div class="c-collection__cta-text">
                                <a
                                  href={item.linkUrl ? item.linkUrl : ``}
                                  class={`o-cta-arrow ${
                                    item.image ? 'o-cta-arrow--white' : ''
                                  }`}
                                  target={
                                    item.linkOpensInNewWindow
                                      ? '_blank'
                                      : '_self'
                                  }
                                  rel="noreferrer"
                                >
                                  <strong>
                                    {item.linkText
                                      ? item.linkText
                                      : `Learn More`}
                                  </strong>
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Collection Alt' ? (
              <Section.Inner full>
                <Grid
                  className={`${gridClass()} c-collection c-collection--alt`}
                >
                  {content.map((item, index) => {
                    return gridLayout === 'Alternating Nested' &&
                      index &&
                      index + 1 <= Math.floor(content.length / 5) * 5 &&
                      ((index + 1) % 10 === 2 ||
                        ((index + 1) % 5 === 1 && (index + 1) % 10 !== 1)) ? (
                      // Every 10n + 2 or 10n + 6 item in loop.
                      <Grid.Item>
                        <Grid
                          className={`${gridClass()} c-collection c-collection--alt c-collection--alt-nested`}
                        >
                          {content[index] && gridItem(content[index], index)}
                          {content[index + 1] &&
                            gridItem(content[index + 1], index + 1)}
                          {content[index + 2] &&
                            gridItem(content[index + 2], index + 2)}
                          {content[index + 3] &&
                            gridItem(content[index + 3], index + 3)}
                        </Grid>
                      </Grid.Item>
                    ) : gridLayout === 'Alternating Nested' &&
                      index + 1 <= Math.floor(content.length / 5) * 5 &&
                      (index % 10 === 0 || index % 10 === 9) ? (
                      // Every 10th, 11th or first item in loop.
                      gridItem(item, index)
                    ) : gridLayout === 'Alternating Nested' &&
                      index + 1 > Math.floor(content.length / 5) * 5 &&
                      (index + 1) % 5 <= 3 ? (
                      // The 1st - 3rd remaining item in a loop not cleanly divisble by 5
                      Math.abs(
                        Math.floor(content.length / 5) * 5 - content.length
                      ) > 2 && (index + 1) % 5 === 1 ? (
                        // 1st remaining item with a remainder more than 2
                        <Grid.Item>
                          <Grid
                            className={`${gridClass()} c-collection c-collection--alt c-collection--alt-nested`}
                          >
                            {content[index] && gridItem(content[index], index)}
                            {content[index + 1] &&
                              gridItem(content[index + 1], index + 1)}
                          </Grid>
                        </Grid.Item>
                      ) : Math.abs(
                          Math.floor(content.length / 5) * 5 - content.length
                        ) > 2 && (index + 1) % 5 === 3 ? (
                        // 3rd remaining item with a remainder more than 2
                        <Grid.Item>
                          <Grid
                            className={`${gridClass()} c-collection c-collection--alt c-collection--alt-nested`}
                          >
                            {content[index] && gridItem(content[index], index)}
                            {content[index + 1] ? (
                              gridItem(content[index + 1], index + 1)
                            ) : (
                              <Grid.Item className="l-grid-item u-background-color--plasma"></Grid.Item>
                            )}
                          </Grid>
                        </Grid.Item>
                      ) : Math.abs(
                          Math.floor(content.length / 5) * 5 - content.length
                        ) <= 2 ? (
                        // Remainder of only 1 or 2 items.
                        gridItem(item, index)
                      ) : (
                        ''
                      )
                    ) : gridLayout !== 'Alternating Nested' ? (
                      gridItem(item, index)
                    ) : (
                      ``
                    )
                  })}
                  {gridLayout === 'Alternating Nested' &&
                    Math.abs(
                      Math.floor(content.length / 5) * 5 - content.length
                    ) === 1 && (
                      <Grid.Item className="l-grid-item u-background-color--plasma"></Grid.Item>
                    )}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Collection CTA' ? (
              <Section.Inner full>
                <Grid
                  className={`${gridClass()} c-collection c-collection--cta`}
                >
                  {content.map((item, index) => {
                    return (
                      <Grid.Item>
                        <div
                          className={`c-collection__item ${
                            item.image
                              ? `c-collection__item--with-bg lazyload`
                              : ''
                          } ${
                            ((index + 1) & 1
                              ? 'c-collection__item--odd '
                              : 'c-collection__item--even ') +
                            collectionItemClass(index)
                          } c-collection__item--cta`}
                          data-bgset={
                            item.image
                              ? `${item.image.file.url}?fit=fill&w=${
                                  gridLayout === '1 column' ? '1358' : '905'
                                }&h=${
                                  gridLayout === '1 column' ? '975' : '650'
                                }`
                              : ''
                          }
                        >
                          {(item.title || item.body || item.linkText) && (
                            <div
                              className={`c-collection__content ${
                                item.image
                                  ? 'c-collection__content--reverse'
                                  : ''
                              }`}
                            >
                              <div className="c-collection__desc">
                                {item.title && (
                                  <h3 className="c-collection__title o-heading--xl">
                                    {item.title}
                                  </h3>
                                )}
                                {item.body && (
                                  <DangerousHTML>
                                    {item.body.html.text}
                                  </DangerousHTML>
                                )}
                              </div>
                              {item.linkUrl && (
                                <div className="c-collection__ctas">
                                  <div class="c-collection__cta-text">
                                    <a
                                      href={item.linkUrl ? item.linkUrl : ``}
                                      class={`o-button`}
                                      target={
                                        item.linkOpensInNewWindow
                                          ? '_blank'
                                          : '_self'
                                      }
                                      rel="noreferrer"
                                    >
                                      <strong>
                                        {item.linkText
                                          ? item.linkText
                                          : `Learn More`}
                                      </strong>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Block' ? (
              <Section.Inner xl>
                <Grid className={`${gridClass()} u-padding--quad--bottom`}>
                  {content.map((item) => {
                    return (
                      <Grid.Item>
                        <Block
                          title={item.title}
                          linkWindow={item.linkOpensInNewWindow}
                          link={item.linkUrl ? item.linkUrl : ``}
                          linkText={
                            item.linkText ? item.linkText : `Learn More`
                          }
                        >
                          <Block.Image>
                            {item.image && (
                              <img
                                loading="lazy"
                                className="lazyload"
                                data-src={`${item.image.file.url}`}
                                alt={item.image.title}
                              />
                            )}
                          </Block.Image>
                          {item.body && (
                            <div className="u-spacing">
                              {item.logo && (
                                <img
                                  loading="lazy"
                                  className="lazyload"
                                  data-src={`${item.logo.file.url}`}
                                  alt={item.image.title}
                                />
                              )}
                              <DangerousHTML>
                                {item.body.html.text}
                              </DangerousHTML>
                            </div>
                          )}
                        </Block>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Simple Block' ? (
              <Section.Inner xl className="u-spacing--sections">
                <Grid
                  className={`${gridClass()} u-padding--quint--bottom l-grid--100-column-gap u-align--top`}
                >
                  {content.map((item) => {
                    return (
                      <Grid.Item
                        className={
                          item.mobileOrder
                            ? `l-grid-item u-order--${item.mobileOrder}`
                            : 'l-grid-item'
                        }
                      >
                        <Block
                          className="c-block__simple"
                          title={item.image ? null : item.title}
                          link={item.linkUrl ? item.linkUrl : ``}
                          linkText={
                            item.image
                              ? null
                              : item.linkText
                              ? item.linkText
                              : `Learn More`
                          }
                        >
                          <Block.Image>
                            {item.image && (
                              <img
                                loading="lazy"
                                className="lazyload"
                                data-src={`${item.image.file.url}`}
                                alt={item.image.title}
                              />
                            )}
                          </Block.Image>
                          {!item.image && item.body && (
                            <div className="u-spacing">
                              {item.logo && (
                                <img
                                  loading="lazy"
                                  className="lazyload"
                                  data-src={`${item.logo.file.url}`}
                                  alt={item.image.title}
                                />
                              )}
                              <DangerousHTML>
                                {item.body.html.text}
                              </DangerousHTML>
                            </div>
                          )}
                        </Block>
                      </Grid.Item>
                    )
                  })}
                </Grid>
              </Section.Inner>
            ) : contentBlockType === 'Block Row' ? (
              <Section.Inner l className="u-spacing--quad">
                {content.map((item) => {
                  return item.linkWraps ? (
                    <a href={item.linkUrl}>
                      <BlockRow title={item.title}>
                        <BlockRow.Image>
                          {item.image && (
                            <img
                              loading="lazy"
                              src={`${item.image.file.url}?fit=fill&w=300&h=225`}
                              alt={item.image.title}
                            />
                          )}
                        </BlockRow.Image>
                        {item.body && (
                          <div className="u-spacing">
                            <DangerousHTML>{item.body.html.text}</DangerousHTML>
                          </div>
                        )}
                      </BlockRow>
                    </a>
                  ) : (
                    <BlockRow
                      title={item.title}
                      link={item.linkUrl ? item.linkUrl : ``}
                      linkText={item.linkText ? item.linkText : `Learn More`}
                    >
                      <BlockRow.Image>
                        {item.image && (
                          <img
                            loading="lazy"
                            src={`${item.image.file.url}?fit=fill&w=300&h=225`}
                            alt={item.image.title}
                          />
                        )}
                      </BlockRow.Image>
                      {item.body && (
                        <div className="u-spacing">
                          <DangerousHTML>{item.body.html.text}</DangerousHTML>
                        </div>
                      )}
                    </BlockRow>
                  )
                })}
              </Section.Inner>
            ) : contentBlockType === 'Block 2 Column' ? (
              <Section.Inner xl className="u-spacing--sections">
                {content.map((item, index) => {
                  function twoColumnClass() {
                    if (index % 2) {
                      return `c-two-column--reverse`
                    } else {
                      return ``
                    }
                  }
                  return (
                    <SectionTwoColumn
                      bulb
                      title={item.title}
                      className={`${twoColumnClass()} ${paddingClass()}`}
                    >
                      <SectionTwoColumn.Logo />
                      {item.body && (
                        <SectionTwoColumn.Description>
                          <DangerousHTML>{item.body.html.text}</DangerousHTML>
                        </SectionTwoColumn.Description>
                      )}
                      <SectionTwoColumn.Buttons />
                      {item.image && (
                        <SectionTwoColumn.Image>
                          <img
                            loading="lazy"
                            src={`${item.image.file.url}?fit=fill&w=600&h=450`}
                            alt={item.image.title}
                          />
                        </SectionTwoColumn.Image>
                      )}
                    </SectionTwoColumn>
                  )
                })}
              </Section.Inner>
            ) : (
              <></>
            ))}
          {buttons && (
            <div className="u-center-block u-text-align--center u-space--double--top">
              {buttons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
            </div>
          )}
        </Section>
      )}
    </React.Fragment>
  )
}

LandingPageSection.propTypes = {
  internal: PropTypes.node,
  name: PropTypes.string,
  headline: PropTypes.string,
  dropdownsHeadline: PropTypes.string,
  description: PropTypes.string,
  featuredImage: PropTypes.node,
  featureTables: PropTypes.node,
  body: PropTypes.node,
  accordionItems: PropTypes.node,
  pardotFormReference: PropTypes.node,
  pardotFormReferencePlacement: PropTypes.string,
  buttons: PropTypes.node,
  backgroundColor: PropTypes.string,
  backgroundTopCurve: PropTypes.string,
  backgroundBottomCurve: PropTypes.string,
  content: PropTypes.node,
  contenBlockType: PropTypes.string,
  customBackground: PropTypes.node,
  gridLayout: PropTypes.string,
  rowLayout: PropTypes.string,
  customBackgroundPosition: PropTypes.string,
  disableLazy: PropTypes.bool,
}

export default LandingPageSection

export const query = graphql`
  fragment ContentfulLandingPageSectionAccordionFragment on ContentfulLandingPageSectionAccordion {
    internal {
      type
    }
    name
    headline
    description
    accordionItems {
      sessionTitle
      sessionDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      speaker
      time
      hasConcurrentSession
    }
    backgroundColor
    backgroundTopCurve
    backgroundBottomCurve
    customBackground {
      ...ContentfulImageFragment
    }
    customBackgroundPosition
  }
  fragment ContentfulLandingPageSectionDropdownsFragment on ContentfulLandingPageSectionDropdowns {
    internal {
      type
    }
    dropdownsHeadline
    featureTables {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
    }
  }
  fragment ContentfulLandingPageSectionRowFragment on ContentfulLandingPageSectionRow {
    internal {
      type
    }
    name
    headline
    description
    featuredImage {
      ...ContentfulImageFragment
    }
    featuredVideo
    body {
      html: childMarkdownRemark {
        text: html
      }
    }
    pardotFormReferencePlacement
    buttons {
      buttonText
      buttonLink
      newWindow
    }
    rowLayout
    backgroundColor
    backgroundTopCurve
    backgroundBottomCurve
    customBackground {
      ...ContentfulImageFragment
    }
    customBackgroundPosition
  }
  fragment ContentfulLandingPageSectionGridFragment on ContentfulLandingPageSectionGrid {
    internal {
      type
    }
    name
    headline
    body {
      html: childMarkdownRemark {
        text: html
      }
    }
    content {
      title
      image {
        ...ContentfulImageFragment
        gatsbyImageData(
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          width: 148
          height: 42
        )
      }
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      person {
        slug
        name
        headshot {
          ...ContentfulImageFragment
        }
        positionTitle
        company
        about {
          html: childMarkdownRemark {
            text: html
          }
        }
      }
      linkUrl
      linkText
      linkWraps
      linkOpensInNewWindow
      mobileOrder
    }
    buttons {
      buttonText
      buttonLink
      newWindow
    }
    contentBlockType
    gridLayout
    backgroundColor
    backgroundTopCurve
    backgroundBottomCurve
    customBackground {
      ...ContentfulImageFragment
    }
    customBackgroundPosition
  }
`
