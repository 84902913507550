import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Block from 'molecules/block'
import Layout from 'layouts'
import Grid from 'atoms/grid'
import Card from 'molecules/card'

// Sections
import Section from 'atoms/section'
import Slider from 'react-slick'
import GalleryBlock from 'molecules/block-gallery'

// Images
import logosSecurityPCI from '../images/logos-security-pci.png'
import logosSecuritySOC from '../images/logos-security-soc.png'
import logosSecurityHIPAA from '../images/logos-security-hipaa.png'
import logosSecurityISO from '../images/logos-security-iso17100.jpg'
import logoPinterest from '../images/logo-pinterest.svg'
import logoDialpad from '../images/logo-adroll.png'
import logoYext from '../images/company-logos/logo-yext.png'

import review3 from '../images/g2-review3.png'
import review4 from '../images/g2-review4.png'
import review5 from '../images/g2-review5.png'
import fastmoneyIcon from '../images/icons/icon_fast-money-light-ring.svg'
import graphIcon from '../images/icons/icon_graph-curve-light-ring.svg'
import handshakeIcon from '../images/icons/icon_handshake-light-ring.svg'
import iconQa from '../images/icons/icon_qa-ring-light.svg'
import iconBadgeG2 from '../images/icons/badge-g2.svg'
// import logoSmartlingPlus from '../images/smartling-plus-logo-color.svg'

import photoNao from '../images/Nao_1.png'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const galleryImages = [
  // review,
  // review2,
  review3,
  review4,
  review5,
]

class Plans2021Page extends React.Component {
  state = {
    activeIndex: 'left',
  }
  render() {
    return (
      <Layout>
        <div className="l-page--plans">
          <Metadata
            title="Smartling Pricing: Plans Tailored To How Much You Translate"
            description="Smartling’s pricing is based on the plan you need and the translations you create. Software subscriptions and translation services are tailored to each business and scale with the number of languages you translate and the number of integrations you use."
            keywords="Smartling price, Smartling plans, How much is Smartling, Translation software, Translation Management System, translation platform, localization platform"
            image="http://images.ctfassets.net/a8pkciehvm1g/7BF3nz4CAIFOUiiVm1t45M/2d1ca4b00142fd12a0c2c1e48590a998/meta-image.png"
          />

          <section
            class="o-section c-section__hero c-hero c-hero--center u-gradient--secondary-top"
            data-bgset=""
          >
            <div class="o-section--inner l-wrap l-container--xl">
              <div class="c-hero--left">
                <div class="c-hero__content u-spacing--double">
                  <h1 class="c-hero__title o-section-title--heavy">
                    Smartling Pricing
                  </h1>
                  <p class="c-hero__description">
                    Translate the world’s digital content with Smartling. Get
                    started, scale, or optimize your language translation
                    program today.
                  </p>
                  <div class="c-hero__buttons">
                    <a class="o-button" href="/meeting-request/" role="button">
                      Book a Meeting
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <article
            className="l-article u-spacing--sections"
            style={{ marginBottom: '0' }}
          >
            <Section className="c-tabs__section c-feature-plans">
              <Section.Inner l>
                <div className="u-spacing--double u-align--center">
                  <div className="c-customer-block c-customer-block--3up">
                    <div class="c-card c-card--cols u-spacing">
                      <div class="c-card__body u-spacing--half">
                        <p class="c-card__description">
                          <div className="c-feature-table u-text-align--left">
                            <div className="c-feature-table__title c-feature-table__title--core">
                              <h3>Core</h3>
                            </div>
                            <div className="c-feature-table__desc c-feature-table__desc--core">
                              <p>
                                A simple drag and drop portal to request and
                                receive translations.
                              </p>
                            </div>
                            <div className="c-feature-table__api-details c-feature-table__api-details--core">
                              <p>
                                <strong>Connect with Smartling:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Drag & Drop Files</li>
                              </ul>
                            </div>
                            <div className="c-feature-table__features c-feature-table__features--core">
                              <p>
                                <strong>Top Features:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Translation Memory</li>
                                <li>Glossary</li>
                              </ul>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>

                    <div class="c-card c-card--cols u-spacing">
                      <div class="c-card__body u-spacing--half">
                        <p class="c-card__description">
                          <div className="c-feature-table u-text-align--left">
                            <div className="c-feature-table__title c-feature-table__title--core">
                              <h3>Growth</h3>
                            </div>
                            <div className="c-feature-table__desc c-feature-table__desc--core">
                              <p>
                                Translate applications with an intuitive, robust
                                translation platform.
                              </p>
                            </div>
                            <div className="c-feature-table__api-details c-feature-table__api-details--growth">
                              <p>
                                <strong>Connect with Smartling:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Drag & Drop Files</li>
                                <li>API</li>
                                <li>Integrations</li>
                                <li>Global Delivery Network</li>
                              </ul>
                            </div>
                            <div className="c-feature-table__features c-feature-table__features--growth">
                              <p>
                                <strong>Top Features:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>CAT Tool</li>
                                <li>Visual Context</li>
                                <li>Strings View</li>
                                <li>Machine Translation Integrations</li>
                                <li>Reporting and Analytics</li>
                              </ul>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>

                    <div class="c-card c-card--cols u-spacing">
                      <div class="c-card__body u-spacing--half">
                        <p class="c-card__description">
                          <div className="c-feature-table u-text-align--left">
                            <div className="c-feature-table__title c-feature-table__title--core">
                              <h3>Enterprise</h3>
                            </div>
                            <div className="c-feature-table__desc c-feature-table__desc--core">
                              <p>
                                Centralize and manage translation across your
                                global content channels.
                              </p>
                            </div>
                            <div className="c-feature-table__api-details c-feature-table__api-details--enterpise">
                              <p>
                                <strong>Connect with Smartling:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Drag & Drop Files</li>
                                <li>API</li>
                                <li>Integrations</li>
                                <li>Global Delivery Network</li>
                              </ul>
                            </div>
                            <div className="c-feature-table__features c-feature-table__features--enterpise">
                              <p>
                                <strong>Top Features:</strong>
                              </p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Custom Workflows</li>
                                <li>Translation Job Automation</li>
                                <li>Single Sign-On</li>
                                <li>MT Automation</li>
                                <li>Advanced Translation Memory Management</li>
                                <li>Review Mode</li>
                                <li>Vendor Management</li>
                                <li>Advanced Reporting and Analytics</li>
                              </ul>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="o-section--inner u-padding--double--bottom">
                    <div className="u-text-align--center u-center-block">
                      <a href="/why-smartling" role="button" class="o-button">
                        Why Smartling?
                      </a>
                    </div>
                  </div>

                  <h1 class="o-section-title--heavy u-space--quad--top">
                    3 Ways to Buy Smartling
                  </h1>
                  <p className="o-heading--m u-font-weight--normal u-padding--bottom">
                    Only pay for what you translate with Smartling. Avoid
                    complicated contracts, capacity planning and price modeling.
                  </p>

                  <Grid threeUp className="u-padding--top c-integrate-platform">
                    <Grid.Item className="u-text-align--center l-grid__item">
                      <div
                        className="l-grid__item-image u-space--bottom"
                        style={{ maxWidth: '130px' }}
                      >
                        <img
                          loading="lazy"
                          src={fastmoneyIcon}
                          alt="Fast Money Icon"
                        />
                      </div>
                      <span className="o-heading--l u-color--primary">
                        Pay-Go
                      </span>
                      <br />
                      <p>
                        Usage-based pricing with no money down and no
                        commitments.
                      </p>
                    </Grid.Item>

                    <Grid.Item className="u-text-align--center l-grid__item">
                      <div
                        className="l-grid__item-image u-space--bottom"
                        style={{ maxWidth: '130px' }}
                      >
                        <img loading="lazy" src={graphIcon} alt="Graph Icon" />
                      </div>
                      <span className="o-heading--l u-color--primary">
                        Volume Discounts
                      </span>
                      <br />
                      <p>
                        Discounts applied as your usage grows, so you always get
                        a fair price.
                      </p>
                    </Grid.Item>

                    <Grid.Item className="u-text-align--center l-grid__item">
                      <div
                        className="l-grid__item-image u-space--bottom"
                        style={{ maxWidth: '130px' }}
                      >
                        <img
                          loading="lazy"
                          src={handshakeIcon}
                          alt="Handshake Icon"
                        />
                      </div>
                      <span className="o-heading--l u-color--primary">
                        Committed Use
                      </span>
                      <br />
                      <p>
                        Talk to our sales team for access to deep discounts when
                        you commit to usage.
                      </p>
                    </Grid.Item>
                  </Grid>
                  <a href="/meeting-request/" role="button" class="o-button">
                    Get Started
                  </a>
                </div>
              </Section.Inner>

              {/*Alt 2-col layout*/}
              {/*<Section className="c-tabs__section c-feature-plans">
              <Section.Inner l>
                <div className="u-spacing--double u-align--center">

                  <div className="c-customer-block c-customer-block--2up">
                    <div class="c-card c-card--cols u-spacing">
                      <div class="c-card__body u-spacing--half">
                        <p class="c-card__description">
                          <div className="c-feature-table u-text-align--left">
                            <div className="c-feature-table__title c-feature-table__title--core">
                              <h3>Growth</h3>
                            </div>
                            <div className="c-feature-table__desc c-feature-table__desc--core">
                              <p>Translate applications with an intuitive, robust translation platform.</p>
                            </div>
                            <div className="c-feature-table__api-details c-feature-table__api-details--growth">
                              <p><strong>Connect with Smartling:</strong></p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Drag & Drop Files</li>
                                <li>API</li>
                                <li>Integrations</li>
                                <li>Global Delivery Network</li>
                              </ul>
                            </div>
                            <div className="c-feature-table__features c-feature-table__features--growth">
                              <p><strong>Top Features:</strong></p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>CAT Tool</li>
                                <li>Visual Context</li>
                                <li>Strings View</li>
                                <li>Machine Translation Integrations</li>
                                <li>Reporting and Analytics</li>
                              </ul>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>

                    <div class="c-card c-card--cols u-spacing">
                      <div class="c-card__body u-spacing--half">
                        <p class="c-card__description">
                          <div className="c-feature-table u-text-align--left">
                            <div className="c-feature-table__title c-feature-table__title--core">
                              <h3>Enterprise</h3>
                            </div>
                            <div className="c-feature-table__desc c-feature-table__desc--core">
                              <p>Centralize and manage translation across your global content channels.</p>
                            </div>
                            <div className="c-feature-table__api-details c-feature-table__api-details--enterpise">
                              <p><strong>Connect with Smartling:</strong></p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Drag & Drop Files</li>
                                <li>API</li>
                                <li>Integrations</li>
                                <li>Global Delivery Network</li>
                              </ul>
                            </div>
                            <div className="c-feature-table__features c-feature-table__features--enterpise">
                              <p><strong>Top Features:</strong></p>
                              <ul className="o-list--checked o-list--checked--left">
                                <li>Custom Workflows</li>
                                <li>Translation Job Automation</li>
                                <li>Single Sign-On</li>
                                <li>MT Automation</li>
                                <li>Advanced Translation Memory Management</li>
                                <li>Review Mode</li>
                                <li>Vendor Management</li>
                                <li>Advanced Reporting and Analytics</li>
                              </ul>
                            </div>
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>

                <div className="o-section--inner u-padding--double--bottom">
                  <div className="u-text-align--center u-center-block">
                    <a href="/why-smartling" role="button" target="_blank" class="o-button">Why Smartling?</a>
                  </div>
                </div>

                <h1 class="o-section-title--heavy u-space--quad--top">3 Ways to Buy Smartling</h1>
                <p className="o-heading--m u-font-weight--normal u-padding--bottom">Only pay for what you translate with Smartling. Avoid complicated contracts, capacity planning and price modeling.
                </p>

                <Grid threeUp className="u-padding--top c-integrate-platform">
                  <Grid.Item className="u-text-align--center l-grid__item">
                    <div className="l-grid__item-image u-space--bottom" style={{ maxWidth: '130px' }}>
                      <img loading="lazy" src={fastmoneyIcon} alt="Fast Money Icon" />
                    </div>
                    <span className="o-heading--l u-color--primary">Pay-Go</span>
                    <br />
                    <p>Usage-based pricing with no money down and no commitments.</p>
                  </Grid.Item>
                  
                  <Grid.Item className="u-text-align--center l-grid__item">
                    <div className="l-grid__item-image u-space--bottom" style={{ maxWidth: '130px' }}>
                      <img loading="lazy" src={graphIcon} alt="Graph Icon" />
                    </div>
                    <span className="o-heading--l u-color--primary">Volume Discounts</span>
                    <br />
                    <p>Discounts applied as your usage grows, so you always get a fair price.</p>
                  </Grid.Item>

                  <Grid.Item className="u-text-align--center l-grid__item">
                    <div className="l-grid__item-image u-space--bottom" style={{ maxWidth: '130px' }}>
                      <img loading="lazy" src={handshakeIcon} alt="Handshake Icon" />
                    </div>
                    <span className="o-heading--l u-color--primary">Committed Use</span>
                      <br />
                      <p>Talk to our sales team for access to deep discounts when you commit to usage.</p>
                  </Grid.Item>
                </Grid>
                <a href="/meeting-request/" role="button" target="_blank" class="o-button">Get Started</a>
              </div>
            </Section.Inner>*/}
            </Section>

            <div class="u-gradient--secondary u-gradient--secondary-reverse u-padding--section">
              <section
                class="o-section c-section__cta c-cta  undefined undefined "
                data-bgset=""
              >
                <div class="o-section--inner l-wrap l-container--l">
                  <div class="c-cta__content u-spacing">
                    <h3 class="c-cta__title">
                      Save up to 50% on Software with Smartling+
                      {/*<img loading="lazy"
                      className="o-smartling-plus-logo"
                      src={logoSmartlingPlus}
                      alt="Smartling Plus logo"
                    />*/}
                    </h3>
                    <div class="c-cta__description">
                      <div class="u-spacing--double">
                        <div>
                          Smartling+ is the most efficient way to localize
                          content. It bundles our best in class translation
                          management system and software automation with
                          transparent, quality-oriented language services.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="c-cta__buttons">
                    <a
                      class="o-button"
                      href="/translation-services/translators/"
                      role="button"
                    >
                      Meet our Translators
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-center l-grid--100-column-gap  l-grid--reverse--sm">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={photoNao}
                      alt="Translator named Nao"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Words with Meaning
                    </h3>
                    <p>
                      Communicate directly with your translators, eliminate
                      unnecessary fees, and get to market faster with Smartling
                      Language Services.
                    </p>
                    <a href="/meeting-request/" class="o-link">
                      Talk to an Expert
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <Section
              centerCTA
              className="c-section__right c-integrate-platform"
            >
              <Section.Header>
                <Block title="The Smartling Advantage">
                  <Block.Image />
                  An unparalleled combination of software and language services
                  enables our customers to achieve turn-key localization
                  programs.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="c-integrate-platform__content">
                  <div className="l-grid l-grid--4up">
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="o-heading--xxl u-color--primary">50%</div>
                      <h4>Faster time to market</h4>
                    </div>
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="o-heading--xxl u-color--primary">
                        99.99%
                      </div>
                      <h4>Uptime Guarantee</h4>
                    </div>
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="o-heading--xxl u-color--primary">
                        100%
                      </div>
                      <h4>Translation Quality Guarantee</h4>
                    </div>
                    <div className="l-grid__item u-spacing--half u-text-align--center">
                      <div className="o-heading--xxl u-color--primary">30%</div>
                      <h4>Cost Savings</h4>
                    </div>
                  </div>
                </div>
                <div className="o-section--inner u-padding--double--top u-padding--double--bottom">
                  <div className="u-text-align--center u-center-block">
                    <a href="/why-smartling" role="button" class="o-button">
                      Why Smartling?
                    </a>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section>
              <Section.Inner l className="u-spacing--double">
                <div className="o-section--inner l-wrap l-container--l">
                  <div className="u-text-align--center u-center-block">
                    <h2 className="c-cta__title">
                      All-in-One Language Translation Solution
                    </h2>
                  </div>
                </div>
                <div className="o-section--inner l-wrap u-spacing--double">
                  <div className="c-block-row u-text-align--left">
                    {/* one */}
                    <div className="u-padding--double--left">
                      <h3>Software Services</h3>
                      <ul class="o-list--checked o-list--checked--left">
                        <li>Automated translation workflows</li>
                        <li>Cloud-based translation memory</li>
                        <li>Developer API</li>
                        <li>Dozens of content integrations</li>
                        <li>Global Delivery Network (Translation Proxy)</li>
                        <li>Integrated terminology and style guide</li>
                        <li>In-platform reporting and analytics</li>
                        <li>Single Sign-On</li>
                        <li>Uptime Guarantees</li>
                        <li>CAT Tool and Review Mode with Visual Context</li>
                      </ul>
                    </div>

                    {/* two */}
                    <div>
                      <h3>Language Services</h3>
                      <ul class="o-list--checked o-list--checked--left">
                        <li>Professional translation</li>
                        <li>Specialized translation</li>
                        <li>Transcreation</li>
                        <li>Neural Machine Translation</li>
                        <li>International SEO Management</li>
                        <li>Desktop Publishing</li>
                        <li>Transcription & Subtitle Translation</li>
                        <li>Translation Quality Assurance</li>
                        <li>Linguistic Asset Development</li>
                        <li>White-Glove Project Management</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <section className="o-section c-section__cta c-cta c-cta--center c-ready-to-start c-ready-to-start--nb">
              <div className="o-section--inner l-wrap l-container--l">
                <div
                  className="c-cta__content u-spacing u-text-align--center"
                  style={{ maxWidth: '1000px' }}
                >
                  <img
                    loading="lazy"
                    className="c-cta__header-img"
                    src={iconQa}
                    alt="QA bubbles Icon"
                    style={{ maxWidth: '130px' }}
                  />
                  <h2 className="c-cta__title">
                    Have Questions about Pricing?
                  </h2>
                </div>
                <div className="c-cta__buttons">
                  <a
                    className="o-button"
                    href="/meeting-request/"
                    role="button"
                  >
                    Talk to an expert
                  </a>
                </div>
              </div>
            </section>

            <section class="o-section " data-bgset="">
              <div class="u-spacing--quad">
                <div class="c-section__header" style={{ maxWidth: '1200px' }}>
                  <div class="c-block undefined u-spacing">
                    <div class="c-block__text u-spacing">
                      <div class="u-spacing">
                        <h2 class="c-cta__title">
                          Smartling leads the way in enterprise security
                        </h2>
                        <div class="c-block__description"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="o-section--inner l-wrap l-container--l">
                  <div class="l-grid c-security-logos c-security-logos--alt l-grid--4up">
                    <div class="l-grid-item">
                      <div class="c-block undefined u-spacing">
                        <div class="c-block__image">
                          <img
                            loading="lazy"
                            class="c-security-logo c-security-logo--pci"
                            src={logosSecurityPCI}
                            alt="PCI"
                          />
                        </div>
                        <div class="c-block__text u-spacing">
                          <div class="u-spacing">
                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                            <h3 class="c-block__title"></h3>
                            <div class="c-block__description">
                              <strong>PCI</strong>
                              <p>
                                Certifies presence of best security practices
                                for secure processing and transmission of credit
                                card data.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="l-grid-item">
                      <div class="c-block undefined u-spacing">
                        <div class="c-block__image">
                          <img
                            loading="lazy"
                            class="c-security-logo c-security-logo--soc"
                            src={logosSecuritySOC}
                            alt="SOC 2"
                          />
                        </div>
                        <div class="c-block__text u-spacing">
                          <div class="u-spacing">
                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                            <h3 class="c-block__title"></h3>
                            <div class="c-block__description">
                              <strong>SOC 2</strong>
                              <p>
                                Certifies a third-party vendor’s controls over
                                security, availability, processing integrity,
                                and confidentiality or privacy.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="l-grid-item">
                      <div class="c-block undefined u-spacing">
                        <div class="c-block__image">
                          <img
                            loading="lazy"
                            class="c-security-logo c-security-logo--hipaa"
                            src={logosSecurityHIPAA}
                            alt="HIPAA"
                          />
                        </div>
                        <div class="c-block__text u-spacing">
                          <div class="u-spacing">
                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                            <h3 class="c-block__title"></h3>
                            <div class="c-block__description">
                              <strong>HIPAA</strong>
                              <p>
                                Certifies an external vendor’s controls over
                                privacy and security of certain health
                                information covered by the law.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="l-grid-item">
                      <div class="c-block undefined u-spacing">
                        <div class="c-block__image">
                          <img
                            loading="lazy"
                            class="c-security-logo c-security-logo--iso"
                            src={logosSecurityISO}
                            alt="ISO 17100"
                          />
                        </div>
                        <div class="c-block__text u-spacing">
                          <div class="u-spacing">
                            {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                            <h3 class="c-block__title"></h3>
                            <div class="c-block__description">
                              <strong>ISO 17100</strong>
                              <p>
                                Certifies presence and capability of standard
                                processes and resources to deliver a quality
                                translation service that will meet the client’s
                                and other applicable specifications.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div>
              <div className="u-spacing--quad">
                <Section>
                  <Section.Header>
                    <Block title="Hundreds of Customers Love Smartling" />
                  </Section.Header>
                  <div className="c-logo-strip"></div>
                </Section>

                <Section>
                  <Section.Inner xl>
                    <div className="c-customer-block--4up l-grid l-grid--4up">
                      <Card>
                        <Card.Image>
                          <img
                            loading="lazy"
                            src={logoPinterest}
                            alt="Pinterest"
                            className="u-logo--small"
                          />
                        </Card.Image>
                        <p>
                          "Smartling’s Translation Management System has
                          eliminated a number of daily localization tasks, once
                          required of our engineers, freeing up their time to
                          work on other projects."
                        </p>
                        <div className="c-customer--details">
                          <div className="c-customer-image--silvia"></div>
                          <h5 className="u-color--primary u-space--top c-customer-image">
                            Silvia Ovideo-López, Localization and International
                            Operations 
                          </h5>
                        </div>
                      </Card>

                      <Card>
                        <Card.Image>
                          <img
                            loading="lazy"
                            src={logoDialpad}
                            alt="Adroll"
                            className="u-logo--small"
                          />
                        </Card.Image>
                        <p>
                          "We were able to launch our new AdRoll Group website
                          in seven languages within just two weeks!"
                        </p>
                        <div className="c-customer--details">
                          <div className="c-customer-image--trinh"></div>
                          <h5 className="u-color--primary u-space--top c-customer-image">
                            Gavin Flood, Senior Director of Marketing
                          </h5>
                        </div>
                      </Card>

                      <Card>
                        <Card.Image>
                          <img
                            loading="lazy"
                            src={logoYext}
                            alt="Yext"
                            className="u-logo--small"
                          />
                        </Card.Image>
                        <p>
                          “At the end of the day, Smartling’s technology is
                          incredibly reliable, and the customer experience is
                          fantastic.”
                        </p>
                        <div className="c-customer--details">
                          <div className="c-customer-image--birenz"></div>
                          <h5 className="u-color--primary u-space--top c-customer-image">
                            Jessica Birenz, Director of Planning and Project
                            Management
                          </h5>
                        </div>
                      </Card>

                      <Card className="c-card__cta">
                        <Card.Image></Card.Image>
                        <p>
                          Smartling puts its customers at the forefront of its
                          business strategy. Learn how our customers leverage
                          Smartling to realize translation and localization
                          efficiency gains
                        </p>
                        <div className="c-customer--details">
                          <div className="c-cta__button">
                            <a
                              className="o-button"
                              href="/resources/case-study/"
                              role="button"
                            >
                              See More
                            </a>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </Section.Inner>
                </Section>
              </div>
            </div>

            <section
              class="o-section c-section__cta c-cta c-cta--center u-spacing"
              data-bgset=""
            >
              <div class="c-block__image">
                <img
                  loading="lazy"
                  src={iconBadgeG2}
                  alt="G2 Leader Spring 2021"
                  style={{ height: '130px' }}
                />
              </div>
              <div class="o-section--inner l-wrap l-container--l">
                <div class="c-cta__content u-spacing">
                  <h2 class="c-cta__title">Rated #1 by users on G2 </h2>
                  <div class="c-cta__description">
                    But don't just take our word for it!
                    <Slider {...settings} className="u-padding--top">
                      {galleryImages.map((image) => (
                        <GalleryBlock
                          className="c-gallery-block--small c-gallery-block--review"
                          src={`${image}?fit=fill&w=460`}
                        />
                      ))}
                    </Slider>
                    <div class="c-cta__buttons">
                      <a
                        class="o-button"
                        href="https://www.g2.com/products/smartling/reviews?utf8=✓&amp;filters%5Bcomment_answer_values%5D=&amp;order=highest_rated"
                        role="button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read Reviews on G2
                      </a>
                      <a class="o-button  " href="/compare" role="button">
                        Smartling vs. Competition
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="c-product-intro-section u-gradient--senery-alt u-padding--section c-section__bg--signup">
              <div className="u-spacing--and-half l-container l-wrap">
                <div className="u-spacing--double u-max-width--66--dt">
                  <h3 className="c-block__title o-section-title o-section-title--heavy u-color--yellow--light">
                    The fastest way to publish translations.
                  </h3>
                  <p className="c-cta__description">
                    Personalize your content into any language with Smartling’s
                    full-service translation solution.
                  </p>
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a name="o-pardot-form" class="u-block">
                      <div className="o-pardot-form">
                        <iframe
                          className="lazyload u-margin--zero"
                          title="Pardot Form"
                          id="pardot-newsletter"
                          width="100%"
                          scrolling="no"
                          data-src="https://resource.smartling.com/l/703963/2021-03-03/z1kdf"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default Plans2021Page
