import React from 'react'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import { Link } from 'gatsby'

const ThreeColumnCta = ({
  title,
  leftImgSrc,
  leftAlt,
  leftLinkText,
  leftLink,
  middleImgSrc,
  middleAlt,
  middleLink,
  middleLinkText,
  rightImgSrc,
  rightAlt,
  rightLink,
  rightLinkText,
}) => {
  return (
    <Section className="u-padding--quad--top u-space--quad--bottom">
      <Section.Header className="l-container--xl">
        <h2 className="o-heading--l u-text-align--center">{title}</h2>
      </Section.Header>
      <Section.Inner xl>
        <Grid threeUp>
          <Grid.Item className="c-block">
            <img
              loading="lazy"
              className="l-grid__item-image"
              src={leftImgSrc}
              alt={leftAlt}
              aria-hidden="true"
              style={{ maxWidth: '300px' }}
            />
            <div className="c-block u-space--top">
              <Link to={leftLink} class="o-link u-space--top u-color--primary">
                {leftLinkText}
              </Link>
            </div>
          </Grid.Item>
          <Grid.Item className="c-block">
            <img
              loading="lazy"
              className="l-grid__item-image"
              src={middleImgSrc}
              alt={middleAlt}
              aria-hidden="true"
              style={{ maxWidth: '300px' }}
            />
            <div className="c-block u-space--top">
              <Link
                to={middleLink}
                class="o-link u-space--top u-color--primary"
              >
                {middleLinkText}
              </Link>
            </div>
          </Grid.Item>
          <Grid.Item className="c-block">
            <img
              loading="lazy"
              className="l-grid__item-image"
              src={rightImgSrc}
              alt={rightAlt}
              aria-hidden="true"
              style={{ maxWidth: '300px' }}
            />
            <div className="c-block u-space--top">
              <Link to={rightLink} class="o-link u-space--top u-color--primary">
                {rightLinkText}
              </Link>
            </div>
          </Grid.Item>
        </Grid>
      </Section.Inner>
    </Section>
  )
}

export default ThreeColumnCta
