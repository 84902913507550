import React from 'react'
import PropTypes from '../prop-types'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
import Alert from 'organisms/alert'
import '../scss/main.scss'

const locales = [
  { lang: 'en', url: 'https://www.smartling.com' }, // Generic English
  { lang: 'en-US', url: 'https://www.smartling.com' }, // US English
  { lang: 'es', url: 'https://es.smartling.com' }, // Generic Spanish
  { lang: 'es-ES', url: 'https://es.smartling.com' }, // Spanish for Spain
  { lang: 'fr', url: 'https://fr.smartling.com' }, // Generic French
  { lang: 'fr-FR', url: 'https://fr.smartling.com' }, // French for France
  { lang: 'de', url: 'https://de.smartling.com' }, // Generic German
  { lang: 'de-DE', url: 'https://de.smartling.com' }, // German for Germany
  { lang: 'da', url: 'https://dk.smartling.com' }, // Generic Danish
  { lang: 'da-DK', url: 'https://dk.smartling.com' }, // Danish for Denmark
  { lang: 'sv', url: 'https://se.smartling.com' }, // Generic Swedish
  { lang: 'sv-SE', url: 'https://se.smartling.com' }, // Swedish for Sweden
  { lang: 'nl', url: 'https://nl.smartling.com' }, // Generic Dutch
  { lang: 'nl-NL', url: 'https://nl.smartling.com' }, // Dutch for Netherlands
  { lang: 'it', url: 'https://it.smartling.com' }, // Generic Italian
  { lang: 'it-IT', url: 'https://it.smartling.com' }, // Italian for Italy
  { lang: 'pt', url: 'https://pt.smartling.com' }, // Generic Portuguese
  { lang: 'pt-PT', url: 'https://pt.smartling.com' }, // Portuguese for Portugal
  { lang: 'zh', url: 'https://cn.smartling.com' }, // Generic Chinese
  { lang: 'zh-CN', url: 'https://cn.smartling.com' }, // Chinese for China
  { lang: 'x-default', url: 'https://www.smartling.com' }, // Default for unspecified regions
]

const Layout = ({ children }) => {
  const canonicalUrl = typeof window !== 'undefined' ? window.location.href : ''
  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {locales.map(({ lang, url }) => (
          <link
            rel="alternate"
            hrefLang={lang}
            href={`${url}${currentPath}`}
            key={lang}
          />
        ))}

        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://www.smartling.com${currentPath}`}
        />
      </Helmet>
      <Alert />
      <main className="l-main">
        <Header />
        <div className="l-content">{children}</div>
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
