import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import BlockPerson from 'molecules/block-person'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'

class AuthorsPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    return (
      <Layout>
        <Metadata
          title="Authors - Smartling"
          description=""
          keywords=""
          image=""
        />
        <Hero center curveCenter gradientSecondary title="Smartling Authors">
          <Hero.Description>
            At Smartling, we love great content. And our authors are regularly
            launching new pieces on the localization and translation industry to
            keep you in the know, now.
          </Hero.Description>
        </Hero>
        <article className="l-article">
          {data.authors.totalCount > 0 ? (
            <Section>
              <Section.Inner l>
                {data.authors.edges.map(({ node }) => (
                  <BlockPerson {...node} />
                ))}
              </Section.Inner>
            </Section>
          ) : (
            <p className="u-text-align--center">
              There are no authors at this time.
            </p>
          )}
        </article>
      </Layout>
    )
  }
}

export default AuthorsPage

export const query = graphql`
  query AuthorsPageQuery {
    authors: allContentfulPerson(
      sort: { fields: [name], order: ASC }
      filter: {
        type: { eq: "Author" }
        slug: { ne: "test-person-do-not-delete" }
      }
    ) {
      ...ContentfulPersonFragment
    }
  }
`
