import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionSoftwareAutomation = () => (
  <SectionCta title="Agile translation for any platform.">
    <SectionCta.Description>
      Plug it in and plug away. Automate the translation process to convert
      content into any language.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/software">How it works</Button>
      <Button outline url="/products/automate/integrations">
        Integrate your stack
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionSoftwareAutomation
