import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import Grid from 'atoms/grid'

import ThreeColumnCta from 'organisms/ThreeColumnCta'

import MetadataImg from '../../images/smartling-metadata-default.jpeg'
import TranslationServicesBook from '../../images/fintech-book.png'
import PardotResponsive from '../../02-organisms/pardot-responsive'

import gdnIcon from '../../images/use-cases/marketing/gdn-icon.png'
import integrationsIcon from '../../images/use-cases/marketing/integration-icon.png'
import translatorIcon from '../../images/use-cases/marketing/translator-icon.png'

const heroImage =
  'https://images.ctfassets.net/a8pkciehvm1g/5PvGG71elFqBXhha0OOTcO/ce7b4ae721c856e6e347091c4ba9f6ad/3Q220920_-_Landing_Page_-_Government_-_1200x675_-_2.png?h=250'
const translationCost =
  'https://images.ctfassets.net/a8pkciehvm1g/LpQSqrsmmj6Y2DcYrpbtO/94238dc7365fa2d1771de54daf207dfd/WT200201_FEB_content-management.png?h=250'
const freeUpTime =
  'https://images.ctfassets.net/a8pkciehvm1g/1WE54sjF3FeRaoqJ2fAler/21deb97999a02815c14e31fef958d7b3/BP20210824_-_The_Ultimate_Guide_to_Collaborative_Translation_-_770x460_-_Website_Hero.jpg?h=250'
const machineTranslations =
  'https://images.ctfassets.net/a8pkciehvm1g/X2G70e2w43FwTD2F8hhpk/da63f9c47a3ed46c75805a45a42d4e50/BP20211115_-_Globalization_-_What_It_Is_and_What_Matters_for_Translation_-_750x422_-_1.jpg?h=250'

const government = () => {
  return (
    <Layout>
      <Metadata
        title="Title: Smartling for Government Agencies | Translate Website, Documents, Content"
        description="Smartling is how governments translate websites, documents and policies for their LEP limited English-proficiency populations."
        keywords="government agency localization, website translation, state and local governments, policy translation, LEP, language access"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="Meet language access policy requirements within your budget"
        >
          <Hero.Description>
            Smartling is how state, local, and federal governments meet the
            growing needs of their limited English-proficiency (LEP) population
            to communicate important information in their preferred language.
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              width="100%"
              src={heroImage}
              alt="Translation for Government Agencies"
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#bookademo">Book a Meeting</Button>
          </Hero.Buttons>
        </Hero>
        <Section className="l-wrap l-container--xl u-padding--bottom">
          {/* <Section.Header></Section.Header> */}
          <Section.Inner xl>
            <Block className="u-padding--bottom">
              <h3>
                Powering Top State, Local, and Education Government Agencies
              </h3>
            </Block>
          </Section.Inner>
        </Section>
        <Section l className="l-wrap u-padding--top">
          <Section.Header>
            <Block>
              <h2>
                It’s the easiest, most cost-effective way to provide complete
                language access.
              </h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--double--bottom">
            <p>
              How?
              <br /> With our professional linguists who are local experts and
              native speakers experienced in the government sector.
            </p>
            <p>
              With integrations ready to be connected to your existing tools and
              systems.
            </p>
            <p>When you combine all of those, you get:</p>

            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>Tens of thousands of dollars saved</li>
              <li>Hundreds of thousands of human hours saved</li>
              <li>New languages launched in as few as two days</li>
            </ul>
            <div className="u-align--center u-spacing">
              <a
                role="button"
                href="#bookademo"
                class="o-link u-color--primary"
              >
                Book a Meeting
              </a>
            </div>
          </Section.Inner>
        </Section>
        <Section>
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>
                  Improve translation cost and efficiency with Translation
                  Management Software
                </h3>

                <p>
                  You can reduce translation costs and increase ROI without
                  sacrificing quality.
                </p>
                <p>
                  Simplified translation processes, reduced repetitive work,
                  better consistency, and decreased time to market. This is how
                  Translation Management Software can be leveraged to improve
                  ROI.
                </p>

                <a
                  role="button"
                  href="/resources/101/four-ways-translation-management-software-reduces-costs-and-increases/"
                  class="o-link u-color--primary"
                >
                  Learn How Efficiency Improves ROI
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={translationCost}
                  alt="stylized drawings of content and computer screens"
                  className="u-max-width--70"
                  aria-hidden="true"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={freeUpTime}
                  alt="Two hands shaking"
                  className="u-max-width--70"
                  aria-hidden="true"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                {' '}
                <h3>Free Up Time with Managed Services</h3>
                <p>
                  Would you benefit from a team of experts implementing and
                  maintaining your strategy and integrations?
                </p>
                <p>
                  Extend your team at a fraction of the cost with our managed
                  services.
                </p>
                <a
                  role="button"
                  href="/professional-services/"
                  class="o-link u-color--primary"
                >
                  Show Me Smartling Managed Services
                </a>
              </Grid.Item>
            </Grid>
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>The Benefits of Machine Translation</h3>
                <p>
                  Machine translation is a priority for many global companies
                  today because it helps improve time to market and ROI.
                </p>
                <p>But where to start is the question.</p>
                <p>
                  Let’s talk about how machine translation can be used to
                  improve efficiency, quality, and cost.
                </p>
                <a
                  role="button"
                  href="/resources/101/state-of-machine-translation/"
                  class="o-link u-color--primary"
                >
                  Take Me to Machine Translation 101
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={machineTranslations}
                  alt="purple stylyzed draing of earth"
                  className="u-max-width--70"
                  aria-hidden="true"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  alt="Book with text on cover: The Complete Guide to Translation Services"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                {' '}
                <h3>The Complete Guide to Translation Services</h3>
                <p>
                  When language services and translation software work together,
                  you can improve your return on investment by saving money and
                  increasing output
                </p>
                <a
                  role="button"
                  href="/resources/101/the-complete-guide-to-translation-services/"
                  class="o-link u-color--primary"
                >
                  Learn More
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>

        <ThreeColumnCta
          title="Bring translation into your existing workflow"
          leftImgSrc={gdnIcon}
          leftAlt="Global Distribution Network Icon"
          leftLink="/software/global-delivery-network/"
          leftLinkText={
            <>
              Instant Website <br /> Localization
            </>
          }
          middleImgSrc={integrationsIcon}
          middleAlt="Two Puzzle Pieces"
          middleLink="/software/"
          middleLinkText={
            <>
              See Our Pre-Built <br /> Integrations
            </>
          }
          rightImgSrc={translatorIcon}
          rightAlt="Letter A and a Chinese character with a woman in the middle"
          rightLink="/translation-services/"
          rightLinkText={
            <>
              Professional <br />
              Translation Services
            </>
          }
        />
        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="bookademo">
                    BOOK A MEETING
                  </h2>
                  <iframe
                    src="https://resource.smartling.com/l/703963/2022-08-16/3bkllr"
                    id="pardotForm"
                    className="pardot-responsive"
                  ></iframe>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default government
