import React from 'react'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'

// Sections
import Hero from 'organisms/hero'
import SectionContact from 'components/sections/contact-us'

import Layout from 'layouts'

class ContactUsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata title="Contact Us" description="" keywords="" />
        <Hero center curveBottom gradientPrimary title="Contact Us">
          <Hero.Buttons>
            <Button url="/email-submissions">Contact Smartling</Button>
            <Button
              url="mailto:rfp@smartling.com "
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit an RFP
            </Button>
          </Hero.Buttons>
        </Hero>
        <div className="u-background-accent--left u-padding--quad--bottom">
          <SectionContact />
        </div>
        <div className="u-text-align--center">

              <p className="u-padding--quad--bottom">
              Got an RFP to send us? <a href="mailto:rfp@smartling.com" target="_blank" rel="noreferrer">Email our sales team</a>
              </p>
            </div>
      </Layout>
    )
  }
}

export default ContactUsPage
