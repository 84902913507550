import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import SectionCta from 'organisms/cta'
import Grid from 'atoms/grid'
import { Link } from 'gatsby'

import MetadataImg from '../../images/use-cases/software-apps/metadata-img.jpg'
import MarketingHero from '../../images/use-cases/software-apps/marketing-hero.png'
import Logos from '../../images/use-cases/software-apps/marketing-logos.jpg'
import TranslationServicesBook from '../../images/fintech-book.png'
import mobileGif from '../../images/use-cases/software-apps/mobile-app.gif'
import softwareLocal from '../../images/use-cases/software-apps/software-local.jpg'
import DesignApps from '../../images/use-cases/software-apps/design-apps.jpg'
import gdnIcon from '../../images/use-cases/software-apps/gdn-icon.png'
import intergrationsIcon from '../../images/use-cases/software-apps/integration-icon.png'
import translatorIcon from '../../images/use-cases/software-apps/translator-icon.png'
import CaseStudyLogoSrc from '../../images/use-cases/software-apps/Lyft.png'
import CaseStudyPersonSrc from '../../images/use-cases/software-apps/ZakH.png'

import PardotResponsive from '../../02-organisms/pardot-responsive'
import HubSpotForm from 'components/forms/HubSpotForm';

const LogosAlt =
  'Samsung logo, gofundme logo, IGH Hotel and Resorts logo, Pintrest logo, Mastercard logo'
const CaseStudyLogoAlt = 'Lyft Logo'
const CaseStudyPersonAlt = 'Zak Haitkin'

const FinTech = () => {
  return (
    <Layout>
      <Metadata
        title="App Localization | Smartling"
        description="Localize your translation platforms across all applications in any language with Smartling"
        keywords="software localization, application translation, app localization, software translation, app translation, global marketing"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="Develop Localized Software and App Experiences"
        >
          <Hero.Description>
            Smartling provides software translation services for applications,
            product documentation, and UX. It's the easiest and fastest way to
            deliver a localized user experience.
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              src={MarketingHero}
              alt="Stylized image"
              aria-hidden={true}
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#schedule-meeting">Book a Meeting</Button>
          </Hero.Buttons>
        </Hero>
        <Section className="l-wrap l-container--xl u-padding--bottom">
          {/* <Section.Header></Section.Header> */}
          <Section.Inner xl>
            <img loading="lazy" src={Logos} alt={LogosAlt} />
            <Block className="u-padding--bottom">
              <h3>Powering the World's Top Product Teams</h3>
            </Block>
          </Section.Inner>
        </Section>

        <Section l className="l-wrap u-padding--top">
          <Section.Header>
            <Block>
              <h2>The Importance of Software & App Localization </h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--quad--bottom">
            <p className="u-align--center">
              Software and app localization is a process that many companies
              recognize as vital to achieving success. It means adapting
              industry software to a specific region or culture with the hopes
              of enhancing the customer experience. Software localization
              enables direct communication with your target audience by giving
              them the opportunity to utilize your product in their preferred
              language. This builds stronger customer relations and loyalty
              while allowing your company to communicate globally
            </p>
          </Section.Inner>
        </Section>

        <Section l className="l-wrap u-padding--top">
          <Section.Header>
            <Block>
              <h2>Help more users experience your product in their language</h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--quad--bottom">
            <p className="u-align--center">
              How do our software and app localization services work?
            </p>
            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>
                Smartling’s translation management system  allows you to
                automate your software translation strategy while maintaining
                quality, brand voice, and style.
              </li>
              <li>
                Our professional linguists are local experts and native
                speakers.
              </li>
              <li>
                We have integrations ready to be connected to your existing code
                repository and developer tools
              </li>
            </ul>
            <p className="u-align--center">
              Benefits of Smartling’s software app localization services:
            </p>
            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>Tens of thousands of dollars saved.</li>
              <li>Hundreds of thousands of human hours saved.</li>
              <li>New languages launched in as few as two days.</li>
            </ul>
            <div className="u-align--center u-spacing">
              <a
                role="button"
                href="#schedule-meeting"
                class="o-link u-color--primary"
              >
                Book a Meeting
              </a>
            </div>
          </Section.Inner>
        </Section>

        <Section className="u-padding--quad--bottom">
          <Section.Header className="l-container--xl">
            <Block>
              <h3 className="u-font-weight--strong">
                Bring translation into your existing workflow.
              </h3>
            </Block>
          </Section.Header>
          <Section.Inner xl>
            <Grid threeUp>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={gdnIcon}
                  alt="Global Distribution Network Icon"
                  aria-hidden="true"
                  style={{ maxWidth: '300px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/software/global-delivery-network/"
                    class="o-link u-space--top u-color--primary"
                  >
                    Instant
                    <br />
                    Website Localization
                  </Link>
                </div>
              </Grid.Item>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={intergrationsIcon}
                  alt="Two Puzzle Pieces"
                  aria-hidden="true"
                  style={{ maxWidth: '300px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/software/"
                    class="o-link u-space--top u-color--primary"
                  >
                    See Our Pre-Built <br />
                    Integrations
                  </Link>
                </div>
              </Grid.Item>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={translatorIcon}
                  alt="Letter A and a Chinese character with a woman in the middle"
                  aria-hidden="true"
                  style={{ maxWidth: '300px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/translation-services/"
                    class="o-link u-space--top u-color--primary"
                  >
                    Professional
                    <br />
                    Translation Services
                  </Link>
                </div>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>

        <Section className="u-space--quad--bottom">
          <Section.Inner s className="u-space--quad--bottom">
            <Grid className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3 class="u-text-align--center">
                  How Pinterest Centralized Content Management and Localization
                </h3>
                <div style="padding:62.47% 0 0 0;position:relative;">
                  <iframe
                    src="https://player.vimeo.com/video/685974336?h=7938277f3f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    title="How Pinterest Centralized Content Management and Localization"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <br />
        <Section className="u-space--quad--top">
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>Design Your Product with Localization in Mind</h3>

                <p>
                  Consumers want services that provide information in their
                  preferred language(s). If you’re building an app for a global
                  audience, check out this guide to successful software
                  localization.
                </p>

                <a
                  role="button"
                  href="/resources/101/the-everyday-guide-to-software-localization/"
                  class="o-link u-color--primary"
                >
                  Take Me There
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={softwareLocal}
                  alt="Letter A being translated into a Chinese character"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={mobileGif}
                  alt="Envolope with at symbol"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                <h3>Support Translated Mobile Content via Apps</h3>
                <p>
                  There is a massive market for mobile users around the world.
                  The Apple App Store for example, is currently used and
                  available in over 150 countries globally and supports over 40
                  different languages. App internationalization is critical for
                  driving engagement and an important piece of your global
                  strategy. Learn how to ensure that anyone can enjoy your app,
                  no matter what language they speak.
                </p>
                <a
                  role="button"
                  href="/resources/101/how-to-localize-your-mobile-app/"
                  class="o-link u-color--primary"
                >
                  How to Localize Your Mobile App
                </a>
              </Grid.Item>
            </Grid>
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>Connect Smartling to Your Everyday Design Tools</h3>

                <p>
                  Ease the work of your brand’s UX and Product teams. Reduce
                  time to market. Enjoy cost-savings. Smartling’s integration is
                  the easiest way to develop multilingual apps.
                </p>

                <a
                  role="button"
                  href="/software/#design"
                  class="o-link u-color--primary"
                >
                  See Design Tool Integrations
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={DesignApps}
                  alt="Acronym SEO and map marker pin"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <SectionCta centerCTA>
              <SectionCta.Buttons>
                <Button url="#schedule-meeting">
                  Schedule a 30-Min Introduction Call
                </Button>
              </SectionCta.Buttons>
            </SectionCta>
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item>
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  alt="Book with text on cover: The Complete Guide to Translation Services"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                <h3>The Complete Guide to Translation Services</h3>

                <p>
                  When language services and translation management software
                  work together, they can significantly improve your software &
                  mobile app localization results.
                </p>

                <a
                  role="button"
                  href="/resources/101/the-complete-guide-to-translation-services/"
                  class="o-link u-color--primary"
                >
                  Take me there
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>

        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section class="o-section--inner l-wrap l-container--xl">
              <div id="sumup-case-study" className="u-space--bottom">
                <div class="c-quote-box">
                  <div class="u-spacing--double">
                    <div class="u-flex c-quote-top">
                      <img
                        loading="lazy"
                        src={CaseStudyPersonSrc}
                        alt={CaseStudyPersonAlt}
                        className="u-round"
                      />
                      <div class="c-quote-attribute">
                        <img
                          loading="lazy"
                          src={CaseStudyLogoSrc}
                          style={{ maxWidth: '120px' }}
                          alt={CaseStudyLogoAlt}
                        />
                        <div>
                          <strong>Zak Haitkin</strong>
                        </div>
                        <div>Localization Program Manager, Lyft</div>
                      </div>
                    </div>
                    <div class="o-section-title o-section-title--small c-quote o-section-title--medium">
                      We leveraged Smartling to make sure we had automation for
                      all of our content - no matter if it was web, app, help
                      center or developer materials - for all of our languages.
                    </div>
                    <a
                      href="/resources/101/how-lyft-scaled-content-for-8-languages/"
                      class="o-button o-button--wide o-button--no-shadow"
                    >
                      Watch Webinar
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="u-align--center u-spacing">
                <h2>Book a 20-Minute Demo</h2>
                <iframe
                  src="https://resource.smartling.com/l/703963/2022-01-06/2p6w73"
                  type="text/html"
                  frameborder="0"
                  title="pardot form"
                  allowtransparency="true"
                  scrolling="no"
                  style="margin: 15px auto; border: 0px; height: 170px; min-height: 100%; width: auto; min-width: 50%; overflow: hidden;"
                  id="pardotForm"
                ></iframe>
              </div> */}
            </section>

            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="schedule-meeting">
                    Book a 30-Minute Demo
                  </h2>
                  <div id="hubspot-form-container" className="hubspot-form-container">
                    <HubSpotForm portalId='40052555' formId='2c71823f-8982-4d51-80e8-db545ae03b30' />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FinTech
