import React from 'react'
import Button from 'atoms/button'
import Metadata from '../layouts/metadata'

// Sections
import Hero from 'organisms/hero'

import Layout from 'layouts'

class ManifestoPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Manifesto - Smartling"
          description=""
          keywords=""
          image=""
        />
        <Hero center curveBottom gradientPrimary title="Our north star">
          <Hero.Description>
            Smartling’s manifesto is a clear-eyed, honest declaration of
            purpose.
          </Hero.Description>
        </Hero>
        <article className="l-article u-spacing--sections">
          <div className="l-article__body l-wrap l-container--l">
            <div className="o-text u-spacing--double">
              <h1>Talk is cheap</h1>
              <p>Words have meaning.</p>
              <p>
                Words are verbs, nouns, and pronouns.
                <br />
                They are feminine and masculine.{' '}
              </p>
              <p>
                Words can hold truths that become self-evident.
                <br />
                Words are huddled masses yearning to breathe free.
                <br />
                Words give us the power to change, to overcome, to dream, to
                give hope to a man, and to make a giant leap for mankind.
                <br />
              </p>
              <p>
                You can condemn them, defend them, censor them, but you can
                never underestimate the power of words. So, choose them wisely.
              </p>
              <p>
                Translation is a purpose and a privilege. Translation is about
                the truth—making sense of place, culture, history, and the sum
                total of the human condition.
              </p>
              <p>
                We see language as a bridge, not a barrier.
                <br />
                We value context over content.
                <br />
                We aren’t always right, but we strive to get it right.
                <br />
                We believe translation is the search for meaning, but believe
                strongly that it is also a plea to be heard.
              </p>
              <p>
                We find words in all great things.
                <br />
                We see what’s truly possible in words.
              </p>
              <p>Talk is cheap. So, let us be rich in meaning.</p>

              <Button url="/meeting-request/">Book a demo</Button>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default ManifestoPage
