import PropTypes from 'prop-types'

const { shape, oneOfType, arrayOf, node, string } = PropTypes

const link = shape({
  url: string.isRequired,
  text: string,
  className: string,
})

const children = oneOfType([arrayOf(node), node])

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...PropTypes,
  link,
  children,
}
