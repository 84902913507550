import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'

import Layout from 'layouts/basic'

import MetaDataImage from '../images/meta-image.png'

const SFLoginTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || data.page.title}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
    />
    <div className="l-page--sf-login">
      <article
        class="l-article"
        style={{ backgroundImage: `url(${MetaDataImage})` }}
      >
        <header class="c-header">
          <div class="c-header--inner u-spacing">
            <div class="c-header__body o-text">
              {data.page.body && (
                <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
              )}
            </div>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </div>
        </header>
      </article>
    </div>
  </Layout>
)

export default SFLoginTemplate

export const pageQuery = graphql`
  query SFLoginTemplateQuery {
    page: contentfulBasicPage(path: { eq: "sf-login" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
