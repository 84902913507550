import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/optimizely-hero.jpg'
import imageRocket from '../../../images/icons/rocket-no-shadow.svg'
import imageJobStatus from '../../../images/job-status.gif'
import imageDemo1 from '../../../images/episerver-in-smartling.gif'
import imageDemo2 from '../../../images/episerver-context.gif'
import logoBenefit from '../../../images/company-logos/logo-benefit.svg'
import imageJeffShih from '../../../images/jeffshih_circle-small.png'

var imgStyle = {
  maxWidth: '500px',
}

class OptimizelyPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Optimizely Translation Integration | Smartling"
          description="Looking to translate your Optimizely web content management platform? See how Smartling seamlessly integrates with Optimizely for easy translation management."
          keywords=""
          image={heroImage}
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Localize Optimizely Experiences for Marketers and Developers"
          >
            <Hero.Description>
              Smartling's Optimizely translation integration enables marketers &
              developers to professionally translate and localize Optimizely
              experiences into any language.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Global Delivery Network"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <Section centerCTA>
              <Section.Header>
                <Block title="The easiest way to translate Optimizely experiences"></Block>
              </Section.Header>
              <Section.Inner m>
                <div className="u-text-align--center u-spacing--double">
                  <p>
                    Smartling’s Optimizely Connector automatically ingests your
                    content into the platform, allowing you to authorize this
                    content for translation from within Smartling. Translators
                    benefit from dynamic context to see how each string will fit
                    into your translated sites, and Translations are
                    automatically sent back to Optimizely once complete.
                  </p>
                  <p>
                    The integration simplifies the complexities of supporting
                    global websites — giving marketers and website developers a
                    scalable solution to developing incredible digital
                    experiences in any language, more quickly than ever before.
                  </p>
                </div>
              </Section.Inner>
            </Section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageRocket}
                      alt="Rocket"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Accelerate time to market
                    </h3>
                    <p>
                      Smartling’s Optimizely translation integration enables a
                      developer-light solution to creating and maintaining a
                      global website architecture, and simplifies the process of
                      continuously translating content into any language by
                      automatically retrieving new content and pushing
                      translated content back to Optimizely.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Control translation of different types of content
                    </h3>
                    <p>
                      Smartling’s Optimizely Connector ingests four types of
                      Optimizely content: Projects content, Blocks content, CMS
                      content and Commerce content. Each type of content can be
                      managed differently or the same - it’s fully customizable
                      so that you have granular control of your content and the
                      translation process.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <img
                    loading="lazy"
                    src={imageDemo1}
                    alt="Optimizely in Smartling Demo"
                  />
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageDemo2}
                      alt="Optimizely Context Demo"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Provide translators with dynamic context
                    </h3>
                    <p>
                      Smartling automatically ingests screenshots of your
                      digital experience so that Smartling’s professional
                      translators can make better decisions about how to
                      translate the content. The WYSIWIG interface ensures
                      translation accuracy and eliminates mistakes that are
                      commonplace with ecommerce translation, like brand
                      inconsistencies or string-length errors on CTAs.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Digital Experiences Gone Global">
                  As the world grows more interconnected, companies need to
                  launch quality translations that drive conversions without
                  scaling teams or effort.
                </Block>
                <div class="u-spacing u-text-align--center">
                  <a
                    class="o-button  u-space"
                    href="/meeting-request/"
                    role="button"
                  >
                    Book a meeting
                  </a>
                </div>
              </Section.Header>
              <Section.Inner l>
                <img
                  loading="lazy"
                  className="u-center-block"
                  src={imageJobStatus}
                  alt="demo showing job status demo"
                />
              </Section.Inner>
            </Section>

            <div className="u-gradient--secondary u-curve--top--left u-curve--bottom--center u-padding--section">
              <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
                <div class="c-section__header">
                  <div class="c-block u-spacing">
                    <div class="c-block__text u-spacing">
                      <div class="u-spacing">
                        <div class="c-block__description u-spacing--double">
                          <div class="u-text-align--left u-font-weight--bold">
                            “I would not have been able to launch our sites as
                            quickly as I did if Smartling’s Optimizely
                            integration was not in place. Smartling demonstrated
                            a level of expertise and knowledge in localization
                            and Optimizely content management that is
                            unmatched—and as a result, the integration is
                            incredibly dependable and enabled us to launch a new
                            website with confidence.”
                          </div>
                          <div class="u-text-align--left">
                            <div class="u-flex">
                              <img
                                loading="lazy"
                                src={imageJeffShih}
                                alt="Jeff Shih"
                                style={{
                                  width: '120px',
                                  height: '120px',
                                  marginRight: '20px',
                                }}
                              />
                              <div class="u-spacing--half">
                                <div>
                                  Jeff Shih
                                  <br />
                                  Senior Director of Global Web
                                </div>
                                <img
                                  loading="lazy"
                                  src={logoBenefit}
                                  alt="Benefit Cosmetics logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Top 5 Reasons Why Optimizely customers choose Smartling">
                  <Block.Image>
                    <Icons.IconStarCircleLight xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--left">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="1" class="u-font--l">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Onboard in 30 days or less.</b>
                            <br />
                            We make your success our priority. Our solution
                            engineers and developers have a robust understanding
                            of localization and Optimizely’s content management
                            framework. We deploy these skills to your account so
                            that you can deploy content and realize value
                            quickly.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="2" class="u-font--l">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Context matters.</b>
                            <br />
                            Smartling’s WYSIWYG translation editor statistically
                            improves translation quality outcomes for linguists
                            and reviewers. This is the most effective way to
                            manage your brand at scale without having to take
                            screenshots and coordinate efforts with translators
                            because it’s done automatically.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="3" class="u-font--l">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Data-driven localization.</b>
                            <br />
                            Every single activity related to translating
                            Optimizely content is logged and seamlessly surfaced
                            so you know exactly what’s going on within your
                            translation program. Customers regularly learn how
                            to drive down cost and increase efficiency on a
                            quarterly basis with these insights and guidance
                            from our customer success team.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span
                              role="img"
                              aria-label="4"
                              class="u-font--l"
                              and-half--
                            >
                              4️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>The best translators.</b>
                            <br />
                            Odds are you don’t know the language you’re trying
                            to support, which is why it is so critically
                            important to know the people translating your
                            content. Smartling assigned highly qualified
                            professionals who are vetted, certified and
                            centrally managed - but not kept secret. You will
                            know your translator. We will introduce you to them,
                            and you will come to appreciate the value of this
                            relationship because it is unmatched by our
                            competition.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="5" class="u-font--l">
                              5️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Managed Services.</b>
                            <br />
                            If your team is overwhelmed with development
                            activities and doesn’t have the bandwidth or
                            headcount for a full time localization or developer
                            resource, Smartling servers as an extension to your
                            team to offset any work related to day-to-day
                            management. Our team of support experts stands ready
                            to assist you. In the meantime, we have
                            comprehensive documentation on our Optimizely
                            integration and translation management platform.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default OptimizelyPage
