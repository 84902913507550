import React, { useState, useEffect, useRef } from 'react'
import PropTypes from '../prop-types'
import cx from 'classnames'
import { Tab } from '@headlessui/react'

const Tabs = ({ data, variant, dots, timeout }) => {
  const [activePanel, setActivePanel] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const tabListRef = useRef(null)

  const className = cx('o-tabs', {
    [`o-tabs--${variant}`]: variant,
  })

  // Automatically switch the active tab based on the timeout prop
  useEffect(() => {
    if (timeout) {
      const intervalId = setInterval(() => {
        setActivePanel((activePanel + 1) % data.length)
        setSelectedIndex((activePanel + 1) % data.length)
      }, timeout)

      return () => clearInterval(intervalId)
    }
  }, [activePanel, data.length, timeout, selectedIndex])

  // Set the active tab when a tab title is clicked
  function handleTabClick(index) {
    setActivePanel(index)
    setSelectedIndex(index)
  }

  // Scroll the tab list to show the active tab
  useEffect(() => {
    const tabList = tabListRef.current
    const activeTab = tabList.querySelector('.is-active')
    const activeTabRect = activeTab.getBoundingClientRect()
    const tabListRect = tabList.getBoundingClientRect()

    if (activeTabRect.right > tabListRect.right) {
      tabList.scroll({
        left: tabList.scrollLeft + activeTabRect.right - tabListRect.right,
        behavior: 'smooth',
      })
    } else if (activeTabRect.left < tabListRect.left) {
      tabList.scroll({
        left: tabList.scrollLeft - (tabListRect.left - activeTabRect.left),
        behavior: 'smooth',
      })
    }
  }, [selectedIndex])

  return (
    <div className={className}>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        {/* Render the tab titles */}
        <Tab.List className="o-tabs--titles" ref={tabListRef}>
          {data.map((item, index) => (
            <Tab as={React.Fragment} key={index}>
              {() => (
                <button
                  className={
                    selectedIndex === index
                      ? 'is-active o-tabs--title'
                      : 'o-tabs--title'
                  }
                  onClick={() => handleTabClick(index)}
                  style={{ zIndex: 40 - index }}
                >
                  {item.tabTitle}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        {/* Render the tab content */}
        <div className="o-tabs--panels">
          {data.map((item, index) => (
            <Tab.Panel key={index} className="o-tabs--content">
              {item.highlightText && (
                <p className="o-tabs--content--highlight-text u-text-gradient--underline">
                  {item.highlightText}
                </p>
              )}
              {item.tabContent.image && (
                <img
                  loading="lazy"
                  src={item.tabContent.image}
                  alt={item.tabContent.title}
                />
              )}
              {item.tabContent.tabContent && (
                <p className="o-tabs--content--text">
                  {item.tabContent.tabContent}
                </p>
              )}
            </Tab.Panel>
          ))}
        </div>
      </Tab.Group>
      {/* Render the dots */}
      {dots && (
        <div className="o-tabs--dots">
          {data.map((item, index) => (
            <button
              key={index}
              id={`headless-tabs-dot-${index}`}
              className={
                activePanel === index ? 'o-tabs--dot is-active' : 'o-tabs--dot'
              }
              onClick={() => handleTabClick(index)}
              aria-visible={false}
            ></button>
          ))}
        </div>
      )}
    </div>
  )
}

Tabs.defaultProps = {
  variant: '',
  dots: true,
}

export default Tabs
