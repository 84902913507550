import React from 'react'
import PropTypes from '../prop-types'
import Helmet from 'react-helmet'
import Header from './header'
import Footer from './footer'
import '../scss/main.scss'

const locales = [
  { lang: 'en', url: 'https://www.smartling.com' },
  { lang: 'en-US', url: 'https://www.smartling.com' },
  { lang: 'es', url: 'https://es.smartling.com' },
  { lang: 'es-ES', url: 'https://es.smartling.com' },
  { lang: 'fr', url: 'https://fr.smartling.com' },
  { lang: 'fr-FR', url: 'https://fr.smartling.com' },
  { lang: 'de', url: 'https://de.smartling.com' },
  { lang: 'de-DE', url: 'https://de.smartling.com' },
  { lang: 'da', url: 'https://dk.smartling.com' },
  { lang: 'da-DK', url: 'https://dk.smartling.com' },
  { lang: 'sv', url: 'https://se.smartling.com' },
  { lang: 'sv-SE', url: 'https://se.smartling.com' },
  { lang: 'nl', url: 'https://nl.smartling.com' },
  { lang: 'nl-NL', url: 'https://nl.smartling.com' },
  { lang: 'it', url: 'https://it.smartling.com' },
  { lang: 'it-IT', url: 'https://it.smartling.com' },
  { lang: 'pt', url: 'https://pt.smartling.com' },
  { lang: 'pt-PT', url: 'https://pt.smartling.com' },
  { lang: 'zh', url: 'https://cn.smartling.com' },
  { lang: 'zh-CN', url: 'https://cn.smartling.com' },
  { lang: 'x-default', url: 'https://www.smartling.com' },
]

const Layout = ({ children }) => {
  const canonicalUrl = typeof window !== 'undefined' ? window.location.href : ''
  const currentPath =
    typeof window !== 'undefined' ? window.location.pathname : ''

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {locales.map(({ lang, url }) => (
          <link
            rel="alternate"
            hrefLang={lang}
            href={`${url}${currentPath}`}
            key={lang}
          />
        ))}

        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://www.smartling.com${currentPath}`}
        />
      </Helmet>

      <main className="l-main">
        <Header />
        <div className="l-content">{children}</div>
        <Footer />
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.func,
}

export default Layout
