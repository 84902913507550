import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Section from 'atoms/section'
import { DangerousHTML } from 'atoms/contentful/html'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

// Sections
import Hero from 'organisms/hero'
import Tabs from 'molecules/tabs'
import Layout from 'layouts'

class TranslatePage extends React.Component {
  render() {
    const { data } = this.props
    const slsPage = data.slsPage

    return (
      <Layout>
        <Metadata
          title={slsPage.metadataTitle}
          description={slsPage.metadataDescription}
          keywords={slsPage.metadataKeyword}
          image={slsPage.metaDataImage}
        />
        <div className="c-page-translate c-page-sls-page">
          <Hero
            className="u-padding--quad--bottom"
            curveLeftReverse
            gradientPrimary
            title={slsPage.title}
          >
            <Hero.Description>
              <span className="c-hero__cta-text o-section-title--medium">
                {slsPage.subHeading}
              </span>
            </Hero.Description>
            <Hero.Image>
              {slsPage.heroImage?.gatsbyImageData ? (
                <GatsbyImage
                  image={slsPage.heroImage.gatsbyImageData}
                  alt={slsPage.heroImage.title}
                  objectFit="contain"
                  objectPosition="center"
                />
              ) : (
                <img
                  loading="lazy"
                  src={slsPage.heroImage?.file?.url || ''}
                  alt={slsPage.heroImage?.title || ''}
                  width={610}
                  height={372}
                />
              )}
            </Hero.Image>
            {slsPage.heroButton && (
              <Hero.Buttons>
                {slsPage.heroButton.map((button) => {
                  return (
                    <Button url={button?.buttonLink}>
                      {button.buttonText}
                    </Button>
                  )
                })}
              </Hero.Buttons>
            )}
          </Hero>

          <article className="l-article ">
            <Section className="o-section-sls--split-tabs" centerCTA>
              <Section.Inner
                xl
                className="o-section--inner-home u-spacing--quad u-space--quad--top"
              >
                <div className="l-grid l-grid--2up l-grid--align-top">
                  <div className="l-grid-item">
                    <div class="u-spacing o-tabs--content-section">
                      <h2 className="o-block--title">{slsPage.tabs.title}</h2>
                      <DangerousHTML>
                        {slsPage.tabs.text.html.text}
                      </DangerousHTML>
                    </div>
                  </div>
                  <div className="l-grid-item o-tabs--sls-page">
                    <Tabs
                      variant="highlight"
                      dots
                      timeout={2000}
                      data={slsPage.tabs.tabItems}
                    />
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section
              id="translation-services"
              className="o-section u-section-plans--background-secondary u-padding--sext--top u-padding--quad--bottom"
            >
              <Section.Inner xl>
                {slsPage.features.showRowTitle && (
                  <div className="u-space--triple--bottom">
                    <h2 className="u-font--xl u-text--line-height--tight">
                      {slsPage.features.rowTitle}
                    </h2>
                  </div>
                )}
                <div className="cards-list--cards u-space--quad--bottom">
                  {slsPage.features.gridItems.map((card) => {
                    return (
                      <div key={card.id} className="c-card c-card--bare">
                        {card.headline && (
                          <p className="c-card--title u-font-weight--bold u-font--m u-padding--quarter--bottom">
                            {card.headline}
                          </p>
                        )}
                        {card.description.description && (
                          <p className="c-card--description u-text-align--left u-font--m">
                            {card.description.description}
                          </p>
                        )}
                        {card.button?.buttonLink && (
                          <div className="c-card--link-wrap u-space--top u-text-align--left">
                            <Link
                              to={card.button?.buttonLink}
                              target={
                                card.button.newWindow ? '_blank' : '_self'
                              }
                              rel="noopener noreferrer"
                              class="o-link u-color--primary-alt u-font--m"
                            >
                              {card.button.buttonText}
                            </Link>
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
                <div className="u-text-align--center">
                  {slsPage.features?.button.map((button) => {
                    return (
                      <Button
                        className="o-features-button"
                        url={button?.buttonLink}
                      >
                        {button.buttonText}
                      </Button>
                    )
                  })}
                </div>
              </Section.Inner>
            </Section>

            <Section
              className="o-section o-section__sls__cta-section u-padding--sext--top u-padding--sext--bottom"
              centerCTA
            >
              <Section.Inner xl>
                {slsPage.ctaCardSection.showRowTitle && (
                  <div className="u-padding--triple--bottom u-text-align--center">
                    <h2 className="u-font--xl u-text--line-height--tight">
                      {slsPage.ctaCardSection.rowTitle}
                    </h2>
                  </div>
                )}
                <div
                  className={`l-grid l-grid--${slsPage.ctaCardSection.numCols}up u-space--quad--bottom`}
                >
                  {slsPage.ctaCardSection.gridItems.map((card) => {
                    return (
                      <div key={card.id} class="l-grid-item">
                        <div class="c-card c-card--bare u-text-align--center">
                          <div class="c-card--bare-image u-text-align--center">
                            {card.image?.gatsbyImageData ? (
                              <GatsbyImage
                                image={card.image.gatsbyImageData}
                                alt={card.image.title}
                                objectFit="contain"
                                objectPosition="center"
                              />
                            ) : (
                              <img
                                loading="lazy"
                                src={card.image?.file?.url || ''}
                                alt={card.image?.title || ''}
                                width={294}
                                height={144}
                              />
                            )}
                          </div>
                          {card.headline && (
                            <p className="c-card--title u-font-weight--bold u-font--m u-padding--quarter--bottom">
                              {card.headline}
                            </p>
                          )}
                          {card.description.description && (
                            <p className="c-card--description u-font--m">
                              {card.description.description}
                            </p>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="u-text-align--center">
                  {slsPage.ctaCardSection?.button.map((button) => {
                    return (
                      <Button
                        className="o-features-button"
                        url={button?.buttonLink}
                      >
                        {button.buttonText}
                      </Button>
                    )
                  })}
                </div>
              </Section.Inner>
            </Section>

            <Section
              className="o-section u-padding--sext--top  u-padding--sext--bottom"
              gradientAccentAlt
            >
              <Section.Inner xl>
                {slsPage.trustedBySection.showRowTitle && (
                  <div className="u-padding--triple--bottom u-text-align--center">
                    <h2 className="u-font--xl u-text--line-height--tight">
                      {slsPage.trustedBySection.rowTitle}
                    </h2>
                  </div>
                )}
                <div class="l-grid l-grid--4up l-grid__sls__trusted-by">
                  {slsPage.trustedBySection.gridItems.map((card) => {
                    return (
                      <div key={card.id} class="l-grid-item">
                        <div class="c-card c-card--logo u-spacing">
                          <div class="c-block__image u-text-align--center">
                            {card.image?.gatsbyImageData ? (
                              <GatsbyImage
                                image={card.image.gatsbyImageData}
                                alt={card.image.title}
                                objectFit="contain"
                                objectPosition="center"
                              />
                            ) : (
                              <img
                                loading="lazy"
                                src={card.image?.file?.url || ''}
                                alt={card.image?.title || ''}
                                width={294}
                                height={144}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </Section.Inner>
            </Section>

            <Section className="o-section u-padding--sext--top u-padding--quint--bottom ">
              <Section.Inner m>
                <div className="o-testimonial--block">
                  <div className="u-space--quad--bottom">
                    <h3 className="o-testimonial--quote u-text--line-height--tight ">
                      {slsPage.testimonial.description.description}
                    </h3>
                  </div>
                  <div className="o-testimonial--block--content">
                    {slsPage.testimonial.image.gatsbyImageData ? (
                      <GatsbyImage
                        image={slsPage.testimonial.image.gatsbyImageData}
                        alt={slsPage.testimonial.image.title}
                        objectFit="contain"
                      />
                    ) : (
                      <img
                        src={slsPage.testimonial.image.file.url}
                        alt={slsPage.testimonial.image.file.title}
                        width="209"
                        height="48"
                        loading="lazy"
                      />
                    )}
                    <div className="o-testimonial--block__author">
                      <p className="u-font--m u-font-weight--bold o-testimonial--block__author--tile">
                        {slsPage.testimonial.secondaryTitle}
                      </p>
                      <p className="u-font--m o-testimonial--block__author--tile">
                        {slsPage.testimonial.secondaryDescription}
                      </p>
                    </div>
                    <Link
                      to={slsPage.testimonial?.button.buttonLink}
                      class="o-link u-color--primary o-testimonial--block__link"
                    >
                      {slsPage.testimonial?.button.buttonText}
                    </Link>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section
              className="u-space--quint--top u-padding--sext--top u-padding--sext--bottom"
              centerCTA
              style={{ backgroundColor: '#F1ECFC' }}
            >
              <Section.Inner xl>
                <div className="l-grid l-grid--40-60 l-grid--align-top">
                  <div className="l-grid-item">
                    <div className="c-sls__callout--content">
                      <h2 className="o-block--title">
                        {slsPage.calloutSection.title}
                      </h2>
                      <div className="c-sls__callout--text">
                        <DangerousHTML>
                          {slsPage.calloutSection.body.html.text}
                        </DangerousHTML>
                      </div>
                    </div>
                  </div>
                  <div className="l-grid-item u-padding--top ">
                    {slsPage.calloutSection.image.gatsbyImageData ? (
                      <GatsbyImage
                        image={slsPage.calloutSection.image.gatsbyImageData}
                        alt={slsPage.calloutSection.image.title}
                        objectFit="contain"
                      />
                    ) : (
                      <img
                        src={slsPage.calloutSection.image.file.url}
                        alt={slsPage.calloutSection.image.file.title}
                        width="209"
                        height="48"
                        loading="lazy"
                      />
                    )}
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section
              className="o-section--resources u-padding--sext--top"
              centerCTA
            >
              <Section.Inner xl>
                {slsPage.resourcesSection.showRowTitle && (
                  <div className="u-space--triple--bottom u-text-align--center">
                    <h2 className="u-font--xl u-text--line-height--tight">
                      {slsPage.resourcesSection.rowTitle}
                    </h2>
                  </div>
                )}
                <div class="l-grid l-grid--3up l-grid__sls__resources">
                  {slsPage.resourcesSection.gridItems.map((card) => {
                    return (
                      <div key={card.id} class="l-grid-item">
                        <div class="c-card c-card--article u-spacing">
                          <div class="c-block__image">
                            {card.image?.gatsbyImageData ? (
                              <GatsbyImage
                                image={card.image.gatsbyImageData}
                                alt={card.image.title}
                                objectFit="cover"
                                objectPosition="center"
                              />
                            ) : (
                              <img
                                loading="lazy"
                                src={card.image?.file?.url || ''}
                                alt={card.image?.title || ''}
                                width={400}
                                height={225}
                              />
                            )}
                          </div>
                          {card.headline && (
                            <h3 className="u-font-weight--bold u-font--l-alt u-padding--quarter--bottom c-card--article__title">
                              {card.headline}
                            </h3>
                          )}
                          {card.description.description && (
                            <p className="c-card--description u-text-align--left u-font--m">
                              {card.description.description}
                            </p>
                          )}
                          {card.button.buttonText && (
                            <Link
                              to={card.button?.buttonLink}
                              target={
                                card.button.newWindow ? '_blank' : '_self'
                              }
                              rel="noopener noreferrer"
                              class="o-link u-color--primary-alt"
                            >
                              {card.button.buttonText}
                            </Link>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
                {slsPage.resourcesSection?.button.map((button) => {
                  return (
                    <div className="u-text-align--center u-space--quad--top">
                      <Button url={button?.buttonLink}>
                        {button.buttonText}
                      </Button>
                    </div>
                  )
                })}
              </Section.Inner>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default TranslatePage

export const query = graphql`
  query TranslationServicesPageQuery {
    metaDataImage: allContentfulAsset(
      filter: { title: { eq: "Metadata Image Default" } }
    ) {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
    slsPage: contentfulTranslationServicesPage {
      metadataTitle
      metadataDescription
      metadataKeyword
      title
      subHeading
      heroButton {
        buttonText
        buttonLink
      }
      heroImage {
        gatsbyImageData(
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          quality: 100
          width: 556
          height: 415
        )
        title
        file {
          url
        }
      }
      tabs {
        title
        text {
          html: childMarkdownRemark {
            text: html
          }
        }
        tabItems {
          tabTitle
          highlightText
          tabContent {
            tabContent
          }
        }
      }
      features {
        rowTitle
        numCols
        showRowTitle
        button {
          buttonLink
          buttonText
        }
        gridItems {
          id
          headline
          description {
            description
          }
          button {
            buttonLink
            buttonText
          }
        }
      }
      ctaCardSection {
        rowTitle
        showRowTitle
        numCols
        button {
          buttonLink
          buttonText
        }
        gridItems {
          id
          headline
          image {
            gatsbyImageData(
              width: 223
              height: 160
              quality: 100
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
            file {
              url
            }
          }
          description {
            description
          }
          button {
            buttonLink
            buttonText
          }
        }
      }
      trustedBySection {
        rowTitle
        showRowTitle
        numCols
        gridItems {
          id
          image {
            gatsbyImageData(
              width: 294
              height: 144
              quality: 100
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
            file {
              url
            }
          }
        }
      }
      testimonial {
        headline
        description {
          description
        }
        image {
          title
          gatsbyImageData(
            width: 209
            height: 48
            quality: 100
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
          file {
            url
          }
        }
        button {
          buttonLink
          buttonText
          newWindow
        }
        secondaryTitle
        secondaryDescription
      }
      calloutSection {
        title
        body {
          html: childMarkdownRemark {
            text: html
          }
        }
        image {
          gatsbyImageData(
            width: 718
            height: 323
            quality: 100
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
      }
      resourcesSection {
        rowTitle
        showRowTitle
        numCols
        button {
          buttonLink
          buttonText
        }
        gridItems {
          id
          headline
          image {
            gatsbyImageData(
              width: 464
              height: 263
              quality: 100
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
            file {
              url
            }
          }
          description {
            description
          }
          button {
            buttonLink
            buttonText
          }
        }
      }
    }
  }
`
