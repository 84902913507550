import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import { Link } from 'gatsby'

import MetadataImg from '../../images/use-cases/customer-support/metadata-img.jpg'
import MarketingHero from '../../images/use-cases/customer-support/marketing-hero.png'
import Logos from '../../images/use-cases/customer-support/marketing-logos.jpg'
import TranslationServicesBook from '../../images/fintech-book.png'
import saleForce from '../../images/use-cases/customer-support/sale-force-logo.png'
import zendesk from '../../images/use-cases/customer-support/zendesk-logo.png'
import mindTouch from '../../images/use-cases/customer-support/mindtouch-logo.png'

import PardotResponsive from '../../02-organisms/pardot-responsive'
import HubSpotForm from 'components/forms/HubSpotForm';

const LogosAlt =
  'coursera logo, gofundme logo, Redit logo, Monese logo, oclc logo'

const FinTech = () => {
  return (
    <Layout>
      <Metadata
        title="Smartling for Customer Support | Translate Customer Support"
        description="Localize your customer support across every language"
        keywords="zendesk localization, zendesk translation, multilingual support, software translation, customer support integration"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="Meet Customers with Translated Support Content in Every Language"
        >
          <Hero.Description>
            Smartling’s translation management software connects to your
            everyday tools so you can translate all of your customer support
            materials into their languages instantly. It's the simplest way to
            deliver a localized support experience and improve customers'
            satisfaction.
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              src={MarketingHero}
              alt="Stylized image"
              aria-hidden={true}
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#schedule-meeting">BOOK A MEETING</Button>
          </Hero.Buttons>
        </Hero>
        <Section className="l-wrap l-container--xl u-padding--bottom">
          {/* <Section.Header></Section.Header> */}
          <Section.Inner xl>
            <img loading="lazy" src={Logos} alt={LogosAlt} />
            <Block className="u-padding--bottom">
              <h3>Powering the World's Top Brands</h3>
            </Block>
          </Section.Inner>
        </Section>
        <Section l className="l-wrap u-padding--top">
          <Section.Header>
            <Block>
              <h2>
                Improve Customer Retention and Satisfaction with Translated
                Support Content in Their Language
              </h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--double--bottom u-spacing">
            <p className="u-align--center">How?</p>
            <p className="u-align--center">
              With software that allows you to automate your translations while
              maintaining quality, brand voice, and style.
            </p>
            <p className="u-align--center">
              With our team of professional linguists who are local experts and
              native speakers.
            </p>
            <p className="u-align--center">
              With integrations ready to be connected to your existing customer
              support systems.
            </p>
            <p className="u-align--center">
              When you combine all of those, you get:
            </p>
            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>Tens of thousands of dollars saved</li>
              <li>Hundreds of thousands of human hours saved</li>
              <li>New languages launched instantly</li>
            </ul>
            <div className="u-align--center u-spacing">
              <p>
                Configure automation rules, establish workflow preferences,
                maintain style guides, and more.
              </p>

              <a
                role="button"
                href="#schedule-meeting"
                class="o-link u-color--primary"
              >
                Book a Meeting
              </a>
            </div>
            <Grid className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3 class="u-text-align--center">
                  How Monese Saved Thousands and Improved Speed
                </h3>
                <div style="padding:56.25% 0 0 0;position:relative;">
                  <iframe
                    src="https://player.vimeo.com/video/697539399?h=d4f124408c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    title="How Monese Saved Thousands &amp;amp; Improved Speed | Global Ready Conference 2022"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <Section className="u-padding--double--top">
          <Section.Header className="l-container--xl">
            <Block>
              <h3 className="u-font-weight--strong">
                Bring translation into your existing workflow.
              </h3>
            </Block>
          </Section.Header>
          <Section.Inner xl>
            <Grid threeUp>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={mindTouch}
                  alt="Letter A and a Chinese character with a woman in the middle"
                  aria-hidden="true"
                  style={{ maxWidth: '250px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/translation-services/translators/"
                    class="o-link u-space--top u-color--primary"
                  >
                    Smartling For <br /> Mindtouch
                  </Link>
                </div>
              </Grid.Item>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={saleForce}
                  alt="Global Distribution Network Icon"
                  aria-hidden="true"
                  style={{ maxWidth: '250px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/software/global-delivery-network/"
                    class="o-link u-space--top u-color--primary"
                  >
                    Smartling For <br /> Salesforce Service Cloud
                  </Link>
                </div>
              </Grid.Item>
              <Grid.Item className="c-block">
                <img
                  loading="lazy"
                  className="l-grid__item-image"
                  src={zendesk}
                  alt="Two Puzzle Pieces"
                  aria-hidden="true"
                  style={{ maxWidth: '250px' }}
                />
                <div className="c-block u-space--top">
                  <Link
                    to="/software/"
                    class="o-link u-space--top u-color--primary"
                  >
                    Smartling For <br />
                    Zendesk
                  </Link>
                </div>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <Section>
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item>
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  alt="Book with text on cover: The Complete Guide to Translation Services"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                <h3>The Complete Guide to Translation Services</h3>

                <p>
                  When language services and translation software work together,
                  they can significantly improve your localization results –
                  both online or via mobile application.
                </p>

                <a
                  role="button"
                  href="/resources/101/the-complete-guide-to-translation-services/"
                  class="o-link u-color--primary"
                >
                  Learn More
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="schedule-meeting">
                    Book a 30-Minute Demo
                  </h2>
                  <div id="hubspot-form-container" className="hubspot-form-container">
                    <HubSpotForm portalId='40052555' formId='156d20af-92a7-4a0f-8eed-14ceb2e294d0' />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FinTech
