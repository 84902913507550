import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/company-logos/logo-figma.png'
import metaImage from '../../../images/company-logos/smartling-figma.png'
import imageBundle from '../../../images/ui/figma_bundle_prototypes.gif'
import imageStatus from '../../../images/ui/figma_real_time_status.gif'
import imageContext from '../../../images/ui/figma_visual_context.gif'
import imageMatters from '../../../images/ui/figma_why_figma_prototypes_matter.gif'

var imgStyle = {
  maxWidth: '500px',
}

class AdobeExperienceManagerPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Figma Integration with Translation Management | Smartling"
          description="Design, prototype, and translate products and digital environments in one place with the Figma and Smartling integration. Start shipping better designs with translations from start to finish today."
          keywords=""
          image={metaImage}
        />
        <div className="c-integration-detail">
          <Hero curveBottom gradientSecondary title="Design for Global">
            <Hero.Description>
              When you start translating and localizing your content as soon as
              the design prototypes are available, the entire process for
              shipping new product gets faster. Smartling’s localization plugin
              for Figma seamlessly connects your designers with Smartling’s
              translation service in just one click.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Global Delivery Network"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <Section>
              <Section.Header>
                <Block title="Why does translating Figma prototypes matter?">
                  <Block.Image />
                  You prototype user experiences in English because that’s the
                  primary language of your product or digital environment. You
                  check to see if text fits within buttons, how layouts change
                  with different devices and make design decisions based on your
                  preferred user experience. Why not do this for your global
                  user base, too?
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                  <div className="l-grid-item">
                    <div class="u-spacing">
                      <p class="u-spacing--double">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              ✅
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Eliminate costly changes to your builds when
                            translations don’t fit with designs
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              ✅
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Understand how the length of text for different
                            languages impacts global users during the
                            prototyping process
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              ✅
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Accelerate the translation process by submitting
                            content to linguists earlier in the process
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="l-grid-item">
                    <div class="c-product-intro-image">
                      <img
                        loading="lazy"
                        src={imageMatters}
                        alt="Prototypes UI"
                      />
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section>
              <Section.Header>
                <Block title="Work in Figma. Translate with Smartling.">
                  <Block.Image />
                  Without leaving Figma, you can submit prototypes for
                  professional, machine or pseudo translation to get the gist
                  for what the localized environment will look like before you
                  put your “pencil down” on a project.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div class="c-cta__video">
                  <iframe
                    title="video 1"
                    src="https://player.vimeo.com/video/420448431?title=0&amp;byline=0&amp;portrait=0"
                    frameborder="0"
                    width="560px"
                    height="315px"
                    allow="autoplay"
                  ></iframe>
                </div>
              </Section.Inner>
            </Section>

            <div className="u-gradient--primary u-curve--top--left u-curve--bottom--center u-padding--section">
              <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
                <Section.Header>
                  <div class="c-block undefined u-spacing">
                    <div class="c-block__text u-spacing">
                      <div class="u-spacing">
                        <h3 class="c-block__title u-text-align--left">
                          Figma Translation with Smartling is Pretty
                        </h3>
                        <div class="c-block__description u-text-align--left">
                          Our translation service makes translation pretty, just
                          like your designs.
                        </div>
                      </div>
                    </div>
                  </div>
                </Section.Header>
              </section>
            </div>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageBundle}
                      alt="Bundle Prototypes UI"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Bundle All Prototypes
                    </h3>
                    <p>
                      Identify every page, frame and string you want to
                      translate, and then submit it to Smartling with one click.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Visual Context
                    </h3>
                    <p>
                      Translators can see how your prototype looks as they
                      translate your content in our WYSIWYG editor.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageContext}
                      alt="Visual Context"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageStatus}
                      alt="Real Time Status UI"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Real Time Status
                    </h3>
                    <p>
                      The clock is ticking. Get real-time translation updates in
                      Figma without sending an email to ask, “Is it done yet?”
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section className="c-feature-plans">
              <Section.Header>
                <Block title="How Figma Translation Works with Smartling"></Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card c-card--cols c-card--full-height">
                  <div className=" u-text-align--left u-s">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Select pages, frames and strings to translate
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Figma exports JSON file and screenshots of every
                            page
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Translations take place in Smartling; you can check
                            status in Figma <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="checkmark">
                              🍀
                            </span>
                          </div>
                          <div class="u-padding--half--left">
                            Pull translations from Figma and look at your
                            results. Lucky you.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section gradientSecondary className="u-padding--section">
              <Section.Inner s className="u-spacing--double">
                <h2 className="o-heading--xxl u-text-align--center">
                  You’ve got questions. We’ve got answers.
                </h2>
                <ul className="o-list--rows">
                  <li>
                    <span className="o-question">
                      What happens if I resubmit the same file with new content?
                    </span>
                    <span className="o-answer">
                      <span>
                        Smartling will only translate the new content.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="o-question">
                      What type of translation do I use to get an idea for what
                      a localized design will look like?
                    </span>
                    <span className="o-answer">
                      <span>
                        Use pseudo translation. It’s instant, free and gives you
                        enough visibility into what the experience will look
                        like in other languages.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span className="o-question">
                      Our designers are already too busy. Why should I add this
                      to their plate?
                    </span>
                    <span className="o-answer">
                      Ask them if they’ve ever had to redesign the digital
                      experience because of a translation issue that presented
                      itself later in the process. If the answer is “yes,” then
                      you will have answered your question.
                    </span>
                  </li>
                  <li>
                    <span className="o-question">
                      Should I use human translation from the design stage?
                    </span>
                    <span className="o-answer">
                      <span>
                        The most agile localization programs start by
                        translating the content as designers are building the
                        end-user experience. Changes to content in the design
                        phase are typically minimal, so start using human
                        translation as soon as you believe the content is
                        finished or close to finished, even if the designs are
                        not. And remember, if the content does change, you will
                        be able to leverage translation memory to offset any
                        additional costs incurred with translating new content.
                      </span>
                    </span>
                  </li>
                </ul>
              </Section.Inner>
            </Section>

            <SectionCta
              centerCTA
              title="Ready to design with Figma and translate with Smartling?"
            >
              <SectionCta.Description>
                Join the ranks of Smartling’s world-class customers today.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="/meeting-request/">Book a Demo</Button>
              </SectionCta.Buttons>
            </SectionCta>
          </article>
        </div>
      </Layout>
    )
  }
}

export default AdobeExperienceManagerPage
