import React from 'react'
import PropTypes from '../prop-types'
import cx from 'classnames'
import { allByType } from '../util/react'

const Grid = ({ twoUp, threeUp, fourUp, className, children }) => {
  const classes = cx(className, {
    'l-grid--2up': twoUp,
    'l-grid--3up': threeUp,
    'l-grid--4up': fourUp,
  })

  return (
    <div className={`l-grid ${classes}`}>{allByType(children, Grid.Item)}</div>
  )
}

Grid.propTypes = {
  twoUp: PropTypes.bool,
  threeUp: PropTypes.bool,
  fourUp: PropTypes.bool,
  className: PropTypes.string,
}

Grid.Item = ({ children, className, classes }) => {
  if (className) {
    classes = className
  } else {
    classes = 'l-grid-item'
  }

  return <div className={classes}>{children}</div>
}

export default Grid
