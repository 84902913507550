import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

const PardotResponsive = (props) => {
  useEffect(() => {
    window.iFrameResize({ log: true, checkOrigin: false, bodyPadding: '0 0 20px 0', }, '.responsive-iframe')
  }, [])

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="/iframe-resizer-client-script.js"
          async
        ></script>
      </Helmet>
      <div className="o-pardot-form o-pardot-form--responsive u-block">
        <iframe className='responsive-iframe' title="Pardot Form" src={props.url} width={'100%'} />
      </div>
    </>
  )
}

export default PardotResponsive
