import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionManage = () => (
  <SectionCta
    title="All of your translation projects. All in one place."
    centerCTA
  >
    <SectionCta.Description>
      Full visibility. Full control. Manage tasks and assignments within a
      cloud-based platform.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button url="/products/translate/">Learn More</Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionManage
