import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import { DangerousHTML } from 'atoms/contentful/html'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

import SectionOnboarding from 'components/sections/onboarding'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionProfessionalServices from 'components/sections/professional-services'
import SectionTranslationInterface from 'components/sections/translation-interface'
import SectionTranslationServices from 'components/sections/translation-services'
import SectionSupport from 'components/sections/support'

import MetaDataImage from '../images/meta-image.png'
import Layout from 'layouts'

const IntegrationTemplate = ({ data, error }) => (
  <Layout>
    <div className="c-integration-detail">
      <Metadata
        title={data.page.metadataTitle || `${data.page.heroTitle} - Smartling`}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={MetaDataImage}
      />
      <Hero
        className="c-hero__integration"
        curveBottom
        gradientSecondary
        title={data.page.heroTitle}
      >
        <Hero.Description>
          {data.page.heroDescription && (
            <DangerousHTML>{data.page.heroDescription.html.text}</DangerousHTML>
          )}
        </Hero.Description>
        <Hero.Image>
          {data.page.heroImage && (
            <GatsbyImage
              src={data.page.heroImage.gatsbyImageData}
              alt={data.page.heroTitle}
            />
          )}
        </Hero.Image>
        {data.page.heroButtons && (
          <Hero.Buttons>
            {data.page.heroButtons.map((button) => {
              return (
                <Button
                  url={button.buttonLink}
                  target={button.newWindow ? `_blank` : `_self`}
                >
                  {button.buttonText ? button.buttonText : `Learn More`}
                </Button>
              )
            })}
          </Hero.Buttons>
        )}
      </Hero>
      <div className="u-spacing--sections">
        {data.page.videoId && (
          <SectionCta centerCTA>
            <SectionCta.Video>
              <iframe
                title="video 1"
                src={`https://player.vimeo.com/video/${data.page.videoId}?title=0&byline=0&portrait=0`}
                frameborder="0"
                width="560px"
                height="315px"
                allow="autoplay"
                encrypted-media
                allowfullscreen
              />
            </SectionCta.Video>
          </SectionCta>
        )}

        <article className="l-article u-spacing--sections">
          <div className="u-background-accent--right u-spacing--sections">
            <Section className="c-section__grid-blocks c-grid-blocks">
              <Section.Header>
                <Block title={data.page.featuresTitle}>
                  <Block.Image>
                    <Icons.HeaderWorld xxl />
                  </Block.Image>
                  {data.page.featuresDescription && (
                    <DangerousHTML>
                      {data.page.featuresDescription}
                    </DangerousHTML>
                  )}
                </Block>
              </Section.Header>
              <Section.Inner l>
                <Grid threeUp className="has-icon-bulbs">
                  {data.page.features &&
                    data.page.features.map((feature, i) => (
                      <Grid.Item>
                        <Block
                          title={feature.title}
                          link={feature.link}
                          linkText="Learn More"
                        >
                          <Block.Image>{getFeatureIcon(i)}</Block.Image>
                          {feature.shortDescription && (
                            <DangerousHTML>
                              {feature.shortDescription}
                            </DangerousHTML>
                          )}
                        </Block>
                      </Grid.Item>
                    ))}
                </Grid>
              </Section.Inner>
            </Section>
          </div>

          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionOnboarding />
          </div>
          <SectionProfessionalServices />
          <div className="u-gradient--primary u-curve--top u-curve--bottom u-padding--section">
            <SectionCustomerSuccess />
          </div>
          <SectionSupport />
          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionTranslationInterface />
          </div>
          <SectionTranslationServices />
        </article>
      </div>
    </div>
  </Layout>
)

export default IntegrationTemplate

export const pageQuery = graphql`
  query IntegrationTemplateQuery($slug: String!) {
    page: contentfulIntegration(slug: { eq: $slug }) {
      heroTitle
      heroDescription {
        html: childMarkdownRemark {
          text: html
        }
      }
      heroImage {
        ...ContentfulImageFragment
        gatsbyImageData(
          width: 500
          height: 280
          formats: [WEBP, AUTO]
          placeholder: BLURRED
        )
      }
      heroButtonUrl
      heroButtonText
      heroButtonNewWindow
      videoId
      featuresTitle
      featuresDescription
      features {
        title
        link
        shortDescription
      }
      ctaTitle
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      getStartedLink
      docsLink
      metadataTitle
      metadataDescription
      metadataKeywords
    }
  }
`

// HELPERS

function getFeatureIcon(num) {
  switch (num) {
    case 0:
      return <Icons.IconComputer />
    case 1:
      return <Icons.IconSmartphone />
    case 2:
      return <Icons.IconComputerApps />
    case 3:
      return <Icons.IconAtSymbol />
    case 4:
      return <Icons.IconChatQuote />
    case 5:
      return <Icons.IconChatQuote />

    default:
      return getFeatureIcon(num % 6)
  }
}
