import React from 'react'
import SectionTwoColumn from 'organisms/two-column'
import logoImage from '../../images/logo-collage.png'

const SectionSmartlingTranslationServices = () => (
  <div className="u-padding--section">
    <SectionTwoColumn bulb white>
      <SectionTwoColumn.Image>
        <img
          loading="lazy"
          src={logoImage}
          alt="Business Smartling works with"
        />
      </SectionTwoColumn.Image>
      <SectionTwoColumn.Logo />
      <SectionTwoColumn.Buttons />
      <SectionTwoColumn.Description>
        <h2>
          Smartling Language Services powers translation for the most innovative
          businesses.
        </h2>
      </SectionTwoColumn.Description>
    </SectionTwoColumn>
  </div>
)

export default SectionSmartlingTranslationServices
