import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Image from 'atoms/contentful/image'
import Grid from 'atoms/grid'
import Section from 'atoms/section'
import BlockPost from 'molecules/block-post'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'

import Layout from 'layouts'

const PersonTemplate = ({ data, error }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || `${data.page.name} - Smartling Person`}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={data.page.headshot?.file.url || MetaDataImage}
    />
    <Hero
      curveBottom
      gradientPrimary
      title={data.page.name}
      className="c-hero__person"
    >
      {data.page.headshot && (
        <Hero.Image>
          <Image {...data.page.headshot} />
        </Hero.Image>
      )}
      {data.page.positionTitle && (
        <Hero.Description>
          <span>{data.page.positionTitle}</span>
          {data.page.company && <span>{` at ${data.page.company}`}</span>}
        </Hero.Description>
      )}
    </Hero>
    <article className="l-article u-spacing--double">
      {data.page.about && (
        <div className="l-wrap l-container--s u-spacing--double">
          <div className="o-text o-text--secondary">
            <DangerousHTML>{data.page.about.html.text}</DangerousHTML>
          </div>
        </div>
      )}
      {data.posts.totalCount > 0 && (
        <Section gradientTertiary curveTop>
          <Section.Inner xl className="u-spacing--triple">
            <div className="l-grid__header">
              <h2>Latest Articles by {data.page.name.split(' ')[0]}</h2>
            </div>
            <Grid threeUp className="c-resources">
              {data.posts.edges.map(({ node }) => (
                <Grid.Item>
                  <BlockPost {...node} />
                </Grid.Item>
              ))}
            </Grid>
          </Section.Inner>
        </Section>
      )}
    </article>
  </Layout>
)

export default PersonTemplate

export const pageQuery = graphql`
  query PersonTemplateQuery($slug: String!) {
    page: contentfulPerson(slug: { eq: $slug }) {
      slug
      name
      headshot {
        ...ContentfulImageFragment
      }
      positionTitle
      company
      about {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
    }
    posts: allContentfulResource(
      limit: 6
      sort: { fields: [publishDate], order: DESC }
      filter: { author: { slug: { eq: $slug } } }
    ) {
      ...ContentfulResourceFragment
    }
  }
`
