import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Grid from 'atoms/grid'
import { getPodcastPath } from '../routes'
import Card from 'molecules/card'
// import PodcastSubscribe from 'atoms/podcastSubscribe'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'
import MetaDataImage from '../images/meta-image.png'

const moment = require('../../static/vendor/moment.min.js')


class PodcastCategoryTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    return (
      <Layout>
        <div className={'l-page--podcast'}>
          <Metadata
            title={`${data.page.metadataTitle || data.page.name} - Smartling`}
            description={data.page.metadataDescription}
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={MetaDataImage}
          />

          <Hero
            gradientPrimary
            title={data.page.name}
            disableLazy
            backgroundImage={MetaDataImage}
          >
            <Hero.Description>
              {data.page.useBodyInHero && data.page.body ? (
                <div className="u-spacing">
                  <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                </div>
              ) : (
                <p>{data.page.description}</p>
              )}
            </Hero.Description>
          </Hero>

          <div className="u-spacing--sections">
            {data.podcasts && (
              <Section className="c-podcasts">
                <Section.Inner l className="u-spacing--quad">
                  {/* {data.page.subscribeLinks?.length && (
                    <PodcastSubscribe
                      className="u-align--center"
                      longDesc={data.page.subscribeCta}
                      subscribeLinks={data.page.subscribeLinks}
                    />
                  )} */}
                  <div className="c-podcast-subscribe u-align--center">
                    <p className="u-color--primary">
                      <strong>Subscribe to the MTWWW Podcast</strong>
                    </p>
                    <a href="https://podcasts.apple.com/us/podcast/move-the-world-with-words/id1491817164">
                      Apple Podcast
                    </a>{' '}
                    /{' '}
                    <a href="https://open.spotify.com/show/3KaSPPSvjWHp5dgq4Ygax9">
                      Spotify
                    </a>
                  </div>
                  <Grid oneUp>
                    {data.podcasts.edges.map(({ node }) =>
                      node.name !== 'Test Podcast' ? (
                        <Grid.Item>
                          <Card
                            title={node.name}
                            className={
                              node.heroImage
                                ? 'c-card--podcast c-card--w-podcast-img'
                                : 'c-card--podcast'
                            }
                            link={getPodcastPath(node.slug, data.page.key)}
                            linkText="Listen"
                            kicker={
                              node.publishDate
                                ? moment(node.publishDate).format(
                                    'MMMM Do YYYY'
                                  )
                                : null
                            }
                          >
                            <Card.Image>
                              {node.heroImage && (
                                <div
                                  className="c-card__image__podcast"
                                  style={{
                                    backgroundImage: `url(${node.heroImage.file.url}?fit=fill&w=550&h=550)`,
                                  }}
                                ></div>
                              )}
                            </Card.Image>

                            {node.description && (
                              <DangerousHTML>
                                {node.description.html.text}
                              </DangerousHTML>
                            )}
                          </Card>
                        </Grid.Item>
                      ) : (
                        ''
                      )
                    )}
                  </Grid>
                </Section.Inner>
              </Section>
            )}

            {!data.page.useBodyInHero && data.page.body && (
              <article className="l-article u-spacing--sections">
                <div className="l-wrap l-container--l u-spacing--double">
                  <div className="l-article__body o-text o-text--secondary">
                    {data.page.body && (
                      <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                    )}
                  </div>
                </div>
              </article>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PodcastCategoryTemplate

export const query = graphql`
  query PodcastCategoryTemplateQuery($key: String!) {
    page: contentfulPodcastCategory(key: { eq: $key }) {
      name
      description
      key
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      subscribeLinks {
        buttonText
        buttonLink
        newWindow
      }
      subscribeCta
      useBodyInHero
    }
    podcasts: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { key: { eq: $key } } }
      limit: 9
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            ...ContentfulImageFragment
          }
        }
      }
    }
  }
`
