import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import Button from 'atoms/button'
import EventBlockPost from 'molecules/eventblock-post'
import { getEventPath } from '../routes'
import Hero from 'organisms/hero'
import Image from 'atoms/contentful/image'
import { DangerousHTML } from 'atoms/contentful/html'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Section from 'atoms/section'

import Layout from 'layouts'

const moment = require('../../static/vendor/moment.min.js')

const EventCategoryTemplate = ({ data, error }) => {
  return (
    <Layout>
      <Metadata
        title={data.page.metadataTitle || `${data.page.name} - Smartling`}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={MetaDataImage}
      />
      <Section
        gradientTertiary
        className="c-hero__header c-hero__resource-category u-padding--section"
      >
        <Section.Header>
          <Block title={data.page.name}>
            <Block.Image />
            {data.page.description && (
              <DangerousHTML>{data.page.description.html.text}</DangerousHTML>
            )}
          </Block>
        </Section.Header>
      </Section>
      {data.page.featuredEvent && (
        <Hero
          gradientPrimary
          gradientEventCategory
          curveTop
          centerCTA
          curveCenter
          className="c-event-hero c-hero__resource"
          title={data.page.featuredEvent?.name}
        >
          <Hero.Kicker>
            {data.page.featuredEvent?.event_category.name}
          </Hero.Kicker>
          <Hero.Description>
            <span className="o-event-date">
              {data.page.featuredEvent?.startDate &&
                moment(data.page.featuredEvent?.startDate).format(
                  'MMMM Do YYYY'
                )}
              {data.page.featuredEvent?.eventLocation?.city &&
                `, ${data.page.featuredEvent?.eventLocation.city}`}
              {data.page.featuredEvent?.eventLocation?.state &&
                ` ${data.page.featuredEvent?.eventLocation.state}`}
            </span>
            {data.page.featuredEvent?.shortDescription && (
              <span>{data.page.featuredEvent?.shortDescription}</span>
            )}
          </Hero.Description>

          <Hero.Buttons>
            {data.page.featuredEvent?.buttonUrl && (
              <Button url={getEventPath(data.page.featuredEvent?.slug)}>
                Learn More
              </Button>
            )}
          </Hero.Buttons>
          <Hero.Image>
            <Image {...data.page.featuredEvent?.heroImage} />
          </Hero.Image>
        </Hero>
      )}
      {data.all.length > 0 && (
        <article className="l-article u-spacing--quad u-space--quad--top">
          <Section>
            <Section.Inner xl>
              {data.all?.edges && (
                <Grid twoUp className="c-events">
                  {data.all.edges.map(({ node }) => (
                    <Grid.Item>
                      <EventBlockPost {...node} />
                    </Grid.Item>
                  ))}
                </Grid>
              )}
            </Section.Inner>
          </Section>
        </article>
      )}
    </Layout>
  )
}

export default EventCategoryTemplate

export const query = graphql`
  query EventCategoryTemplateQuery($key: String!) {
    page: contentfulEventCategory(key: { eq: $key }) {
      name
      key
      description {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      featuredEvent {
        name
        slug
        startDate
        eventLocation {
          city
          state
        }
        heroImage {
          ...ContentfulImageFragment
        }
        shortDescription
        buttonText
        buttonUrl
        event_category {
          key
          name
        }
      }
    }
    all: allContentfulEvent(
      sort: { fields: [startDate, name], order: ASC }
      filter: {
        slug: { ne: "test-event" }
        event_category: { elemMatch: { key: { eq: $key } } }
      }
    ) {
      ...ContentfulEventFragment
    }
  }
`
