import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GalleryBlock from 'molecules/block-gallery-alert'
import Section from 'atoms/section'
import Slider from 'react-slick'
import Cookie from 'universal-cookie'

const cookie = new Cookie()

const settings = {
  dots: false,
  infinite: true,
  autoPlay: true,
  speed: 700,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  rtl: true,
}

class Alert extends React.Component {
  state = {
    showBanner: false,
  }

  componentDidMount() {
    this.setState({
      showBanner: cookie.get('closed_top_banner') ? false : true,
    })
  }

  closeHandler(t) {
    cookie.set('closed_top_banner', true, {
      path: '/',
      expires: new Date(Date.now() + 43200000),
    })
    t.setState({ showBanner: false })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query AlertQuery {
            banners: allContentfulBanner(
              filter: { title: { ne: "Coronavirus Preparedness Statement" } }
            ) {
              totalCount
              edges {
                node {
                  title
                  bannerCta
                  bannerLink
                }
              }
            }
          }
        `}
        render={(data) => (
          <React.Fragment>
            <Section
              className="c-alert react-cookie-banner"
              style={{ display: this.state.showBanner ? 'block' : 'none' }}
            >
              <Slider {...settings}>
                {data?.banners?.edges.map(({ node }) => (
                  <GalleryBlock
                    className="c-gallery-block--small"
                    title={node.title}
                    cta={node.bannerCta}
                    slug={node.bannerLink}
                  />
                ))}
              </Slider>

              <button
                role="button"
                aria-label="Button Close"
                class="button-close"
                onClick={() => {
                  this.closeHandler(this)
                }}
              >
                {' '}
              </button>
            </Section>
          </React.Fragment>
        )}
      />
    )
  }
}

export default Alert
