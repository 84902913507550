import React from 'react'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

// Images
import sampleRfp from '../images/sampleRFP.jpg'

class onboardTMSPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL

    return (
      <Layout>
        <div>
          <Metadata
            title="5 Advantages to Using a Translation Management System - Smartling"
            description="Maximize your time, money and quality of translations with a comprehensive TMS partner."
            keywords=""
            image={sampleRfp}
          />
          <Hero
            gradientPrimary
            curveBottom
            center
            title="5 Advantages to Using a Translation Management System"
          >
            <Hero.Description>
              Maximize your time, money and quality of translations with a
              comprehensive TMS partner.
            </Hero.Description>
          </Hero>

          <Section className="u-padding--section">
            <Section.Inner l>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={sampleRfp} alt="Sample RFP" />
                </Grid.Item>
                <Grid.Item>
                  <h3 className="u-space--bottom">
                    The tricky part about creating your translation RFP?
                  </h3>
                  <p className="u-space--bottom">
                    Recognizing all of the different things your company does
                    that will impact the types of translation solutions you
                    need...{' '}
                    <span role="img" aria-label="sweat-smile">
                      😅
                    </span>
                  </p>
                  <p className="u-space--bottom">
                    Smartling’s Sample RFP will help assess your own business
                    requirements to help better identify the appropriate
                    solution for you.
                  </p>
                  <a
                    href="https://bot.smartling.com/sample-translation-rfp"
                    className="o-link"
                  >
                    Download Translation RFP Template
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section">
            <Section.Header>
              <h2 className="u-color--primary u-space--bottom u-text-align--center">
                How Investing in The Right Tools for a Global Strategy Drives
                ROI
              </h2>
              <p>
                A Translation Management System (TMS) provides the necessary
                functionality and efficiency to deliver amazing brand
                experiences and content in any language.
              </p>
            </Section.Header>
            <Section.Inner s>
              <div className="u-flex u-justify-content--center">
                <ul className="o-list--checked u-space--zero--right">
                  <li>Automated workflows save time</li>
                  <li>Translation Memory saves money</li>
                  <li>
                    Provides greater visibility into the translation process
                  </li>
                  <li>
                    Quality checks ensure all published content is error-free
                  </li>
                  <li>
                    Tap into native speakers from anywhere in the world via a
                    cloud-based platform
                  </li>
                </ul>
              </div>
              <p className="u-space--double--top">
                Translation is at the center of global revenue, and it’s
                important to invest in the right technology and expertise to
                ensure a smooth introduction, deliver the highest quality
                results, and maximize this area for potential growth to deliver
                the highest ROI possible.
              </p>
            </Section.Inner>
          </Section>

          <Section
            gradientSecondary
            className="c-section__grid-blocks c-grid-blocks u-padding--section"
          >
            <Section.Header>
              <h2 className="u-text-align--center">
                Smartling’s Sample Translation RFP provides:
              </h2>
            </Section.Header>
            <Section.Inner l className="u-padding--top">
              <Grid threeUp>
                <Grid.Item>
                  <Block title="Framework For Growth">
                    Questions for companies to better understand what you need
                    to expand to global markets.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Technical Evaluation">
                    It’s not just about creating global content, technical
                    configurations need to be considered.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Service & Support Inquiry">
                    The contract is signed.. what does the relationship with the
                    TMS partner look like from here?
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section" id="guide">
            <Section.Inner l>
              <h2 className="u-text-align--center u-space--double--bottom">
                Our sample RFP was created to help with all of these variables.
              </h2>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={sampleRfp} alt="Sample RFP" />
                </Grid.Item>
                <Grid.Item>
                  <a
                    href="https://bot.smartling.com/sample-translation-rfp"
                    className="o-link"
                  >
                    Download Now
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <div className="u-background-accent--right u-background-accent--right-small u-background-accent--left u-background-accent--left-small u-padding--section">
            <SectionCta centerCTA className="c-cta--why-smartling">
              <SectionCta.Description>
                <div className="u-spacing">
                  <h2>
                    Ready to swipe right?{' '}
                    <span role="img" aria-label="fire">
                      🔥
                    </span>
                  </h2>
                  <div>
                    Businesses depend on technology to drive efficiency and cost
                    savings. At Smartling, we take this to heart and are
                    equipped to scale alongside your ever-changing business.
                  </div>
                  <div>Book a meeting with our team to get started.</div>
                  <Button url="/meeting-request/" className="u-spacing">
                    REQUEST A DEMO
                  </Button>
                </div>
              </SectionCta.Description>
            </SectionCta>
          </div>
        </div>
      </Layout>
    )
  }
}

export default onboardTMSPage
