import React from 'react'
import Metadata from '../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Block from 'molecules/block'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import { StaticImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionTranslationInterface from 'components/sections/translation-interface'
import SectionTranslationServices from 'components/sections/translation-services'
import SectionPlatinumSupport from 'components/sections/platinum-support'
import SectionSoftwareAutomation from 'components/sections/software-automation'

import Layout from 'layouts'

// Images
import heroImage from '../../images/illustrations/users.svg'

class MeetOurTranslatorsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Smartling Translators - Smartling"
          description="With Smartling’s language translation service, you will have access to thousands of highly skilled translators for 150 languages and 50 industries."
          keywords=""
        />
        <Hero
          flip
          curveRight
          gradientSecondary
          title="Human translation. Superhuman project management."
          className="o-heading--xl"
        >
          <Hero.Description>
            <strong>
              On time, every time. We bring your content to life in any
              language.
            </strong>{' '}
            Our team works across more than 50 industries and actively
            translates into 150 languages. From product launches to legal
            documents, we tackle and transform your content and brand voice into
            any language.
          </Hero.Description>
          <Hero.Image>
            <StaticImage
              src="../../images/illustrations/users.svg"
              alt="Translate"
              width={720}
              height={620}
              objectFit="scale-down"
            />
          </Hero.Image>
        </Hero>

        <article className="l-article u-spacing--sections">
          <Section className="c-section__grid-blocks c-grid-blocks">
            <Section.Header>
              <Block title="Our people give your brand a global voice.">
                <Block.Image>
                  <Icons.HeaderWorld xxl />
                </Block.Image>
                <strong>
                  Certified, professional translators bringing your brand and
                  message to life.
                </strong>{' '}
                Some translation projects require greater attention to detail,
                cultural understanding, and specialized knowledge. Smartling’s
                professional linguists have domain expertise and tackle
                translation projects on time and accurately.
              </Block>
            </Section.Header>
            <Section.Inner l>
              <Grid threeUp className="has-icon-bulbs">
                <Grid.Item>
                  <Block title="No Learning Curve">
                    <Block.Image>
                      <Icons.IconUpDownArrows />
                    </Block.Image>
                    Each of our professional translators are already onboarded
                    to Smartling. There’s no downtime needed to get familiar
                    with the platform. Our translators are ready to dive
                    headfirst into your project.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Certified">
                    <Block.Image>
                      <Icons.IconCertifiedBadge />
                    </Block.Image>
                    Every translator is certified, and only 5% who apply are
                    accepted.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Accessible">
                    <Block.Image>
                      <Icons.IconComputerApps />
                    </Block.Image>
                    Access our translators at any step of the project. Get
                    real-time updates. Share changes.
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>
          <div className="u-gradient--primary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionTranslationInterface />
          </div>
          <SectionTranslationServices />
          <div className="u-gradient--primary u-curve--top u-curve--bottom u-padding--section">
            <SectionSoftwareAutomation />
          </div>
          <SectionCustomerSuccess />
          <div className="u-gradient--tertiary u-curve--top">
            <SectionPlatinumSupport />
          </div>
        </article>
      </Layout>
    )
  }
}

export default MeetOurTranslatorsPage
