import React from 'react'
import cx from 'classnames'
import PropTypes from '../prop-types'

const Button = ({
  className,
  outline,
  outlineWhite,
  secondary,
  children,
  size,
  ...patternProps
}) => {
  const classes = cx(className, {
    'o-button--outline': outline,
    'o-button--small': size === 'sm',
    'o-button--outline-white': outlineWhite,
    'o-button--secondary': secondary,
  })
  return (
    <PatternButton {...patternProps} className={classes}>
      {children}
    </PatternButton>
  )
}

Button.propTypes = {
  outline: PropTypes.bool,
}

export default Button

/* ----------------------------- PATTERN ----------------------------- */

export const PatternButton = ({
  className,
  addClasses,
  url,
  target,
  children,
}) => (
  <a
    className={`o-button ${className} ${addClasses ? addClasses : ''}`}
    href={url}
    role="button"
    target={target}
  >
    {children}
  </a>
)

PatternButton.propTypes = {
  className: PropTypes.string,
  addClasses: PropTypes.string,
  url: PropTypes.string,
}
