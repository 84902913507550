import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../../layouts/metadata'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'
import SectionTranslators from 'components/sections/translators'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts'

import MetaDataImage from '../../images/meta-image.png'

// Images
import heroImage from '../../images/illustrations/illustration_translation-leverage.png'
import sectionImage from '../../images/oana_drinking_coffee.jpg'

class LinguisticAssetManagementPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL.
    const { data } = this.props
    return (
      <Layout>
        <Metadata
          title={`${data.page.metadataTitle || data.page.title} - Smartling`}
          description={data.page.metadataDescription}
          keywords={data.page.metadataKeywords?.join(`, `)}
          image={MetaDataImage}
        />

        <Hero
          curveLeftReverse
          gradientPrimary
          title="Proactively realize translation preferences and high quality results."
        >
          <Hero.Description>
            <div className="u-spacing--and-half">
              <div className="c-hero__cta-text">
                Linguistic Asset Management is a comprehensive professional
                service offered by Smartling to position translators to create
                high quality content by establishing and optimizing your
                glossary and style guide.
              </div>
            </div>
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Translation Leverage" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Schedule a Meeting</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <SectionCta title="Our approach drives quality results.">
            <SectionCta.Description>
              <div class="u-padding--bottom">
                Today’s product and consumer experiences are powered by words.
                And we think every word you use across languages, content types,
                and devices can make or break your user experience. That’s why
                Smartling offers Linguistic Asset Management.
              </div>
              <div>
                We use professionally trained translators and software to
                identify key terminology and to document your content experience
                to enable your long term success. The end result? An optimized
                glossary and style guide that helps deliver high quality
                content.
              </div>
            </SectionCta.Description>
          </SectionCta>

          <Section>
            <Section.Inner l>
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-spacing u-text-align--center u-center-block">
                  <h2 className="c-cta__title">Two Simple Plans</h2>
                  <div className="c-cta__description u-padding--double--bottom">
                    Whether you’re getting started with translation for the
                    first time or are looking to optimize your program,
                    Smartling’s linguistic asset management plans make it simple
                    to proactively manage translation preferences.
                  </div>
                </div>
              </div>
              <div className="c-card c-card--cols c-card--full-height u-spacing--double">
                <div className="l-grid l-grid--2x4 l-grid--2x4--gutter-border u-text-align--left">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1">
                    <h3 class="u-color--primary">Essential</h3>
                    <p>Launch new languages with confidence.</p>
                  </div>
                  <div className="l-grid-area l-grid-area--b1">
                    <p>
                      <strong>Starting at</strong>
                    </p>
                    <h2 className="u-color--secondary">$500</h2>
                    <p> + Translated and localized versions at $150/ locale</p>
                  </div>
                  <div className="l-grid-area l-grid-area--c1">
                    <p>
                      <strong>English Glossary</strong>
                      <br />
                      20 terms
                    </p>
                  </div>
                  <div className="l-grid-area l-grid-area--d1">
                    <p>
                      <strong>Foundational English Style Guide</strong>
                      <br />
                      Analysis of your content to document the tone, formality
                      and style. The output is a two-page style guide.
                    </p>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <h3 class="u-color--primary">Premium</h3>
                    <p>Strategically improve translation quality.</p>
                  </div>
                  <div className="l-grid-area l-grid-area--b2">
                    <p>
                      <strong>Starting at</strong>
                    </p>
                    <h2 className="u-color--secondary">$2,000</h2>
                    <p> + Translated and localized versions at $950/ locale</p>
                  </div>
                  <div className="l-grid-area l-grid-area--c2">
                    <p>
                      <strong>English Glossary</strong>
                      <br />
                      100 terms with algorithmic approach to sourcing
                      terminology
                    </p>
                  </div>
                  <div className="l-grid-area l-grid-area--d2 u-text-align--left">
                    <p>
                      <strong>Comprehensive English Style Guide</strong>
                      <br />
                      Expertly crafted direction for how translators can manage
                      content for specialized industries or niche content. The
                      output is a 5-10 page style guide that considers the
                      localization requirements of each region.
                    </p>
                  </div>
                </div>
              </div>

              <div className="u-spacing u-text-align--center u-padding--top">
                <br />
                <Button url="/plans" addClasses="u-space">
                  Shop the Plans
                </Button>
              </div>
            </Section.Inner>
          </Section>

          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionCta title="Why Linguistic Assets are important.">
              <SectionCta.Description>
                Your content experience is your product experience, in English
                and every other language you support. Linguistic assets is an
                inexpensive and proactive way to mitigate the inherent risks of
                handing over your carefully crafted English content for
                translation.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="/resources/101/why-you-need-to-leverage-linguistic-assets-for-high-quality-on-brand/">
                  Learn More
                </Button>
              </SectionCta.Buttons>
            </SectionCta>
          </div>

          <section
            class="o-section c-section__grid-blocks c-grid-blocks u-spacing--double"
            data-bgset=""
          >
            <div className="o-section--inner l-wrap l-container--xs-s">
              <div className=" u-spacing u-text-align--center">
                <h2 className="c-cta__title">Two Assets. One Result.</h2>
                <div className="c-cta__description">
                  The glossary and style guide are the references translators
                  depend on to achieve your brands’ personality in any language.
                </div>
              </div>
            </div>
            <div class="o-section--inner l-wrap l-container--xl">
              <div class="l-grid c-cards l-grid--2up">
                <div class="l-grid-item">
                  <div class="c-card u-spacing undefined">
                    <div class="c-card__body u-spacing--half">
                      <h4 class="c-card__title">What is a Glossary?</h4>
                      <p class="c-card__description">
                        A glossary is a tool for ensuring consistency in
                        terminology. Adding details to a term can improve
                        quality and efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="l-grid-item">
                  <div class="c-card u-spacing undefined">
                    <div class="c-card__body u-spacing--half">
                      <h4 class="c-card__title">What is a Style Guide?</h4>
                      <p class="c-card__description">
                        A style guide is a collection of preferences for content
                        formatting, writing tone, and style to ensure
                        consistency in every language.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <SectionCta
            centerCTA
            title="Our translators help you move the world with words"
          >
            <SectionCta.Description>
              <div className="u-spacing">
                <div className="u-font--m u-font-weight--normal">
                  With a 5% pass rate, Smartling ensures that only the best
                  translators across the global are working with you to deliver
                  impactful content. Human translators provide the human touch.
                  With experience in 50 business verticals and over 150
                  languages, you can count on Smartling to deliver quality work.
                  A friendly and reliable face? You bet.
                </div>
              </div>
            </SectionCta.Description>
            <SectionCta.ImageLarge>
              <img
                loading="lazy"
                src={sectionImage}
                alt="An interview with Oana"
              />
            </SectionCta.ImageLarge>
            <SectionCta.Body>
              <div className="u-spacing--double u-text-align--left">
                <p className="u-font--m">
                  "Translators are not dictionaries. We are so much more than
                  that. Words are powerful, and they bring people together."
                </p>
                <p className="u-font--m">
                  <strong>Oana, </strong>a French translator for Smartling
                  <br />
                  <a
                    href="/resources/101/how-passion-and-perspective-bring-language-to-life"
                    class="o-link"
                  >
                    Meet Oana
                  </a>
                </p>
              </div>
            </SectionCta.Body>
          </SectionCta>

          <Section>
            <Section.Inner l>
              <div className="c-translate-slider">
                <SectionTranslators title="Meet our Translators" />
              </div>
            </Section.Inner>
          </Section>

          <section className="o-section c-section__cta c-cta c-cta--center">
            <div className="o-section--inner l-wrap l-container--l">
              <div className="c-cta__content u-spacing u-text-align--center">
                <h2 className="c-cta__title">Interested in learning more?</h2>
                <div className="c-cta__description">
                  High-quality translations require a solid foundation. Reach
                  out to us to learn how our approach delivers meaningful
                  results.
                </div>
              </div>
              <div className="c-cta__buttons">
                <a
                  className="o-button  "
                  href="/meeting-request/"
                  role="button"
                >
                  Schedule a Meeting
                </a>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default LinguisticAssetManagementPage

export const query = graphql`
  query LinguisticAssetManagementPageQuery {
    page: contentfulBasicPage(path: { eq: "linguistic-asset-management" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      heroImage {
        ...ContentfulImageFragment
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
      }
    }
  }
`
