import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Layout from 'layouts'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

// Images
import imageGuide1 from '../images/eBook_covid.jpg'
import imageWithin3 from '../images/within3-tile.png'
import imageGuide2 from '../images/ebook.png'
import imageDaniel from '../images/3-daniel-web.jpg'
import imageButterfly from '../images/butterfly-network-mission.png'
import imageBmj from '../images/bmj-r2-04.png'
import imageNoborders from '../images/translators-wo-borders.png'
import iconTeam from '../images/icons/icon_team-light-ring.svg'

class Covid19Page extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL.
    const { data } = this.props
    return (
      <Layout>
        <div className="l-page--plans">
          <Metadata
            title="COVID-19 Resources and Support | Smartling"
            description="COVID-19 has impacted the way we all communicate with our customers around the world. Check out these resources to help you better navigate this global pandemic."
            keywords={data.page.metadataKeywords?.join(`, `)}
            image="https://images.ctfassets.net/a8pkciehvm1g/4OI1VWg6CTb5nGAtAKxrMv/103de8aa12d9a8e7980ba976301c2f55/WebsiteTile_Covid19_900x675.png"
          />

          <Hero
            gradientPrimary
            curveLeft
            center
            title="Our specialty is communication in every language."
          >
            <Hero.Description>
              When navigating global crises, clear communication is key when
              everything else seems uncertain. We’ve put together a few tools
              and resources to better help you respond to and navigate the
              COVID-19 global pandemic.
            </Hero.Description>
            <Hero.Image />
            <Hero.Buttons>
              <Button url="/meeting-request/">schedule a meeting</Button>
            </Hero.Buttons>
          </Hero>

          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      The ultimate guide to maximizing your translation value.
                    </h3>
                    <p>
                      Translation is a critical component of global business
                      continuity. It is the fuel to customer enablement, sales
                      growth and retention of existing customers, and your
                      brand. Translation represents empathy at every touchpoint
                      on the consumer journey — it says to any person who
                      consumes your product that they matter to you. We've
                      collected insights from some of the experts at Smartling
                      to help your team maximize the value of translation with
                      an agile and centralized process.
                    </p>
                    <a
                      href="/resources/101/maximizing-translation-value/"
                      class="o-link"
                    >
                      View the eBook
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageGuide1} alt="eBook" />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageWithin3} alt="Jobs UI" />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Uniting healthcare professionals globally to fight
                      COVID-19.
                    </h3>
                    <p>
                      During a time when face-to-face meetings and in-person
                      collaboration has become outright impossible, companies
                      like Within3 have come to the rescue. Smartling customer,
                      Within3, developed an online discussion platform for
                      healthcare organizations that facilitates secure,
                      compliant communications between physicians, nurses,
                      payers, and patients. And in times like these, healthcare
                      workers need all the support they can get. Discover how
                      Within3 is changing the game for the healthcare industry.
                    </p>
                    <a href="/resources/101/within3/" class="o-link">
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      How agile translation helps in times of crises.
                    </h3>
                    <p>
                      Caught in email-based translation workflows with many
                      moving parts, The BMJ (formerly known as The British
                      Medical Journal) knew they needed to find a solution to
                      scale their operation. With Smartling’s cloud-based
                      translation management system, BMJ was able to create a
                      fully automated translation process, decreased their time
                      to market from 13 to six weeks, decreased translation
                      costs, and reduced project management efforts by 75%.
                    </p>
                    <a href="/resources/case-study/bmj/" class="o-link">
                      Read the case study
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageBmj} alt="BMJ case study" />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageDaniel} alt="Jobs UI" />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Still moving the world with words, in new ways.
                    </h3>
                    <p>
                      Translators have always been critical, but the type of
                      content they’re translating today has certainly shifted.
                      Discover how Smartling’s translators have evolved to meet
                      the growing needs of their clients and the type of content
                      most in demand right now.
                    </p>
                    <a
                      href="/resources/101/how-covid-19-has-translators-working-on-new-content/"
                      class="o-link"
                    >
                      Check out their stories
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Selling in uncertain times: a few tips to keep top of
                      mind.
                    </h3>
                    <p>
                      When the world feels as if it’s in a constant state of
                      flux, we must be mindful of how important it is to connect
                      with businesses, customers, and partners globally.
                      Adopting a global content strategy is a key differentiator
                      for brands as they shift and adapt to both the changing
                      demands of consumers, and new supply limitations
                      introduced during the COVID-19 pandemic.
                    </p>
                    <a
                      href="/resources/101/selling-in-uncertain-times/"
                      class="o-link"
                    >
                      View the eBook
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageGuide2} alt="Jobs UI" />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageButterfly}
                      alt="Butterfly iQ"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Stopping the spread of COVID-19 with portable ultrasound.
                    </h3>
                    <p>
                      Portable ultrasound powered by a smartphone? You read that
                      correctly. Butterfly Network, a Smartling customer,
                      developed the Butterfly iQ, a handheld device that arms
                      doctors with a medical imaging system on the go. And in
                      the wake of a global crisis, technology like this has
                      proven invaluable. With just a smartphone and internet
                      connection, the Butterfly iQ empowers doctors around the
                      world to diagnose and monitor COVID-19 patients.
                    </p>
                    <a
                      href="/resources/101/butterfly-network-covid19/"
                      class="o-link"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Giving back to our community in a time of crisis.
                    </h3>
                    <p>
                      Translators Without Borders (TWB) is an organization near
                      and dear to us, and they’re on a mission to raise $200,000
                      for its Emergency Response Fund to provide COVID-19
                      information in multiple languages. Join us by contributing
                      to this important cause aimed to disseminate vital
                      coronavirus news to the world’s most vulnerable. For every
                      donation made to TWB, we will match up to $5,000.
                    </p>
                    <a
                      href="https://translatorswithoutborders.z2systems.com/np/clients/translatorswithoutborders/donation.jsp?campaign=51"
                      class="o-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Donate Today
                    </a>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageNoborders}
                      alt="Translators without borders"
                    />
                  </div>
                </div>
              </div>
            </section>

            <div className="u-gradient--secondary u-curve--top u-curve--bottom u-padding--section">
              <SectionCta title="Still here, moving the world with words.">
                <SectionCta.Description>
                  <div className="u-spacing--double">
                    <div>
                      Smartling is committed to providing safe working
                      conditions for our employees, and business continuity for
                      our customers, partners, and translators. It has always
                      been our mission to help brands localize at scale and we
                      are dedicated to keeping our cloud-based platform fully
                      operational, without interruption.
                    </div>
                  </div>
                </SectionCta.Description>
                <SectionCta.Buttons>
                  <Button url="/covid19/updates">
                    Read our full preparedness statement
                  </Button>
                </SectionCta.Buttons>
              </SectionCta>
            </div>

            <section className="o-section c-section__cta c-cta c-cta--center c-ready-to-start c-ready-to-start--nb">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-spacing u-text-align--center">
                  <img
                    loading="lazy"
                    className="c-cta__header-img"
                    src={iconTeam}
                    alt="Team Icon"
                  />
                  <h2 className="c-cta__title">We're here to support you.</h2>
                  <div className="c-cta__description">
                    Content recommendations, localization tips, product
                    support... you name it. Schedule time with our team today.
                    We can't wait to connect.
                  </div>
                </div>
                <div className="c-cta__buttons">
                  <a
                    className="o-button "
                    href="/meeting-request/"
                    role="button"
                  >
                    Schedule a meeting
                  </a>
                </div>
              </div>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default Covid19Page

export const query = graphql`
  query Covid19PageQuery {
    page: contentfulBasicPage(path: { eq: "covid19" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      metadataImage {
        ...ContentfulImageFragment
      }
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      heroImage {
        ...ContentfulImageFragment
      }
    }
  }
`
