import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import PardotForm from 'components/forms/pardot'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionTranslators from 'components/sections/translators'

// Images
import sectionImage from '../images/translators-oana.jpg'

import Layout from 'layouts'

const BookTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || data.page.title}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
    />
    <div className="l-page--book">
      <Hero
        gradientPrimary
        center
        title="Move the World with Words"
        backgroundImage={MetaDataImage}
      >
        <Hero.Kicker>Coffee table book available now!</Hero.Kicker>
        <Hero.Description>
          <h3>We’re on a mission to humanize translation. Will you join us?</h3>
        </Hero.Description>
        <Hero.Buttons>
          <Button
            url="https://www.amazon.com/Move-the-World-with-Words/dp/1733441603"
            target="_blank"
          >
            Buy the Book
          </Button>
          <Button url="/meeting-request/">Get a demo</Button>
        </Hero.Buttons>
        <Hero.Buttons>
          {data.page.heroButtons &&
            data.page.heroButtons.map((button) => {
              return (
                <Button
                  url={button.buttonLink}
                  target={button.newWindow ? `_blank` : `_self`}
                >
                  {button.buttonText ? button.buttonText : `Learn More`}
                </Button>
              )
            })}
        </Hero.Buttons>
      </Hero>
      <article className="l-article u-spacing--sections">
        <div className="l-wrap l-container--l u-spacing--double">
          <div className="l-container--s o-text o-text--secondary">
            {data.page.body && (
              <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
            )}
            {data.page?.pardotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Below Body' ? (
                <PardotForm
                  iframe={data.page?.pardotFormReference?.pardotFormLink}
                />
              ) : (
                ``
              ))}
          </div>
        </div>
        <SectionCta
          centerCTA
          gradientPrimary
          curveTop
          curveBottom
          title="How Passion and Perspective Bring Language to Life"
        >
          <SectionCta.Description>
            <div className="u-spacing">
              <p className="u-font--m">
                An interview with Oana, a freelance translator who lives in a
                small town near Toulouse, France.
              </p>
            </div>
          </SectionCta.Description>
          <SectionCta.ImageLarge>
            <img
              loading="lazy"
              src={sectionImage}
              alt="An interview with Oana"
            />
          </SectionCta.ImageLarge>
          <SectionCta.Buttons>
            <Button url="/resources/101/how-passion-and-perspective-bring-language-to-life">
              Read Oana's story
            </Button>
          </SectionCta.Buttons>
        </SectionCta>
        <SectionCta title="Home" centerCTA>
          <SectionCta.Description>
            <p className="u-font--m">
              Let's bring home meaning and work together to move the world with
              words.
            </p>
          </SectionCta.Description>
          <SectionCta.Video>
            <iframe
              title="Video"
              src="https://player.vimeo.com/video/336098356?title=0&byline=0&portrait=0"
              frameborder="0"
              width="100%"
              height="auto"
            />
          </SectionCta.Video>
          <SectionCta.Buttons>
            <Button url="/meeting-request/">Let's work together</Button>
          </SectionCta.Buttons>
        </SectionCta>
        <SectionTranslators
          title="Who would you like to meet next?"
          gradientTertiary
          curveTop
        />
      </article>
    </div>
  </Layout>
)

export default BookTemplate

export const pageQuery = graphql`
  query BookTemplateQuery {
    page: contentfulBasicPage(path: { eq: "book" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords

      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
