import React from 'react'
import PropTypes from '../prop-types'
import * as Icons from 'atoms/icons'

const Block = ({ className, slug, title, src }) => {
  return (
    <PatternBlock className={className} slug={slug} title={title} src={src} />
  )
}

Block.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
}

export default Block

/* ----------------------------- PATTERN ----------------------------- */

export const PatternBlock = ({ className, slug, title, alt, src }) => {
  return (
    <React.Fragment>
      <a href={slug} className={`c-gallery-block ${className}`}>
        {src && (
          <img
            loading="lazy"
            src={src}
            alt={title || alt}
            className="c-gallery-block__image"
          />
        )}
        {title && (
          <div className="c-gallery-block__dek">
            <h3 className="c-gallery-block__title">
              <Icons.IconGallery m />
              {title}
            </h3>
          </div>
        )}
      </a>
    </React.Fragment>
  )
}

PatternBlock.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
}
