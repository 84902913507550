import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import blmImage from '../images/BLM.png'
import ldfImage from '../images/LDF.png'
import ejiImage from '../images/EJI.png'
import antiRacismImage from '../images/smartling_anti-racism.png'
import Helmet from 'react-helmet'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'
import Layout from 'layouts'

import MetaDataImage from '../images/meta-image.png'

class DiversityTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    return (
      <Layout>
        <Helmet>
          <body className="l-page--diversity u-header--white" />
        </Helmet>
        <div>
          <Metadata
            title={`${data.page?.title} - Smartling`}
            description={data.page?.metadataDescription}
            keywords={data.page?.metadataKeywords?.join(`, `)}
            image={MetaDataImage}
          />

          <Hero center curveLeftReverse title={data.page?.title}>
            <Hero.Description></Hero.Description>
          </Hero>

          <div className="u-spacing--sections u-padding--quad--top">
            <SectionCta title="Together against racism.">
              <SectionCta.Description>
                <div className="u-spacing">
                  <div>
                    Smartling was built on the principles of inclusion,
                    understanding, and communication. And it has always been our
                    mission to reach a global community through the power of
                    language. But, we can do better, and <br />
                    we <u>will</u> do better.
                  </div>
                  <div>Join us in taking a stand against racism.</div>
                </div>
              </SectionCta.Description>
            </SectionCta>

            <Section centerCTA>
              <Section.Header>
                <div class="c-block u-spacing">
                  <div class="c-block__text u-spacing">
                    <div class="u-spacing">
                      <h3 class="c-block__title u-font--xl">
                        A commitment to partnership.
                      </h3>
                      <div class="c-block__description u-font--m">
                        We’re partnering with Black Lives Matter, NAACP Legal
                        Defense & Education Fund, and Equal Justice Initiative.
                        We will match donations – details forthcoming.
                        {/* For every donation made, we’ll match your generosity up to $10,000. */}
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Header>
              <Section.Inner l>
                <div className="l-grid l-grid--3x3">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1">
                    <div className="l-grid__item-image l-grid__item-image--left">
                      <img loading="lazy" src={blmImage} alt="Integrate" />
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b1 u-text-align--left">
                    <p>
                      “Black Lives Matter Foundation, Inc is a global
                      organization, whose mission is to eradicate white
                      supremacy and build local power to intervene in violence
                      inflicted on Black communities by the state and
                      vigilantes.”
                    </p>
                  </div>
                  <div className="l-grid-area l-grid-area--c1 u-text-align--left">
                    <a
                      role="button"
                      href="https://secure.actblue.com/donate/ms_blm_homepage_2019"
                      class="o-link u-space--bottom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Donate Now
                    </a>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <div className="l-grid-area l-grid-area--a1">
                      <div className="l-grid__item-image l-grid__item-image--left">
                        <img loading="lazy" src={ldfImage} alt="Translate" />
                      </div>
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b2 u-text-align--left">
                    <p>
                      “The NAACP Legal Defense and Educational Fund, Inc. is
                      America’s premier legal organization fighting for racial
                      justice.”
                    </p>
                  </div>
                  <div className="l-grid-area l-grid-area--c2 u-text-align--left">
                    <a
                      role="button"
                      href="https://org2.salsalabs.com/o/6857/p/salsa/donation/common/public/?donate_page_KEY=15780&_ga=2.190304552.2032071846.1592398500-265407576.1591738274"
                      class="o-link u-space--bottom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Donate Now
                    </a>
                  </div>

                  {/* three */}
                  <div className="l-grid-area l-grid-area--a3">
                    <div className="l-grid-area l-grid-area--a1">
                      <div className="l-grid__item-image l-grid__item-image--left">
                        <img loading="lazy" src={ejiImage} alt="Publish" />
                      </div>
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b3 u-text-align--left">
                    <p>
                      “EJI [Equal Justice Initiative] is committed to ending
                      mass incarceration and excessive punishment in the U.S.,
                      challenging racial and economic injustice, and protecting
                      basic human rights for the most vulnerable people in
                      American society.”
                    </p>
                  </div>
                  <div className="l-grid-area l-grid-area--c3 u-text-align--left">
                    <a
                      role="button"
                      href="https://support.eji.org/give/153413/#!/donation/checkout"
                      class="o-link u-space--bottom"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Donate Now
                    </a>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <SectionCta title="A commitment to change at our core.">
              <SectionCta.Description>
                <div className="u-spacing">
                  <div>
                    It’s important to put our words into action. That’s why
                    we’re taking steps to make Smartling a better place to work
                    - a place that is reflective of the world in which we wish
                    to live.
                  </div>

                  <ul className="o-list--checked o-list--checked--left">
                    <li>
                      <span className="u-font--m">
                        Working with diversity and inclusion advisors to guide
                        leadership team on integrating sustainable strategies
                        and programs
                      </span>
                    </li>
                    <li>
                      <span className="u-font--m">
                        Introducing a council to drive the diversity and
                        inclusion agenda forward
                      </span>
                    </li>
                    <li>
                      <span className="u-font--m">
                        Launching a regular series of seminars and trainings on
                        topics of racism and social justice, such as unconscious
                        bias training
                      </span>
                    </li>
                    <li>
                      <span className="u-font--m">
                        Hosting regular meetings and open office hours with the
                        leadership team to provide a space for conversation on
                        topics related to inclusion for all minorities
                      </span>
                    </li>
                  </ul>

                  <div>
                    We’ve opened our eyes, our minds, and our hearts, and we are
                    committed to change.
                  </div>
                </div>
              </SectionCta.Description>
            </SectionCta>

            {/* <Section>
              <Section.Header>
                <div class="u-spacing--double">
                  <div class="u-font--m c-quote">
                    <em>
                      I spent my entire childhood in a military family, and 10
                      years in the service myself. The military community truly
                      is a melting pot, with people from every possible
                      background and demographic. I served with other soldiers
                      who were male, female, black, white, latino, asian, gay,
                      straight, Christian, Jewish, rich, poor - you name it. The
                      rule of thumb in the military is simple: let’s get through
                      this alive, together. That’s the only thing that matters.
                      <br />
                      <br />
                      We need more of that in the world today.
                    </em>
                  </div>
                  <p>Jack Welde, CEO</p>
                </div>
              </Section.Header>
            </Section> */}

            <div style={{ backgroundColor: 'black' }}>
              <Section>
                <Section.Inner xl>
                  <img
                    loading="lazy"
                    src={antiRacismImage}
                    alt="Together Against Racism"
                  />
                </Section.Inner>
              </Section>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default DiversityTemplate

export const query = graphql`
  query DiversityTemplateQuery {
    page: contentfulBasicPage(path: { eq: "diversity" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
