import React from 'react'
import Metadata from '../layouts/metadata'
import Block from 'molecules/block'
import Section from 'atoms/section'
import * as Icons from 'atoms/icons'

// Sections
import Layout from 'layouts'

// Images
import heroImage from '../images/gabriela-flag.png'
import iconRocket from '../images/icons/icon_rocket-light-ring.svg'
import iconDiamond from '../images/icons/icon_diamond-light-ring.svg'
import iconPiggy from '../images/icons/icon_piggybank-light-ring.svg'
import imageFlavio from '../images/flavio-flag.png'
import imageOana from '../images/oana-flag.png'
import logoYext from '../images/company-logos/logo-yext.png'

class TranslatePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Language Services Translation Test | Smartling"
          description="Partner with Smartling, the leading translation services provider, that understands the importance of your content and does what it takes to deliver quality results. Conduct a translation test with us today."
          keywords="Translation agency, Professional translators, Professional translation services, Professional language services"
          image={heroImage}
        />
        <div className="c-page-translate">
          <section
            class="o-section c-section__hero c-hero u-gradient--secondary u-curve--bottom--left--reverse undefined"
            data-bgset=""
          >
            <div class="o-section--inner l-wrap l-container--xl">
              <div class="c-hero--left">
                <div class="c-hero__content u-spacing--double u-padding--hero">
                  <h1 class="c-hero__title o-section-title--heavy">
                    We love a good test.
                  </h1>
                  <p class="c-hero__description">
                    <div class="u-spacing--and-half">
                      <div class="c-hero__cta-text">
                        A translation test that is. It’s how we prove our
                        ability to translate any content—your content—with
                        excellence.
                      </div>
                    </div>
                  </p>
                  <div class="c-hero__buttons">
                    <a
                      class="o-button  "
                      href="#language-services-consultation"
                      role="button"
                    >
                      Schedule Consultation
                    </a>
                  </div>
                </div>
              </div>
              <div class="c-hero--right">
                <div class="c-hero__image">
                  <img
                    loading="lazy"
                    src={heroImage}
                    alt="Oana, a translator"
                  />
                </div>
              </div>
            </div>
          </section>

          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-grid--reverse--sm">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageFlavio}
                      alt="Flavio Translator"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Your Content Matters
                    </h3>
                    <p>
                      We understand the care teams take to write content that
                      reflects the ambitious brand and product experience you
                      deliver to customers. That’s why we’re an open book when
                      it comes to your consideration of Smartling’s language
                      services. It’s of utmost importance to us that you trust
                      our capabilities and that we partner to deliver results
                      that have impact.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Test us. It’s easy (and free).">
                  <Block.Image>
                    <Icons.IconAccuracy xxxl />
                  </Block.Image>
                  Our team selects linguists based on the target languages,
                  content type and domain of your business.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="l-grid l-grid--3x2">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1">
                    <h3 class="u-color--primary">Content Sample</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b1 o-divider-gradient--m-only">
                    <p>
                      Select 500 words that are representative of your content
                      for translation.
                    </p>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <h3 class="u-color--primary">Linguistic Assets</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b2 o-divider-gradient--m-only">
                    <p>
                      Provide a glossary of terms and style guide as you would
                      for any translation project.
                    </p>
                  </div>

                  {/* three */}
                  <div className="l-grid-area l-grid-area--a3">
                    <h3 class="u-color--primary">Feedback Loop</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b3">
                    <p>
                      Thoroughly review the content with your regular Q/A
                      process and share feedback with Smartling.
                    </p>
                  </div>
                </div>
              </Section.Inner>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="#language-services-consultation"
                  role="button"
                >
                  Get Started Now
                </a>
              </div>
            </Section>

            <Section>
              <Section.Header>
                <Block title="We Deliver Results">
                  <Block.Image />
                  Smartling enables customer success for three reasons.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="l-grid l-grid--3x4">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1">
                    <div className="l-grid__item-image l-grid__item-image--sm u-center-block">
                      <img loading="lazy" src={iconRocket} alt="Rocket Icon" />
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b1">
                    <h3 class="u-color--primary">50% Faster to Market</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--c1">
                    <p>
                      Achieve predictable translation results and timelines.
                    </p>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <div className="l-grid__item-image l-grid__item-image--sm u-center-block">
                      <img
                        loading="lazy"
                        src={iconDiamond}
                        alt="Diamond Icon"
                      />
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b2">
                    <h3 class="u-color--primary">100% Quality Guarantee</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--c2">
                    <p>Measure your quality, and guarantee results.</p>
                  </div>

                  {/* three */}
                  <div className="l-grid-area l-grid-area--a3">
                    <div className="l-grid__item-image l-grid__item-image--sm u-center-block">
                      <img
                        loading="lazy"
                        src={iconPiggy}
                        alt="Piggy Bank Icon"
                      />
                    </div>
                  </div>
                  <div className="l-grid-area l-grid-area--b3">
                    <h3 class="u-color--primary">30% Cost Savings</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--c3">
                    <p>
                      You will pay less for human translation, and the linguist
                      will make more.
                    </p>
                  </div>
                </div>
              </Section.Inner>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/translation-services/sls/lsp-industry-analysis/"
                  role="button"
                >
                  Smartling v Competition
                </a>
              </div>
            </Section>

            <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
              <section
                class="o-section c-section__cta c-cta  undefined undefined"
                data-bgset=""
              >
                <div class="o-section--inner l-wrap l-container--l">
                  <div class="c-cta__content u-spacing">
                    <h3 class="c-cta__title" style={{ maxWidth: '760px' }}>
                      "I view Smartling as our partner, not just as a vendor
                      that we pay for. In the end, I find by talking with the
                      Smartling team, we're able to come together to find
                      solutions to our challenges."
                    </h3>
                    <div class="c-cta__description">
                      <div class="u-spacing--double">
                        <div>- Jessica Birenz at Yext</div>
                      </div>
                    </div>
                    <img
                      loading="lazy"
                      src={logoYext}
                      alt="Yext logo"
                      style={{ maxWidth: '65px' }}
                    />
                  </div>
                  <div class="c-cta__buttons">
                    <a
                      class="o-button  "
                      href="/resources/101/translation-isnt-blind-how-to-launch-new-languages/"
                      role="button"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <Section centerCTA>
              <Section.Header>
                <Block title="An Agency Partner (without the fuss).">
                  <Block.Image>
                    <Icons.IconTeam xxxl />
                  </Block.Image>
                  Smartling is a full service translation agency that renders a
                  variety of services, from T&E to Desktop Publishing.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div className="l-grid l-grid--3x2">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1">
                    <h3 class="u-color--primary">1 Word Minimum</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b1 o-divider-gradient--m-only">
                    <p>
                      Since there are no project minimums, you can submit one
                      word for translation, and only pay for this one word to be
                      translated.
                    </p>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <h3 class="u-color--primary">98% of Strings Unchanged</h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b2 o-divider-gradient--m-only">
                    <p>
                      Our translators deliver translations that our customers
                      don’t change because the work is representative of the
                      brands that submit the orders.
                    </p>
                  </div>

                  {/* three */}
                  <div className="l-grid-area l-grid-area--a3">
                    <h3 class="u-color--primary">
                      99% of Words Delivered On Time
                    </h3>
                  </div>
                  <div className="l-grid-area l-grid-area--b3">
                    <p>
                      Your deadlines matter because you’re trying to include
                      translations in tight release cycles. We get it, and we’re
                      up to this challenge.
                    </p>
                  </div>
                </div>
              </Section.Inner>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/translation-services/sls"
                  role="button"
                >
                  Learn more
                </a>
              </div>
            </Section>

            <section className="o-section c-section__cta c-cta c-cta--center c-ready-to-start c-ready-to-start--zero-margin">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-spacing u-text-align--center">
                  <img
                    loading="lazy"
                    style={{ width: '296px' }}
                    src={imageOana}
                    alt="Gabriela Translator"
                  />
                  <h2 className="c-cta__title">Choose your words wisely.</h2>
                  <div className="c-cta__description">
                    Start using Smartling Language Services Today.
                  </div>
                </div>
                <div className="c-cta__buttons">
                  <a
                    className="o-button  "
                    href="#language-services-consultation"
                    role="button"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default TranslatePage
