import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { getTranslatorPath } from '../../routes'
import GalleryBlock from 'molecules/block-gallery'
import Section from 'atoms/section'
import Slider from 'react-slick'
import cx from 'classnames'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const SectionTranslators = ({
  title,
  gradientPrimary,
  gradientSecondary,
  gradientTertiary,
  curveTop,
  curveTopLeft,
  curveTopRight,
  curveBottom,
  curveBottomCenter,
}) => {
  const classes = cx({
    'u-gradient--primary': gradientPrimary,
    'u-gradient--secondary': gradientSecondary,
    'u-gradient--tertiary': gradientTertiary,
    'u-curve--top': curveTop,
    'u-curve--top--left': curveTopLeft,
    'u-curve--top--right': curveTopRight,
    'u-curve--bottom': curveBottom,
    'u-curve--bottom--center': curveBottomCenter,
  })

  return (
    <StaticQuery
      query={graphql`
        query SectionTranslatorsQuery {
          moreGalleries: allContentfulGallery {
            ...ContentfulGalleryFragment
          }
        }
      `}
      render={data => (
        <Section className={`c-section__gallery-recirc ${classes}`}>
          <Section.Inner xl className="u-spacing">
            {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
            <Slider {...settings}>
              {data.moreGalleries.edges.map(
                ({ node }) =>
                  node.galleryImages[0] && (
                    <GalleryBlock
                      className="c-gallery-block--small"
                      title={node.title}
                      src={`${node.galleryImages[0].file.url}?fit=fill&w=300&h=300`}
                      slug={getTranslatorPath(node.slug)}
                    />
                  )
              )}
            </Slider>
          </Section.Inner>
        </Section>
      )}
    />
  )
}

export default SectionTranslators
