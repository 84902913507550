import React from 'react'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

import SectionTwoColumn from 'organisms/two-column'

import caseStudyImage1 from '../../../images/illustrations/case-study-ihg.png'
import caseStudyLogo1 from '../../../images/logo-igh.png'
import caseStudyImage2 from '../../../images/illustrations/case-study-eurail.png'
import caseStudyLogo2 from '../../../images/logo-eurail.svg'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/integration-aem.png'
import imageIntegration from '../../../images/integrations/adobe-integration.png'
import imageLabel from '../../../images/integrations/adobe-label-lt.png'
import imageCatTool from '../../../images/product-illustration_CATtool-GIF.gif'

var imgStyle = {
  maxWidth: '500px',
}

class AdobeExperienceManagerPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Adobe Experience Manager Translation Integration | Smartling"
          description="Translate Adobe content via Smartling’s AEM translation integration. Now supporting AEM Classic, AEM Touch and AEM as a Cloud Service."
          keywords=""
          image={heroImage}
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Adobe Experience Manager Translation Integration"
          >
            <Hero.Description>
              The most customizable CMS meets the most agile TMS. Smartling’s
              Adobe Experience Manager translation integration enables the most
              personalized, complex websites to be translated and localized into
              any language.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Global Delivery Network"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Now Supporting AEM as a Cloud Service
                    </h3>
                    <p>
                      As Adobe accelerates digital transformation with their new{' '}
                      <a
                        href="https://www.adobe.com/marketing/experience-manager/cloud-service.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Adobe Experience Manager cloud
                      </a>{' '}
                      offering, Smartling’s integration enables you to access
                      unparalleled translation management tools and services
                    </p>
                    <p>
                      Smartling integrates with your existing AEM instance and
                      supporting content infrastructure, enabling you to easily
                      manage language translation at scale. There are multiple
                      integration types that simplify this process for AEM
                      customers, including:
                      <ul className="o-list--checked o-list--checked--left">
                        <li>
                          AEM Classic, Touch and Cloud as a Service Integrations
                        </li>
                        <li>
                          An https{' '}
                          <a href="/software/global-delivery-network/">
                            web proxy
                          </a>
                        </li>
                        <li>
                          Hundreds of <a href="/software/api/">API Endpoints</a>
                        </li>
                      </ul>
                    </p>
                    <p>
                      These integration possibilities connect your Adobe
                      experience to the most powerful Cloud Translation
                      Management System on the market. It’s the simplest place
                      to configure automation rules, establish workflow
                      preferences, maintain brand assets, and access translators
                      selected to match the domain of your business. Simply put,
                      it makes managing multilingual AEM instances a breeze.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageIntegration}
                      alt="Adobe Experience Manager integration"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageLabel}
                      alt="Adobe Experience Manager label"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Centrally Power Global Experiences
                    </h3>
                    <p>
                      Smartling integrates with your Adobe Experience Manager
                      instance and workflow so that you don’t have to leave a
                      familiar platform to deploy content in any language. Files
                      in AEM, pages, DAM, and tags are all seamlessly translated
                      without the need to copy and paste content from the CMS
                      into a spreadsheet and then circulated by email for
                      translation. Smartling automates the entire process
                      surrounding AEM translation, and makes it possible to
                      integrate author and production environments to support
                      agile translation.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Robust translation backbone
                    </h3>
                    <p>
                      Smartling’s Translation Management System gives you full
                      visibility and scalability into translation projects. You
                      can easily manage tasks and assignments within the
                      cloud-based platform. Smartling’s world-class translation
                      services are the perfect match to your content
                      requirements, supporting any language you require and
                      hand-picked based on your business domain. What’s more?
                      They work within a visual context interface to deliver
                      high quality results.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img loading="lazy" src={imageCatTool} alt="Cat Tool" />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-padding--top">
                  <div class="c-cta__video">
                    <iframe
                      title="Smartling's CEO Jack Welde in Think Tank by Adobe from Smartling on Vimeo."
                      src="https://player.vimeo.com/video/210295818?title=0&byline=0&portrait=0"
                      frameborder="0"
                      width="100%"
                      height="auto"
                      allow="autoplay;
                      encrypted-media"
                      allowfullscreen
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Tried and True, Even in the Think Tank
                    </h3>
                    <p>
                      Smartling co-authored the Adobe Experience Manager
                      localization framework years ago, and continues its
                      commitment to providing a secure cloud-based framework as
                      an Adobe Exchange Business Partner. Smartling’s CEO even
                      spoke to the vision for global consumer experiences in
                      Adobe’s Think Tank at their 2017 conference.
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <div className="l-case-studies u-background-accent--left u-background-accent--right">
              <Section>
                <Section.Header>
                  <Block title="Case Studies">
                    <Block.Image>
                      <Icons.IconTrophyCircleLight xxl />
                    </Block.Image>
                  </Block>
                </Section.Header>
              </Section>

              <div className="u-spacing--sections">
                <SectionTwoColumn mobile title="5,600+ Hotel Websites">
                  <SectionTwoColumn.Logo>
                    <img loading="lazy" src={caseStudyLogo1} alt="IGH Logo" />
                  </SectionTwoColumn.Logo>
                  <SectionTwoColumn.Description>
                    <p>
                      IHG continuously translates its digital properties using
                      Smartling's website proxy, Adobe Experience Manager
                      integration, and code repo connector.
                    </p>
                  </SectionTwoColumn.Description>
                  <SectionTwoColumn.Buttons>
                    <Button
                      target="_blank"
                      url="/resources/case-study/intercontinental-hotels-group/"
                    >
                      View Case Study
                    </Button>
                  </SectionTwoColumn.Buttons>
                  <SectionTwoColumn.Image>
                    <img
                      loading="lazy"
                      src={caseStudyImage1}
                      alt="IGH Website"
                    />
                  </SectionTwoColumn.Image>
                </SectionTwoColumn>

                <SectionTwoColumn
                  flip
                  mobile
                  title="Laying trackwork for a new translation process"
                >
                  <SectionTwoColumn.Logo>
                    <img
                      loading="lazy"
                      class="o-logo--l"
                      src={caseStudyLogo2}
                      alt="Eurail Logo"
                    />
                  </SectionTwoColumn.Logo>
                  <SectionTwoColumn.Description>
                    <p>
                      Eurail launched translations on their new Adobe Experience
                      Manager website with Smartling's integration, language
                      services, and professional services.
                    </p>
                  </SectionTwoColumn.Description>
                  <SectionTwoColumn.Buttons>
                    <Button
                      target="_blank"
                      url="/resources/case-study/eurail-com/"
                    >
                      View Case Study
                    </Button>
                  </SectionTwoColumn.Buttons>
                  <SectionTwoColumn.Image>
                    <img loading="lazy" src={caseStudyImage2} alt="Eurorail" />
                  </SectionTwoColumn.Image>
                </SectionTwoColumn>
              </div>
            </div>

            <SectionCta
              title="IHG powers 5,600 hotel websites with Smartling."
              centerCTA
            >
              <SectionCta.Video>
                <iframe
                  title="video 1"
                  src="https://player.vimeo.com/video/207829792?title=0&byline=0&portrait=0"
                  frameborder="0"
                  width="560px"
                  height="315px"
                  allow="autoplay"
                  encrypted-media
                  allowfullscreen
                />
              </SectionCta.Video>
            </SectionCta>

            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Five reasons why Adobe customers love Smartling:">
                  <Block.Image>
                    <Icons.IconStarCircleLight xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--left">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="1" class="u-font--l">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Onboard in 30 days or less.</b> We make your
                            success our priority.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="2" class="u-font--l">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Context matters.</b> Statistically improve
                            translation quality with a WYSIWYG editor for
                            linguists and reviewers.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="3" class="u-font--l">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Data-driven localization.</b> We surface data and
                            insights, so you can drive down cost and increase
                            efficiency.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span
                              role="img"
                              aria-label="4"
                              class="u-font--l"
                              and-half--
                            >
                              4️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>The best translators.</b> Professionals who are
                            vetted, certified and centrally managed so you don’t
                            have to.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="5" class="u-font--l">
                              5️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Managed Services.</b> An extension to your team,
                            so your team can focus on personalizing the end user
                            experience.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section centerCTA>
              <Section.Header>
                <Block title="The Most Advanced CMS Meets the Most Advanced TMS">
                  <Block.Image>
                    <Icons.IconBinocularsRing xxxl />
                  </Block.Image>
                  Join the ranks of Smartling’s world-class customers today and
                  discover how Smartling’s AEM translation integration can scale
                  your digital experience into any language.
                </Block>
              </Section.Header>

              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default AdobeExperienceManagerPage
