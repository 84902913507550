import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import PardotForm from 'components/forms/pardot'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import MetaDataImage from '../images/meta-image.png'

// Images
import sectionImage1 from '../images/translators-rockstar.jpg'

import Layout from 'layouts'

const SummerTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || data.page.title}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
    />
    <div className="l-page--summer">
      <Hero curveBottom gradientPrimary title={data.page.title}>
        <Hero.Description>
          <p>
            We’ve automated all of our marketing with Unicorns so that we can go
            enjoy summer while it lasts!
          </p>
          <p>
            Just kidding! We’re here. And we’re here because we know that you’re
            planning something: a new product line, a content refresh, or maybe
            an upgrade to your tech stack.
          </p>
          {data.page?.pardotFormReference &&
            (data.page?.pardotFormReferencePlacement === 'Hero' ? (
              <PardotForm
                iframe={data.page?.pardotFormReference?.pardotFormLink}
              />
            ) : (
              ``
            ))}
        </Hero.Description>

        <Hero.Image>
          <img loading="lazy" src={MetaDataImage} alt="Summer School" />
        </Hero.Image>

        <Hero.Buttons>
          {data.page.heroButtons &&
            data.page.heroButtons.map((button) => {
              return (
                <Button
                  url={button.buttonLink}
                  target={button.newWindow ? `_blank` : `_self`}
                >
                  {button.buttonText ? button.buttonText : `Learn More`}
                </Button>
              )
            })}
        </Hero.Buttons>
      </Hero>
      <article className="l-article u-spacing--sections">
        <SectionCta title="Back to school." centerCTA>
          <SectionCta.Description>
            {data.page.body && (
              <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
            )}
          </SectionCta.Description>
        </SectionCta>
        <SectionCta
          title="Meet Smartling"
          gradientSecondary
          curveTop
          centerCTA
          curveBottomCenter
        >
          <SectionCta.Description>
            Smartling invented Cloud Translation to address the increasing
            complexities that are inherent with global content management.
            Today,{' '}
            <a href="https://www.smartling.com/resources/case-study/">
              hundreds of companies
            </a>{' '}
            rely on Smartling Translation Cloud to translate websites, complex
            web applications, marketing automation, and product documentation
            from one language into any.
          </SectionCta.Description>
          <SectionCta.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </SectionCta.Buttons>
        </SectionCta>
        <SectionCta centerCTA title="How it works">
          <SectionCta.Description>
            <div className="u-spacing u-text-align--left">
              <p>
                Smartling connects to your existing tools and content
                infrastructure, enabling you to easily manage language
                translation at scale. There are multiple integration types that
                simplify this process, including:
              </p>
              <ul className="o-list--checked">
                <li>
                  Pre-built{' '}
                  <a
                    href="/products/automate/integrations/"
                    title="Smartling Integrations"
                  >
                    integrations
                  </a>{' '}
                  with popular Content Management Systems
                </li>
                <li>
                  An https{' '}
                  <a
                    href="/software/global-delivery-network/"
                    title="Smartling Web Proxy"
                  >
                    web proxy
                  </a>
                </li>
                <li>
                  Hundreds of{' '}
                  <a href="/software/api/" title="Smartling APIs">
                    API endpoints
                  </a>
                </li>
              </ul>
              <p>
                These integrations connect you to the most powerful Cloud
                Translation Management System on the market. It’s the simplest
                place to configure automation rules, establish workflow
                preferences, maintain style guides, and more.
              </p>
            </div>
          </SectionCta.Description>
          <SectionCta.Buttons>
            <Button url="#b2bdemo" center primary>
              Get a Demo
            </Button>
            <Button url="/resources/101/global-ready-success-kit-2019/" outline>
              Free Success Kit
            </Button>
          </SectionCta.Buttons>
        </SectionCta>
        <SectionCta
          title="Rockstar. Translator."
          gradientPrimary
          curveTop
          centerCTA
          curveBottomCenter
        >
          <SectionCta.Description>
            <div className="u-spacing">
              <p className="u-font--m">
                Create electric content with Flavio, a translator by day and
                rockstar by night.
              </p>
            </div>
          </SectionCta.Description>
          <SectionCta.ImageLarge>
            <img
              loading="lazy"
              className="lazyload"
              data-src={sectionImage1}
              alt="Rockstar. Translator."
            />
          </SectionCta.ImageLarge>
          <SectionCta.Buttons>
            <Button url="/translators">Meet our translators</Button>
          </SectionCta.Buttons>
        </SectionCta>
        <SectionCta title="Anywhere, Today" centerCTA>
          <SectionCta.Description>
            Pick a playlist. Cars on demand. Book a flight. Swipe left - or
            right. We exist because our customers are the fastest growing, most
            innovative brands of our time. Our value proposition hinges on you
            being able to pre-configure, control, and adjust Smartling so that
            translation just happens in the background while you remain focused
            on building the best in class product in your category.
          </SectionCta.Description>
          <SectionCta.Video>
            <iframe
              title="Video"
              src="https://player.vimeo.com/video/260429276?title=0&byline=0&portrait=0"
              frameborder="0"
              width="100%"
              height="auto"
            />
          </SectionCta.Video>
          <SectionCta.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => {
                return (
                  <Button
                    url={button.buttonLink}
                    target={button.newWindow ? `_blank` : `_self`}
                  >
                    {button.buttonText ? button.buttonText : `Learn More`}
                  </Button>
                )
              })}
          </SectionCta.Buttons>
        </SectionCta>
      </article>
    </div>
  </Layout>
)

export default SummerTemplate

export const pageQuery = graphql`
  query SummerTemplateQuery {
    page: contentfulBasicPage(path: { eq: "summer" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
