import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/zendesk-integration-logo.png'
import imageIntegration from '../../../images/smartling-zendesk-integration.png'
import imageRocket from '../../../images/icons/rocket-no-shadow.svg'
import imageCatTool from '../../../images/product-illustration_CATtool-eiffel.gif'
import imageCatTool2 from '../../../images/product-illustration_CATtool-runner.gif'
import imageTranslation from '../../../images/human-translation.png'

var imgStyle = {
  maxWidth: '500px',
}

class ZendeskIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Zendesk Language Translation Integration | Smartling"
          description="Translate Zendesk help centers with Smartling’s Zendesk language translation integration. Discover how Smartling makes Zendesk translation turnkey."
          keywords=""
          image={heroImage}
          alt="Zendesk Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Zendesk Translation Integration"
          >
            <Hero.Description>
              Smartling’s Zendesk connector enables you to continuously
              translate help center content without leaving Zendesk.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Zendesk logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Help! How do I translate my help center?
                    </h3>
                    <p>
                      Zendesk enables your ability to support, sell and engage
                      customers. Smartling makes it possible for you to deliver
                      amazing service worldwide through language translation.
                    </p>
                    <p>
                      Smartling’s integration is configured within minutes. An
                      API token exchange installs a Smartling widget within
                      Zendesk so that you can easily submit content for
                      translation into any language without having to leave
                      Zendesk.
                    </p>
                    <p>
                      No more copy-paste of Zendesk content into a spreadsheet
                      or GoogleDoc that’s circulated to translation agencies.
                      With Smartling’s Zendesk integration, you’re submitting
                      content to a known translator through our translation
                      management system, and the entire process surrounding
                      translation is automated.
                    </p>
                    <p>
                      When worldwide customers land on your help center, speak
                      in their language with Smartling.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageIntegration}
                      alt="Zendesk integration"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      How Monese leveraged MT and Zendesk to replace the need
                      for 24/7 Support
                    </h3>
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                      <iframe
                        src="https://player.vimeo.com/video/697539399?h=d4f124408c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style="position:absolute;top:0;left:0;width:100%;height:100%;"
                        title="How Monese Saved Thousands &amp;amp; Improved Speed | Global Ready Conference 2022"
                      ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageRocket}
                      alt="Rocket"
                      style={{ maxWidth: '250px' }}
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Multiple Zendesk Content Types Supported
                    </h3>

                    <p>
                      Smartling’s Zendesk language translation integration
                      enables the seamless translation and localization of
                      multiple content types, including:
                      <ul className="o-list--checked o-list--checked--left">
                        <li>Categories</li>
                        <li>Sections</li>
                        <li>Articles</li>
                        <li>Dynamic Content</li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Localize Images
                    </h3>
                    <p>
                      When you want to display different images on your
                      translated websites, Smartling’s Zendesk translation
                      integration enables you to easily localize images -
                      oftentimes screenshots of your own product with text
                      overlays - to make the end-user experience native to every
                      person.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageCatTool}
                      alt="CAT Tool demo"
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageCatTool2}
                      alt="Cat Tool demo"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Provide Translators with Dynamic Context
                    </h3>
                    <p>
                      Smartling automatically ingests screenshots of your
                      Zendesk experience so that Smartling’s professional
                      translators can make better decisions about how to
                      translate the content. The WYSIWIG interface ensures
                      translation accuracy and eliminates mistakes that are
                      commonplace with documentation, like technical product
                      names, buttons and in-platform actions that are familiar
                      to your users.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Machine translation for Zendesk
                    </h3>
                    <p>
                      Translating your help center is not a small task. And if
                      you’re hosting hundreds or thousands of articles in
                      Zendesk, it may not be necessary or cost effective to
                      translate all of the content, or to use a human translator
                      for all of your content. Smartling’s Zendesk integration
                      enables you to choose exactly what content you want to
                      translate, and how you want it to be translated, i.e. by
                      human or machine.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageTranslation}
                      alt="Human Translation"
                    />
                  </div>
                </div>
              </div>
            </section>
            <Section centerCTA>
              <Section.Header>
                <Block title="Help is on the way.">
                  <Block.Image>
                    <Icons.IconIntegrationCircleLight xxxl />
                  </Block.Image>
                  Smartling’s Zendesk translation integration helps customers
                  translate help content. What can else we say? We like to help!
                </Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default ZendeskIntegrationPage
