import React from 'react'
import Metadata from '../../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import BlockRow from 'molecules/block-row'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

// Images
import personTrinh from '../../images/automate/person-trinh.png'
import heroImage from '../../images/illustrations/analytics.svg'
import imageAnalytics from '../../images/illustrations/illustration_analytics-condensed.png'
import imageTeams from '../../images/illustrations/Teams_Marketing.png'
import imageMgmt from '../../images/illustrations/illustration_project-mgmt-condensed.png'
import iconAccuracy from '../../images/icons/icon_accuracy-dark.svg'
import iconDialpad from '../../images/automate/dialpad-logo.png'
import iconAnalytics from '../../images/icons/icon_analytics-light-ring.svg'
import iconQuality from '../../images/icons/icon_quality-confidence-score-light-ring.svg'
import iconChecklist from '../../images/icons/icon_checklist-light-ring.svg'
import iconRocket from '../../images/icons/icon_rocket-dark.svg'
import uiCosts from '../../images/ui/cost estimates.png'
import uiQuality from '../../images/ui/product-illustration_smartling-UI-qualityscore-white.png'

class AnalyzePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="AI-Driven Translation Management Platform | Smartling"
          description="The Smartling translation management platform leverages machine learning to measure and improve translation quality and performance with real-time analytics."
          keywords="Translation measurement, Translation quality metrics"
        />
        <Hero
          curveBottom
          gradientPrimary
          title="Translate content. Measure results."
        >
          <Hero.Description>
            Smartling’s data-driven approach to localization gives your team a
            shared framework for measuring, communicating and improving
            translation performance.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Analyze" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Get a Demo</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article l-analytics u-spacing--sections">
          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Translation measurement starts in the cloud.
                  </h3>
                  <p>
                    Centralizing your translations into a single platform does
                    more than simplify the process of end-to-end management. It
                    enables you to capture data and insights into your
                    translation program that will propel your team to release
                    new content faster.
                  </p>
                  <p>
                    For some, it will unlock ways to significantly alter a
                    workflow, and for others it will unlock foundational
                    information that guides your budgeting, resource management
                    and return on investment.
                  </p>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img loading="lazy" src={imageAnalytics} alt="Analytics" />
                </div>
              </div>
            </div>
          </section>

          <Section centerCTA>
            <Section.Header>
              <Block title="Translation Quality Metrics">
                <Block.Image />
              </Block>
            </Section.Header>
            <Section.Inner l>
              <div className="l-grid l-grid--3x3">
                {/* one */}
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img loading="lazy" src={iconAnalytics} alt="Analytics" />
                  </div>
                </div>
                <div className="l-grid-area l-grid-area--b1">
                  <h3 class="u-color--primary">Workflow Reporting</h3>
                </div>
                <div className="l-grid-area l-grid-area--c1">
                  <p>
                    Actionable data insights to optimize your translation
                    process.
                  </p>
                </div>

                {/* two */}
                <div className="l-grid-area l-grid-area--a2">
                  <div className="l-grid-area l-grid-area--a1">
                    <div className="l-grid__item-image">
                      <img
                        loading="lazy"
                        src={iconQuality}
                        alt="Quality Score"
                      />
                    </div>
                  </div>
                </div>
                <div className="l-grid-area l-grid-area--b2">
                  <h3 class="u-color--primary">Quality Confidence Score</h3>
                </div>
                <div className="l-grid-area l-grid-area--c2">
                  <p>
                    The first predictive, machine learning-powered measure of
                    translation quality.
                  </p>
                </div>

                {/* three */}
                <div className="l-grid-area l-grid-area--a3">
                  <div className="l-grid-area l-grid-area--a1">
                    <div className="l-grid__item-image">
                      <img loading="lazy" src={iconChecklist} alt="Checklist" />
                    </div>
                  </div>
                </div>
                <div className="l-grid-area l-grid-area--b3">
                  <h3 class="u-color--primary">
                    Linguistic Quality Assurance (LQA)
                  </h3>
                </div>
                <div className="l-grid-area l-grid-area--c3">
                  <p>
                    Evaluate translation quality with customizable error
                    typologies.
                  </p>
                </div>
              </div>
            </Section.Inner>
          </Section>

          <div className="u-gradient--primary u-curve--top--left u-curve--bottom--center u-padding--section">
            <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
              <Section.Header>
                <div class="c-block undefined u-spacing">
                  <div class="c-block__text u-spacing">
                    <div class="u-spacing">
                      <h3 class="c-block__title u-text-align--left">
                        Why Translation Quality Metrics Matter
                      </h3>
                      <div class="c-block__description u-text-align--left">
                        Today’s consumer experience is either entirely digital,
                        or complimented by digital content. Words in your app,
                        your website, shopping cart or your help center engage
                        users, and to be competitive on the global scale, these
                        words have to be translated. With more content being
                        created and translated, the margin for error and gain
                        also increases. Smartling measures this margin, and
                        simplifies your ability to manage translation at scale.
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Header>
            </section>
          </div>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Identify and remove translation bottlenecks.
                  </h3>
                  <p>
                    Workflow analytics illuminate the components of your
                    translation process that are positively or negatively
                    impacting quality, time-to-market and overhead.{' '}
                  </p>
                  <a
                    href="/resources/product/save-time-and-money-with-smartlings-translation-workflow-reports/"
                    class="o-link"
                  >
                    Learn More
                  </a>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img
                    loading="lazy"
                    src={imageMgmt}
                    alt="Project Management"
                  />
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="c-product-intro-image">
                  <img loading="lazy" src={uiCosts} alt="Costs" />
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Understand your translation costs.
                  </h3>
                  <p>
                    With cost estimates and translation savings reports, you can
                    stay within budget and track the effectiveness of your
                    translation memory over time.{' '}
                  </p>
                  <a
                    href="/resources/101/choosing-a-language-services-provider-webinar/"
                    class="o-link"
                  >
                    How to Select an LSP
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    A common language for quality.
                  </h3>
                  <p>
                    Imagine an algorithm designed to compute billions of data
                    points related to translation quality. What impact would
                    this have on understanding and managing translation quality
                    at scale? We did. And then, we built it.
                  </p>
                  <p>
                    Quality Confidence Score™ provides a real-time view of
                    translation quality across your projects, languages, and
                    translation providers. It gives you a prioritized set of
                    actions that will have the most meaningful impact on
                    improving translation quality. For example, if a translation
                    job doesn’t have visual context, the score is lower, and the
                    advice is to provide context.{' '}
                  </p>
                  <a
                    href="/resources/101/webinar-machine-learning-going-beyond-machine-translation/"
                    class="o-link"
                  >
                    Discover Smartling's AI approach to managing quality
                  </a>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img loading="lazy" src={uiQuality} alt="Quality Score" />
                </div>
              </div>
            </div>
          </section>

          <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="c-product-intro-image">
                  <img loading="lazy" src={imageTeams} alt="Marketing Teams" />
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Quality Evaluations done your way.
                  </h3>
                  <p>
                    LQA is a human-driven process that facilitates the objective
                    evaluation of translation quality. Smartling's LQA tools let
                    you customize the error schema and configure where in your
                    workflow you want to review translations. The data generated
                    from these reviews can be used for strategic decision making
                    and to measure whether service level agreements are
                    satisfied.
                  </p>
                  <a
                    href="https://help.smartling.com/hc/en-us/articles/1260806295070"
                    class="o-link"
                  >
                    Discover LQA
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div className="u-background-accent--left">
            <Section className="c-section__block-rows c-block-rows">
              <Section.Header>
                <Block title="Big data. Simple analysis.">
                  <Block.Image>
                    <span class="u-icon u-icon--xxl">
                      <img
                        loading="lazy"
                        src={iconAccuracy}
                        className=""
                        alt="arrow"
                      />
                    </span>
                  </Block.Image>
                  Smartling enables you to get a birds eye view of your supply
                  chain and your costs with on-demand reports built into the
                  platform.
                  <section
                    class="o-section c-section__cta c-cta c-cta--center undefined"
                    data-bgset=""
                  >
                    <div class="o-section--inner l-wrap l-container--l">
                      <div class="c-cta__content u-spacing"></div>
                      <div class="c-cta__buttons">
                        <a
                          class="o-button--wide o-button o-button--skinny o-button--no-shadow"
                          href="https://help.smartling.com/hc/en-us/sections/115000722854-Reporting"
                          rel="noopener noreferrer"
                          target="_blank"
                          role="button"
                        >
                          View All Reports
                        </a>
                      </div>
                    </div>
                  </section>
                </Block>
              </Section.Header>
              <Section.Inner m className="u-spacing--quad">
                <BlockRow>
                  <BlockRow.Image />
                  <h4 className="u-color--primary">Workflow Velocity</h4>
                  <p>
                    How much time is content spending in each stage of your
                    translation workflow?
                  </p>
                </BlockRow>
                <BlockRow>
                  <BlockRow.Image />
                  <h4 className="u-color--primary">Workflow Changes</h4>
                  <p>
                    How much value is each workflow step adding towards
                    improving translation quality?
                  </p>
                </BlockRow>
                <BlockRow>
                  <BlockRow.Image />
                  <h4 className="u-color--primary">Cost Savings</h4>
                  <p>How much money was saved with translation memory?</p>
                </BlockRow>
                <BlockRow>
                  <BlockRow.Image />
                  <h4 className="u-color--primary">Cost Estimates</h4>
                  <p>
                    How much money will it cost to translate a project,
                    including savings from your translation memory?
                  </p>
                </BlockRow>
              </Section.Inner>
            </Section>
          </div>

          <SectionCta
            title="Results-oriented Customer Engagement"
            gradientSecondary
            curveTop
            centerCTA
            curveBottomCenter
          >
            <SectionCta.Description>
              Best practices. Digital transformation. Strategy alignment. A
              dedicated Customer Success and Account Manager are focused on
              achieving your business objectives.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="/software/translation-management-system/onboarding-enablement-services/">
                Customer Experience
              </Button>
              <Button url="/excellence-every-touchpoint">
                Excellence at Every Touchpoint
              </Button>
            </SectionCta.Buttons>
          </SectionCta>

          <Section className="c-feature-plans">
            <Section.Header>
              <div class="c-block__image">
                <span class="u-icon u-icon--xxl">
                  <img loading="lazy" alt="" src={iconRocket} />
                </span>
              </div>
              <Block title="How translation analytics have impacted customers">
                The insights gained by customers using Smartling analytics have
                resulted in a wide range of optimizations to their translation
                programs, including:
              </Block>
            </Section.Header>
            <Section.Inner l>
              <div className="c-card c-card--cols c-card--full-height">
                <div className="l-grid l-grid--2x1 u-text-align--left u-s">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1 u-spacing--quad">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Changes to translation workflows and resources
                      </li>
                    </ul>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Centralization of all content to Smartling
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="l-grid l-grid--2x1 u-text-align--left u-s">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1 u-spacing--quad">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Enhanced configurations to maximize visual context
                        coverage
                      </li>
                    </ul>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Segmentation of content to specific workflows to control
                        costs
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="l-grid l-grid--2x1 u-text-align--left u-s">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1 u-spacing--quad">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Sprints to improve glossaries and style guides
                      </li>
                    </ul>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Introduction of translation job automation to reduce
                        project management
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="l-grid l-grid--2x1 u-text-align--left u-s">
                  {/* one */}
                  <div className="l-grid-area l-grid-area--a1 u-spacing--quad">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Reprogramming of translation memory leverage settings
                      </li>
                    </ul>
                  </div>

                  {/* two */}
                  <div className="l-grid-area l-grid-area--a2">
                    <ul class="o-list--checked o-list--checked--left">
                      <li class="u-space--double">
                        Simplified process for reporting back to the business on
                        return on investment
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Section.Inner>
          </Section>

          <div className="u-gradient--tertiary u-spacing--double">
            <div class="o-section--inner l-wrap l-container--l u-spacing">
              <div class="c-cta__content u-center-block  u-text-align--center">
                <h2 class="c-cta__title">
                  The result? A “game-changing” approach to managing
                  translation.
                </h2>
              </div>
            </div>
            <div class="c-section-container c-section-container--white">
              <section class="o-section--inner l-wrap l-container--xl">
                <div class="u-spacing--sections">
                  <div class="c-quote-box">
                    <div class="u-spacing--double">
                      <div class="u-flex c-quote-top">
                        <img
                          loading="lazy"
                          src={personTrinh}
                          alt="Trinh Nguyen"
                        />
                        <div class="c-quote-attribute">
                          <img
                            loading="lazy"
                            src={iconDialpad}
                            alt="Dialpad Icon"
                          />
                          <div>
                            <strong>Trinh Nguyen</strong>
                          </div>
                          <div>Project Manager, Dialpad</div>
                        </div>
                      </div>
                      <div class="o-section-title o-section-title--small c-quote o-section-title--medium">
                        The vital role that Smartling plays in my day-to-day is
                        efficiency. Now that I've mastered the platform and have
                        automated most of our translation process, I'm able to
                        spend my time on my main tasks at hand. It's a real
                        game-changer.
                      </div>
                      <a
                        href="/resources/case-study/dialpad/"
                        class="o-button o-button--wide o-button--no-shadow"
                      >
                        Read the Case Study
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionCta title="Join the Ranks.">
              <SectionCta.Description>
                Smartling is the #1 rated Translation Management System by users
                on G2.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="https://www.g2.com/products/smartling/reviews">
                  Read the Reviews
                </Button>
              </SectionCta.Buttons>
            </SectionCta>
          </div>

          <SectionCta
            centerCTA
            title="Ready to get unparalleled translation analytics?"
          >
            <SectionCta.Description>
              The best way to understand Smartling’s ability to transform your
              translation program with analysis is to see the platform for
              yourself.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="/meeting-request/">Book a Demo</Button>
            </SectionCta.Buttons>
          </SectionCta>
        </article>
      </Layout>
    )
  }
}

export default AnalyzePage
