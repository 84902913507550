import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Block from 'molecules/block'
import Grid from 'atoms/grid'
import BlockPost from 'molecules/block-post'

// Sections
import Section from 'atoms/section'
import FilterNavigation from 'organisms/filter-navigation'

import Layout from 'layouts'

import MetaDataImage from '../images/meta-image.png'

const ResourceCategoryTemplate = ({ data, error }) => {
  // Get the slug of the featured post
  const featuredSlug = data.featured?.edges[0]?.node.featuredResource?.slug

  // Filter out the featured post from the list of all posts
  const nonFeaturedPosts = data.all.edges.filter(
    ({ node }) => node.slug !== featuredSlug
  )

  // Select the last two non-featured posts
  const mostRecent = nonFeaturedPosts.slice(0, 2)

  // Remaining posts excluding the most recent
  const remaining = nonFeaturedPosts.slice(2)

  return (
    <Layout>
      <Metadata
        title={data.page.metadataTitle || `${data.page.name} - Smartling`}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={MetaDataImage}
        noindex
      />
      <Section
        gradientSecondary
        curveBottom
        className="c-hero__header c-hero__resource-category u-padding--section"
      >
        <Section.Header>
          <Block title={data.page.name}>
            <Block.Image />
            {data.page.description}
          </Block>
        </Section.Header>
        <Section.Inner xl className="u-spacing--quad">
          <FilterNavigation />
          <Grid
            twoUp
            className="l-grid__resources-featured c-resources__featured"
          >
            {data.featured?.edges.map(
              ({ node }) =>
                node.featuredResource && (
                  <Grid.Item key={node.featuredResource.slug}>
                    <div className="c-resources__blocks-featured u-spacing--double">
                      <React.Fragment>
                        <h5>Featured</h5>
                        <BlockPost {...node.featuredResource} />
                      </React.Fragment>
                    </div>
                  </Grid.Item>
                )
            )}
            {mostRecent.length > 0 && (
              <Grid.Item>
                <div className="c-resources__blocks-most-recent u-spacing--double">
                  <h5>Most Recent</h5>
                  {mostRecent.map(({ node }) => (
                    <BlockPost key={node.slug} {...node} />
                  ))}
                </div>
              </Grid.Item>
            )}
          </Grid>
        </Section.Inner>
      </Section>
      <article className="l-article u-spacing--quad u-space--quad--top">
        <Section>
          <Section.Inner xl>
            {remaining.length > 0 && (
              <Grid threeUp className="c-resources">
                {remaining.map(({ node }) => (
                  <Grid.Item key={node.slug}>
                    <BlockPost {...node} />
                  </Grid.Item>
                ))}
              </Grid>
            )}
          </Section.Inner>
        </Section>
      </article>
    </Layout>
  )
}

export default ResourceCategoryTemplate

export const query = graphql`
  query ResourceCategoryTemplateQuery($key: String!) {
    page: contentfulResourceCategory(key: { eq: $key }) {
      name
      key
      description
      metadataTitle
      metadataDescription
      metadataKeywords
    }
    featured: allContentfulResourceCategory(
      limit: 1
      filter: { key: { eq: $key } }
    ) {
      ...ContentfulResourceCategoryFragment
    }
    all: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { categories: { key: { eq: $key } } }
    ) {
      ...ContentfulResourceFragment
    }
  }
`
