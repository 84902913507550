import React from 'react'

const PodcastSubscribe = ({ className, longDesc, subscribeLinks }) => {
  function makeClassName(string) {
    return string.replace(/\s/g, '-').toLowerCase()
  }
  return (
    <div className={`c-podcast-subscribe ${className ? className : ''}`}>
      {longDesc && (
        <p className="u-color--primary u-font--l">
          <strong>
            Subscribe to <em>{longDesc}</em>
          </strong>
        </p>
      )}
      {!longDesc}
      {subscribeLinks &&
        subscribeLinks.map((link, index) => (
          <>
            <a
              href={link.buttonLink ? link.buttonLink : ''}
              className={makeClassName(link.buttonText)}
            >
              {link.buttonText ? link.buttonText : ''}
            </a>
          </>
        ))}
    </div>
  )
}

export default PodcastSubscribe
