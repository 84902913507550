import React from 'react'
import Metadata from '../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import Card from 'molecules/card'

// Sections
import Hero from 'organisms/hero'
import SectionOnboarding from 'components/sections/onboarding'
import SectionProfessionalServices from 'components/sections/professional-services'
import SectionCustomerSuccess from 'components/sections/customer-success'
import SectionContact from 'components/sections/contact'
import SectionSupport from 'components/sections/support'
import SectionTranslationInterface from 'components/sections/translation-interface'
import SectionTranslationServices from 'components/sections/translation-services'

import Layout from 'layouts'

// Images
import heroImage from '../../images/illustrations/api.svg'

// Inline Styles
var imgStyle = {
  minWidth: '100%',
}

class ApiPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Translation Software API - Smartling"
          description="The Smartling API allows you to scale and automate your translation projects. Our translation software gives you real-time reporting with endless customization."
          keywords=""
        />

        <Hero
          curveLeft
          gradientSecondary
          title="Hundreds of API endpoints. Endless customizations."
        >
          <Hero.Description>
            Developers look to Smartling’s API service to seamlessly scale
            translation management for any application
          </Hero.Description>
          <Hero.Image>
            <img
              loading="lazy"
              style={imgStyle}
              src={heroImage}
              alt="Global Delivery Network"
            />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Get a Demo</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <Section className="c-section__grid-blocks c-grid-blocks u-background-accent--right">
            <Section.Header>
              <Block title="A robust API to meet your translation needs.">
                <Block.Image>
                  <Icons.HeaderOrganization xxl />
                </Block.Image>
                <h5 className="u-space--half--bottom">
                  The possibilities are endless. Configure the API to realize
                  agile human or machine translation.
                </h5>
                Complex solutions that feel refreshingly simple.
              </Block>
            </Section.Header>

            <Section.Inner m>
              <Grid threeUp className="has-icon-bulbs">
                <Grid.Item>
                  <Block title="REST APIs for scalable web services">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    The preferred API technology for connecting and interacting
                    with cloud-based software.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="SDKs for a head-start">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    SDKs for Java, Python, and PHP guide your integration from
                    the get-go. Build and launch your integration quickly.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="CLI for file management">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    Fast access to API endpoints from your terminal; open source
                    and{' '}
                    <a
                      href="https://github.com/Smartling/smartling-cli"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      available on GitHub
                    </a>
                    .
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="HTTPS requests structured in JSON">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    Smartling’s Translation API uses defined HTTPS requests to
                    ensure data privacy and structured{' '}
                    <a
                      href="https://help.smartling.com/hc/en-us/articles/360008000733-JSON"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      JSON
                    </a>{' '}
                    responses for smooth integration.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Scale project management">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    Automate and scale translation management for all content
                    types in any content systems.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Real-time status reporting">
                    <Block.Image>
                      <Icons.IconComputer />
                    </Block.Image>
                    Gain full visibility into the status of translation
                    projects.
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>
          <Section gradientTertiary curveTop centerCTA curveBottomCenter>
            <Section.Inner l>
              <Grid twoUp className="c-cards">
                <Grid.Item>
                  <Card title="Files">
                    <Card.Image />
                    Upload, download, and check the translation status of files
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Strings">
                    <Card.Image />
                    Submit and retrieve individual strings
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Jobs">
                    <Card.Image />
                    Create, authorize, and modify translation projects
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Context">
                    <Card.Image />
                    Upload visual context (images and HTML) with strings
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Issues">
                    <Card.Image />
                    Respond to source and translation issues
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Glossary">
                    <Card.Image />
                    <a
                      href="https://help.smartling.com/hc/en-us/articles/115003188994-Add-a-Term-to-a-Glossary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Add glossary
                    </a>{' '}
                    terms and translations, as well as importing and exporting
                    glossary files
                  </Card>
                </Grid.Item>
              </Grid>
              <div className="o-buttons u-text-align--center u-space--quad--top">
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  url="https://developer.smartling.com/docs/smartling-developer-center"
                >
                  Discover more API endpoints
                </Button>
              </div>
            </Section.Inner>
          </Section>

          <div className="u-gradient--primary u-curve--top--right u-curve--bottom--center u-padding--section">
            <SectionOnboarding />
          </div>
          <SectionProfessionalServices />

          <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionCustomerSuccess />
          </div>
          <SectionSupport />
          <div className="u-gradient--primary u-curve--top--left u-curve--bottom--center u-padding--section">
            <SectionTranslationServices />
          </div>
          <SectionTranslationInterface />
          <div className="u-gradient--tertiary u-curve--top">
            <SectionContact />
          </div>
        </article>
      </Layout>
    )
  }
}

export default ApiPage
