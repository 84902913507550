import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionSupport = () => (
  <SectionCta title="You have questions.<br />We have answers." centerCTA>
    <SectionCta.Description>
      A team of support experts, ready to assist you.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button target="_blank" url="http://help.smartling.com">
        View our Help Center
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionSupport
