import React from 'react'

const AutomateDiagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853.36 281.83">
    <defs>
      <clipPath id="a">
        <circle cx="74" cy="169.35" r="60" fill="none" />
      </clipPath>
      <clipPath id="b">
        <circle cx="300.74" cy="169.35" r="60" fill="none" />
      </clipPath>
      <linearGradient
        id="c"
        y1="34.37"
        x2="822.27"
        y2="34.37"
        gradientTransform="matrix(1, 0, 0, -1, 0, 243.12)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#f19375" />
        <stop offset="0.23" stop-color="#f28f7e" />
        <stop offset="0.61" stop-color="#f68396" />
        <stop offset="1" stop-color="#fb74b6" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="0"
        y1="113.28"
        x2="822.27"
        y2="113.28"
        xlinkHref="#c"
      />
      <linearGradient
        id="e"
        x1="0"
        y1="73.77"
        x2="822.27"
        y2="73.77"
        xlinkHref="#c"
      />
      <linearGradient
        id="f"
        x1="0"
        y1="73.78"
        x2="822.27"
        y2="73.78"
        xlinkHref="#c"
      />
      <clipPath id="i">
        <circle cx="526.01" cy="169.35" r="60" fill="none" />
      </clipPath>
      <linearGradient
        id="j"
        x1="-5657.58"
        y1="-231.35"
        x2="-5490.94"
        y2="-211.72"
        gradientTransform="matrix(0.71, -1, -0.79, -0.62, 4522.89, -5571.41)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0" />
        <stop offset="0.14" stop-color="#e9e4fa" stop-opacity="0.15" />
        <stop offset="0.3" stop-color="#d7cef6" stop-opacity="0.32" />
        <stop offset="0.48" stop-color="#cabef3" stop-opacity="0.5" />
        <stop offset="0.68" stop-color="#c2b5f2" stop-opacity="0.71" />
        <stop offset="0.96" stop-color="#c0b2f1" />
      </linearGradient>
      <linearGradient
        id="k"
        x1="-5821.53"
        y1="-392.12"
        x2="-5610.04"
        y2="-354.11"
        gradientTransform="matrix(0.68, -0.83, -0.76, -0.65, 4343.68, -4846.1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0" />
        <stop offset="0.13" stop-color="#ded3f7" stop-opacity="0.13" />
        <stop offset="0.27" stop-color="#bda6ef" stop-opacity="0.27" />
        <stop offset="0.42" stop-color="#a181e8" stop-opacity="0.42" />
        <stop offset="0.57" stop-color="#8b65e3" stop-opacity="0.57" />
        <stop offset="0.71" stop-color="#7c50df" stop-opacity="0.71" />
        <stop offset="0.86" stop-color="#7344dd" stop-opacity="0.86" />
        <stop offset="1" stop-color="#7040dc" />
      </linearGradient>
      <linearGradient
        id="l"
        x1="790.41"
        y1="218.01"
        x2="856.43"
        y2="218.01"
        gradientTransform="matrix(1, -0.01, -0.01, -1, -1.94, 268.12)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" stop-opacity="0" />
        <stop offset="0.85" stop-color="#d5e4ff" />
      </linearGradient>
    </defs>
    <title>automate-diagram</title>
    <circle cx="74" cy="169.35" r="60" fill="#fff" />
    <g clip-path="url(#a)">
      <polygon
        points="79.27 133.09 85.66 139.49 92.93 139.49 105.25 150.86 147.62 193.22 147.62 267.67 119.14 267.67 41.92 190.45 48.01 185.42 70.52 185.16 74 179.14 73.79 162.37 70.05 157.95 61.24 157.33 59.17 156.49 56.41 156.49 52.52 152.6 54.35 151.71 78.52 151.71 79.27 133.09"
        fill="#d5e4ff"
      />
    </g>
    <path
      d="M104.86,202.46H60.22V139.28H92a2.09,2.09,0,0,1,1.48.61l10.8,10.81a2.11,2.11,0,0,1,.61,1.48v50.28Z"
      fill="#fff"
    />
    <path
      d="M105.82,203.42H59.26v-65.1H92a3,3,0,0,1,2.16.9L104.92,150a3,3,0,0,1,.9,2.16ZM61.18,201.5H103.9V152.18a1.14,1.14,0,0,0-.33-.8l-10.8-10.81a1.14,1.14,0,0,0-.8-.33H61.18Z"
      fill="#56189e"
    />
    <path
      d="M76.9,146.48H67.66a1,1,0,1,1,0-1.92H76.9a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M76.9,150.51H67.66a1,1,0,1,1,0-1.92H76.9a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M96.94,168.3H68.62a1,1,0,1,1,0-1.92H96.94a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M96.94,173.76H68.62a1,1,0,0,1,0-1.92H96.94a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M96.94,179.21H68.62a1,1,0,0,1,0-1.92H96.94a1,1,0,0,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M96.94,184.66H68.62a1,1,0,0,1,0-1.92H96.94a1,1,0,0,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M96.94,190.11H68.62a1,1,0,1,1,0-1.92H96.94a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <polyline points="92.05 139.28 92.05 151.77 104.38 151.77" fill="#fff" />
    <path
      d="M104.38,152.73H91.09V139.28a1,1,0,0,1,1.92,0v11.53h11.37a1,1,0,1,1,0,1.92Z"
      fill="#56189e"
    />
    <path
      d="M48.47,185.37H67.21A6.81,6.81,0,0,0,74,178.56V164.15a6.81,6.81,0,0,0-6.81-6.81H39.66a6.81,6.81,0,0,0-6.81,6.81v14.41a6.81,6.81,0,0,0,6.81,6.81h2.27v5.07Z"
      fill="#fff"
    />
    <path
      d="M41,192.4v-6.07H39.66a7.78,7.78,0,0,1-7.77-7.77V164.15a7.78,7.78,0,0,1,7.77-7.77H67.21A7.78,7.78,0,0,1,75,164.15v14.41a7.78,7.78,0,0,1-7.77,7.77H48.8Zm-1.31-34.11a5.86,5.86,0,0,0-5.85,5.85v14.41a5.86,5.86,0,0,0,5.85,5.85h3.23v4.07l5.26-4.07H67.22a5.86,5.86,0,0,0,5.85-5.85V164.14a5.85,5.85,0,0,0-5.85-5.85Z"
      fill="#56189e"
    />
    <circle cx="43.52" cy="171.15" r="2.04" fill="#fff" />
    <path
      d="M43.52,174.14a3,3,0,1,1,3-3A3,3,0,0,1,43.52,174.14Zm0-4.07a1.08,1.08,0,1,0,1.08,1.08A1.08,1.08,0,0,0,43.52,170.07Z"
      fill="#56189e"
    />
    <circle cx="52.95" cy="171.15" r="2.04" fill="#fff" />
    <path
      d="M53,174.14a3,3,0,1,1,3-3A3,3,0,0,1,53,174.14Zm0-4.07A1.08,1.08,0,1,0,54,171.15,1.08,1.08,0,0,0,53,170.07Z"
      fill="#56189e"
    />
    <circle cx="62.37" cy="171.15" r="2.04" fill="#fff" />
    <path
      d="M62.37,174.14a3,3,0,1,1,3-3A3,3,0,0,1,62.37,174.14Zm0-4.07a1.08,1.08,0,1,0,1.08,1.08A1.08,1.08,0,0,0,62.37,170.07Z"
      fill="#56189e"
    />
    <rect x="53.44" y="134.12" width="25.08" height="17.64" fill="#fff" />
    <polyline points="68.61 148.63 62.08 138.47 53.88 151.23" fill="#fff" />
    <path
      d="M53.88,152.19a1,1,0,0,1-.52-.15,1,1,0,0,1-.29-1.33l9-14,7.34,11.42a1,1,0,0,1-1.62,1l-5.72-8.9-7.39,11.5A1,1,0,0,1,53.88,152.19Z"
      fill="#56189e"
    />
    <polyline points="77.73 151.71 71.99 144.18 66.26 151.71" fill="#fff" />
    <path
      d="M66.26,152.67a.91.91,0,0,1-.58-.2,1,1,0,0,1-.18-1.35l6.5-8.53,6.5,8.53A1,1,0,0,1,77,152.29l-5-6.52-5,6.52A1,1,0,0,1,66.26,152.67Z"
      fill="#56189e"
    />
    <path
      d="M79.48,152.73h-27V133.16h27ZM54.4,150.81H77.56V135.09H54.4Z"
      fill="#56189e"
    />
    <circle cx="300.74" cy="169.35" r="60" fill="#fff" />
    <g clip-path="url(#b)">
      <path
        d="M304.2,160.79l4.7-10.21,23.73-10.34,36.29,36.29L373.5,195,355,213.45c-4,4-50.65-50.65-50.65-50.65M351,219.63l-49-49L270.8,201.87S301,232.54,301,232s63.64,0,63.64,0Z"
        fill="#d5e4ff"
      />
    </g>
    <path
      d="M301.94,195.59a4.5,4.5,0,0,0-4.44-1.76.42.42,0,0,1-.4-.12h0a.45.45,0,0,1,0-.63l6.95-7a.53.53,0,0,0,0-.74l-6.81-6.81a.45.45,0,0,1,0-.63h0a.44.44,0,0,1,.4-.12,4.52,4.52,0,1,0-3.64-3.64.42.42,0,0,1-.12.4h0a.45.45,0,0,1-.63,0l-7.6-7.6a.53.53,0,0,0-.74,0l-7,6.95a.45.45,0,0,0,0,.63h0a.44.44,0,0,0,.4.12,4.52,4.52,0,1,1-3.64,3.64.42.42,0,0,0-.12-.4h0a.45.45,0,0,0-.63,0l-6.84,6.84a.53.53,0,0,0,0,.74l7.6,7.6a.45.45,0,0,1,0,.63h0a.44.44,0,0,1-.4.12A4.52,4.52,0,1,0,278,197.5a.42.42,0,0,1,.12-.4h0a.45.45,0,0,1,.63,0l6.81,6.81a.53.53,0,0,0,.74,0l6.84-6.84a.45.45,0,0,1,.63,0h0a.44.44,0,0,1,.12.4,4.52,4.52,0,1,0,8.08-1.88Z"
      fill="#fff"
    />
    <line x1="282.8" y1="200.43" x2="288.07" y2="195.16" fill="none" />
    <line x1="290.43" y1="192.8" x2="289.93" y2="193.3" fill="none" />
    <path
      d="M326,138.82a4.5,4.5,0,0,0-1.76,4.44.42.42,0,0,1-.12.4h0a.45.45,0,0,1-.63,0l-6.95-6.95a.53.53,0,0,0-.74,0L309,143.52a.45.45,0,0,1-.63,0h0a.44.44,0,0,1-.12-.4,4.52,4.52,0,1,0-3.64,3.64.42.42,0,0,1,.4.12h0a.45.45,0,0,1,0,.63l-7.6,7.6a.53.53,0,0,0,0,.74l6.95,7a.45.45,0,0,0,.63,0h0a.44.44,0,0,0,.12-.4,4.52,4.52,0,1,1,3.64,3.64.42.42,0,0,0-.4.12h0a.45.45,0,0,0,0,.63l6.84,6.84a.53.53,0,0,0,.74,0l7.6-7.6a.45.45,0,0,1,.63,0h0a.44.44,0,0,1,.12.4,4.52,4.52,0,1,0,3.64-3.64.42.42,0,0,1-.4-.12h0a.45.45,0,0,1,0-.63l6.81-6.81a.53.53,0,0,0,0-.74l-6.84-6.84a.45.45,0,0,1,0-.63h0a.44.44,0,0,1,.4-.12,4.52,4.52,0,1,0-1.88-8.08Z"
      fill="#fff"
    />
    <line x1="330.86" y1="157.96" x2="325.59" y2="152.69" fill="none" />
    <line x1="323.23" y1="150.33" x2="323.74" y2="150.83" fill="none" />
    <path
      d="M285.91,205a1.44,1.44,0,0,1-1-.43L279,198.73a5.48,5.48,0,1,1-5.88-5.87l-6.65-6.65a1.47,1.47,0,0,1,0-2.09l6.84-6.84a1.39,1.39,0,0,1,2,0,1.41,1.41,0,0,1,.39,1.25,3.56,3.56,0,1,0,2.86-2.85,1.43,1.43,0,0,1-1.25-.38,1.39,1.39,0,0,1,0-2l6.95-6.95a1.51,1.51,0,0,1,2.09,0L293,173a5.48,5.48,0,1,1,5.88,5.87l5.86,5.86a1.5,1.5,0,0,1,0,2.1l-6,6a5.47,5.47,0,1,1-5.89,5.87L287,204.62A1.63,1.63,0,0,1,285.91,205Zm-7.49-9a1.4,1.4,0,0,1,1,.41l6.5,6.5,6.53-6.53a1.39,1.39,0,0,1,1-.41h0a1.41,1.41,0,0,1,1,.41,1.43,1.43,0,0,1,.39,1.25,3.55,3.55,0,1,0,6.36-1.46h0a3.56,3.56,0,0,0-3.5-1.39,1.4,1.4,0,0,1-1.25-2.37l6.64-6.64-6.5-6.5a1.4,1.4,0,0,1,0-2,1.44,1.44,0,0,1,1.25-.39,3.55,3.55,0,0,0,3.42-5.7,3.55,3.55,0,0,0-5.3-.3,3.56,3.56,0,0,0-1,3.15,1.4,1.4,0,0,1-2.37,1.25L285.29,168l-5.69,5.69a5.47,5.47,0,1,1-5.88,5.87l-5.58,5.58,7.29,7.29a1.4,1.4,0,0,1,0,2,1.43,1.43,0,0,1-1.25.39,3.55,3.55,0,0,0-3.42,5.7,3.55,3.55,0,0,0,5.3.3,3.56,3.56,0,0,0,1-3.15,1.41,1.41,0,0,1,1.38-1.66Zm15.38,1.74Z"
      fill="#56189e"
    />
    <path
      d="M282.8,201.39a1,1,0,0,1-.68-1.64l5.27-5.27a1,1,0,0,1,1.36,1.36l-5.27,5.27A.94.94,0,0,1,282.8,201.39Z"
      fill="#56189e"
    />
    <path
      d="M289.93,194.26a1,1,0,0,1-.68-1.64l.5-.5a1,1,0,0,1,1.36,1.36l-.5.5A1,1,0,0,1,289.93,194.26Z"
      fill="#56189e"
    />
    <path
      d="M315.57,174.73a1.47,1.47,0,0,1-1.05-.43l-6.84-6.84a1.39,1.39,0,0,1,0-2,1.45,1.45,0,0,1,1.25-.39,3.56,3.56,0,1,0-2.85-2.86,1.41,1.41,0,0,1-1.38,1.66h0a1.41,1.41,0,0,1-1-.41l-6.95-6.95a1.47,1.47,0,0,1,0-2.09l6.66-6.66a5.47,5.47,0,0,1-5.05-5.76,5.55,5.55,0,0,1,2.06-4,5.47,5.47,0,0,1,8.86,3.86l5.86-5.86a1.51,1.51,0,0,1,2.09,0l6,6a5.48,5.48,0,0,1,2.2-4h0a5.47,5.47,0,1,1,3.67,9.86L335,153.8a1.47,1.47,0,0,1,0,2.09l-5.86,5.86a5.48,5.48,0,1,1-5.87,5.88l-6.66,6.65A1.41,1.41,0,0,1,315.57,174.73ZM310,167.05l5.58,5.58,7.29-7.29a1.4,1.4,0,0,1,2.37,1.25,3.55,3.55,0,0,0,5.7,3.42,3.55,3.55,0,0,0,.3-5.3,3.56,3.56,0,0,0-3.15-1,1.4,1.4,0,0,1-1.25-2.37l6.5-6.5-6.53-6.53a1.39,1.39,0,0,1,0-2,1.43,1.43,0,0,1,1.25-.39,3.56,3.56,0,1,0-2.85-2.86,1.4,1.4,0,0,1-.39,1.25,1.37,1.37,0,0,1-1,.41h0a1.39,1.39,0,0,1-1-.41l-6.64-6.64-6.5,6.5a1.4,1.4,0,0,1-2.36-1.25,3.55,3.55,0,0,0-5.7-3.42,3.55,3.55,0,0,0-.3,5.3,3.55,3.55,0,0,0,3.15,1,1.43,1.43,0,0,1,1.25.38,1.39,1.39,0,0,1,0,2l-7.3,7.3,5.69,5.69a5.47,5.47,0,1,1,9.32,4.31A5.54,5.54,0,0,1,310,167.05Zm-1.4-.07Zm-3.79-19.28ZM324.2,143Z"
      fill="#56189e"
    />
    <path
      d="M330.86,158.92a.94.94,0,0,1-.68-.28l-5.27-5.27a1,1,0,0,1,1.36-1.36l5.27,5.27a1,1,0,0,1-.68,1.64Z"
      fill="#56189e"
    />
    <path
      d="M323.74,151.79a.94.94,0,0,1-.68-.28l-.5-.5a1,1,0,1,1,1.36-1.36l.5.5a1,1,0,0,1-.68,1.64Z"
      fill="#56189e"
    />
    <path
      d="M427.24,174.16a.12.12,0,0,0-.18.1v4H370.2l-.26,1.7a70,70,0,0,1-138.07,1.95,2,2,0,1,0-3.94.66,74,74,0,0,0,145.7-.3h53.44v3.69a.12.12,0,0,0,.18.1l10.1-5.83a.12.12,0,0,0,0-.21Z"
      fill="url(#c)"
    />
    <path
      d="M427.24,152.45a.12.12,0,0,0-.18.1v3.92H373.62a74,74,0,0,0-145.71-.3,2,2,0,1,0,3.94.66,70,70,0,0,1,138.08,1.95l.26,1.7h56.86v3.74a.12.12,0,0,0,.18.1l10.1-5.83a.12.12,0,0,0,0-.21Z"
      fill="url(#d)"
    />
    <path
      d="M202.68,164.47l10.1-5.83a.12.12,0,0,0,0-.21l-10.1-5.83a.12.12,0,0,0-.18.1v3.77H146.88a74,74,0,1,0,0,25.77H202.5v3.83a.12.12,0,0,0,.18.1l10.1-5.83a.12.12,0,0,0,0-.21l-10.1-5.83a.12.12,0,0,0-.18.1v3.83h-59l-.26,1.7a70,70,0,1,1,0-21.16l.26,1.7h59v3.89A.13.13,0,0,0,202.68,164.47Z"
      fill="url(#e)"
    />
    <path
      d="M808.83,126.93a1.88,1.88,0,0,0-2.8-.34l-.07.06a1.92,1.92,0,0,0-.42,2.59,69.45,69.45,0,0,1,12.56,44.92,70,70,0,1,1-69.84-74.82q2.94,0,5.82.26A1.92,1.92,0,0,0,756.16,98a.06.06,0,0,1,0,0,2,2,0,0,0-1.77-2.39c-2-.18-4.07-.27-6.13-.27a74.19,74.19,0,1,0,60.56,31.59Z"
      fill="url(#f)"
    />
    <path
      d="M652.7,174.16a.12.12,0,0,0-.18.1v4h-57l-.26,1.7a70,70,0,0,1-138.07,1.95,2,2,0,1,0-3.94.66,74,74,0,0,0,145.7-.3h53.63v3.69a.12.12,0,0,0,.18.1l10.1-5.83a.12.12,0,0,0,0-.21Z"
      fill="url(#c)"
    />
    <path
      d="M652.7,152.45a.12.12,0,0,0-.18.1v3.92H598.89a74,74,0,0,0-145.71-.3,2,2,0,1,0,3.94.66,70,70,0,0,1,138.08,1.95l.26,1.7h57v3.74a.12.12,0,0,0,.18.1l10.1-5.83a.12.12,0,0,0,0-.21Z"
      fill="url(#d)"
    />
    <circle cx="526.01" cy="169.35" r="60" fill="#56189e" />
    <g clip-path="url(#i)">
      <polygon
        points="554.95 236.15 579.24 230.79 589.69 182.26 570.98 163.55 567.83 158.04 563.58 154.68 557.58 153.27 556.39 150.82 553.78 147.52 548.58 145.06 544.29 145.16 543.59 143.79 538.94 138.45 532.1 135.26 525.21 135.11 519.23 137.35 513.96 142.21 511.13 148.47 505.35 148.37 500.85 152.44 499.02 157.21 496.54 156.66 493.13 156.93 488.6 158.64 486.14 163.32 482.19 164.03 479.28 166.16 476.81 169.89 479 176.89 484.85 180.6 496.54 180.07 551.24 234.77 554.95 236.15"
        fill="#451787"
      />
    </g>
    <path
      d="M557.58,181a1,1,0,0,1,0-1.92,12.44,12.44,0,0,0,0-24.88l-1.05,0-.18-.75a9.88,9.88,0,0,0-9.66-7.64,9.56,9.56,0,0,0-2.19.25l-.76.17-.32-.71a16.63,16.63,0,0,0-31.34,3.12l-.21.92-.93-.2a8.89,8.89,0,0,0-1.92-.21,9.07,9.07,0,0,0-9,8.11l-.13,1.22-1.15-.42a9,9,0,0,0-3.08-.54,9.11,9.11,0,0,0-8.57,6.06l-.23.64h-1.25a7.41,7.41,0,1,0,0,14.82,1,1,0,0,1,0,1.92,9.33,9.33,0,0,1-.08-18.66A11,11,0,0,1,498.25,156,11,11,0,0,1,509,147.28a11.12,11.12,0,0,1,1.41.09,18.55,18.55,0,0,1,34.41-3.29,11.66,11.66,0,0,1,1.86-.15A11.8,11.8,0,0,1,558,152.31a14.36,14.36,0,0,1-.44,28.72Z"
      fill="#fff"
    />
    <path
      d="M557.58,181a1,1,0,0,1,0-1.92,12.44,12.44,0,0,0,0-24.88l-1.05,0-.18-.75a9.88,9.88,0,0,0-9.66-7.64,9.56,9.56,0,0,0-2.19.25l-.76.17-.32-.71a16.63,16.63,0,0,0-31.34,3.12l-.21.92-.93-.2a8.89,8.89,0,0,0-1.92-.21,9.07,9.07,0,0,0-9,8.11l-.13,1.22-1.15-.42a9,9,0,0,0-3.08-.54,9.11,9.11,0,0,0-8.57,6.06l-.23.64h-1.25a7.41,7.41,0,1,0,0,14.82,1,1,0,0,1,0,1.92,9.33,9.33,0,0,1-.08-18.66A11,11,0,0,1,498.25,156,11,11,0,0,1,509,147.28a11.12,11.12,0,0,1,1.41.09,18.55,18.55,0,0,1,34.41-3.29,11.66,11.66,0,0,1,1.86-.15A11.8,11.8,0,0,1,558,152.31a14.36,14.36,0,0,1-.44,28.72Z"
      fill="#fff"
    />
    <path
      d="M557.58,181a1,1,0,0,1,0-1.92,12.44,12.44,0,0,0,0-24.88l-1.05,0-.18-.75a9.88,9.88,0,0,0-9.66-7.64,9.56,9.56,0,0,0-2.19.25l-.76.17-.32-.71a16.63,16.63,0,0,0-31.34,3.12l-.21.92-.93-.2a8.89,8.89,0,0,0-1.92-.21,9.07,9.07,0,0,0-9,8.11l-.13,1.22-1.15-.42a9,9,0,0,0-3.08-.54,9.11,9.11,0,0,0-8.57,6.06l-.23.64h-1.25a7.41,7.41,0,1,0,0,14.82h11a1,1,0,0,1,0,1.92h-11a9.33,9.33,0,0,1-.08-18.66A11,11,0,0,1,498.25,156,11,11,0,0,1,509,147.28a11.12,11.12,0,0,1,1.41.09,18.55,18.55,0,0,1,34.41-3.29,11.66,11.66,0,0,1,1.86-.15A11.8,11.8,0,0,1,558,152.31a14.36,14.36,0,0,1-.44,28.72Z"
      fill="#fff"
    />
    <path
      d="M523.06,176.89a2.15,2.15,0,1,0,1.52.63,2.14,2.14,0,0,0-1.52-.63"
      fill="#f26c5d"
    />
    <path
      d="M523.18,153.52a25.33,25.33,0,0,0-11.39,2.71,1.37,1.37,0,0,0,.61,2.6,1.38,1.38,0,0,0,.62-.15A22.66,22.66,0,0,1,543.46,189a1.37,1.37,0,0,0,.62,1.84,1.33,1.33,0,0,0,.61.14,1.38,1.38,0,0,0,1.23-.76,25.44,25.44,0,0,0-22.74-36.73"
      fill="#f26c5d"
    />
    <path
      d="M523.06,171.17a7.85,7.85,0,1,0,7.85,7.84,7.85,7.85,0,0,0-7.85-7.84m0,12.94a5.11,5.11,0,1,1,5.1-5.11,5.11,5.11,0,0,1-5.1,5.11"
      fill="#f26c5d"
    />
    <path
      d="M523.06,165.27A13.76,13.76,0,0,0,509.32,179a13.5,13.5,0,0,0,.63,4.1,1.38,1.38,0,0,0,1.32,1,1.23,1.23,0,0,0,.42-.07,1.39,1.39,0,0,0,.91-1.73,10.91,10.91,0,1,1,7.19,7.2,1.53,1.53,0,0,0-.41-.07,1.38,1.38,0,0,0-.41,2.7,13.73,13.73,0,1,0,4.09-26.84"
      fill="#f26c5d"
    />
    <path
      d="M523.06,159.31a19.61,19.61,0,0,0-17.56,10.76,1.38,1.38,0,0,0,.6,1.86,1.42,1.42,0,0,0,.63.15,1.41,1.41,0,0,0,1.24-.76,16.94,16.94,0,1,1,22.82,22.76,1.38,1.38,0,0,0,.63,2.61,1.31,1.31,0,0,0,.63-.16,19.69,19.69,0,0,0-9-37.22"
      fill="#f26c5d"
    />
    <circle cx="748.27" cy="169.35" r="60" fill="#fff" />
    <path
      d="M828.72,66.88l-69.84,118.5a17.47,17.47,0,0,1-23.83,6.46h0A17.47,17.47,0,0,1,728.59,168L798,47.33Z"
      fill="url(#j)"
    />
    <path
      d="M814.08,76.9l-75.42,130a10.74,10.74,0,0,1-14.66,4h0a10.74,10.74,0,0,1-4-14.66l75.42-130Z"
      fill="url(#k)"
    />
    <path
      d="M796.92,33a71.75,71.75,0,0,0-4.08,8.43c-.3.7-.58,1.42-.86,2.15-4-.83-26.94-4.48-30.13,22.4,0,0,13.19-8,25.44-4.23-.19,1.21-.36,2.44-.52,3.7l16.36,9.68,16.37,9.68c1-.74,2-1.49,3-2.24,9.2,8.93,8.54,24.34,8.54,24.34,22-15.75,7.75-34.13,5.12-37.2,1.27-1.49,2.44-3,3.53-4.45C863.25,33.18,847,1,847,1s-32.25,1.11-49.9,31.74"
      fill="#fff"
    />
    <path
      d="M839.67,65.22a84.33,84.33,0,0,1-17.18,17.32c-1,.75-2,1.5-3,2.24L803.14,75.1l-16.36-9.68c.15-1.26.33-2.48.52-3.7a140.13,140.13,0,0,1,5.55-20.32A71.75,71.75,0,0,1,796.93,33c0-.09.11-.17.16-.26C814.74,2.08,847,1,847,1s16.28,32.21-7.3,64.26Zm.39-31.38a10.78,10.78,0,1,0-14.76,3.79A10.78,10.78,0,0,0,840.06,33.84ZM822,53.6a5.25,5.25,0,1,0-7.2,1.85A5.26,5.26,0,0,0,822,53.6Z"
      fill="url(#l)"
    />
    <path
      d="M836.28,19.07a10.78,10.78,0,1,1-14.76,3.79A10.79,10.79,0,0,1,836.28,19.07Z"
      fill="#fff"
    />
    <path
      d="M836.15,69.68c2.62,3.07,16.9,21.45-5.12,37.2,0,0,.65-15.41-8.54-24.34a84.22,84.22,0,0,0,17.19-17.32h0C838.6,66.72,837.43,68.2,836.15,69.68Z"
      fill="#fff"
    />
    <path
      d="M820.1,46.4a5.26,5.26,0,1,1-7.19,1.85A5.26,5.26,0,0,1,820.1,46.4Z"
      fill="#fff"
    />
    <path
      d="M792,43.55c.27-.73.56-1.45.86-2.15a139.77,139.77,0,0,0-5.55,20.32C775,58,761.85,66,761.85,66,765,39.08,788,42.73,792,43.55Z"
      fill="#fff"
    />
    <line x1="791.34" y1="74.9" x2="781.35" y2="91.78" fill="none" />
    <line x1="800.16" y1="80.11" x2="781.29" y2="112.02" fill="none" />
    <line x1="808.99" y1="85.34" x2="799.01" y2="102.22" fill="none" />
    <path
      d="M840.46,65.8l.68-.94.14-.19a0,0,0,0,1,0,0C863.74,32.88,848,.87,847.85.55L847.57,0,847,0c-.33,0-33,1.48-50.7,32.22v0a0,0,0,0,1,0,0,2.39,2.39,0,0,0-.13.21A41.7,41.7,0,0,0,792,41.06q-.3.69-.57,1.41c-3.71-.66-13-1.57-20.5,3.82-5.55,4-8.91,10.57-10,19.56l-.23,2,1.68-1c.13-.08,12.39-7.39,23.87-4.36-.14.95-.28,1.91-.4,2.89l-.08.62,33.82,20,.51-.37c.79-.57,1.55-1.14,2.33-1.74,8.17,8.6,7.67,22.87,7.67,23l-.08,2,1.6-1.14c7.36-5.26,11.51-11.37,12.34-18.15,1.12-9.16-4.16-16.89-6.52-19.82C838.51,68.37,839.51,67.09,840.46,65.8Zm-77.4-1.6c1.22-7.47,4.22-13,8.93-16.36,6.77-4.86,15.21-4.14,18.8-3.53a149.57,149.57,0,0,0-4.25,16.22C776.83,58,767,62.22,763.06,64.2Zm56.39,19.43L787.8,64.91c.13-1,.28-2,.44-3h0a140.22,140.22,0,0,1,5.49-20.1,72.22,72.22,0,0,1,4-8.31l.15-.23h0s0,0,0,0C813.75,5.72,841.86,2.34,846.4,2c2,4.39,13.63,33.42-7.12,62.17-.12.17-.24.34-.37.5a87,87,0,0,1-16.33,16.64l0,0-.66.52C821.07,82.43,820.27,83,819.45,83.63ZM842,89.28c-.7,5.75-4.08,11-10,15.69-.14-4.36-1.23-15-8.05-22.3A83.82,83.82,0,0,0,836.1,71.13C838.38,74,843,81.06,842,89.28Z"
      fill="#56189e"
    />
    <path
      d="M823.45,49.39a6.23,6.23,0,1,0-6.05,7.75,6.24,6.24,0,0,0,5.37-3A6.08,6.08,0,0,0,823.45,49.39Zm-2.32,3.72a4.29,4.29,0,1,1-7.39-4.37,4.28,4.28,0,0,1,2.62-2,4.45,4.45,0,0,1,1.08-.14,4.28,4.28,0,0,1,4.15,3.23A4.24,4.24,0,0,1,821.13,53.11Z"
      fill="#56189e"
    />
    <path
      d="M824.81,38.45a11.75,11.75,0,0,0,16.08-4.13h0a11.74,11.74,0,1,0-16.08,4.13Zm-2.47-15.1a9.85,9.85,0,0,1,8.47-4.81,9.75,9.75,0,0,1,5,1.36,9.82,9.82,0,1,1-13.44,3.45Z"
      fill="#56189e"
    />
    <path d="M242.19,262h3.56v15.4h-3.56Z" fill="#451787" />
    <path
      d="M261.26,270.61v6.77h-3.43v-6.24c0-1.92-.88-2.8-2.4-2.8s-2.84,1-2.84,3.19v5.85h-3.43V265.55h3.28v1.38a5.18,5.18,0,0,1,3.92-1.56C259.15,265.37,261.26,267,261.26,270.61Z"
      fill="#451787"
    />
    <path
      d="M272,276.81a4.88,4.88,0,0,1-2.8.75c-2.79,0-4.42-1.43-4.42-4.25v-4.86H263v-2.64h1.82v-2.88h3.43v2.88h3v2.64h-3v4.82a1.38,1.38,0,0,0,1.48,1.56,2.3,2.3,0,0,0,1.39-.44Z"
      fill="#451787"
    />
    <path
      d="M285.09,272.43h-9a3.16,3.16,0,0,0,3.39,2.38,4,4,0,0,0,3-1.14l1.83,2a6.22,6.22,0,0,1-4.89,1.91c-4.09,0-6.75-2.57-6.75-6.09a6,6,0,0,1,6.31-6.1,5.86,5.86,0,0,1,6.17,6.14C285.16,271.77,285.11,272.15,285.09,272.43Zm-9-2h5.83a3,3,0,0,0-5.83,0Z"
      fill="#451787"
    />
    <path
      d="M299.65,265.55v9.85c0,4.4-2.37,6.43-6.64,6.43a9.74,9.74,0,0,1-5.81-1.63l1.36-2.46a7.14,7.14,0,0,0,4.18,1.34c2.42,0,3.48-1.1,3.48-3.24v-.5a4.87,4.87,0,0,1-3.74,1.47,5.72,5.72,0,1,1,0-11.44,4.78,4.78,0,0,1,3.92,1.69v-1.51Zm-3.38,5.54a3.12,3.12,0,1,0-3.11,2.91A2.87,2.87,0,0,0,296.27,271.09Z"
      fill="#451787"
    />
    <path
      d="M310.19,265.37v3.17c-.28,0-.51-.05-.77-.05-1.89,0-3.15,1-3.15,3.31v5.58h-3.43V265.55h3.28v1.56A4.76,4.76,0,0,1,310.19,265.37Z"
      fill="#451787"
    />
    <path
      d="M322.64,270.63v6.75h-3.21v-1.47a3.88,3.88,0,0,1-3.61,1.65c-2.77,0-4.42-1.54-4.42-3.59s1.47-3.54,5.08-3.54h2.73c0-1.47-.88-2.33-2.73-2.33a5.72,5.72,0,0,0-3.41,1.1l-1.23-2.4a9.13,9.13,0,0,1,5.1-1.43C320.55,265.37,322.64,267,322.64,270.63Zm-3.43,3v-1.21h-2.35c-1.61,0-2.12.6-2.12,1.39s.73,1.43,1.94,1.43A2.54,2.54,0,0,0,319.21,273.64Z"
      fill="#451787"
    />
    <path
      d="M333.38,276.81a4.9,4.9,0,0,1-2.8.75c-2.79,0-4.42-1.43-4.42-4.25v-4.86h-1.82v-2.64h1.82v-2.88h3.43v2.88h3v2.64h-3v4.82a1.38,1.38,0,0,0,1.48,1.56,2.27,2.27,0,0,0,1.38-.44Z"
      fill="#451787"
    />
    <path
      d="M346.47,272.43h-9a3.16,3.16,0,0,0,3.39,2.38,4,4,0,0,0,3-1.14l1.83,2a6.22,6.22,0,0,1-4.89,1.91c-4.09,0-6.75-2.57-6.75-6.09a6,6,0,0,1,6.31-6.1,5.86,5.86,0,0,1,6.16,6.14C346.53,271.77,346.49,272.15,346.47,272.43Zm-9-2h5.83a3,3,0,0,0-5.83,0Z"
      fill="#451787"
    />
    <path
      d="M480,264.89h-4.93V262h13.42v2.91h-4.92v12.49H480Z"
      fill="#451787"
    />
    <path
      d="M497.23,265.37v3.17c-.29,0-.51-.05-.77-.05-1.89,0-3.15,1-3.15,3.31v5.58h-3.43V265.55h3.28v1.56A4.75,4.75,0,0,1,497.23,265.37Z"
      fill="#451787"
    />
    <path
      d="M509.68,270.63v6.75h-3.21v-1.47a3.88,3.88,0,0,1-3.61,1.65c-2.77,0-4.42-1.54-4.42-3.59s1.47-3.54,5.08-3.54h2.73c0-1.47-.88-2.33-2.73-2.33a5.72,5.72,0,0,0-3.41,1.1l-1.23-2.4a9.13,9.13,0,0,1,5.1-1.43C507.59,265.37,509.68,267,509.68,270.63Zm-3.43,3v-1.21H503.9c-1.61,0-2.12.6-2.12,1.39s.73,1.43,1.94,1.43A2.54,2.54,0,0,0,506.25,273.64Z"
      fill="#451787"
    />
    <path
      d="M524.88,270.61v6.77h-3.43v-6.24c0-1.92-.88-2.8-2.4-2.8s-2.84,1-2.84,3.19v5.85h-3.43V265.55h3.28v1.38a5.2,5.2,0,0,1,3.92-1.56C522.77,265.37,524.88,267,524.88,270.61Z"
      fill="#451787"
    />
    <path
      d="M526.84,276.28l1.14-2.46A8.15,8.15,0,0,0,532.1,275c1.58,0,2.18-.41,2.18-1.07,0-1.94-7.15,0-7.15-4.69,0-2.25,2-3.83,5.48-3.83a9.73,9.73,0,0,1,4.55,1L536,268.85a6.83,6.83,0,0,0-3.41-.88c-1.55,0-2.21.48-2.21,1.1,0,2,7.16.06,7.16,4.73,0,2.2-2,3.76-5.59,3.76A10.11,10.11,0,0,1,526.84,276.28Z"
      fill="#451787"
    />
    <path d="M539.67,261.06h3.43v16.32h-3.43Z" fill="#451787" />
    <path
      d="M556.76,270.63v6.75h-3.21v-1.47a3.88,3.88,0,0,1-3.61,1.65c-2.77,0-4.42-1.54-4.42-3.59s1.47-3.54,5.08-3.54h2.73c0-1.47-.88-2.33-2.73-2.33a5.72,5.72,0,0,0-3.41,1.1L546,266.8a9.13,9.13,0,0,1,5.1-1.43C554.67,265.37,556.76,267,556.76,270.63Zm-3.43,3v-1.21H551c-1.6,0-2.11.6-2.11,1.39s.73,1.43,1.94,1.43A2.54,2.54,0,0,0,553.33,273.64Z"
      fill="#451787"
    />
    <path
      d="M567.5,276.81a4.9,4.9,0,0,1-2.8.75c-2.79,0-4.42-1.43-4.42-4.25v-4.86h-1.83v-2.64h1.83v-2.88h3.43v2.88h2.95v2.64h-2.95v4.82a1.38,1.38,0,0,0,1.48,1.56,2.27,2.27,0,0,0,1.38-.44Z"
      fill="#451787"
    />
    <path
      d="M580.59,272.43h-9a3.16,3.16,0,0,0,3.39,2.38,4,4,0,0,0,3-1.14l1.83,2a6.22,6.22,0,0,1-4.89,1.91c-4.09,0-6.75-2.57-6.75-6.09a6,6,0,0,1,6.31-6.1,5.86,5.86,0,0,1,6.16,6.14C580.65,271.77,580.61,272.15,580.59,272.43Zm-9-2h5.83a3,3,0,0,0-5.83,0Z"
      fill="#451787"
    />
    <path
      d="M720.19,267.57c0,3.43-2.57,5.57-6.69,5.57h-3.1v4.24h-3.57V262h6.67C717.62,262,720.19,264.12,720.19,267.57Zm-3.61,0c0-1.69-1.1-2.68-3.28-2.68h-2.9v5.34h2.9C715.48,270.23,716.58,269.24,716.58,267.57Z"
      fill="#451787"
    />
    <path
      d="M734.62,265.55v11.83h-3.26V276a4.79,4.79,0,0,1-3.67,1.58c-3,0-5.06-1.67-5.06-5.32v-6.69h3.43v6.18c0,2,.88,2.86,2.4,2.86s2.73-1,2.73-3.19v-5.85Z"
      fill="#451787"
    />
    <path
      d="M750.7,271.47a5.75,5.75,0,0,1-5.89,6.09,4.61,4.61,0,0,1-3.72-1.54v1.36h-3.28V261.06h3.43v5.74a4.59,4.59,0,0,1,3.57-1.43A5.75,5.75,0,0,1,750.7,271.47Zm-3.47,0a3,3,0,1,0-3,3.27A3,3,0,0,0,747.23,271.47Z"
      fill="#451787"
    />
    <path d="M753,261.06h3.43v16.32H753Z" fill="#451787" />
    <path
      d="M759.19,262a2,2,0,0,1,2.14-1.91,1.93,1.93,0,0,1,2.13,1.85,2.14,2.14,0,0,1-4.27.06Zm.42,3.57H763v11.83h-3.43Z"
      fill="#451787"
    />
    <path
      d="M765.09,276.28l1.14-2.46a8.15,8.15,0,0,0,4.12,1.14c1.58,0,2.18-.41,2.18-1.07,0-1.94-7.15,0-7.15-4.69,0-2.25,2-3.83,5.47-3.83a9.75,9.75,0,0,1,4.56,1l-1.15,2.45a6.78,6.78,0,0,0-3.41-.88c-1.54,0-2.2.48-2.2,1.1,0,2,7.16.06,7.16,4.73,0,2.2-2,3.76-5.59,3.76A10.11,10.11,0,0,1,765.09,276.28Z"
      fill="#451787"
    />
    <path
      d="M790,270.61v6.77h-3.44v-6.24c0-1.92-.88-2.8-2.39-2.8s-2.84,1-2.84,3.19v5.85h-3.44V261.06h3.44v5.72a5.16,5.16,0,0,1,3.76-1.41C787.91,265.37,790,267,790,270.61Z"
      fill="#451787"
    />
    <g class="o-fade-word o-fade-word--1">
      <path
        d="M23.33,277.38l0-9.24-4.53,7.62H17.17l-4.51-7.42v9H9.32V262h2.94L18,271.55,23.71,262h2.92l.05,15.4Z"
        fill="#451787"
      />
      <path
        d="M29.2,271.47c0-3.57,2.75-6.1,6.52-6.1s6.49,2.53,6.49,6.1-2.73,6.09-6.49,6.09S29.2,275,29.2,271.47Zm9.53,0a3,3,0,1,0-3,3.27A3,3,0,0,0,38.73,271.47Z"
        fill="#451787"
      />
      <path
        d="M57.39,271.47a5.75,5.75,0,0,1-5.9,6.09A4.62,4.62,0,0,1,47.77,276v1.36H44.49V261.06h3.44v5.74a4.59,4.59,0,0,1,3.56-1.43A5.76,5.76,0,0,1,57.39,271.47Zm-3.48,0a3,3,0,1,0-3,3.27A3,3,0,0,0,53.91,271.47Z"
        fill="#451787"
      />
      <path
        d="M59.26,262a2,2,0,0,1,2.13-1.91,1.92,1.92,0,0,1,2.13,1.85,2.14,2.14,0,0,1-4.26.06Zm.41,3.57h3.44v11.83H59.67Z"
        fill="#451787"
      />
      <path d="M66.3,261.06h3.43v16.32H66.3Z" fill="#451787" />
      <path
        d="M84.45,272.43h-9a3.16,3.16,0,0,0,3.39,2.38,4,4,0,0,0,3-1.14l1.83,2a6.22,6.22,0,0,1-4.89,1.91c-4.09,0-6.75-2.57-6.75-6.09a6,6,0,0,1,6.31-6.1,5.86,5.86,0,0,1,6.16,6.14C84.51,271.77,84.47,272.15,84.45,272.43Zm-9-2h5.83a3,3,0,0,0-5.83,0Z"
        fill="#451787"
      />
      <path
        d="M103.41,274.08H96.26l-1.36,3.3H91.24L98.11,262h3.52l6.88,15.4h-3.74Zm-1.12-2.7-2.44-5.9-2.45,5.9Z"
        fill="#451787"
      />
      <path
        d="M122.77,271.47a5.75,5.75,0,0,1-5.9,6.09,4.59,4.59,0,0,1-3.56-1.43v5.52h-3.43v-16.1h3.28v1.36a4.59,4.59,0,0,1,3.71-1.54A5.75,5.75,0,0,1,122.77,271.47Zm-3.48,0a3,3,0,1,0-3,3.27A3,3,0,0,0,119.29,271.47Z"
        fill="#451787"
      />
      <path
        d="M138,271.47a5.75,5.75,0,0,1-5.9,6.09,4.59,4.59,0,0,1-3.56-1.43v5.52h-3.43v-16.1h3.27v1.36a4.62,4.62,0,0,1,3.72-1.54A5.75,5.75,0,0,1,138,271.47Zm-3.48,0a3,3,0,1,0-3,3.27A3,3,0,0,0,134.47,271.47Z"
        fill="#451787"
      />
    </g>
    <g class="o-fade-word o-fade-word--2">
      <path
        d="M50.77,262l-5,15.4H41.9L38.51,267,35,277.38h-3.8L26.14,262h3.7l3.48,10.83L37,262h3.3l3.52,10.92L47.36,262Z"
        fill="#451787"
      />
      <path
        d="M63.15,272.43H54.2a3.15,3.15,0,0,0,3.38,2.38,4,4,0,0,0,3-1.14l1.83,2a6.21,6.21,0,0,1-4.88,1.91c-4.1,0-6.76-2.57-6.76-6.09a6,6,0,0,1,6.32-6.1,5.86,5.86,0,0,1,6.16,6.14C63.22,271.77,63.17,272.15,63.15,272.43Zm-9-2H60a3,3,0,0,0-5.83,0Z"
        fill="#451787"
      />
      <path
        d="M78.4,271.47a5.75,5.75,0,0,1-5.9,6.09A4.62,4.62,0,0,1,68.78,276v1.36H65.5V261.06h3.44v5.74a4.59,4.59,0,0,1,3.56-1.43A5.76,5.76,0,0,1,78.4,271.47Zm-3.48,0a3,3,0,1,0-3,3.27A3,3,0,0,0,74.92,271.47Z"
        fill="#451787"
      />
      <path
        d="M79.54,276.28l1.14-2.46A8.13,8.13,0,0,0,84.8,275c1.58,0,2.18-.41,2.18-1.07,0-1.94-7.16,0-7.16-4.69,0-2.25,2-3.83,5.48-3.83a9.75,9.75,0,0,1,4.56,1l-1.15,2.45A6.78,6.78,0,0,0,85.3,268c-1.54,0-2.2.48-2.2,1.1,0,2,7.15.06,7.15,4.73,0,2.2-2,3.76-5.58,3.76A10.11,10.11,0,0,1,79.54,276.28Z"
        fill="#451787"
      />
      <path
        d="M92,262a2,2,0,0,1,2.13-1.91,1.92,1.92,0,0,1,2.13,1.85A2.14,2.14,0,0,1,92,262Zm.41,3.57H95.8v11.83H92.36Z"
        fill="#451787"
      />
      <path
        d="M106.62,276.81a4.87,4.87,0,0,1-2.79.75c-2.8,0-4.43-1.43-4.43-4.25v-4.86H97.58v-2.64H99.4v-2.88h3.44v2.88h3v2.64h-3v4.82a1.38,1.38,0,0,0,1.47,1.56,2.3,2.3,0,0,0,1.39-.44Z"
        fill="#451787"
      />
      <path
        d="M119.71,272.43h-8.95a3.15,3.15,0,0,0,3.38,2.38,4,4,0,0,0,3-1.14l1.82,2a6.21,6.21,0,0,1-4.88,1.91c-4.1,0-6.76-2.57-6.76-6.09a6,6,0,0,1,6.32-6.1,5.86,5.86,0,0,1,6.16,6.14C119.78,271.77,119.73,272.15,119.71,272.43Zm-9-2h5.83a3,3,0,0,0-5.83,0Z"
        fill="#451787"
      />
    </g>
  </svg>
)

export default AutomateDiagram
