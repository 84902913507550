import React from 'react'
import PropTypes from '../prop-types'
import * as Icons from 'atoms/icons'

const Block = ({ className, slug, title, cta, src }) => {
  return (
    <PatternBlock
      className={className}
      slug={slug}
      title={title}
      cta={cta}
      src={src}
    />
  )
}

Block.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.string,
  src: PropTypes.string,
}

export default Block

/* ----------------------------- PATTERN ----------------------------- */

export const PatternBlock = ({ className, slug, title, cta, src }) => {
  return (
    <React.Fragment>
      <div href={slug} className={`c-gallery-block ${className}`}>
        {src && (
          <img
            loading="lazy"
            src={src}
            alt={title}
            className="c-gallery-block__image"
          />
        )}
        {title && (
          <div className="c-gallery-block__dek">
            <h3 className="c-gallery-block__title">
              <a href={slug}>
                <Icons.IconGallery m />
                {title}
                <span href={slug} class="c-gallery-block__cta">
                  {cta}
                </span>
              </a>
            </h3>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

PatternBlock.propTypes = {
  className: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  src: PropTypes.string,
}
