import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Grid from 'atoms/grid'
import { getPodcastPath } from '../routes'
import PodcastSubscribe from 'atoms/podcastSubscribe'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import MetaDataImage from '../images/meta-image.png'
import Layout from 'layouts'

class PodcastCategoryArchiveTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    return (
      <Layout>
        <div className={'l-page--podcast'}>
          <Metadata
            title={`${data.page.metadataTitle || data.page.name} - Smartling`}
            description={data.page.metadataDescription}
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={MetaDataImage}
          />

          <Hero gradientPrimary title={data.page.name}></Hero>

          <div className="u-spacing--sections">
            {data.seasonOne && (
              <Section className="c-podcasts">
                <Section.Inner l className="u-spacing--double">
                  {data.page.subscribeLinks?.length && (
                    <PodcastSubscribe
                      className="u-align--center"
                      longDesc={data.page.subscribeCta}
                      subscribeLinks={data.page.subscribeLinks}
                    />
                  )}
                  <h3 id="season-two">Season Two</h3>
                  <Grid threeUp>
                    {data.seasonTwo.edges.map(({ node }) =>
                      node.name !== 'Test Podcast' ? (
                        <Grid.Item className="u-position--relative">
                          <a href={getPodcastPath(node.slug, data.page.key)}>
                            {(node.listingImage?.file ||
                              node.heroImage?.file) && (
                              <img
                                loading="lazy"
                                src={
                                  node.listingImage?.file
                                    ? `${node.listingImage?.file?.url}?fit=fill&w=550&h=300`
                                    : `${node.heroImage?.file?.url}?fit=fill&w=550&h=300`
                                }
                                alt={node.name}
                                className="c-gallery-block__image"
                              />
                            )}
                            {node.name && (
                              <div className="u-spacing--half u-padding--half--top">
                                <h6 className="u-text-align--left u-font-weight--normal u-text-transform--capitalize">
                                  {node.name}
                                </h6>
                                <p class="u-padding--and-half--top">
                                  <strong className="u-position--bottom o-link">
                                    Listen Now
                                  </strong>
                                </p>
                              </div>
                            )}
                          </a>
                        </Grid.Item>
                      ) : (
                        ''
                      )
                    )}
                  </Grid>
                  <h3 id="season-one">Season One</h3>
                  <Grid threeUp>
                    {data.seasonOne.edges.map(({ node }) =>
                      node.name !== 'Test Podcast' ? (
                        <Grid.Item className="u-position--relative">
                          <a href={getPodcastPath(node.slug, data.page.key)}>
                            {(node.listingImage?.file ||
                              node.heroImage?.file) && (
                              <img
                                loading="lazy"
                                src={
                                  node.listingImage?.file
                                    ? `${node.listingImage?.file?.url}?fit=fill&w=550&h=300`
                                    : `${node.heroImage?.file?.url}?fit=fill&w=550&h=300`
                                }
                                alt={node.name}
                                className="c-gallery-block__image"
                              />
                            )}
                            {node.name && (
                              <div className="u-spacing--half u-padding--half--top">
                                <h6 className="u-text-align--left u-font-weight--normal u-text-transform--capitalize">
                                  {node.name}
                                </h6>
                                <p class="u-padding--and-half--top">
                                  <strong className="u-position--bottom o-link">
                                    Listen Now
                                  </strong>
                                </p>
                              </div>
                            )}
                          </a>
                        </Grid.Item>
                      ) : (
                        ''
                      )
                    )}
                  </Grid>
                </Section.Inner>
              </Section>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default PodcastCategoryArchiveTemplate

export const query = graphql`
  query PodcastCategoryArchiveTemplateQuery($key: String!) {
    page: contentfulPodcastCategory(key: { eq: $key }) {
      name
      description
      key
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      subscribeLinks {
        buttonText
        buttonLink
        newWindow
      }
      subscribeCta
      useBodyInHero
    }
    seasonTwo: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { key: { eq: $key } }, seasonNumber: { eq: 2 } }
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          seasonNumber
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            ...ContentfulImageFragment
          }
          listingImage {
            ...ContentfulImageFragment
          }
        }
      }
    }
    seasonOne: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { key: { eq: $key } }, seasonNumber: { eq: null } }
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          seasonNumber
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            ...ContentfulImageFragment
          }
          listingImage {
            ...ContentfulImageFragment
          }
        }
      }
    }
  }
`
