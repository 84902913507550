import React from 'react'
import PropTypes from '../prop-types'
import * as Icons from 'atoms/icons'

const ShareBar = ({ className, title, uri, reverseColor }) => {
  return (
    <PatternShareBar
      className={className}
      title={title}
      uri={uri}
      reverseColor={reverseColor}
    />
  )
}

ShareBar.propTypes = {
  className: PropTypes.string,
  uri: PropTypes.string,
  title: PropTypes.string,
}

export default ShareBar

/* ----------------------------- PATTERN ----------------------------- */

export const PatternShareBar = ({ className, title, uri, reverseColor }) => {
  const shareUrl = 'https://www.smartling.com' + uri
  const shareTitle = encodeURI(title)
  return (
    <React.Fragment>
      <div className="c-share-bar">
        <a
          href={`https://twitter.com/intent/tweet/?text=${shareTitle}&url=${shareUrl}`}
          aria-label="Social Icon Twitter"
          className="c-social__link c-social__link-twitter u-space--half--left u-space--half--right"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Twitter
            s
            className={reverseColor ? 'u-path-fill--white' : ''}
          />
        </a>
        <a
          href={`https://facebook.com/sharer/sharer.php?u=${shareUrl}`}
          aria-label="Social Icon Facebook"
          className="c-social__link c-social__link-facebook u-space--half--left u-space--half--right"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Facebook
            s
            className={reverseColor ? 'u-path-fill--white' : ''}
          />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
          aria-label="Social Icon LinkedIn"
          className="c-social__link c-social__link-linkedin u-space--half--left u-space--half--right"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icons.Linkedin
            s
            className={reverseColor ? 'u-path-fill--white' : ''}
          />
        </a>
      </div>
    </React.Fragment>
  )
}

PatternShareBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}
