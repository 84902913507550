import React, { useEffect, useState } from 'react'
import Section from 'atoms/section'
import Slider from 'react-slick'

export const RelatedLinks = ({ title, ctaLink, ctaText, url, cards }) => {
  // Slick carousel settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true, // Enable arrows
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div className="o-home-tout__dots mx-auto">
        <ul>{dots}</ul>
      </div>
    ),
  }

  return (
    <Section centerCTA className="o-related-links">
      {title && (
        <Section.Header>
          <h2 className="u-align--center">{title}</h2>
        </Section.Header>
      )}
      <SectionContent center>
        <Slider {...settings}>
          {cards?.map((card, index) => (
            <div key={index} style={{ maxWidth: '100px' }}>
              <Card {...card} />
            </div>
          ))}
        </Slider>
        {ctaLink && ctaText && (
          <div className="u-text-align--center u-padding--triple--top">
            <a role="button" href={ctaLink} className="o-link u-color--primary">
              {ctaText}
            </a>
          </div>
        )}
      </SectionContent>
    </Section>
  )
}

const SectionContent = ({ children }) => (
  <Section.Inner xl>{children}</Section.Inner>
)

const Card = ({ imageSrc, imageAlt, title, description, ctaLink }) => (
  <div
    className="o-home-tout__card u-text-align--center"
    style={{ margin: '0 10px' }}
  >
    <div className="l-grid-area l-grid-area--a1">
      <div className="u-padding--bottom">
        <img
          src={imageSrc}
          alt={imageAlt}
          width={445}
          height={250}
          loading="lazy"
          placeholder="blurred"
        />
      </div>
    </div>
    {title && (
      <div className="l-grid-area l-grid-area--b1">
        <h3 className="u-padding--bottom">{title}</h3>
      </div>
    )}
    <div className="l-grid-area l-grid-area--c1 u-align--center u-spacing">
      {description && <p>{description}</p>}
      {ctaLink && (
        <a href={ctaLink} className="o-link u-color--primary">
          Learn more
        </a>
      )}
    </div>
  </div>
)
