import React from 'react'
import FeaturedResource from 'molecules/block-featured-resource'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import BlockResource from './block-resource'
import { StaticImage } from 'gatsby-plugin-image'

const iconEmail = '../images/icons/icon_email-light-ring.png'

const BlockResources = ({ resourcesData, firstResource, showFirst }) => {
  return (
    <>
      {showFirst && (
        <Section className="u-space--bottom">
          <Section.Inner l>
            <Grid twoUp>
              <Grid.Item>
                <div
                  className="c-block c-block__space-between u-background-color--primary u-spacing u-padding--double"
                  style={{
                    borderRadius: 35,
                    height: '420px',
                    width: '300px',
                  }}
                >
                  <p className="u-color--white">
                    The latest in localization{' '}
                    <span className="u-color--quaternary">
                      <strong>straight to your inbox.</strong>
                    </span>
                  </p>
                  <StaticImage
                    width={110}
                    height={110}
                    src={iconEmail}
                    alt="Email"
                    loading="lazy"
                  />
                  <a
                    className="o-button o-button--white"
                    href="#footer-newsletter"
                  >
                    Subscribe
                  </a>
                </div>
              </Grid.Item>
              <Grid.Item>
                <h5 className="u-space--half--bottom">Featured</h5>
                <FeaturedResource
                  postType={firstResource.postType}
                  image={firstResource.image}
                  categories={firstResource.categories}
                  slug={firstResource.slug}
                  title={firstResource.title}
                  tags={firstResource.tags}
                />
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
      )}
      <Section>
        <Section.Inner xl className="l-grid u-padding--and-half--top">
          <Grid threeUp>
            {resourcesData.map((resource) => (
              <Grid.Item>
                <BlockResource
                  postType={resource.node.postType}
                  image={resource.node.image}
                  categories={resource.node.categories}
                  slug={resource.node.slug}
                  title={resource.node.title}
                  tags={resource.node.tags}
                />
              </Grid.Item>
            ))}
          </Grid>
        </Section.Inner>
      </Section>
    </>
  )
}

export default BlockResources
