import React from 'react'
import Metadata from '../../../layouts/metadata'
import Button from 'atoms/button'
import BattleDiagram from 'atoms/digital-battle-diagram'
import ScrollAnimation from 'react-animate-on-scroll'
import { StaticImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/nao-left.png'

// Inline Styles
var imgStyle = {
  minWidth: '600px',
  maxHeight: 'none',
}

class TranslatePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Smartling Language Services vs The Competition | Smartling"
          description="Not all Language Services Providers (LSPs) are created equal. Smartling’s approach saves you time, costs, and delays on translation. Check out the LSP industry analysis now."
          keywords="Translation agency, Professional translators, Professional translation services, Professional language services"
          image={heroImage}
        />
        <div className="c-page-translate">
          <Hero
            curveLeftReverse
            gradientSecondary
            title="This is an honest LSP industry analysis. Are you ready?"
          >
            <Hero.Description>
              <div className="u-spacing--and-half">
                <h2 className="c-hero__subtitle o-section-title--medium">
                  Let’s go!{' '}
                  <span role="img" aria-label="finger pointing down">
                    👇
                  </span>{' '}
                </h2>
              </div>
            </Hero.Description>
            <Hero.Image>
              <StaticImage
                style={imgStyle}
                src="../../../images/nao-left.png"
                alt="Translator Nao"
                width={620}
                height={465}
                objectFit="scale-down"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="#language-services-consultation">
                Schedule a Consultation
              </Button>
            </Hero.Buttons>
          </Hero>

          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      A Decades-Old Problem.
                    </h3>
                    <p>
                      You have been distanced from your translators. And there’s
                      a reason for that. Over the past two decades, language
                      services providers have acquired other LSPs. It’s
                      introduced more complexity, layers, and costs into a
                      relationship that isn’t buyer-centric. Smartling is
                      different.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <StaticImage
                      src="../../../images/smartling-vs-competition_graphic1.png"
                      alt="Problem graphic"
                      width={500}
                      height={460}
                      objectFit="scale-down"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-grid--reverse--sm">
                <div className="l-grid-item">
                  <div class="c-product-intro-image">
                    <StaticImage
                      src="../../../images/smartling-vs-competition_graphic2.png"
                      alt="Solution graphic"
                      width={500}
                      height={473}
                      objectFit="scale-down"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      The Solution? Know Your Translator.
                    </h3>
                    <p>
                      With Smartling, you not only have access to great
                      translators, but also the opportunity to communicate with
                      them directly via Slack and within the platform. When you
                      peel back the layers, you’re also saving on time, cost,
                      and unnecessary human-based workflows that cause delays to
                      your schedule with an old-school approach.
                      <a
                        href="/translation-services/sls/smartling-experience/"
                        class="o-link"
                      >
                        The Smartling Experience
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-space--and-half--bottom">
                  <div class="u-spacing--and-half o-divider-gradient">
                    <h3 class="o-section-title o-section-title--heavy">
                      Adios to the Old Way.{' '}
                      <span role="img" aria-label="waving hand emoji">
                        👋
                      </span>{' '}
                    </h3>
                  </div>
                </div>
                <div className="l-grid-item u-spacing--and-half">
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span role="img" class="o-emoji" aria-label="ghost emoji">
                        👻
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Unknown Translators vs Translators You Know</h3>
                      <p>
                        The layers between you and the translator are
                        intentional. The typical LSP doesn’t want you to know
                        the translator—they fear you’ll contract with them
                        directly. We connect you directly with translators so
                        that you can get the job done faster and with less fuss.
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="face palm emoji"
                      >
                        🤦‍♀️
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>95% Managed by People vs 95% Managed by Software</h3>
                      <p>
                        Language services providers are just that—services
                        providers. They aren’t technology companies. Smartling
                        is the #1 rated translation management system that also
                        delivers translation services. Our software eliminates
                        all of the manual work surrounding translation, and
                        makes the translation process faster.
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="expressionless emoji"
                      >
                        😑
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Batched Content to Avoid Minimums vs No Minimums</h3>
                      <p>
                        Agencies have to do manual work because they don’t have
                        software. The amount of time it takes for them to
                        project manage five words is the same as 500, so they
                        charge a minimum number of words to make sure they’re
                        not losing money on project management. Since
                        Smartling’s project management is almost entirely
                        automated, there’s no reason to have a minimum—so we
                        don’t!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-space--and-half--bottom">
                  <div class="u-spacing--and-half o-divider-gradient">
                    <h3 class="o-section-title o-section-title--heavy">
                      Hola to the Smart Way.{' '}
                      <span role="img" aria-label="smiling face emoji">
                        😁
                      </span>{' '}
                    </h3>
                  </div>
                </div>
                <div className="l-grid-item u-spacing--and-half">
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="lightening emoji"
                      >
                        ⚡️
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Guesswork vs Data</h3>
                      <p>
                        Your translation agency has little-to-no insight into
                        the actual performance of a translator because they
                        don’t have the robust technology to assess how often
                        they are doing good work. Smartling’s technology and
                        project management team continuously assess the
                        performance of each person, and make adjustments to our
                        roster if the velocity and acceptance rate of their work
                        is compromised.
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="sun coming from behind cloud emoji"
                      >
                        ⛅️
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Translates Offline vs Translates in the Cloud</h3>
                      <p>
                        Most translators show up to work with their own CAT
                        Tool. That’s great for them, but it isn’t great for you
                        because it’s yet another layer of complexity that
                        they’re introducing into your workflow. It eliminates
                        any possibility of data capture, utilization of
                        translation memory, and more. Every Smartling translator
                        is an expert in our software and CAT Tool. They leverage
                        the cloud-based translation memory, glossary, and style
                        guides regularly. And with visual context built right
                        into the experience, they’re ready to get to work every
                        single day to deliver amazing work for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="c-product-intro-section">
              <div class="u-spacing--and-half">
                <div class="l-wrap l-container--s">
                  <h3 class="o-section-title o-section-title--heavy u-text-align--center">
                    It’s a digital battle for the ages.
                  </h3>
                </div>
                <div class="u-spacing--and-half l-wrap l-container--m">
                  <p>
                    The demand for translation is rapidly expanding because the
                    world’s digital transformation was accelerated by the events
                    of 2020. That means you’re going to have to manage more
                    content, and more complex requirements for translation.
                    Every single advantage counts, and your language services
                    provider is an incredibly important player in this game.
                  </p>
                  <ScrollAnimation
                    animateIn="showItems"
                    animateOut="hideItems"
                    initiallyVisible="true"
                  >
                    <BattleDiagram />
                  </ScrollAnimation>
                  <div class="u-spacing u-text-align--center">
                    <a
                      class="o-button  u-space"
                      href="#language-services-consultation"
                      role="button"
                    >
                      Switch to Smartling
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <div className="u-gradient--secondary u-curve--top u-curve--bottom--center u-padding--section">
              <section
                class="o-section c-section__cta c-cta  undefined undefined"
                data-bgset=""
              >
                <div class="o-section--inner l-wrap l-container--l">
                  <div class="c-cta__content u-spacing">
                    <h3 class="c-cta__title" style={{ maxWidth: '760px' }}>
                      "We ended up getting on a call with Smartling’s Japanese
                      translator and translation service team to talk through
                      our preferences for Japanese content. This isn’t normal
                      for the industry, having the ability to speak directly
                      with the translator; I was so impressed."
                    </h3>
                    <div class="c-cta__description">
                      <div class="u-spacing--double">
                        <div>- Jessica Birenz, Yext</div>
                      </div>
                    </div>
                    <StaticImage
                      style={{ maxWidth: '65px' }}
                      src="../../../images/company-logos/logo-yext.png"
                      alt="Yext logo"
                      width={65}
                      height={65}
                      objectFit="scale-down"
                    />
                  </div>
                  <div class="c-cta__buttons">
                    <a
                      class="o-button  "
                      href="/resources/101/translation-isnt-blind-how-to-launch-new-languages/"
                      role="button"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <SectionCta
              centerCTA
              className="c-ready-to-start"
              title="Reach your end-users in their language."
            >
              <SectionCta.Description>
                And join the hundreds of companies using Smartling’s language
                services to reach their customers in any language today.
              </SectionCta.Description>
              <SectionCta.Buttons>
                <Button url="#language-services-consultation">
                  Schedule Consultation
                </Button>
              </SectionCta.Buttons>
            </SectionCta>
          </article>
        </div>
      </Layout>
    )
  }
}

export default TranslatePage
