import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../../layouts/metadata'
import { getPodcastPath } from '../../routes'
import PodcastSubscribe from 'atoms/podcastSubscribe'
import Slider from 'react-slick'
import PardotForm from '../../03-components/forms/pardot'
// import PardotForm from 'components/forms/pardot'
import HubSpotForm from 'components/forms/HubSpotForm';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import MetaDataImage from '../../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'

import Layout from 'layouts'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const locShowLogo = '../../images/locshow_logo_globe2.svg'
const locShowJooRi = '../../images/JooRiChoi-headshot.png'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

class LocShowTemplate extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    const { data } = this.props
    const hubspotFormReference = data.page.hubspotFormReference || {};
    const { portalid, formid, campaignId } = hubspotFormReference;
    return (
      <Layout>
        <div className={'l-page--locshow o-locshow-bg'}>
          <Metadata
            title={`${data.page.metadataTitle || data.page.name} - Smartling`}
            description={data.page.metadataDescription}
            keywords={data.page.metadataKeywords?.join(`, `)}
            image={MetaDataImage}
          />

          <Hero
            curveBottom
            backgroundPrimary
            title="Become an expert in translation and localization"
          >
            <Hero.Description>
              The Loc Show is your go-to podcast to hear from industry leaders,
              pick up localization knowledge and connect your business with
              people around the globe
            </Hero.Description>

            <Hero.Image>
              <StaticImage
                className="locshow-logo"
                src={locShowLogo}
                alt="The LocShow"
                width={380}
                height={380}
                objectFit="scale-down"
              />
            </Hero.Image>
          </Hero>

          <div className="u-spacing--sections">
            {data.seasonOne && (
              <Section className="c-podcasts">
                <Section.Inner l className="u-spacing--quad">
                  {data.page.subscribeLinks?.length && (
                    <PodcastSubscribe
                      className="u-align--center"
                      longDesc={data.page.subscribeCta}
                      subscribeLinks={data.page.subscribeLinks}
                    />
                  )}

                  <div className="c-podcast-slider">
                    <div class="u-padding--bottom">
                      {/* Leave #hash off of link to most recent season */}
                      <h2 className="u-color--primary">
                        <span className="c-podcast-slider__all-episodes">
                          Season 3 Episodes
                        </span>
                      </h2>
                    </div>
                    <Slider {...settings}>
                      {data.seasonThree.edges.map(
                        ({ node }) =>
                          node.heroImage && (
                            <a
                              key={data.page.key}
                              href={getPodcastPath(node.slug, data.page.key)}
                              className="u-padding--half"
                            >
                              {(node.listingImage?.gatsbyImageData ||
                                node.heroImage?.gatsbyImageData) &&
                                (node.heroImage.gatsbyImageData ? (
                                  <GatsbyImage
                                    image={node.heroImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ) : (
                                  <GatsbyImage
                                    image={node.listingImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ))}
                              {node.name && (
                                <div className="u-spacing--half u-padding--half--top">
                                  <h6 className="u-text-align--left u-font-weight--normal u-text-transform--capitalize">
                                    {node.name}
                                  </h6>
                                  <p class="u-padding--top">
                                    <strong className="u-position--bottom o-link">
                                      Listen Now
                                    </strong>
                                  </p>
                                </div>
                              )}
                            </a>
                          )
                      )}
                    </Slider>
                  </div>
                  <div className="c-podcast-slider">
                    <div class="u-padding--bottom">
                      {/* Leave #hash off of link to most recent season */}
                      <h2 className="u-color--primary">
                        <span className="c-podcast-slider__all-episodes">
                          Season 2 Episodes
                        </span>
                      </h2>
                    </div>
                    <Slider {...settings}>
                      {data.seasonTwo.edges.map(
                        ({ node }) =>
                          node.heroImage && (
                            <a
                              key={data.page.key}
                              href={getPodcastPath(node.slug, data.page.key)}
                              className="u-padding--half"
                            >
                              {(node.listingImage?.gatsbyImageData ||
                                node.heroImage?.gatsbyImageData) &&
                                (node.heroImage.gatsbyImageData ? (
                                  <GatsbyImage
                                    image={node.heroImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ) : (
                                  <GatsbyImage
                                    image={node.listingImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ))}
                              {node.name && (
                                <div className="u-spacing--half u-padding--half--top">
                                  <h6 className="u-text-align--left u-font-weight--normal u-text-transform--capitalize">
                                    {node.name}
                                  </h6>
                                  <p class="u-padding--top">
                                    <strong className="u-position--bottom o-link">
                                      Listen Now
                                    </strong>
                                  </p>
                                </div>
                              )}
                            </a>
                          )
                      )}
                    </Slider>
                  </div>
                  <div className="c-podcast-slider">
                    <div class="u-padding--bottom">
                      <h2 className="u-color--primary">
                        <span className="c-podcast-slider__all-episodes">
                          Season 1 Episodes
                        </span>
                      </h2>
                    </div>
                    <Slider {...settings}>
                      {data.seasonOne.edges.map(
                        ({ node }) =>
                          node.heroImage && (
                            <a
                              key={data.page.key}
                              href={getPodcastPath(node.slug, data.page.key)}
                              className="u-padding--half"
                            >
                              {(node.listingImage?.gatsbyImageData ||
                                node.heroImage?.gatsbyImageData) &&
                                (node.heroImage.gatsbyImageData ? (
                                  <GatsbyImage
                                    image={node.heroImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ) : (
                                  <GatsbyImage
                                    image={node.listingImage.gatsbyImageData}
                                    alt={node.name}
                                    className="c-gallery-block__image"
                                  />
                                ))}
                              {node.name && (
                                <div className="u-spacing--half u-padding--half--top">
                                  <h6 className="u-text-align--left u-font-weight--normal u-text-transform--capitalize">
                                    {node.name}
                                  </h6>
                                  <p class="u-padding--top">
                                    <strong className="u-position--bottom o-link">
                                      Listen Now
                                    </strong>
                                  </p>
                                </div>
                              )}
                            </a>
                          )
                      )}
                    </Slider>
                  </div>
                </Section.Inner>
              </Section>
            )}

            {!data.page.useBodyInHero && data.page.body && (
              <article className="l-article u-spacing--sections">
                <div className="l-wrap l-container--l u-spacing--double">
                  <div className="u-text-align--center">
                    <div className="c-block__text u-padding--half u-text-align--center">
                      <div className="c-podcast-hubspot u-space--double--top">
                        {data?.page?.hubspotFormReference && (
                          <div id="hubspot-form-container" className="hubspot-form-container">
                            <HubSpotForm portalId={portalid} formId={formid} campaignId={campaignId || ''} />
                          </div>
                        )}
                      </div>
                    </div>
                    
                  </div>
                </div>
              </article>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default LocShowTemplate

export const query = graphql`
  query LocShowTemplateQuery {
    page: contentfulPodcastCategory(key: { eq: "locshow" }) {
      name
      description
      key
      bodyTitle
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      subscribeLinks {
        buttonText
        buttonLink
        newWindow
      }
      subscribeCta
      useBodyInHero
    }
    seasonThree: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { key: { eq: "locshow" } }, seasonNumber: { eq: 3 } }
      limit: 9
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          seasonNumber
          episodeNumber
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            gatsbyImageData(width: 550, height: 300)
          }
          listingImage {
            gatsbyImageData(
              width: 550
              height: 300
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
          }
        }
      }
    }
    seasonTwo: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: { category: { key: { eq: "locshow" } }, seasonNumber: { eq: 2 } }
      limit: 9
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          seasonNumber
          episodeNumber
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            gatsbyImageData(
              formats: [WEBP, AUTO]
              placeholder: BLURRED
              width: 550
              height: 300
            )
          }
          listingImage {
            gatsbyImageData(
              width: 550
              height: 300
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
          }
        }
      }
    }
    seasonOne: allContentfulPodcast(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        category: { key: { eq: "locshow" } }
        seasonNumber: { eq: null }
      }
      limit: 9
    ) {
      totalCount
      edges {
        node {
          name
          slug
          publishDate
          seasonNumber
          episodeNumber
          description {
            html: childMarkdownRemark {
              text: html
            }
          }
          body {
            html: childMarkdownRemark {
              text: html
            }
          }
          heroImage {
            gatsbyImageData(
              width: 550
              height: 300
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
          }
          listingImage {
            gatsbyImageData(
              width: 550
              height: 300
              formats: [WEBP, AUTO]
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`
