import React from 'react'
import { getTagPath } from '../routes'

const ClickableTags = ({ tags }) => {
  return (
    <span>
      {tags.map((tag) => {
        return (
          <a href={getTagPath(tag.key)} className="c-tags__item">
            {tag.name}
          </a>
        )
      })}
    </span>
  )
}

export default ClickableTags
