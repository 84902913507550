import React from 'react'
import Metadata from '../layouts/metadata'
import Layout from 'layouts'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Grid from 'atoms/grid'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import SectionCta from 'organisms/cta'

// Images
import quickWins from '../images/quickwins.jpg'

class onboardTMSPage extends React.Component {
  render() {
    // destructure props to get the data provided by GraphQL
    return (
      <Layout>
        <div>
          <Metadata
            title="3 Core Services to Look For In a Translation Partner - Smartling"
            description="Digital experiences open a unique opportunity for brands to shift their focus to respond to new demands from consumers and businesses. Now is the time to take the plunge and invest in a global digital strategy."
            keywords=""
            image={quickWins}
          />
          <Hero
            gradientPrimary
            curveBottom
            center
            title="3 Core Services to Look For In a Translation Partner"
          >
            <Hero.Description>
              Digital experiences open a unique opportunity for brands to shift
              their focus to respond to new demands from consumers and
              businesses. Now is the time to take the plunge and invest in a
              global digital strategy.
            </Hero.Description>
          </Hero>

          <Section className="u-padding--section">
            <Section.Inner l>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={quickWins} alt="quick wins" />
                </Grid.Item>
                <Grid.Item>
                  <h3 className="u-space--bottom">Not sure where to start?</h3>
                  <p className="u-space--bottom">
                    We’re not afraid to say we’re the experts at this type of
                    thing.. so, we put together our top 3 localization trends so
                    you can get a quick-start on your global expansion goals!
                  </p>
                  <p className="u-space--bottom">
                    Smartling’s Quick-Win report is your first-step playbook to
                    kicking off your globalized content strategy.
                  </p>
                  <a href="#guide" className="o-link">
                    Jumpstart Your Translation Program
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section">
            <Section.Header>
              <h2 className="u-color--primary u-space--bottom u-text-align--center">
                Translation is Vital to Your Global Growth Strategy
              </h2>
              <p className="u-text-align--center">
                We live in a digital world where global expansion is happening
                rapidly and you need to be ready to meet customers where they
                are through words.{' '}
              </p>
            </Section.Header>
          </Section>

          <Section
            gradientSecondary
            className="c-section__grid-blocks c-grid-blocks u-padding--section"
          >
            <Section.Header>
              <h2 className="u-text-align--center">
                Smartling’s Quick Win Guide Provides Tips to Use
              </h2>
            </Section.Header>
            <Section.Inner l className="u-padding--top">
              <Grid threeUp>
                <Grid.Item>
                  <Block title="VIDEO TRANSLATION">
                    A quick and low-effort must-have for your consumer
                    engagement strategy
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="MT USER REVIEWS">
                    Cost-effective strategies for utilizing user reviews that
                    resonate with your target audience
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="LANGUAGE SERVICES">
                    How to know when your target market is worth an investment
                    in a localized strategy
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section" id="guide">
            <Section.Inner l>
              <h2 className="u-text-align--center u-space--double--bottom">
                Translating can be a daunting task. Luckily, we know where to
                start{' '}
                <span role="img" aria-label="backhand pointing down">
                  👇
                </span>
              </h2>
              <Grid twoUp>
                <Grid.Item>
                  <img loading="lazy" src={quickWins} alt="3 quick wins" />
                </Grid.Item>
                <Grid.Item>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a name="o-pardot-form">
                    <div className="o-pardot-form">
                      <iframe
                        title="Pardot Form"
                        id="pardotiframe"
                        width="100%"
                        height="200"
                        type="text/html"
                        allowTransparency={true}
                        scrolling="no"
                        className="lazyload u-center-block pardot-responsive"
                        data-src="https://resource.smartling.com/l/703963/2021-10-11/2hm96j"
                      />
                    </div>
                  </a>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section className="u-padding--section" id="guide">
            <Section.Inner l>
              <div className="u-background-accent--right u-background-accent--right-small u-background-accent--left u-background-accent--left-small">
                <SectionCta centerCTA className="c-cta--why-smartling">
                  <SectionCta.Description>
                    <div className="u-spacing">
                      <h2>
                        Ready to swipe right?{' '}
                        <span role="img" aria-label="fire">
                          🔥
                        </span>
                      </h2>
                      <div>
                        Businesses depend on technology to drive efficiency and
                        cost savings. At Smartling, we take this to heart and
                        are equipped to scale alongside your ever-changing
                        business.
                      </div>
                      <div>Book a meeting with our team to get started.</div>
                      <Button url="/meeting-request/" className="u-spacing">
                        REQUEST A DEMO
                      </Button>
                    </div>
                  </SectionCta.Description>
                </SectionCta>
              </div>
            </Section.Inner>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default onboardTMSPage
