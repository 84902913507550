import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import Grid from 'atoms/grid'

import MetadataImg from '../../images/smartling-metadata-default.jpeg'
import PaypalHero from '../../images/industries/paypal-hero.jpg'
import Logos from '../../images/company-logos/fintech-page-logos.png'
import TranslationServicesBook from '../../images/fintech-book.png'
import SumUpLogo from '../../images/company-logos/SumUp_logo_ModernInk_RGB-FullLogo_web_400width.jpeg'
import DavidPillon from '../../images/DavidPillon+SumUp.jpeg'
import sevenTips from '../../images/fintech-7-tips.jpg'

import PardotResponsive from '../../02-organisms/pardot-responsive'

const FinTech = () => {
  return (
    <Layout>
      <Metadata
        title="Smartling Translation Management | Finance Customers"
        description="Smartling is the #1 Rated Translation Management Software used by top global finance brands"
        keywords="Smartling, translation management, localization, localization tool, fintech"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="Reach Customers On a Global Scale"
        >
          <Hero.Description>
            We handle translations so you can handle the business
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={PaypalHero} alt="PayPal" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#bookademo">Schedule a Meeting</Button>
          </Hero.Buttons>
        </Hero>
        <Section className="l-wrap l-container--xl u-padding--bottom">
          {/* <Section.Header></Section.Header> */}
          <Section.Inner xl>
            <img
              loading="lazy"
              src={Logos}
              alt="gofundme logo, Brookfield logo, Mastercard logo, Paypal logo, coinbase logo"
            />
            <Block className="u-padding--bottom">
              <h3>
                Powering the World’s Top Enterprise Finance & FinTech Brands
              </h3>
            </Block>
          </Section.Inner>
        </Section>
        <Section l className="l-wrap u-padding--top">
          <Section.Header>
            <Block>
              <h2>Digital Finance Experiences For Global Users</h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--double--bottom">
            <p className="u-align--center">
              Smartling powers global growth with our industry leading
              translation management platform and AI-driven language services.
            </p>
            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>Fully built Language Services team</li>
              <li>
                Pre-built <span className="u-color--primary">integrations</span>
              </li>
              <li>
                Dynamic and flexible{' '}
                <span className="u-color--primary">workflows</span>
              </li>
              <li>
                Fastest <span className="u-color--primary">time to market</span>
              </li>
              <li>
                <span className="u-color--primary">Cost savings</span> with a
                centralized platform
              </li>
            </ul>
            <div className="u-align--center u-spacing">
              <p>
                Configure automation rules, establish workflow preferences,
                maintain style guides, and more.
              </p>

              <a
                role="button"
                href="#pardotForm"
                class="o-link u-color--primary"
              >
                Schedule a 30-Min Consultation
              </a>
            </div>
          </Section.Inner>
        </Section>
        <Section>
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>7 Key Tips for Better Financial Translation</h3>

                <p>
                  The rise in international business relationships has led to
                  increased demand for financial translation services.  We’ve
                  compiled the top 7 best practices for financial translators or
                  businesses navigating financial translation
                </p>

                <a
                  role="button"
                  href="/resources/101/7-key-tips-for-better-financial-translation/"
                  class="o-link u-color--primary"
                >
                  Learn More
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={sevenTips}
                  alt="Seven Tips for better financial translation"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  alt="Book with text on cover: The Complete Guide to Translation Services"
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                {' '}
                <h3>The Complete Guide to Translation Services</h3>
                <p>
                  Learn how language services combined with translation and
                  localization software can help you better serve your global
                  audience, whether online or via mobile application.
                </p>
                <a
                  role="button"
                  href="/resources/101/the-complete-guide-to-translation-services/"
                  class="o-link u-color--primary"
                >
                  Learn More
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section class="o-section--inner l-wrap l-container--xl">
              <div id="sumup-case-study" className="u-space--bottom">
                <div className="u-align--center u-space--bottom">
                  <h2>SumUp: From Zero to Localization Hero</h2>
                  <p className="u-font--m">
                    How SumUp built a scalable global content strategy through
                    automation
                  </p>
                </div>
                <div class="c-quote-box">
                  <div class="u-spacing--double">
                    <div class="u-flex c-quote-top">
                      <img
                        loading="lazy"
                        src={DavidPillon}
                        alt="David Pillon"
                        className="u-round"
                      />
                      <div class="c-quote-attribute">
                        <img
                          loading="lazy"
                          src={SumUpLogo}
                          style={{ maxWidth: '120px' }}
                          alt="SumUp Icon"
                        />
                        <div>
                          <strong>David Pillon</strong>
                        </div>
                        <div>Content Management & Localization Lead</div>
                      </div>
                    </div>
                    <div class="o-section-title o-section-title--small c-quote o-section-title--medium">
                      Our new product page had four components for 20 languages.
                      The first time we did that, we had to do 200 copy/paste
                      actions just for one page! With Smartling, we were able to
                      remove the entire step. This was more than a day's worth
                      of time saved per product page by using Smarting's TMS.
                    </div>
                    <a
                      href="/resources/case-study/sumup-from-zero-to-localization-hero/"
                      class="o-button o-button--wide o-button--no-shadow"
                    >
                      Read the Case Study
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="u-align--center u-spacing">

                <h2>Book a 20-Minute Demo</h2>
                <iframe src="https://resource.smartling.com/l/703963/2022-01-06/2p6w73" type="text/html" frameborder="0" title="pardot form" allowtransparency="true" scrolling="no" style="margin: 15px auto; border: 0px; height: 170px; min-height: 100%; width: auto; min-width: 50%; overflow: hidden;" id="pardotForm"></iframe>
              </div> */}
            </section>

            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="bookademo">
                    Book a 30-Minute Demo
                  </h2>
                  <PardotResponsive url="https://resource.smartling.com/l/703963/2022-04-07/2tg97p" />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FinTech
