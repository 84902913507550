import React from 'react'
import classNames from 'classnames'
import { usePagination, DOTS } from './use-pagination'

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize = 10,
    className,
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  /* Only display the pagination component if there are enough pages */
  if (currentPage === 0 || paginationRange.length < 2) {
    return <div className="c-pagination-placeholder"></div>
  }

  return (
    <ul
      className={classNames('c-pagination-container', 'u-space--and-half--top', {
        [className]: className,
      })}
    >
      {/* <li
        className={classNames('o-pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="o-arrow">{`<`}</div>
      </li> */}
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li className={classNames('o-pagination-item', 'dots')}>{DOTS}</li>
          )
        }
        return (
          // eslint-disable-next-line
          <li
            className={classNames('o-pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      })}
      {/* <li
        className={classNames('o-pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="o-arrow">{`>`}</div>
      </li> */}
    </ul>
  )
}

export default Pagination
