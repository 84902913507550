import React from 'react'
import { DangerousHTML } from 'atoms/contentful/html'

const AccordionTable = (data) => {
  const title = data.data.title
  const table = data.data.body.html.text
  const panelRef = React.useRef(null)
  const [heightVar, setHeight] = React.useState('0')

  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    /**
     * Set panel height
     * 1) If isActive is true, set the height to panel body inner height
     * 2) After the panel animates, change the height to auto to allow for expansion of content inside (e.g. Show more checkboxes)
     * 3) If isActive is false, set the height to body inner height first to allow for smooth collapse before setting to 0 (hidden)
     * 4) Panel timeout should match animation speed (transition in CSS for height)
     */
    function setInitialPanelHeight() {
      if (!panelRef.current) return
      const scrollHeight = panelRef.current.querySelector(
        '.c-table-accordion__table'
      )?.scrollHeight /* 1 */

      if (isOpen) {
        if (scrollHeight) {
          setHeight(`${scrollHeight}px`)
        }

        setTimeout(() => {
          setHeight('auto') /* 2 */
        }, 150) /* 4 */
      } else if (isOpen === false) {
        if (scrollHeight) {
          /* 3 */
          setHeight(`${scrollHeight}px`)
        }

        setTimeout(() => {
          setHeight('0') /* 3 */
        }, 1)
      }
    }

    /**
     * Set panel height on window resize
     * 1) This is different than setInitialPanelHeight to not get weird
     * gitching when window resizing due to timeouts
     * 2) Set "auto" to open panels on screen resize to accommodate any accordion
     * panel height increases.
     * 3) Set height to 0 if panel is closed
     */
    function setPanelHeight() {
      if (isOpen) {
        setHeight('auto') /* 2 */
      } else if (isOpen === false) {
        setHeight('0') /* 3 */
      }
    }

    setInitialPanelHeight()
    window.addEventListener('resize', setPanelHeight) /* 2 */
    return () => {
      window.removeEventListener('resize', setPanelHeight) /* 3 */
    }
  }, [isOpen])

  function handleClick() {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <div
      className={`o-table c-table-accordion ${isOpen ? 'is-open' : ''}`}
      ref={panelRef}
    >
      <div onClick={handleClick} className="c-table-accordion__title">
        <h3 className="c-table-accordion__header">{title}</h3>
        <i class="fa-sharp fa-solid fa-circle-info"></i>
        <div className="c-table-accordion__icon">
          <span className="c-table-accordion__icon--x"></span>
          <span className="c-table-accordion__icon--y"></span>
        </div>
      </div>
      <div className="c-table-accordion__table" style={{ height: heightVar }}>
        <DangerousHTML>{table}</DangerousHTML>
      </div>
    </div>
  )
}

export default AccordionTable
