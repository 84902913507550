import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'

// Images
import sectionImage2 from '../images/Patrick-At-Work_01.jpg'
import integrationLightIcon from '../images/icons/icon_integration-light.svg'
import teamLightIcon from '../images/icons/icon_team-light.svg'
import mgmtLightIcon from '../images/icons/icon_management-light.svg'
import fuzzyLightIcon from '../images/icons/icon_fuzzymatch-light.svg'
import automationLightIcon from '../images/icons/icon_automation-white.svg'
import cloudLightIcon from '../images/icons/icon_cloud-data-light.svg'
import diamondLightIcon from '../images/icons/icon_diamond-light.svg'
import adrollLogo from '../images/ui/adroll-04.png'
import womenWorking from '../images/Katie-And-Sinead-At-Work_01.jpg'
import translationLeverage from '../images/illustrations/illustration_translation-leverage.png'

import Layout from 'layouts'

import MetaDataImage from '../images/meta-image.png'

const ProfessionalServicesTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || data.page.title}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
    />
    <div className="c-page-professional-services">
      <Hero
        curveLeftReverse
        gradientPrimary
        title="Smartling Professional Services"
        className="c-hero__resource"
      >
        <Hero.Description>
          <div className="u-spacing--and-half">
            <div className="c-hero__cta-text">
              A team of experts to optimize your implementation and maintain
              your integrations.
            </div>
          </div>
        </Hero.Description>
        <Hero.Image>
          <img
            loading="lazy"
            src={MetaDataImage}
            alt="People at work in a conference room"
          />
        </Hero.Image>
        <Hero.Buttons>
          <Button url="/meeting-request/">Schedule a meeting</Button>
        </Hero.Buttons>
      </Hero>

      <article className="l-article u-spacing--sections">
        <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
          <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
            <div className="l-grid-item">
              <div class="u-spacing--and-half">
                <h3 class="o-section-title o-section-title--heavy">
                  A weight off your shoulders.
                </h3>
                <p>
                  Smartling Professional Services implements and manages
                  Smartling solutions to seamlessly connect your content
                  platforms with our Translation Cloud. Whether onboarding with
                  Smartling for the first time, adding a new content source, or
                  fine-tuning your current solution for evolving needs, our team
                  is available to provide expertise on strategy and execution.
                  That’s valuable time back in your pocket, so you can focus on
                  the bigger picture.
                </p>
              </div>
            </div>
            <div className="l-grid-item u-padding--top">
              <div class="c-product-intro-image">
                <img loading="lazy" src={sectionImage2} alt="Patrick at work" />
              </div>
            </div>
          </div>
        </section>

        <section class="o-section">
          <div class="c-section__header u-max-width-820">
            <div class="c-block undefined u-spacing">
              <div class="c-block__text u-spacing">
                <div class="u-spacing">
                  <h3 class="c-block__title">
                    Why should your brand partner with our experts?
                  </h3>
                  <div class="c-block__description u-spacing">
                    <div>
                      It’s simple. Our professional services put you in the
                      driver’s seat,{' '}
                      <span class="u-nowrap">helping you to:</span>
                    </div>

                    <ul class="o-list--checked o-list--checked--left">
                      <li>
                        Accelerate implementations to achieve translation
                        project deadlines
                      </li>
                      <li>
                        Anticipate challenges ahead of time and design a game
                        plan to tackle them successfully
                      </li>
                      <li>
                        Leverage expert technologists to complete integration
                        projects
                      </li>
                      <li>
                        Reduce your developers’ involvement with localization
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Section centerCTA>
          <Section.Header>
            <div class="c-block undefined u-spacing">
              <div class="c-block__text u-spacing">
                <div class="u-spacing">
                  <h3 class="c-block__title">
                    What Professional Services can do for you.
                  </h3>
                  <div class="c-block__description">
                    Smartling Professional Services is your key to unlocking our
                    experts with unrivaled industry knowledge. And we’ll help
                    you every step of the way from onboarding to optimizing your
                    configuration long term.
                  </div>
                </div>
              </div>
            </div>
          </Section.Header>
          <Section.Inner l>
            <div className="l-grid l-grid--3x3">
              {/* one */}
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image">
                  <img loading="lazy" src={teamLightIcon} alt="Team Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1">
                <h3 class="u-color--primary">Onboarding Services</h3>
              </div>
              <div className="l-grid-area l-grid-area--c1">
                <p>
                  Full-service{' '}
                  <a href="/software/translation-management-system/onboarding-enablement-services/">
                    onboarding packages
                  </a>{' '}
                  including technical integration, training, project management,
                  and launch support.
                </p>
              </div>
              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img
                      loading="lazy"
                      src={integrationLightIcon}
                      alt="Integration Icon"
                    />
                  </div>
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2">
                <h3 class="u-color--primary">Integration Services</h3>
              </div>
              <div className="l-grid-area l-grid-area--c2">
                <p>
                  Support technical teams adding on new content sources that
                  integrate with the Smartling platform via the web proxy,
                  custom API, or any Smartling connector.
                </p>
              </div>
              {/* three */}
              <div className="l-grid-area l-grid-area--a3">
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img
                      loading="lazy"
                      src={mgmtLightIcon}
                      alt="Management Icon"
                    />
                  </div>
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b3">
                <h3 class="u-color--primary">Migration Services</h3>
              </div>
              <div className="l-grid-area l-grid-area--c3">
                <p>
                  Assist with the migration from an existing solution to
                  Smartling, or changing CMS or integration types.
                </p>
              </div>
            </div>
            <div className="l-grid l-grid--3x3">
              {/* one */}
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image">
                  <img loading="lazy" src={fuzzyLightIcon} alt="Icon" />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1">
                <h3 class="u-color--primary">
                  Re-Implementation on
                  <br />
                  Website Redesign
                </h3>
              </div>
              <div className="l-grid-area l-grid-area--c1">
                <p>
                  Re-implement the web proxy on top of a new website design.
                </p>
              </div>
              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img
                      loading="lazy"
                      src={automationLightIcon}
                      alt="Automation Icon"
                    />
                  </div>
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2">
                <h3 class="u-color--primary">Web Proxy Maintenance</h3>
              </div>
              <div className="l-grid-area l-grid-area--c2">
                <p>
                  For complex websites with highly dynamic content, a dedicated
                  Solution Architect will maintain your custom integration and
                  ensure code releases run smoothly.
                </p>
              </div>
              {/* three */}
              <div className="l-grid-area l-grid-area--a3">
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img
                      loading="lazy"
                      src={cloudLightIcon}
                      alt="Cloud Data Icon"
                    />
                  </div>
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b3">
                <h3 class="u-color--primary">CMS Connector Maintenance</h3>
              </div>
              <div className="l-grid-area l-grid-area--c3">
                <p>
                  Access a dedicated Solution Architect to support your
                  Smartling connector as your CMS environment evolves.
                </p>
              </div>
            </div>
            <div className="l-grid l-grid--3x3">
              {/* two */}
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid-area l-grid-area--a1">
                  <div className="l-grid__item-image">
                    <img
                      loading="lazy"
                      src={diamondLightIcon}
                      alt="Diamond Icon"
                    />
                  </div>
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2">
                <h3 class="u-color--primary">Custom Training Packages</h3>
              </div>
              <div className="l-grid-area l-grid-area--c2">
                <p>
                  Onboard new team members or get a deep dive into a particular
                  part of the Smartling platform.
                </p>
              </div>
            </div>
          </Section.Inner>
        </Section>

        <div className="u-gradient--tertiary u-curve--top u-padding--section">
          <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
            <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
              <div className="l-grid-item">
                <div class="u-spacing--and-half">
                  <h3 class="o-section-title o-section-title--heavy">
                    Customizable, Managed Services at your fingertips.
                  </h3>
                  <p>
                    In addition, the Professional Services team provides
                    customized white-glove localization management services,{' '}
                    <a href="/why-platinum-support/">Managed Services</a>, to
                    fit your strategic needs. Extend your team with Managed
                    Services to meet demanding business goals.
                  </p>
                  <a
                    href="/resources/product/smartlings-platinum-support-customized-white-glove-services-to-fit-your/"
                    class="o-link"
                  >
                    Learn more
                  </a>
                </div>
              </div>
              <div className="l-grid-item u-padding--top">
                <div class="c-product-intro-image">
                  <img
                    loading="lazy"
                    src={womenWorking}
                    alt="Women talking in office"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>

        <section class="o-section--inner l-wrap l-container--l c-product-intro-section u-padding--left u-padding--right ">
          <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
            <div className="l-grid-item u-padding--top">
              <div class="c-product-intro-image">
                <img loading="lazy" src={adrollLogo} alt="Adroll logo" />
              </div>
            </div>
            <div className="l-grid-item">
              <div class="u-spacing--and-half">
                <h3 class="o-section-title o-section-title--heavy">
                  No full time employee to manage translation?
                </h3>
                <p>
                  No problem. Discover how AdRoll utilizes Managed Services to
                  do all the heavy lifting within the Smartling dashboard,
                  enabling their team to focus on core marketing and product
                  development initiatives.
                </p>
                <a href="/resources/case-study/adroll/" class="o-link">
                  Read the Case Study
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class="o-section--inner l-wrap l-container--l u--padding--left u-padding--right ">
          <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
            <div className="l-grid-item">
              <div class="u-spacing--and-half">
                <h3 class="o-section-title o-section-title--heavy">
                  Linguistic Asset Management, covered.
                </h3>
                <p>
                  Your translators need high-quality linguistic assets to
                  deliver high-quality translations to your team. Our two simple
                  plans make it simple to proactively manage your brand’s
                  translation preferences. The end result? An optimized glossary
                  and style guide that helps deliver stellar content.
                </p>
                <a href="/linguistic-asset-management/" class="o-link">
                  Discover Linguistic Asset Management
                </a>
              </div>
            </div>
            <div className="l-grid-item u-padding--top">
              <div class="c-product-intro-image">
                <img
                  loading="lazy"
                  src={translationLeverage}
                  alt="Translation leverage illustration"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="o-section c-section__cta c-cta c-cta--center">
          <div className="o-section--inner l-wrap l-container--l">
            <div className="c-cta__content u-spacing u-text-align--center">
              <h2 className="c-cta__title">
                Interested in learning more about our professional services?
              </h2>
              <div className="c-cta__description">
                Join the hundreds of customers who utilize Smartling’s
                professional service offerings, and see what our team can do for
                you.
              </div>
            </div>
            <div className="c-cta__buttons">
              <a className="o-button  " href="/meeting-request/" role="button">
                Schedule a meeting
              </a>
            </div>
          </div>
        </section>
      </article>
    </div>
  </Layout>
)

export default ProfessionalServicesTemplate

export const ProfessionalServicesQuery = graphql`
  query ProfessionalServicesTemplateQuery {
    page: contentfulBasicPage(path: { eq: "professional-services" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
    }
  }
`
