import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import Button from 'atoms/button'
import HubSpotForm from 'components/forms/HubSpotForm'

import MetaDataImage from '../images/meta-image.png'

// Sections
import Hero from 'organisms/hero'
import LandingPageSection from 'components/sections/landing-page-section'

import Layout from 'layouts'

const BasicPageTemplate = ({ data }) => {
  const hubspotFormReference = data.page.hubspotFormReference || {}
  const { portalid, formid, campaignId } = hubspotFormReference

  return (
    <Layout>
      <Metadata
        title={data.page.metadataTitle || data.page.title}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={MetaDataImage}
        noindex={data.page.noIndex ? true : false}
      />
      <Hero center curveBottom gradientPrimary title={data.page.title}>
        <Hero.Description>
          {data.page.hubspotFormReference &&
            (data.page?.pardotFormReferencePlacement === 'Hero' ? (
              <div
                id="hubspot-form-container"
                className="hubspot-form-container"
              >
                <HubSpotForm
                  portalId={portalid}
                  formId={formid}
                  campaignId={campaignId || ''}
                />
              </div>
            ) : (
              ``
            ))}
        </Hero.Description>
        <Hero.Buttons>
          {data.page.heroButtons &&
            data.page.heroButtons.map((button) => {
              return (
                <Button
                  url={button.buttonLink}
                  target={button.newWindow ? `_blank` : `_self`}
                >
                  {button.buttonText ? button.buttonText : `Learn More`}
                </Button>
              )
            })}
        </Hero.Buttons>
      </Hero>
      <article className="l-article u-spacing--sections">
        <div className="l-article__body l-wrap l-container--m">
          <div className="o-text u-spacing">
            {data.page.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Sidebar' ? (
                <aside className="embedly-card">
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                </aside>
              ) : (
                ``
              ))}
            {data.page.body && (
              <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
            )}
            {data.page.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Below Body' ? (
                <div
                  id="hubspot-form-container"
                  className="hubspot-form-container"
                >
                  <HubSpotForm
                    portalId={portalid}
                    formId={formid}
                    campaignId={campaignId || ''}
                  />
                </div>
              ) : (
                ``
              ))}
          </div>
        </div>
        {data.page.pageSections &&
          data.page.pageSections.map((section) => (
            <LandingPageSection {...section} />
          ))}
      </article>
    </Layout>
  )
}

export default BasicPageTemplate

export const pageQuery = graphql`
  query BasicPageTemplateQuery($pathField: String!) {
    page: contentfulBasicPage(path: { eq: $pathField }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords
      noIndex
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionAccordionFragment
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
        ...ContentfulLandingPageSectionDropdownsFragment
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
    }
  }
`
