import React from 'react'
import * as Icons from 'atoms/icons'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Section from 'atoms/section'
import { graphql } from 'gatsby'

// Sections
import Hero from 'organisms/hero'
import Grid from 'atoms/grid'
import SectionCta from 'organisms/cta'
import Layout from 'layouts'

// Images
import iconReferral from '../images/icons/icon_fast-money-dark.png'
import iconRemote from '../images/icons/icon_remote-work-light-ring.svg'
import iconRemote2 from '../images/icons/icon_remote-work-2-light-ring.png'
import iconHealth from '../images/icons/icon_health-benefits-dark.svg'
import iconPTO from '../images/icons/icon_flexible-vacation-pto-dark.svg'
import iconEquity from '../images/icons/icon_competitive-salary-equity-dark.svg'
import iconCommuter from '../images/icons/icon_commuter-benefits-dark.svg'
import iconRemoteHires from '../images/icons/icon_remote-work-dark.png'
import iconParental from '../images/icons/icon_parental-leave-dark.svg'
import icon401k from '../images/icons/icon_piggybank-dark.svg'
import logoGlassdoor from '../images/company-logos/logo-glassdoor.svg'
import logoComparably from '../images/company-logos/logo-comparably-white.svg'
import logoG2 from '../images/company-logos/logo-G2.svg'
import AmericaMap from '../images/worldmap-americas-purple.png'
import EuropeMap from '../images/worldmap-europe-purple.png'
import metaDataImage from '../images/meta-data-image.jpeg'

// Script
import Script from 'react-load-script'

const CareersPage = () => {
  // console.log(data.page.edges[0].node.file.url)

  return (
    <Layout>
      <Metadata
        title="Careers & Life at Smartling | Smartling"
        description="Smartling is always on the hunt for ambitious talent to join our team of smart and curious people. Check out our open career opportunities and discover what it's like to work at Smartling."
        keywords=""
        image={metaDataImage}
      />
      <Hero gradientTertiary title="Talk is cheap.">
        <Hero.Description>
          <h4 className="u-space--bottom">So, let us be rich in meaning.</h4>
          Every company is using content to engage customers, and today it’s
          essential for this content to be translated. But translation isn't a
          straightforward process. Over the past 11 years, Smartling has
          developed an industry-leading solution adopted by over 1,000 of the
          most widely recognized brands in the world (and growing!). Help us
          translate the world's digital content.
        </Hero.Description>
        <Hero.Image />
        <Hero.Buttons>
          <Button url="#grnhse_iframe">View Global Openings</Button>
        </Hero.Buttons>
        <Hero.Video>
          <iframe
            title="video"
            src="https://player.vimeo.com/video/260429276?autoplay=1&muted=1&title=0&byline=0&portrait=0"
            width="560"
            height="315"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Hero.Video>
      </Hero>
      <Section>
        <Section.Header>
          <h3 class="c-block__title u-color--primary">Why Smartling?</h3>
          <Block className="u-space--and-half--bottom">
            Words are powerful - they bring people together. Our employees bring
            passion and perspective into their work that helps bring meaning to
            Smartling's mission - to move the world with words. Through
            world-class customer service, innovation and relentless
            attentiveness to the needs of our people, Smartling is building a
            culture focused on excellence at every touchpoint.
          </Block>
        </Section.Header>
      </Section>

      <Section
        gradientSecondary
        className="c-section__grid-blocks c-grid-blocks u-padding--section"
      >
        <Section.Inner l className="u-padding--top">
          <Grid twoUp>
            <Grid.Item>
              <img
                loading="lazy"
                className="o-worldmap-purple"
                src={AmericaMap}
                alt="map of North America"
              />
              <div className="u-text-align--center u-space--top u-space--and-half--bottom">
                <span className="o-heading--m u-color--black">
                  North America
                </span>
              </div>
            </Grid.Item>
            <Grid.Item>
              <img
                loading="lazy"
                className="o-worldmap-purple"
                src={EuropeMap}
                alt="map of Europe"
              />
              <div className="u-text-align--center u-space--top u-space--and-half--bottom">
                <span className="o-heading--m u-color--black">World</span>
              </div>
            </Grid.Item>
          </Grid>
          <Grid fourUp>
            <Grid.Item>
              <Block>
                <h1 className="u-font--xxl">150+</h1>
                <span className="o-heading--m u-color--primary">Teammates</span>
              </Block>
            </Grid.Item>
            <Grid.Item>
              <Block>
                <h1 className="u-font--xxl">10+</h1>
                <span className="o-heading--m u-color--primary">Countries</span>
              </Block>
            </Grid.Item>
            <Grid.Item>
              <Block>
                <h1 className="u-font--xxl">5</h1>
                <span className="o-heading--m u-color--primary">
                  Global Offices
                </span>
              </Block>
            </Grid.Item>
            <Grid.Item>
              <Block>
                <h1 className="u-font--xxl">1,000+</h1>
                <span className="o-heading--m u-color--primary">Customers</span>
              </Block>
            </Grid.Item>
          </Grid>
        </Section.Inner>
      </Section>

      <Section className="c-section__grid-blocks c-grid-blocks u-padding--section">
        <Section.Header>
          <h3 className="c-block__title u-color--primary">
            Perks &amp; Benefits
          </h3>
          <Block>
            Smartling is committed to each individual's professional and
            personal success. We offer flexible work options, health and
            well-being initiatives and career growth opportunities to give our
            employees the work-life balance needed to stay happy and productive!
          </Block>
        </Section.Header>
        <Section.Inner l>
          <div className="l-grid l-grid--4up">
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconEquity}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Competitive Salary & Equity</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconPTO}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Flexible vacation & PTO</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconHealth}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Health Benefits<br/>Free Medical Plan for You and your Family</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconParental}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Generous Maternity/Paternity Leave</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={icon401k}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>401k Retirement Planning</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconCommuter}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Commuter Benefits</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconRemoteHires}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Remote Hires</p>
            </div>
            <div className="l-grid-item u-text-align--center">
              <img
                loading="lazy"
                src={iconReferral}
                alt="icon"
                className="u-icon--xl u-space--half--right"
              />
              <p>Employee Referral Bonus</p>
            </div>
          </div>
        </Section.Inner>
      </Section>

      <Section>
        <Section.Inner l className="o-section--inner-home l-container">
          <h3 class="u-text-align--center u-color--primary u-space--triple--bottom">
            How We’re Connecting Remotely
          </h3>
          <div className="l-grid l-grid--2x4 l-grid--custom">
            {/* one */}
            <div className="c-card__blue">
              <div className="l-grid-area l-grid-area--a1">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    data-src={iconRemote}
                    alt="Translator"
                    className="lazyload"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b1 u-space--top">
                <p class="u-text-align--left">
                  “Smartling has done a great job of adapting to the challenges
                  of 2020, and has gone the extra mile to make sure our people
                  are safe, while still making sure we grow as a company.
                  Leaders care about the people they work with, goals are clear,
                  training is available when needed, and communications are
                  fantastic given the challenges of remote work.”
                </p>
              </div>
            </div>

            {/* two */}
            <div className="c-card__blue">
              <div className="l-grid-area l-grid-area--a2">
                <div className="l-grid__item-image l-grid__item-image--sm">
                  <img
                    loading="lazy"
                    data-src={iconRemote2}
                    alt="Translator"
                    className="lazyload"
                  />
                </div>
              </div>
              <div className="l-grid-area l-grid-area--b2 u-space--top">
                <p class="u-text-align--left">
                  “I was to be onboarded as one of the first remote hires, and I
                  had no idea what to expect. 5 months into the role now, and it
                  is abundantly clear to me just how much our leadership invests
                  in new employees. I love the culture, the mission, the
                  day-to-day goals, and all of my colleagues – rating Smartling
                  as a '10' is an easy call for me.”{' '}
                </p>
              </div>
            </div>
          </div>
        </Section.Inner>
      </Section>

      <Section
        gradientTertiary
        className="c-section__grid-blocks c-grid-blocks u-padding--section"
      >
        <Section.Header>
          <Block>
            <Block.Image>
              <Icons.HeaderPointer xxl />
            </Block.Image>
          </Block>
        </Section.Header>
        <Section.Inner xl>
          <h3
            className="o-heading--xxl u-text-align--center"
            style={{ marginTop: '-30px' }}
          >
            Be a Part of Something Great
          </h3>
          <div id="grnhse_app" />
          <Script url="https://boards.greenhouse.io/embed/job_board/js?for=smartling" />
        </Section.Inner>
      </Section>

      <Section
        curveTop
        curveBottom
        gradientPrimary
        className="u-padding--section"
      >
        <Section.Header>
          <Block title="Culture @ Smartling">
            <h4>Don't just take our word for it!</h4>
            <div class="u-flex u-align--center u-space--triple--top">
              <a
                class="u-space--double"
                href="https://www.glassdoor.com/Reviews/Smartling-Reviews-E816924.htm"
                target="_blank"
                rel="noreferrer"
              >
                <span className="u-icon u-icon--100h u-icon--hover">
                  <img
                    loading="lazy"
                    src={logoGlassdoor}
                    alt="Glassdoor logo"
                  />
                </span>
              </a>
              <a
                class="u-space--double"
                href="https://www.comparably.com/companies/smartling"
                target="_blank"
                rel="noreferrer"
              >
                <span className="u-icon u-icon--100h u-icon--hover">
                  <img
                    loading="lazy"
                    src={logoComparably}
                    alt="Comparably logo"
                  />
                </span>
              </a>
              <a
                class="u-space--double"
                href="https://www.g2.com/products/smartling/reviews"
                target="_blank"
                rel="noreferrer"
              >
                <span className="u-icon u-icon--100h u-icon--hover">
                  <img loading="lazy" src={logoG2} alt="G2 logo" />
                </span>
              </a>
            </div>
          </Block>
        </Section.Header>
      </Section>

      <Section className="c-section__grid-blocks c-grid-blocks u-padding--section">
        <Section.Header>
          <Block title="Core Values"></Block>
        </Section.Header>
        <Section.Inner className="c-core-values--inner u-padding--double--top">
          <div class="c-core-values--item">
            <div class="c-core-values--number">01</div>
            <div class="c-core-values--text">Take Care of Our People</div>
          </div>
          <div class="c-core-values--item">
            <div class="c-core-values--number">02</div>
            <div class="c-core-values--text">Good Today, Perfect Tomorrow</div>
          </div>
          <div class="c-core-values--item">
            <div class="c-core-values--number">03</div>
            <div class="c-core-values--text">Constraint Breeds Creativity</div>
          </div>
          <div class="c-core-values--item">
            <div class="c-core-values--number">04</div>
            <div class="c-core-values--text">Debate, Then Commit</div>
          </div>
          <div class="c-core-values--item">
            <div class="c-core-values--number">05</div>
            <div class="c-core-values--text">Be Accountable</div>
          </div>
        </Section.Inner>
        <div className="c-cta__buttons u-align--center u-space--quad--top">
          <Button url="/manifesto" addClasses="o-button--primary">
            Read Our Manifesto
          </Button>
        </div>
      </Section>
      <SectionCta className="u-gradient--senery-alt u-padding--section">
        <SectionCta.Description>
          <h2 class="u-color--yellow--light">We're ready to meet you!</h2>
          <div className="u-spacing--double">
            <div class="u-color--white">
              Is this your next chapter? Come join the team.
            </div>
          </div>
        </SectionCta.Description>
        <SectionCta.Buttons>
          <Button url="#grnhse_app" addClasses="o-button--white">
            View Open Positions
          </Button>
        </SectionCta.Buttons>
      </SectionCta>
    </Layout>
  )
}

export default CareersPage

export const CareersQuery = graphql`
  query CareerPageQuery {
    page: allContentfulAsset(filter: {title: {eq: "Smartling MetaData Default"}}) {
      edges {
        node {
          title
          file {
            url
          }
        }
      }
    }
  }
`
