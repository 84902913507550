import React from 'react'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import GalleryBlock from 'molecules/block-gallery'
import Slider from 'react-slick'
import Section from 'atoms/section'
import Card from 'molecules/card'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionTranslators from 'components/sections/translators'
import Layout from 'layouts'

// Images
import heroImage from '../images/illustrations/how-smartling-works.svg'
import g2Image from '../images/illustrations/g2_icon_rosette-light-ring.svg'
import singleImage from '../images/illustrations/single-source-of-truth.svg'
import catImage from '../images/illustrations/cat-tool.svg'
import productImage from '../images/product-illustration_smartling-UI-qualityscore-white.png'
import animationImage from '../images/product-illustration_smartling-UI-CATtool-animation.gif'
import productGroupImage from '../images/product-group-illustration.png'

// Reviews
import review from '../images/g2-review.png'
import review2 from '../images/g2-review2.png'
import review3 from '../images/g2-review3.png'
import review4 from '../images/g2-review4.png'
import review5 from '../images/g2-review5.png'

// Logo groupings
import logosCMSIntegrations from '../images/logos-CMS-Integrations.png'
import logosMarketingAutomation from '../images/logos-Marketing-Automation.png'
import logosApplicaitonSupport from '../images/logos-Application-Support.png'
import logosCodeRepository from '../images/logos-Code-Repository.png'
import logosCloudStorage from '../images/logos-Cloud-storage.png'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const settings2 = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
}

const galleryImages = [review, review2, review3, review4, review5]

class WhySmartlingPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Why Smartling - Smartling"
          description="Why choose Smartling? Our cloud-based translation management platform is data-driven. We automate the translation process and reduce your cost over time."
          keywords=""
        />
        <Hero
          curveRight
          gradientPrimary
          title="We’re Smartling! 👋"
          className="test"
        >
          <Hero.Description>
            <div className="u-spacing--half">
              <div className="c-hero__description">
                The Leader in Translation Management. We help your business
                automate, manage, and professionally translate content at the
                speed of life.{' '}
                <span role="img" aria-label="rocket">
                  🚀
                </span>
              </div>
            </div>
          </Hero.Description>
          <Hero.Buttons>
            <Button url="/meeting-request/">Request a Demo</Button>
          </Hero.Buttons>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Why Smartling" />
          </Hero.Image>
        </Hero>
        <article className="l-article u-spacing--sections u-spacing--sections--noxl">
          <Section className="c-section__block-rows c-block-rows u-background-accent--right u-background-accent--left">
            <Section.Header>
              <div className="o-badges o-badges--hero-small">
                <img
                  loading="lazy"
                  className="lazyload c-partner-badge"
                  alt="Smartling recognized in Translation Management based on user reviews"
                  data-src={g2Image}
                />
              </div>

              <Block title="Why should you partner with Smartling?">
                Customers have voted us #1 on G2 for our ability to deliver a
                translation management solution unlike most other providers on
                the market. And we’re pretty proud of the growing number of
                world-class enterprises using us to manage their translation
                programs from end-to-end.
              </Block>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <Slider {...settings} className="u-padding--top">
                {galleryImages.map((image) => (
                  <GalleryBlock
                    className="c-gallery-block--small c-gallery-block--review"
                    src={`${image}?fit=fill&w=460`}
                  />
                ))}
              </Slider>
            </Section.Inner>
          </Section>

          <SectionCta
            title="Smartling is your One-Stop Shop for smart, centralized, end-to-end translation management."
            gradientPrimary
            centerCTA
          >
            <SectionCta.Description>
              Smartling enables brands to maximize the output of time, energy,
              and cost spent on translation and localization to deliver
              culturally relevant brand experiences that facilitate global
              growth.
            </SectionCta.Description>
          </SectionCta>

          <SectionCta
            title="Smartling is better equipped to get you Global Ready. 💪"
            centerCTA
          >
            <SectionCta.Description>
              Smartling customers benefit from a robust suite of features and
              functionality designed to centralize control, simplify
              collaboration, accelerate turnaround times, and improve
              translation quality and visibility. See why our customers choose
              us over the competition.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <h2>
                <span role="img" aria-label="arrow down">
                  👇
                </span>
              </h2>
            </SectionCta.Buttons>
          </SectionCta>

          <SectionCta
            reverseCTA
            gradientTertiary
            className="c-cta--why-smartling"
          >
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>Stellar Translation Management</h3>
                <div>
                  Get to market in a flash. Smartling’s Translation Management
                  System is equipped with endless productivity tools to give you
                  real-time control and visibility into your translation
                  process. Our fully integrated, cloud-based platform has
                  hundreds of API endpoints, so your team is fully enabled.
                </div>
                <Button
                  url="/resources/101/the-smartling-advantage/"
                  className="u-spacing"
                >
                  Check out the #1 TMS
                </Button>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img
                loading="lazy"
                src={singleImage}
                alt="single source of truth"
              />
            </SectionCta.ColImage>
          </SectionCta>

          <SectionCta gradientPrimary className="c-cta--why-smartling">
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>Meow!</h3>
                <div>
                  This kitty has bite... A robust translation workbench that
                  enables content writers to work quickly and accurately. It’s a
                  lightning-fast, cloud-based application that revolutionized
                  how translators create content, and communicate with customers
                  to deliver words with meaning.
                </div>
                <Button
                  url="/resources/101/how-smartling-works/"
                  className="u-spacing"
                >
                  Learn about our CAT Tool
                </Button>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img loading="lazy" src={catImage} alt="single source of truth" />
            </SectionCta.ColImage>
          </SectionCta>

          <Section className="c-section__block-rows c-block-rows">
            <Section.Header>
              <Block className="u-spacing">
                <Block.Image />
                <div className="u-spacing">
                  <h3>Easy to Connect</h3>
                  <div>
                    Developer speak reads like gibberish but your translations
                    still have to get done? Smartling’s extensive integrations
                    enable you to seamlessly connect to your CMS, code
                    repository, and marketing automation tools to reduce the
                    burden of localization efforts on your team. We also
                    integrate with most machine translation tools so that you
                    can reach a wider audience with compressed budgets.
                  </div>
                  <Button url="/software/" className="u-spacing">
                    View Integrations
                  </Button>
                </div>
              </Block>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <Slider {...settings2} className="u-padding--top">
                <div className="u-padding">
                  <Card>
                    <Card.Image />
                    <h6>CMS Integrations</h6>
                    <img
                      loading="lazy"
                      src={logosCMSIntegrations}
                      alt="CMS Ingregrations"
                    />
                  </Card>
                </div>
                <div className="u-padding">
                  <Card>
                    <h6>Marketing Automation</h6>
                    <Card.Image />
                    <img
                      loading="lazy"
                      src={logosMarketingAutomation}
                      alt="Marketing Automation"
                    />
                  </Card>
                </div>
                <div className="u-padding">
                  <Card>
                    <h6>Application Support</h6>
                    <Card.Image />
                    <img
                      loading="lazy"
                      src={logosApplicaitonSupport}
                      alt="Application Support"
                    />
                  </Card>
                </div>
                <div className="u-padding">
                  <Card>
                    <h6>Code Repository</h6>
                    <Card.Image />
                    <img
                      loading="lazy"
                      src={logosCodeRepository}
                      alt="Code Repository"
                    />
                  </Card>
                </div>
                <div className="u-padding">
                  <Card>
                    <h6>Cloud Storage</h6>
                    <Card.Image />
                    <img
                      loading="lazy"
                      src={logosCloudStorage}
                      alt="Cloud Storage"
                    />
                  </Card>
                </div>
              </Slider>
            </Section.Inner>
          </Section>

          <SectionCta
            reverseCTA
            gradientTertiary
            className="c-cta--why-smartling"
          >
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>Beauty and a [Data] Beast</h3>
                <div>
                  Smartling’s data-driven platform offers predictive insights
                  into the quality and accuracy of your translated content.
                  That’s because we continuously index hundreds of data points
                  for insights to simplify your translation process, saving you
                  time & money.
                </div>
                <Button
                  url="/software/translation-analytics"
                  className="u-spacing"
                >
                  DATA & ANALYTICS
                </Button>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img loading="lazy" src={productImage} alt="data charts" />
            </SectionCta.ColImage>
          </SectionCta>

          <SectionCta gradientPrimary className="c-cta--why-smartling">
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>Words in Context. It’s a beautiful thing.</h3>
                <div>
                  No one likes a guessing game. Which is why we’ve developed a
                  proprietary tool that shows you how translations will
                  ultimately look in real-time. Translations in context = higher
                  quality work, and fewer go-to-market delays.
                </div>
                <Button
                  url="/resources/101/visual-context-a-translator-tool-that-helps-the-whole-team/"
                  className="u-spacing"
                >
                  Discover Visual Context
                </Button>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img
                loading="lazy"
                src={animationImage}
                alt="Laptop editing title"
                className="u-box-shadow"
              />
            </SectionCta.ColImage>
          </SectionCta>

          <Section className="c-section__block-rows c-block-rows">
            <Section.Header>
              <Block className="u-spacing">
                <Block.Image />
                <div className="u-spacing">
                  <h3>Curated Network of Top Translators.</h3>
                  <div>
                    We have something in common with our customers. We expect
                    the best, which is why we only work with 5% of the
                    professional translators who apply to be a part of our team.
                    You will know your translator, and your translator will know
                    you. We facilitate this relationship with pride and
                    confidence because it leads to better results for everyone.
                  </div>
                  <Button url="/translators/" className="u-spacing">
                    MEET OUR TRANSLATORS
                  </Button>
                </div>
              </Block>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <SectionTranslators />
            </Section.Inner>
          </Section>

          <SectionCta
            reverseCTA
            gradientTertiary
            className="c-cta--why-smartling"
          >
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>Sky’s the limit, baby!</h3>
                <div>
                  When it comes to our technology, we’re always doing more,
                  pushing the boundaries by introducing new product features and
                  bringing the best minds together to deliver the best customer
                  experience.
                </div>
                <Button url="/software/" className="u-spacing">
                  Learn How We Scale
                </Button>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img
                loading="lazy"
                src={singleImage}
                alt="Translation on devices"
              />
            </SectionCta.ColImage>
          </SectionCta>

          <SectionCta gradientPrimary className="c-cta--why-smartling">
            <SectionCta.Description>
              <div className="u-spacing">
                <h3>
                  Calling all brands{' '}
                  <span role="img" aria-label="megaphone">
                    📣
                  </span>
                </h3>
                <div>
                  We help brands of all shapes and sizes localize content across
                  devices and platforms. From marketing content and highly
                  specialized reports, to gaming applications and complicated
                  websites. Brands are ever changing, and so are we.
                </div>
              </div>
            </SectionCta.Description>
            <SectionCta.ColImage>
              <img
                loading="lazy"
                src={productGroupImage}
                alt="Compilation of devices"
              />
            </SectionCta.ColImage>
          </SectionCta>

          <div className="u-background-accent--right u-background-accent--right-small u-background-accent--left u-background-accent--left-small">
            <SectionCta centerCTA className="c-cta--why-smartling">
              <SectionCta.Description>
                <div className="u-spacing">
                  <h2>
                    Ready to swipe right?{' '}
                    <span role="img" aria-label="fire">
                      🔥
                    </span>
                  </h2>
                  <div>
                    Businesses depend on technology to drive efficiency and cost
                    savings. At Smartling, we take this to heart and are
                    equipped to scale alongside your ever-changing business.
                  </div>
                  <div>Book a meeting with our team to get started.</div>
                  <Button url="/meeting-request/" className="u-spacing">
                    REQUEST A DEMO
                  </Button>
                </div>
              </SectionCta.Description>
            </SectionCta>
          </div>
        </article>
      </Layout>
    )
  }
}

export default WhySmartlingPage
