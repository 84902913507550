import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

// Images
import heroImage from '../../../images/integrations/drupal-integration-logo.png'
import imageIntegration from '../../../images/drupal-integration2.png'
import imageRocket from '../../../images/icons/rocket-no-shadow.svg'
import imageCatTool from '../../../images/product-illustration_CATtool-GIF.gif'
import imageTranslation from '../../../images/human-translation.png'
import imageLucid from '../../../images/lucidchart.jpeg'
import imagePinterest from '../../../images/pinterest-03.png'
import imageScore from '../../../images/qualityscore.png'

var imgStyle = {
  maxWidth: '500px',
}

class DrupalIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Drupal TMGMT Integration | Smartling"
          description="Translate Drupal content via Smartling’s Drupal translation integration. Now supporting Drupal 9 TMGMT, Drupal 8 TMGMT, and Drupal 7 Classic."
          keywords=""
          image={heroImage}
          alt="Drupal Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Drupal Translation Integration"
          >
            <Hero.Description>
              Translate nodes, entities, taxonomies and more with Smartling’s
              translation integration for Drupal. Built right into Drupal’s
              translation management (TMGMT) framework, Smartling supports
              Drupal 7, Drupal 8 and Drupal 9.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Drupal logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a Meeting</Button>
              <Button url="https://go.smartling.com/hubfs/Smartling_for_Drupal.pdf" target="_blank">Download Resource</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div class="c-section__header">
                <div class="c-block undefined u-padding--double--bottom">
                  <h3 class="c-block__title">Simplify Localization</h3>
                </div>
              </div>
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <p>
                      Smartling’s pre-built integration enables you to translate
                      a wide range of content types and configure the
                      translation extension in ways that reflect your
                      development environment.
                    </p>
                    <p>
                      Smartling can translate nodes, entities, and
                      internationalization strings like menu labels, terms, and
                      more. The TMGMT framework and Smartling integration
                      drastically simplifies the number of developer hours
                      required to manage localization for global websites.
                    </p>
                    <p>
                      With Smartling as an extension to your Drupal environment,
                      you can easily configure job automation, linguistic assets
                      and style guides, translation memory and customize your
                      workflows.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageIntegration}
                      alt="Drupal integration"
                    />
                  </div>
                </div>
              </div>
            </section>

            <Section className="c-section__grid-blocks c-grid-blocks c-grid-blocks__leadership-team">
              <Section.Header>
                <Block title="See it in action!">
                  Translation for Drupal has never been easier. Join the ranks
                  of Smartling’s world-class customers today.
                </Block>
              </Section.Header>
              <Section.Inner l>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                  <iframe
                    src="https://player.vimeo.com/video/622835294?h=109e799567&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      width: '100%',
                      height: '100%',
                    }}
                    title="5 Things to Know When Localizing Drupal Website in 2022"
                  ></iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </Section.Inner>
            </Section>

            {/* <Section centerCTA>
              <Section.Header>
                <Block title="Download Drupal Integration Overview"></Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center l-container--xs">
                <iframe
                  title="drupalOverview"
                  src="https://resource.smartling.com/l/703963/2021-11-05/2k5kwf"
                  width="100%"
                  height="200"
                  type="text/html"
                  frameborder="0"
                  allowTransparency="true"
                  style={{ border: '0' }}
                ></iframe>
              </div>
            </Section> */}

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageCatTool}
                      alt="Cat Tool demo"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Translate with Visual Context
                    </h3>

                    <p>
                      As you submit content, Smartling automatically ingests
                      context via a Chrome Extension or single line of
                      Javascript so the translator can work in a WYSIWYG
                      interface. It improves translation quality by 30%, which
                      means you can spend less time thinking about translation,
                      and more time building world-class user experiences.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Get Updates in Drupal
                    </h3>
                    <p>
                      Without leaving the developer environment, you can check
                      translation status anytime to answer the age-old question:
                      are my translations ready yet? No more emails with a
                      vendor or project manager. Easy.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageRocket}
                      alt="Rocket"
                      style={{ maxWidth: '200px' }}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageTranslation}
                      alt="Human Translation"
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Professional & machine translation
                    </h3>
                    <p>
                      Considering the volume of content under management to
                      maintain today’s user experience, it is important to have
                      the flexibility to choose what type of translation you
                      apply to your content. Smartling’s Drupal integration
                      enables you to use our professional human translation
                      services and a variety of machine translation options.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Automated quality control
                    </h3>
                    <p>
                      Smartling’s translation management system has nearly 30
                      automated quality controls in place to ensure the target
                      content doesn’t introduce errors in your user experience.
                      This includes things like HTML tag consistency,
                      placeholders, number consistency, typos, double spaces and
                      more.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageScore}
                      alt="Confidence"
                      style={{ minWidth: '400px', marginLeft: '60px' }}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div class="c-section__header">
                <div class="c-block undefined u-padding--double--bottom">
                  <h3 class="c-block__title">
                    Drupal Users{' '}
                    <span role="img" aria-label="heart emoji">
                      💜
                    </span>{' '}
                    Smartling
                  </h3>
                  <div class="c-block__description">
                    <p>
                      <strong>
                        But don't just take our word for it!{' '}
                        <span role="img" aria-label="wink emoji">
                          😉
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="l-grid l-grid--2up">
                <div class="l-grid-item">
                  <div class="c-card u-spacing ">
                    <div class="c-card__body u-spacing--half">
                      <div class="c-card__image">
                        <img
                          loading="lazy"
                          src={imagePinterest}
                          alt="pinterest-illustration"
                        />
                      </div>
                      <h4 class="c-card__title">Pinterest Customer Story</h4>
                      <p class="c-card__description">
                        <div class="u-spacing">
                          <p>
                            "Smartling’s TMS has eliminated a number of daily
                            localization tasks once required of our engineers,
                            freeing up their time to work on other projects."
                          </p>
                          <p>
                            <strong>- Silvia Ovideo-López</strong>, Localization
                            and International Operations
                          </p>
                        </div>
                      </p>
                    </div>
                    <a
                      role="button"
                      href="https://www.smartling.com/resources/case-study/pinterest/"
                      class="o-link"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div class="l-grid-item">
                  <div class="c-card u-spacing ">
                    <div class="c-card__body u-spacing--half">
                      <div class="c-card__image">
                        <img
                          loading="lazy"
                          src={imageLucid}
                          alt="Case Study Tile - Lucidchart"
                        />
                      </div>
                      <h4 class="c-card__title">LucidChart Customer Story</h4>
                      <p class="c-card__description">
                        <div class="u-spacing">
                          <p>
                            "Smartling has allowed us to translate our product
                            into 5 languages quickly and easily, giving us the
                            ability to expand our user base to global,
                            non-English speaking users"
                          </p>
                          <p>
                            <strong>- Mitchell Cox</strong>, International
                            Program Manager
                          </p>
                        </div>
                      </p>
                    </div>
                    <a
                      role="button"
                      href="/resources/case-study/lucidchart/"
                      class="o-link"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Ready to integrate your Drupal websites with Smartling?">
                  Translation for Drupal has never been easier. Join the ranks
                  of Smartling’s world-class customers today.
                </Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a Meeting
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default DrupalIntegrationPage
