import React from 'react'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'
import Button from 'atoms/button'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Card from 'molecules/card'
import Section from 'atoms/section'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'
import SectionTwoColumn from 'organisms/two-column'

import SectionPlatinumSupport from 'components/sections/platinum-support'
import SectionContact from 'components/sections/contact'
import SectionCustomerSuccess from 'components/sections/customer-success'

import Layout from 'layouts'

// Images
import heroImage from '../../../images/illustrations/project-mgmt.png'
import twoColumnImage from '../../../images/illustrations/analytics-cropped.png'

class ProjectManagementPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Project Management For Language Translation - Smartling"
          description="Our project management tools give you the functionality you need to convert your content into any language, from content routing to translator queries."
          keywords=""
        />
        <Hero
          curveLeft
          gradientPrimary
          title="Every language at your fingertips."
        >
          <Hero.Description>
            Spend more time doing your best work. Flip the switch to Smartling’s
            management tools for a modern translation solution that is rich with
            data. Now you can wow the whole world without leaving your desk.
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={heroImage} alt="Cards" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="/meeting-request/">Get a Demo</Button>
          </Hero.Buttons>
        </Hero>

        <article className="l-article u-spacing--sections">
          <Section>
            <Section.Header>
              <Block title="There’s no “I” in data">
                <Block.Image>
                  <Icons.HeaderMachineLearning xxl />
                </Block.Image>
                When teams depend on you, you can depend on Smartling. At every
                point in the translation process, Smartling surfaces data to
                simplify project management. Real-time progress. Translator
                queries. Cost estimates. All the functions you need to convert
                your content into any language.
              </Block>
            </Section.Header>
          </Section>
          <Section className="u-space--double--bottom">
            <Section.Header>
              <Block title="So long copy and paste.">
                <Block.Image>
                  <Icons.HeaderTranslation xxl />
                </Block.Image>
              </Block>
            </Section.Header>
          </Section>
          <SectionTwoColumn
            flip
            white
            bulb
            title="Arrivederci file uploads. Au revoir emails."
          >
            <SectionTwoColumn.Logo />
            <SectionTwoColumn.Description>
              <p>
                Smartling’s translation management system integrates directly
                with your website, mobile app, and more so that you can focus on
                what matters: your words.
              </p>
            </SectionTwoColumn.Description>
            <SectionTwoColumn.Buttons>
              <Button url="/software">How it works</Button>
            </SectionTwoColumn.Buttons>
            <SectionTwoColumn.Image>
              <img
                loading="lazy"
                src={twoColumnImage}
                alt="Translation Automated"
              />
            </SectionTwoColumn.Image>
          </SectionTwoColumn>

          <SectionCta
            title="Translation happens."
            gradientPrimary
            curveTop
            centerCTA
            curveBottom
          >
            <SectionCta.Description>
              Smartling works 24/7 so you don’t have to. Sleep tight knowing
              that your content will be automatically routed to the right
              supplier.
            </SectionCta.Description>
            <SectionCta.Buttons>
              <Button url="/products/automate/integrations">
                Integrate your stack
              </Button>
            </SectionCta.Buttons>
          </SectionCta>
          <Section className="c-section__grid-blocks c-grid-blocks u-background-accent--left">
            <Section.Header>
              <Block title="Automation rules when you make the rules">
                <Block.Image>
                  <Icons.HeaderCloud xxl />
                </Block.Image>
              </Block>
            </Section.Header>
            <Section.Inner l>
              <Grid threeUp className="has-icon-bulbs">
                <Grid.Item>
                  <Block title="Content Routing">
                    <Block.Image>
                      <Icons.IconComputerApps />
                    </Block.Image>
                    Deliver content to the translation supplier of your choice.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Notifications">
                    <Block.Image>
                      <Icons.IconSend />
                    </Block.Image>
                    Ding! Smartling notifies the next user when work is ready
                    for translation, editing or review.
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Translator Queries">
                    <Block.Image>
                      <Icons.IconChatQuote />
                    </Block.Image>
                    Nip all errors in the bud. Reviewers can easily notify the
                    content owner about potential issues with language
                    translations or source words before they are published.
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <Section
            gradientTertiary
            curveTop
            className="c-section__grid-blocks c-grid-blocks"
          >
            <Section.Header>
              <Block title="Centrally managed translator guidelines">
                <Block.Image>
                  <Icons.HeaderOrganization xxl />
                </Block.Image>
                Resources translators really, really like (like really!).
              </Block>
            </Section.Header>
            <Section.Inner l>
              <Grid fourUp className="has-icon-bulbs">
                <Grid.Item>
                  <Block title="Glossary">
                    <Block.Image>
                      <Icons.IconMail />
                    </Block.Image>
                    Key brand terms and instructions on how to use them
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Style guide">
                    <Block.Image>
                      <Icons.IconSwatchbook />
                    </Block.Image>
                    Formatting, tone, and style for brand consistency
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Translation memory">
                    <Block.Image>
                      <Icons.IconCloudSecure />
                    </Block.Image>
                    A database of previously translated content ready to be
                    re-used
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block title="Quality checks">
                    <Block.Image>
                      <Icons.IconCertifiedBadge />
                    </Block.Image>
                    Automatic review for tag consistency, typos, character
                    length, and more
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <div className="u-gradient--primary u-curve--top u-curve--bottom--center u-padding--section">
            <SectionCustomerSuccess />
          </div>

          <Section className="c-section__block-rows c-block-rows">
            <Section.Header>
              <Block title="Set up your team and configure your workspace">
                <Block.Image>
                  <Icons.HeaderStack xxl />
                </Block.Image>
              </Block>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <Grid twoUp className="c-cards">
                <Grid.Item>
                  <Card title="Manage users">
                    <Card.Image />
                    <p>
                      Enterprise-grade permissions enable project managers to
                      configure roles so that users only see what’s relevant.
                    </p>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Customized workflows">
                    <Card.Image />
                    <p>
                      Create one, or many. Smartling adapts to your content and
                      objectives.
                    </p>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Add more languages on demand">
                    <Card.Image />
                    <p>
                      When the business is ready, Smartling is ready. Set up new
                      languages with the click of a button.
                    </p>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="View upfront cost estimates">
                    <Card.Image />
                    <p>
                      Stay within budget. Smartling calculates the cost estimate
                      of each translation job, inclusive of translation
                      leverage, before getting started.
                    </p>
                  </Card>
                </Grid.Item>
                <Grid.Item>
                  <Card title="Plug-in new platforms">
                    <Card.Image />
                    <p>
                      Integrate with your tech and marketing stack. Add content
                      sources with an{' '}
                      <a
                        href="https://help.smartling.com/hc/en-us/articles/115004187694-API-Tokens-and-Authentication"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        API
                      </a>{' '}
                      key and start translating from a familiar workspace.
                    </p>
                  </Card>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>

          <div className="u-gradient--secondary u-curve--top--right u-curve--bottom--center u-padding--section">
            <SectionPlatinumSupport />
          </div>
          <SectionContact />
        </article>
      </Layout>
    )
  }
}

export default ProjectManagementPage
