import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Helmet from 'react-helmet'

// Sections
import Grid from 'atoms/grid'
import LandingPageSection from 'components/sections/landing-page-section'
import Layout from 'layouts'

// Images
import grcLogo from '../images/grc-logo-crop.png'
import globalReady2021 from '../images/global-ready-2021-speakers-opt.png'

import MetaDataImage from '../images/meta-image.png'

const ConferenceTemplate = ({ data }) => (
  <Layout>
    <Helmet>
      <body className="u-header--white" />
    </Helmet>
    <Metadata
      title={data.page.metadataTitle || data.page.title}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
    />
    <div className="c-page-professional-services l-page--grc">
      <section
        style={{
          backgroundImage:
            'url(//images.ctfassets.net/a8pkciehvm1g/6KGFyUOKaYTXYGxmSiR2wK/c65426b32e32fcb0c1699d44f47462a7/GRC_-_Plasma_1_-_Curved_Background_-_1_1x.png)',
        }}
        data-bgset=""
        class="o-section c-section__hero c-hero c-hero--center u-gradient--primary u-curve--bottom--left has-background-image u-overlay"
      >
        <div class="o-section--inner l-wrap l-container--xl">
          <div class="c-hero--left">
            <div class="c-hero__content u-spacing--double">
              <div className="o-grc-logo">
                <img
                  loading="lazy"
                  data-src={grcLogo}
                  alt="GRC Logo"
                  className="lazyload"
                />
              </div>
              <h1 class="c-hero__title o-section-title--heavy">
                Now On Demand
              </h1>
              <p class="c-hero__description">
                <div class="u-spacing">
                  <p>Re-live the Global Ready Conference anywhere, today.</p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a name="o-pardot-form" class="u-block">
                    <div class="o-pardot-form">
                      <iframe
                        class="u-margin--zero"
                        title="Pardot Form"
                        id="pardot-newsletter"
                        scrolling="no"
                        src="https://resource.smartling.com/l/703963/2021-04-26/23lr1v"
                        width="100%"
                      ></iframe>
                    </div>
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="o-section u-padding--quad--top">
        <h1 className="c-hero__title o-section-title--heavy u-text-align--center u-space--quad--top">
          Are You Global Ready?
        </h1>
        <div className="o-section--inner u-flex u-justify-content--center u-space--double--top">
          <ul className="o-bullet-list o-bullet-list--less-pad u-padding u-space--left u-space--right">
            <li>Industry Expert Speakers</li>
            <li>Exciting & Unique Sessions</li>
            <li>Jaw Dropping Insights</li>
          </ul>
          <ul className="o-bullet-list o-bullet-list--less-pad u-padding u-space--left u-space--right">
            <li>Playbook for Globalization</li>
            <li>Live Entertainment</li>
            <li>Smartling+ Announcement</li>
          </ul>
        </div>

        <div class="o-section--inner l-wrap l-container--l ">
          <div class="c-integrate-platform__content">
            <Grid fourUp>
              <Grid.Item className="u-spacing--half u-text-align--center">
                <div class="o-heading--xxl u-color--primary">7</div>
                <h4>Sessions</h4>
              </Grid.Item>
              <Grid.Item className="u-spacing--half u-text-align--center">
                <div class="o-heading--xxl u-color--primary">16</div>
                <h4>Speakers</h4>
              </Grid.Item>
              <Grid.Item className="u-spacing--half u-text-align--center">
                <div class="o-heading--xxl u-color--primary">2,000+</div>
                <h4>Streams</h4>
              </Grid.Item>
              <Grid.Item className="u-spacing--half u-text-align--center">
                <div class="o-heading--xxl u-color--primary">10+</div>
                <h4>Industries Represented</h4>
              </Grid.Item>
            </Grid>
          </div>
        </div>
      </section>

      <section className="o-section u-padding--quad--top">
        <Grid twoUp>
          <Grid.Item>
            {/* TODO: remove the inline styling */}
            <section
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <img
                  loading="lazy"
                  src={globalReady2021}
                  alt="Global Ready 2021 Speakers"
                  style={{ maxWidth: '600px' }}
                />
              </div>
            </section>
          </Grid.Item>
          <Grid.Item>
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <div>
                <h1 className="c-hero__title o-section-title--heavy u-text-align--center">
                  World Class Speakers
                </h1>
                <div className="o-section--inner u-flex u-justify-content--center u-space--double--top">
                  <ul className="o-bullet-list o-bullet-list--less-pad u-padding u-space--left u-space--right">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/minettenorman/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Minette Norman - The Lanvin Agency
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/uwemuegge/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Uwq Muegge - Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/heathslawner/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Heath Slawner - Simon Sinek
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/carolinexie/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Caroline Xie - ICONIQ Capital
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/dpakman/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        David Pakman - Venrock Associatate
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/nancyferreira/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Nancy Ferreira da Rocha - Fedex
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/emil-d-atanassov/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Emil Atanassov - ServiceNow
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/peterfog/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Peter Fogelsanger - Contentstack
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </Grid.Item>
        </Grid>
      </section>

      <section className="o-section u-padding--quad--top">
        <div class="c-section__header">
          <div class="c-block undefined u-spacing">
            <div class="c-block__text u-spacing">
              <div class="u-spacing">
                <h2 class="c-cta__title">
                  Musical Guests
                  <br />
                  The California Honeydrops
                </h2>
                <div class="c-cta__description">
                  <div class="u-spacing">
                    Music for the soul. This performance is bound to get you out
                    of your seat and dancing in your living room{' '}
                    <span role="img" aria-label="trumpet">
                      🎺
                    </span>
                    <span role="img" aria-label="piano">
                      🎹
                    </span>
                    <span role="img" aria-label="saxophone">
                      🎷
                    </span>
                    <span role="img" aria-label="musical notes">
                      🎶
                    </span>
                  </div>
                </div>
                <div class="c-cta__video">
                  <iframe
                    title="Video"
                    src="https://player.vimeo.com/video/536476570"
                    width="640"
                    height="564"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {data.page.pageSections &&
        data.page.pageSections.map((section, index) => (
          <LandingPageSection
            {...section}
            disableLazy={index === 0 ? true : false}
          />
        ))}
    </div>
  </Layout>
)

export default ConferenceTemplate

export const ConferenceQuery = graphql`
  query Conference21TemplateQuery {
    page: contentfulBasicPage(path: { eq: "conference" }) {
      title
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataDescription
      metadataKeywords

      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionRowFragment
        ...ContentfulLandingPageSectionGridFragment
      }
    }
  }
`
