import React from 'react'
import { graphql } from 'gatsby'
import { getTranslatorPath } from '../routes'
import GalleryBlock from 'molecules/block-gallery'
import Layout from 'layouts'
import Section from 'atoms/section'
import Block from 'molecules/block'
import Slider from 'react-slick'
import Metadata from '../layouts/metadata'
import { GatsbyImage } from 'gatsby-plugin-image'

const translatorSettings = {
  dots: false,
  speed: 500,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const settings = {
  dots: false,
  speed: 500,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const TranslatorTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={`Meet ${data.page.title} - Smartling`}
      description=""
      keywords=""
      image=""
    />
    <article className="l-page--gallery l-article u-spacing--sections">
      <Section
        center
        curveBottom
        gradientSecondary
        className="c-hero__header c-hero__header--gallery"
      >
        <Section.Header>
          <div className="o-breadcrumbs">
            <a href="/">Home</a>
            <div className="o-divider" />
            <a href="/translators">Translators</a>
            <div className="o-divider" />
            {data.page.slug && (
              <a href={getTranslatorPath(data.page.slug)}>{data.page.title}</a>
            )}
          </div>
          <Block
            className="u-padding--top u-padding--bottom"
            title={data.page.title}
          />
        </Section.Header>
        <Section.Inner xl>
          <Slider {...translatorSettings}>
            {data.page.galleryImages.map((image, i) => {
              return (
                <div key={i} className="slider-item">
                  {image?.gatsbyImageData ? (
                    <figure>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.title}
                        objectFit="contain"
                        title={image.description}
                      />
                      <figcaption style={{ marginTop: '1rem' }}>
                        {image.description}
                      </figcaption>
                    </figure>
                  ) : (
                    <figure>
                      <img
                        loading="lazy"
                        src={image?.file?.url || ''}
                        alt={image?.title || ''}
                      />
                      <figcaption style={{ marginTop: '1rem' }}>
                        {image.description}
                      </figcaption>
                    </figure>
                  )}
                </div>
              )
            })}
          </Slider>
        </Section.Inner>
      </Section>
      {data.moreGalleries && (
        <Section className="c-section__gallery-recirc">
          <Section.Inner xl className="u-spacing">
            <h3>Who would you like to meet next?</h3>
            <Slider {...settings}>
              {data.moreGalleries.edges.map(
                ({ node }) =>
                  node.galleryImages[0] && (
                    <GalleryBlock
                      className="c-gallery-block--small"
                      title={node.title}
                      src={`${node.galleryImages[0].file.url}?fit=fill&w=300&h=300`}
                      slug={getTranslatorPath(node.slug)}
                    />
                  )
              )}
            </Slider>
          </Section.Inner>
        </Section>
      )}
    </article>
  </Layout>
)

export default TranslatorTemplate

export const translatorQuery = graphql`
  fragment ContentfulGalleryFragment on ContentfulGalleryConnection {
    totalCount
    edges {
      node {
        slug
        title
        galleryImages {
          ...ContentfulImageFragment
        }
      }
    }
  }
  query TranslatorTemplateQuery($slug: String!) {
    page: contentfulGallery(slug: { eq: $slug }) {
      slug
      title
      galleryImages {
        ...ContentfulImageFragment
        gatsbyImageData(
          formats: [WEBP, AUTO]
          placeholder: BLURRED
          width: 1200
          height: 800
          layout: CONSTRAINED
        )
        file {
          url
        }
        description
      }
    }
    moreGalleries: allContentfulGallery(
      sort: { fields: [order], order: ASC }
      filter: { slug: { ne: $slug } }
    ) {
      ...ContentfulGalleryFragment
    }
  }
`
