import React from 'react'
import Button from 'atoms/button'
import Block from 'molecules/block'
import Metadata from '../../../layouts/metadata'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'
import Grid from 'atoms/grid'

// Images
import heroImage from '../../../images/integrations/contenstack.png'
import imagePuzzle from '../../../images/icons/icon_integration-light.svg'
import imageIntegration from '../../../images/integrations/smartling-contentstack-integration.png'
import videoContentstack from '../../../images/video/contentstack-demo-v2.mp4'
import videoPoster from '../../../images/contentstack-poster.jpg'
var imgStyle = {
  maxWidth: '500px',
}

class ContentstackIntegrationPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Contentstack Translation Integration | Smartling"
          description="Translate Contentstack content through the Smartling for Contentstack translation integration. Check it out today."
          keywords=""
          image={heroImage}
          alt="Zendesk Translation Integration for Smartling"
        />
        <div className="c-integration-detail">
          <Hero
            curveBottom
            gradientSecondary
            title="Contentstack Translation Integration"
          >
            <Hero.Description>
              Smartling for Contentstack enables enterprise developers and
              business users to build and deploy content across every channel
              and touchpoint in the consumer journey into any language.
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                style={imgStyle}
                src={heroImage}
                alt="Zendesk logo"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Book a demo</Button>
            </Hero.Buttons>
          </Hero>
          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Translate Contentstack omnichannel experiences into any
                      language
                    </h3>
                    <p>
                      <a
                        href="https://www.contentstack.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contentstack
                      </a>{' '}
                      enables enterprise developers and business users to build
                      and deploy content across every channel and touchpoint in
                      the consumer journey. This content is inherently
                      global—anyone can access it with a web browser or smart
                      device so long as they have access to the internet. How do
                      you ensure those experiences are personalized for your key
                      markets?
                    </p>
                    <p>
                      Smartling is the cutting-edge translation company with
                      technology to automate translation and fully integrated
                      translation services that enables you to reach consumers
                      anywhere.
                    </p>
                    <p>
                      Together, Smartling for Contentstack enables seamless
                      translation, by integrating with Entries and Releases,
                      capturing source content and visual context to trigger
                      Smartling’s high-quality translation workflow.
                    </p>
                    <p>
                      All translations are pulled into Contentstack
                      automatically without any human intervention. Easy peasy.
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageIntegration}
                      alt="Zendesk integration"
                    />
                  </div>
                </div>
              </div>
            </section>

            <Section
              gradientSecondary
              className="c-section__grid-blocks c-grid-blocks u-padding--section"
            >
              <Section.Inner xl>
                <Grid twoUp>
                  <Grid.Item className="c-block u-spacing--half">
                    <h4 id="videos">Smartling for Contentstack</h4>
                    <iframe
                      title="Zendesk"
                      src="https://player.vimeo.com/video/605939786?h=4443322eb6?transparent=false"
                      width="640"
                      height="360"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                  </Grid.Item>
                  <Grid.Item className="c-block u-spacing--half">
                    <h4>Understanding the New Hybrid App</h4>
                    <iframe
                      title="Understanding the New Hybrid App | Smartling & Contentstack"
                      src="https://player.vimeo.com/video/706202981?h=844608b8e5"
                      width="640"
                      height="360"
                      frameborder="0"
                      webkitallowfullscreen
                      mozallowfullscreen
                      allowfullscreen
                    ></iframe>
                  </Grid.Item>
                </Grid>
              </Section.Inner>
            </Section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap l-reverse">
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imagePuzzle}
                      alt="Puzzle Pieces"
                      style={{ maxWidth: '350px' }}
                    />
                  </div>
                </div>
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Smartling for Contentstack is a Translation Extension
                    </h3>

                    <p>
                      Smartling’s integration with Contentstack is designed to
                      feel like an extension to a familiar workspace and offers
                      all of the tools you need to manage translation.
                      <br />
                      <ol className="o-list o-list--numbered--pink">
                        <li>
                          Search, filter and sort Entries and Releases to easily
                          locate and authorize translations
                        </li>
                        <li>Track changes to translated and edited content</li>
                        <li>
                          Configure and customize translation workflows,
                          automations and quality control
                        </li>
                        <li>
                          Communicate directly with the human translator to
                          optimize the content and resolve questions
                        </li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      Smartling for Contentstack Workflow
                    </h3>
                    <ul class="o-list">
                      <li>Contentstack Entries and Releases</li>
                      <li>Smartling Integration for Contentstack</li>
                      <li>Request Translations</li>
                      <li>Customizable Translation Workflow</li>
                      <li>Automatically pull Translations to Contentstack</li>
                    </ul>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video controls poster={videoPoster}>
                      <source type="video/mp4" src={videoContentstack} />
                    </video>
                  </div>
                </div>
              </div>
            </section>
            <Section className="l-wrap l-container--l">
              <Section.Header>
                <Block title="Five Reasons Why Contentstack Customers Choose Smartling">
                  <Block.Image>
                    <Icons.IconStarCircleLight xxxl />
                  </Block.Image>
                </Block>
              </Section.Header>
              <Section.Inner s className="u-spacing--double">
                <div className="c-card--full-height">
                  <div className=" u-text-align--left">
                    {/* one */}
                    <div className="u-spacing--quad">
                      <div class="c-block__description u-text-align--left u-spacing">
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="1" class="u-font--l">
                              1️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Onboard in 30 days or less.</b> We make your
                            success our priority.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="2" class="u-font--l">
                              2️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Context matters.</b> Statistically improve
                            translation quality with a WYSIWYG editor for
                            linguists and reviewers.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="3" class="u-font--l">
                              3️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Automate translation.</b> Eliminate all the
                            friction in the translation workflow by automating
                            the entire process surrounding professional
                            translation. Plug it in, and focus developers on
                            other initiatives that will have a higher impact on
                            the end user experience.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span
                              role="img"
                              aria-label="4"
                              class="u-font--l"
                              and-half--
                            >
                              4️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Know your translator.</b> The translator’s task
                            is to re-write your content in a language you
                            probably don’t speak. Smartling’s unparalleled
                            access to translators gives you confidence, and the
                            translator resources to improve their work.
                            <br />
                          </div>
                        </div>
                        <div class="u-flex">
                          <div>
                            <span role="img" aria-label="5" class="u-font--l">
                              5️⃣
                            </span>
                          </div>
                          <div class="u-padding--left">
                            <b>Customizable infrastructure.</b> Every customer
                            has their own process and requirements. Smartling’s
                            translation management system is capable, feature
                            rich, and fully customizable.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Section.Inner>
            </Section>

            <Section centerCTA>
              <Section.Header>
                <Block title="Top-Rated Squared">
                  <Block.Image>
                    <Icons.IconGoals xxxl />
                  </Block.Image>
                  Integrate Smartling for Contentstack to access the G2 Rated #1
                  Headless CMS and the #1 Translation Management System and
                  translation services provider to translate and localize your
                  content today.
                </Block>
              </Section.Header>
              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Book a demo
                </a>
              </div>
            </Section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default ContentstackIntegrationPage
