import React from 'react'
import { Link, graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Grid from 'atoms/grid'
import BlockPost from 'molecules/block-post'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Layout from 'layouts'

import MetaDataImage from '../images/meta-image.png'

const ResourceTagTemplate = ({ data, error }) => (
  <Layout>
    <Metadata
      title={data.page.metadataTitle || `${data.page.name} - Smartling`}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
      image={MetaDataImage}
      noindex
    />
    <Hero
      curveCenter
      center
      gradientSecondary
      className="c-hero__tags"
      title={data.page.name}
    >
      <Hero.Kicker>
        <Link to="/resources">Learning Center</Link>
      </Hero.Kicker>
    </Hero>
    <article className="l-article u-space--quad--top">
      <Section>
        <Section.Inner xl>
          {data.all ? (
            <Grid threeUp className="c-resources">
              {data.all.edges.map(({ node }) => (
                <Grid.Item>
                  <BlockPost {...node} />
                </Grid.Item>
              ))}
            </Grid>
          ) : (
            <p className="u-text-align--center">
              Sorry, there are no posts at this time.
            </p>
          )}
        </Section.Inner>
      </Section>
    </article>
  </Layout>
)

export default ResourceTagTemplate

export const query = graphql`
  query ResourceTagTemplateQuery($key: String!) {
    page: contentfulResourceTag(key: { eq: $key }) {
      name
      key
      metadataTitle
      metadataDescription
      metadataKeywords
    }
    all: allContentfulResource(
      sort: { fields: [publishDate], order: DESC }
      filter: { tags: { elemMatch: { key: { eq: $key } } } }
    ) {
      ...ContentfulResourceFragment
    }
  }
`
