import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const HubSpotForm = ({ portalId, formId, campaignId }) => {
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement('script');
      script.charset = 'utf-8';
      script.type = 'text/javascript';
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.onload = () => {
        if (window.hbspt) {
          const formOptions = {
            region: 'na1',
            portalId: portalId,
            formId: formId,
            target: '#hubspot-form-container'
          };

          if (campaignId) {
            formOptions.sfdcCampaignId = campaignId;
          }

          window.hbspt.forms.create(formOptions);
        }
      };
      document.body.appendChild(script);
    };

    loadScript();

  }, [portalId, formId, campaignId]);

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="/iframe-resizer-client-script.js"
          async
        ></script>
        <script
          type="text/javascript"
          src="/hubspot-form-style-injection.js"
          async
        ></script>
      </Helmet>
      <div className="o-hubspot-form u-block" id="hubspot-form-container"></div>
    </>
  );
};

export default HubSpotForm;

