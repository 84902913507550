import React, { useState } from 'react'
import cx from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'

const iconCheckbox = '../images/icons/checkmark-icon-purp.svg'

const LanguageSwitcher = ({
  title,
  clickTitle,
  clickBtn,
  language,
  iconFlip,
}) => {
  const [currLang, setCurrLang] = useState('EN')

  const languageOptions = [
    {
      title: 'English',
      subTitle: '(Default Selected)',
      url: 'https://smartling.com',
      langCode: 'EN',
    },
    {
      title: 'Español',
      subTitle: '[ES]',
      url: 'https://es.smartling.com',
      langCode: 'ES',
    },

    {
      title: 'Español',
      subTitle: '[MX]',
      url: 'https://es-mx.smartling.com',
      langCode: 'MX',
    },

    {
      title: 'Français',
      subTitle: '[FR]',
      url: 'https://fr.smartling.com',
      langCode: 'FR',
    },

    {
      title: 'Français',
      subTitle: '[CA]',
      url: 'https://fr-ca.smartling.com',
      langCode: 'CA',
    },

    { title: 'Deutsch', url: 'https://de.smartling.com', langCode: 'DE' },

    { title: 'Dansk', url: 'https://dk.smartling.com', langCode: 'DK' },

    { title: 'Svenska', url: 'https://se.smartling.com', langCode: 'SE' },

    { title: 'Nederlands', url: 'https://nl.smartling.com', langCode: 'NL' },

    { title: 'Italiano', url: 'https://it.smartling.com', langCode: 'IT' },

    { title: 'Português', url: 'https://pt.smartling.com', langCode: 'PT' },

    {
      title: 'Chinese',
      subTitle: '[Simplified]',
      url: 'https://cn.smartling.com',
    },
  ]

  const languageSelector = cx(`c-language-selector--toggle`, {
    'icon-flip': iconFlip,
  })

  const languageSelectorGroup = cx(`c-language-selector--group`, {
    'language-active': language,
  })

  return (
    <div className="c-language-selector sl_norewrite language_selector">
      <button className={languageSelector} onClick={clickBtn}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="11" cy="11" r="10" stroke="white" stroke-width="1.5" />
          <ellipse
            cx="11.0248"
            cy="11"
            rx="3.48624"
            ry="10"
            stroke="white"
            stroke-width="1.5"
          />
          <path
            d="M1.36694 14.7967H20.4495"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
          <path
            d="M1.36694 7.92291H20.4495"
            stroke="white"
            stroke-width="1.5"
            stroke-miterlimit="10"
          />
        </svg>
        {currLang}
      </button>
      <div className={languageSelectorGroup}>
        <ul className="c-language-selector__list">
          {languageOptions?.map((lang, i) => (
            <li className="c-language-selector__list--item" key={i}>
              <a href={lang.url} onClick={clickTitle}>
                <div>
                  <span className="u-font-weight--semi-bold">{lang.title}</span>
                  &nbsp;{lang.subTitle}
                </div>
                {currLang === lang.langCode && (
                  <StaticImage
                    style={{ marginRight: '1rem' }}
                    src={iconCheckbox}
                    className="c-language-selector__active-image"
                    width={16}
                    height={16}
                    lazy="eager"
                    alt="checkmark icon"
                  />
                )}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LanguageSwitcher
