import React from 'react'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'
import Block from 'molecules/block'
import GalleryBlock from 'molecules/block-gallery'
import Section from 'atoms/section'
import Slider from 'react-slick'
import * as Icons from 'atoms/icons'

// Sections
import Hero from 'organisms/hero'
import SectionCta from 'organisms/cta'

import Layout from 'layouts'

// Images
import heroImage from '../images/pressplay-hero1.svg'
import metaImage from '../images/pressplay-meta.png'
import devicesImage from '../images/illustrations/devices.png'
import g2Image from '../images/illustrations/g2_icon_rosette-light-ring.svg'
import logoYext from '../images/company-logos/logo-yext.png'
import imageNao from '../images/nao-left.png'

// Reviews
import review from '../images/g2-5-2020.png'
import review2 from '../images/g2-2-2020.png'
import review3 from '../images/g2-3-2020.png'
import review4 from '../images/g2-4-2020.png'

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const galleryImages = [review, review2, review3, review4]

class ManagePage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Press Play on Translation Today | Smartling"
          description="Translation and localization can no longer be an afterthought. Reach global customers today with the top rated translation management solution on the market."
          keywords=""
          image={metaImage}
        />
        <div className="c-page-manage">
          <Hero
            curveLeftReverse
            gradientPrimary
            title="Press Play on Translation"
          >
            <Hero.Description>
              <div className="u-spacing--and-half">
                <div className="c-hero__cta-text">
                  We all had to press pause on something last year. The office,
                  hot yoga, a trip around the world... Now it’s time to kick off
                  the year anew and press play on your global content strategy.
                </div>
              </div>
            </Hero.Description>
            <Hero.Image>
              <img
                loading="lazy"
                src={heroImage}
                alt="Global Delivery Network"
              />
            </Hero.Image>
            <Hero.Buttons>
              <Button url="/meeting-request/">Schedule a meeting</Button>
            </Hero.Buttons>
          </Hero>

          <article className="l-article u-spacing--sections">
            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing">
                    <h3 class="o-section-title o-section-title--heavy">
                      Digital-First for a Virtual World
                    </h3>
                    <p>
                      From catch-ups with extended family to ordering groceries
                      and professional networking, 2020 turned traditional
                      in-person activities into virtual events. McKinsey
                      recently published a report that says years long digital
                      implementation strategies have been{' '}
                      <a
                        href="https://www.mckinsey.com/featured-insights/coronavirus-leading-through-the-crisis/charting-the-path-to-the-next-normal/coronavirus-is-accelerating-digital-strategy-formulation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        compressed into weeks
                      </a>
                      . Customers expect smooth digital experiences on their
                      terms. Part of this digital transformation means your
                      experience has to be updated more quickly—including the
                      translated versions of your website, app and product. Can
                      you keep pace with the language translation requirements?
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={devicesImage}
                      alt="Devices Illustration"
                    />
                  </div>
                </div>
              </div>
            </section>

            <Section
              className="c-section__block-rows c-block-rows"
              style={{ marginTop: 120 + 'px' }}
            >
              <Section.Header className="u-text-align--center">
                <div className="o-badges o-badges--hero-small">
                  <img
                    loading="lazy"
                    className="lazyload c-partner-badge"
                    alt="Smartling recognized in Translation Management based on user reviews"
                    data-src={g2Image}
                  />
                </div>

                <Block title="Press Play with the Category Leader">
                  Customers have{' '}
                  <a
                    href="https://www.g2.com/categories/translation-management"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    voted us #1 on G2
                  </a>{' '}
                  nine consecutive quarters for our ability to deliver a
                  translation management solution unlike any other on the
                  market. That’s because we help word-class brands—of all shapes
                  and sizes—automate, manage, and professionally translate their
                  content without the fuss.
                </Block>
              </Section.Header>
              <Section.Inner l className="u-spacing--double">
                <Slider {...settings} className="u-padding--top">
                  {galleryImages.map((image) => (
                    <GalleryBlock
                      className="c-gallery-block--small c-gallery-block--review"
                      src={`${image}?fit=fill&w=460`}
                    />
                  ))}
                </Slider>
                <div className="u-text-align--center">
                  <Button url="/software/translation-management-system/">
                    Discover our TMS
                  </Button>
                </div>
              </Section.Inner>
            </Section>

            <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item u-space--and-half--bottom">
                  <div class="u-spacing--and-half o-divider-gradient">
                    <h3 class="o-section-title o-section-title--heavy">
                      Seamless Translation with Zero Headaches
                    </h3>
                  </div>
                </div>
                <div className="l-grid-item u-spacing--and-half">
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span role="img" class="o-emoji" aria-label="cloud emoji">
                        ⛅️
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Centralize efforts. Maximize outputs.</h3>
                      <p>
                        Smartling customers benefit from a robust suite of
                        features and functionality designed to centralize
                        translation. See how our cloud-based technology gives
                        you real-time control and visibility into your
                        translation process.
                      </p>
                      <p>
                        <a href="/software/" class="o-link">
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="cool face emoji"
                      >
                        😎
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Easy Integrations. Effortless translation.</h3>
                      <p>
                        Connecting your tech stack is a breeze so you can spend
                        less time managing translation. Check out our extensive
                        integrations list and start automating your translation
                        workflows.
                      </p>
                      <p>
                        <a href="/software/integrations/" class="o-link">
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="laptop emoji"
                      >
                        👩‍💻
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Deliver websites in any language.</h3>
                      <p>
                        Smartling’s Global Delivery Network helps you get your
                        website up and running, into any language, in a flash.
                        Discover the website proxy that works seamlessly with
                        your CMS or web application.
                      </p>
                      <p>
                        <a
                          href="/software/global-delivery-network/"
                          class="o-link"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="c-icon-text-lockup">
                    <div class="c-icon-text-lockup__icon">
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="lightening emoji"
                      >
                        ⚡️
                      </span>
                    </div>
                    <div class="u-spacing--half c-icon-text-lockup__text">
                      <h3>Hundreds of API endpoints.</h3>
                      <p>
                        With endless customizations. See why developers love
                        Smartling’s API service to seamlessly scale translation
                        management for any application.
                      </p>
                      <p>
                        <a href="/software/api/" class="o-link">
                          Learn More
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="o-section--inner l-wrap l-container--l u-padding--left u-padding--right c-product-intro-section">
              <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                <div className="l-grid-item">
                  <div class="u-spacing--and-half">
                    <h3 class="o-section-title o-section-title--heavy">
                      A World-Class Translation Partner
                    </h3>
                    <p>
                      We source top translators from around the globe based on
                      your specific translation needs. Professional translation?
                      Check. Transcreation? Check. MT post-editing? Check. We
                      deliver translations to our customers on time and against
                      rigorous quality and preference standards. You can think
                      of us as the one-stop shop for translation because that’s
                      exactly what we are.{' '}
                      <span
                        role="img"
                        class="o-emoji"
                        aria-label="cool face emoji"
                      >
                        😎
                      </span>
                    </p>
                    <p>
                      <a href="/translation-services/sls" class="o-link">
                        Discover our Language Services
                      </a>
                    </p>
                  </div>
                </div>
                <div className="l-grid-item u-padding--top">
                  <div class="c-product-intro-image">
                    <img
                      loading="lazy"
                      src={imageNao}
                      alt="Nao, a translator"
                    />
                  </div>
                </div>
              </div>
            </section>

            <div className="u-gradient--secondary u-curve--top u-curve--bottom u-padding--section">
              <SectionCta title="Yext Customer Case Study">
                <SectionCta.Description>
                  <div className="u-spacing--double">
                    <div>
                      Yext reduced translation costs by 25% by partnering with
                      Smartling. Discover how they pulled it off.
                    </div>
                    <img
                      loading="lazy"
                      src={logoYext}
                      alt="Yext logo"
                      style={{ maxWidth: '65px' }}
                    />
                  </div>
                </SectionCta.Description>
                <SectionCta.Buttons>
                  <Button url="/resources/case-study/yext/">Learn More</Button>
                </SectionCta.Buttons>
              </SectionCta>
            </div>

            <Section centerCTA>
              <Section.Header>
                <Block title="Your Content. Any language.">
                  <Block.Image>
                    <Icons.IconVideo xxxl />
                  </Block.Image>
                  Book a meeting with our team and we’ll provide you with expert
                  guidance on how to press play on your global communication
                  requirements. Ready, set, translate <em>today</em>!
                </Block>
              </Section.Header>

              <div class="u-spacing u-text-align--center">
                <a
                  class="o-button  u-space"
                  href="/meeting-request/"
                  role="button"
                >
                  Schedule a Meeting
                </a>
              </div>
            </Section>
            <div className="u-gradient--tertiary u-curve--top">
              <section class="o-section--inner l-wrap l-container--l c-product-intro-section">
                <div className="l-grid l-grid--2up l-grid--align-top l-grid--100-column-gap">
                  <div className="l-grid-item">
                    <div class="u-spacing">
                      <h3 class="o-section-title o-section-title--heavy">
                        Press Play on Translation
                      </h3>
                      <p>
                        Watch{' '}
                        <a
                          href="/resources/101/press-play-on-translation-management-for-2020-webinar/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          this webinar
                        </a>{' '}
                        now to discover why buying a new translation management
                        system will enable your team to drive greater revenue
                        efficiency (and how to do it).
                      </p>
                    </div>
                    <div class="u-spacing--double">
                      <div></div>
                      <a
                        class="o-button"
                        href="/resources/101/press-play-on-translation-management-for-2020-webinar/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        view on demand
                      </a>
                    </div>
                  </div>
                  <div className="l-grid-item u-padding--top">
                    <div className="o-video">
                      <iframe
                        title="video 1"
                        src="https://player.vimeo.com/video/489502224?title=0&byline=0&portrait=0"
                        frameborder="0"
                        width="560px"
                        height="315px"
                        allow="autoplay;
                        encrypted-media"
                        allowfullscreen
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </article>
        </div>
      </Layout>
    )
  }
}

export default ManagePage
