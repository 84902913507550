import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionTranslationServices = () => (
  <SectionCta title="Your translators. Or ours.">
    <SectionCta.Description>
      318 million words are translated in Smartling every year by Translation
      Services. Used by many. Trusted by all.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button
        outline
        target="_blank"
        url="https://help.smartling.com/hc/en-us/articles/115003066613-Add-an-Agency"
      >
        Add your agency
      </Button>
      <Button url="/products/translate/translation-services/">
        Or use ours
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionTranslationServices
