import React from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import { DangerousHTML } from 'atoms/contentful/html'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

// Sections
import Hero from 'organisms/hero'
import Section from 'atoms/section'
import Grid from 'atoms/grid'
import Block from 'molecules/block'
import Button from 'atoms/button'

import GalleryBlock from 'molecules/block-gallery'
import Slider from 'react-slick'

import Layout from 'layouts'
import { Headphones } from 'atoms/icons'
const SuccessImage = '../images/HowToUnleashSuccess.png'

const settingsLogos = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
}

const LearningHubPageTemplate = ({ data }) => (
  <Layout>
    <Metadata
      title={data.page.title ? `${data.page.title} - Smartling` : 'Smartling'}
      description={data.page.metadataDescription}
      keywords={data.page.metadataKeywords?.join(`, `)}
    />
    <div className="c-page-learning-hub">
      <Hero curveLeftReverse gradientPrimary title={data.page.title}>
        {data.page.shortDescription && (
          <Hero.Description>
            <div className="u-spacing--and-half">
              <div className="c-hero__cta-text">
                {data.page.shortDescription}
              </div>
            </div>
          </Hero.Description>
        )}
        {data.page.heroImage?.file && (
          <Hero.Image>
            <GatsbyImage
              image={data.page.heroImage.gatsbyImageData}
              alt={data.page.title}
            />
          </Hero.Image>
        )}
        <Hero.Buttons>
          <Button url="#proguide">Download Pro Guide</Button>
        </Hero.Buttons>
      </Hero>
      <article className="l-article u-spacing">
        {data.page.body?.html?.text && (
          <Section centerCTA>
            <Section.Header>
              <div className="c-block u-spacing">
                <div className="o-text u-spacing">
                  <div className="u-spacing">
                    <h1>{data.page.heading}</h1>
                    <div className="c-block__description">
                      <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                      {data.page.heroButtons &&
                        data.page.heroButtons.map((button) => (
                          <a
                            href={button.buttonLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p style={{ marginTop: '20px' }}>
                              <strong>{button.buttonText}</strong>
                            </p>
                          </a>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </Section.Header>
          </Section>
        )}

        {/* TODO convert this to queried data once layout is approved */}
        {true && (
          <Section>
            <Section.Inner l>
              <Block>
                <Grid twoUp>
                  <Grid.Item>
                    <Block>
                      <Block.Image>
                        <StaticImage
                          width={480}
                          height={271}
                          className="c-gallery-block--review"
                          src="../images/HowToUnleashSuccess.png"
                          alt="Unleash Success"
                        />
                      </Block.Image>
                    </Block>
                  </Grid.Item>
                  <Grid.Item>
                    <Block className="u-text-align--left">
                      <h3 class="c-block__title u-color--primary" id="proguide">
                        Pro Guide: How to Unleash Success in Your Localization
                        Career
                      </h3>
                      <p>
                        A comprehensive guide filled with career-elevating
                        formulas to develop the right skills and knowledge
                        within localization & beyond!
                      </p>
                    </Block>
                    <iframe
                      title="iframe"
                      src="https://resource.smartling.com/l/703963/2022-01-19/2phj53"
                      width="100%"
                      height="320"
                      type="text/html"
                      frameborder="0"
                      allowTransparency="true"
                      style={{ border: '0' }}
                    ></iframe>
                  </Grid.Item>
                </Grid>
              </Block>
            </Section.Inner>
          </Section>
        )}

        {data.page.ads && (
          <Section>
            <Section.Header>
              <div className="c-block u-spacing">
                <div className="o-text u-spacing">
                  <h1 id="interactive-workshops">Interactive Workshops</h1>
                </div>
              </div>
            </Section.Header>
            <Section.Inner xl className="u-spacing--quad">
              <Block>
                <Grid threeUp>
                  {data.page.ads.map((ad) => (
                    <Grid.Item className="hover-up">
                      <Block>
                        <Block.Image>
                          <a href={ad.url} target="_blank" rel="noreferrer">
                            <GatsbyImage
                              className="c-gallery-block--review"
                              image={ad.image.gatsbyImageData}
                              alt="Workshops"
                            />
                          </a>
                        </Block.Image>
                      </Block>
                    </Grid.Item>
                  ))}
                </Grid>
                {data.page.logos && (
                  <>
                    <Section.Header>
                      <div className="c-block u-spacing">
                        <div className="o-text u-spacing">
                          <h1 id="attended-by">
                            Attended by the best in the biz!
                          </h1>
                        </div>
                      </div>
                    </Section.Header>
                    <Slider {...settingsLogos} className="u-padding--top">
                      {data.page.logos.map((logo) => (
                        <GalleryBlock
                          className="c-gallery-block--small c-gallery-block--icon"
                          src={`${logo.image.file.url}?fit=fill&w=120`}
                        />
                      ))}
                    </Slider>
                  </>
                )}
              </Block>
            </Section.Inner>
          </Section>
        )}

        {data.page.blogs && (
          <Section>
            <Section.Header>
              <div className="c-block u-spacing">
                <div className="o-text u-spacing">
                  <h1 id="resources">Blogs + Additional Resources</h1>
                </div>
              </div>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <Block>
                <Grid twoUp>
                  {data.page.blogs.map((blog) => (
                    <Grid.Item className="hover-up">
                      <Block>
                        <Block.Image>
                          <a href={blog.url} target="_blank" rel="noreferrer">
                            <GatsbyImage
                              className="c-gallery-block--review"
                              image={blog.image.gatsbyImageData}
                              alt="Additional resources"
                            />
                          </a>
                        </Block.Image>
                        <a href={blog.url} target="_blank" rel="noreferrer">
                          <h4
                            style={{
                              width: '95%',
                              margin: '-40px auto 0 auto',
                            }}
                          >
                            {blog.title}
                          </h4>
                        </a>
                      </Block>
                    </Grid.Item>
                  ))}
                </Grid>
              </Block>
            </Section.Inner>
          </Section>
        )}
        {data.page.podcasts && (
          <Section>
            <Section.Header>
              <div className="c-block u-spacing">
                <div className="o-text u-spacing">
                  <h1 id="podcasts">Podcasts</h1>
                </div>
              </div>
            </Section.Header>
            <Section.Inner l className="u-spacing--quad">
              <Grid twoUp>
                <Grid.Item>
                  <Block>
                    <GatsbyImage
                      image={data.page.podcasts[0].heroImage.gatsbyImageData}
                      alt="Podcasts"
                    />
                  </Block>
                </Grid.Item>
                <Grid.Item>
                  <Block>
                    <h4 style={{ paddingBottom: '20px' }}>
                      The Loc Show <Headphones s /> Episode 21
                    </h4>
                    <iframe
                      title="player"
                      width="400"
                      height="180"
                      frameborder="no"
                      scrolling="no"
                      seamless
                      src={`https://share.transistor.fm/e/${data.page.podcasts[0].transistorMediaId}`}
                    ></iframe>
                  </Block>
                </Grid.Item>
              </Grid>
            </Section.Inner>
          </Section>
        )}
      </article>
      {data.page.buttons && (
        <article className="l-article u-space--zero--bottom">
          <section className="c-product-intro-section u-gradient--senery-alt u-padding--section c-section__bg--signup">
            <div className="u-spacing--and-half l-container l-wrap">
              <div className="u-spacing--double u-max-width--66--dt">
                <h3 className="c-block__title o-section-title o-section-title--heavy u-color--yellow--light">
                  Interested in working with Smartling?
                </h3>
                <div className="white-buttons--container">
                  {data.page.buttons.map((button) => {
                    return (
                      <a
                        className="white-buttons"
                        href={button.buttonLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {button.buttonText}
                      </a>
                    )
                  })}
                </div>
              </div>
            </div>
          </section>
        </article>
      )}
    </div>
  </Layout>
)

export default LearningHubPageTemplate

export const pageQuery = graphql`
  query LearningHubPageTemplateQuery($pathField: String!) {
    page: contentfulLearningHubPage(path: { eq: $pathField }) {
      title
      heading
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      heroImage {
        ...ContentfulImageFragment
        gatsbyImageData(
          width: 780
          height: 439
          formats: [WEBP, AUTO]
          placeholder: BLURRED
        )
      }
      shortDescription
      metadataTitle
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      ads {
        image {
          ...ContentfulImageFragment
          gatsbyImageData(
            width: 452
            height: 255
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
        url
      }
      logos {
        image {
          ...ContentfulImageFragment
        }
        url
      }
      blogs {
        title
        image {
          description
          ...ContentfulImageFragment
          gatsbyImageData(
            width: 452
            height: 255
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
        url
      }
      podcasts {
        heroImage {
          ...ContentfulImageFragment
          gatsbyImageData(
            width: 550
            height: 300
            formats: [WEBP, AUTO]
            placeholder: BLURRED
          )
        }
        slug
        transistorMediaId
        jwId
      }
      buttons {
        buttonText
        buttonLink
      }
    }
  }
`
