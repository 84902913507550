import React from 'react'
import Button from 'atoms/button'
import SectionCta from 'organisms/cta'

const SectionThirdPartyTranslators = () => (
  <SectionCta title="Our translators. Or yours." centerCTA>
    <SectionCta.Description>
      Choose the right translators for the job. Integrate any language service
      provider or translator to your workflow.
    </SectionCta.Description>
    <SectionCta.Buttons>
      <Button
        target="_blank"
        url="https://help.smartling.com/hc/en-us/articles/115003066613-Add-an-Agency"
      >
        Add your agency
      </Button>
      <Button outline url="/products/translate/translation-services/">
        Or use ours
      </Button>
    </SectionCta.Buttons>
  </SectionCta>
)

export default SectionThirdPartyTranslators
