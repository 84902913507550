import React from 'react'
import Metadata from '../../layouts/metadata'
import Hero from 'organisms/hero'
import Button from 'atoms/button'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'
import Grid from 'atoms/grid'

import MetadataImg from '../../images/smartling-metadata-default.jpeg'
import Logos from '../../images/company-logos/healthcare-page-logos.png'
import PardotResponsive from '../../02-organisms/pardot-responsive'
import personFiegoli from '../../images/automate/fiegoli.jpeg'
import iconButterfly from '../../images/automate/butterflynetwork.png'
import healthCareImage from '../../images/Health_Wellness_Teladoc.png'
import covid19Glossary from '../../images/Healthcare-ABM.jpg'
import TranslationServicesBook from '../../images/fintech-book.png'

const HealthCare = () => {
  return (
    <Layout>
      <Metadata
        title="Smartling Translation Management | Healthcare Customers"
        description="Smartling is the #1 Rated Translation Management Software used by top global healthcare brands"
        keywords="Smartling, translation management, localization, localization tool, healthcare"
        image={MetadataImg}
        alt="Smartling Translation Management"
      />
      <div className="c-industries-detail">
        <Hero
          curveBottom
          gradientSecondary
          title="Reach Patients and Stakeholders On a Global Scale"
        >
          <Hero.Description>
            We handle translations so you can handle the life-saving priorities
          </Hero.Description>
          <Hero.Image>
            <img loading="lazy" src={healthCareImage} alt="Hero image" />
          </Hero.Image>
          <Hero.Buttons>
            <Button url="#bookademo">Schedule a Meeting</Button>
          </Hero.Buttons>
        </Hero>
        <Section className="l-wrap l-container--xl u-padding--bottom">
          <Section.Inner xl>
            <img
              loading="lazy"
              src={Logos}
              alt="classpass logo, zimmer biomet logo, teladoc logo, janssen logo, thebmj logo"
            />
            <Block className="u-padding--bottom">
              <h3>Powering the World’s Top Health Brands</h3>
            </Block>
          </Section.Inner>
        </Section>
        <Section l className="l-wrap u-top--bottom">
          <Section.Header>
            <Block>
              <h2>Digital Healthcare Experiences For Global Users</h2>
            </Block>
          </Section.Header>
          <Section.Inner s className="u-space--double--bottom">
            <p className="u-align--center">
              Smartling powers global growth with our industry-leading
              translation management platform and AI-driven language services.
            </p>
            <ul className="o-list u-space--quad--left u-space--top u-space--bottom">
              <li>Fully built Language Services team</li>
              <li>
                Pre-built <span className="u-color--primary">integrations</span>
              </li>
              <li>
                Dynamic and flexible{' '}
                <span className="u-color--primary">workflows</span>
              </li>
              <li>
                Fastest <span className="u-color--primary">time to market</span>
              </li>
              <li>
                <span className="u-color--primary">Cost savings</span> with a
                centralized platform
              </li>
            </ul>
            <div className="u-align--center u-spacing">
              <p>
                Configure automation rules, establish workflow preferences,
                maintain style guides, and more.
              </p>

              <a
                role="button"
                href="#pardotForm"
                class="o-link u-color--primary"
              >
                Schedule a 30-Min Consultation
              </a>
            </div>
          </Section.Inner>
        </Section>
        <Section>
          <Section.Inner l className="u-spacing--quad l-wrap">
            <Grid twoUp className="u-space--quad--top">
              <Grid.Item className="u-spacing">
                <h3>Developing a Multi-Lingual COVID-19 Glossary</h3>

                <p>
                  Australian Professor, Holly Seale, developed a multi-lingual
                  COVID-19 Glossary to provide a resource for non-english
                  speakers to better understand health and vaccine terms related
                  to the pandemic.
                </p>

                <a
                  role="button"
                  href="/resources/101/developing-and-deploying-a-multilingual-covid-19-glossary"
                  class="o-link u-color--primary"
                >
                  Watch the Interview
                </a>
              </Grid.Item>
              <Grid.Item>
                <img
                  loading="lazy"
                  src={covid19Glossary}
                  alt="Developing a Multi-Lingual COVID-19 Glossary"
                  className="u-max-width--70"
                />
              </Grid.Item>
            </Grid>
            <Grid twoUp>
              <Grid.Item className="u-spacing">
                <img
                  loading="lazy"
                  src={TranslationServicesBook}
                  className="u-max-width--70"
                />
              </Grid.Item>
              <Grid.Item className="u-spacing">
                {' '}
                <h3>The Complete Guide to Translation Services</h3>
                <p>
                  Learn how language services combined with translation and
                  localization software can help you better serve your global
                  audience, whether online or via mobile application.
                </p>
                <a
                  role="button"
                  href="/translation-services"
                  class="o-link u-color--primary"
                >
                  Learn More
                </a>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <div
          className="u-gradient--secondary u-padding--section"
          style={{ marginTop: '70px' }}
        >
          <div>
            <section class="o-section--inner l-wrap l-container--xl">
              <div className="u-space--bottom">
                <div className="u-align--center u-space--bottom">
                  <h2>
                    Butterfly Network: Translating 75,000 words in 10 days
                  </h2>
                  <p className="u-font--m">
                    How Butterfly Network built a scalable global content
                    strategy through automation
                  </p>
                </div>
                <div class="c-quote-box">
                  <div class="u-spacing--double">
                    <div class="u-flex c-quote-top">
                      <img
                        loading="lazy"
                        style={{ borderRadius: '100%' }}
                        src={personFiegoli}
                        alt="Brandon Fiegoli headshot"
                      />
                      <div class="c-quote-attribute">
                        <img
                          loading="lazy"
                          style={{ maxHeight: '30px', width: 'auto' }}
                          src={iconButterfly}
                          alt="Butterfly Network logo"
                        />
                        <div>
                          <strong>Brandon Fiegoli</strong>
                        </div>
                        <div>Project Manager</div>
                      </div>
                    </div>
                    <div class="o-section-title o-section-title--small c-quote o-section-title--medium">
                      You can have the greatest product in the world, but if
                      people don&apos;t know how to use it or if customers
                      can&apos;t get their questions answered about the product,
                      it doesn&apos;t matter.
                    </div>
                    <a
                      href="/resources/case-study/butterfly-network/"
                      class="o-button o-button--wide o-button--no-shadow"
                    >
                      Read the Case Study
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="u-align--center u-spacing">

                <h2>Book a 20-Minute Demo</h2>
                <iframe src="https://resource.smartling.com/l/703963/2022-01-06/2p6w73" type="text/html" frameborder="0" title="pardot form" allowtransparency="true" scrolling="no" style="margin: 15px auto; border: 0px; height: 170px; min-height: 100%; width: auto; min-width: 50%; overflow: hidden;" id="pardotForm"></iframe>
              </div> */}
            </section>

            <section className="o-section c-section__cta c-cta c-cta--center u-space--double--top">
              <div className="o-section--inner l-wrap l-container--l">
                <div className="c-cta__content u-text-align--center">
                  <h2 className="c-cta__title" id="bookademo">
                    Book a 30-Minute Demo
                  </h2>
                  <iframe
                    src="https://resource.smartling.com/l/703963/2022-01-06/2p6w73"
                    id="pardotForm"
                    className="pardot-responsive"
                  ></iframe>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HealthCare
