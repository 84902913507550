import React, { useState } from 'react'
import classNames from 'classnames'
import iconPlus from '../images/icons/icon-plus.svg'

const Accordion = ({ title, content, defaultOpen }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div className="c-accordion">
        <div
          className={
            isOpen ? 'c-accordion--title is-open' : 'c-accordion--title'
          }
          onClick={() => handleClick()}
        >
          <h3 className="c-accordion--title--text">{title}</h3>
          <div className="c-accordion__icon">
            <span className="c-accordion__icon--x"></span>
            <span className="c-accordion__icon--y"></span>
          </div>
        </div>
        <div
          className={
            isOpen ? 'c-accordion--content--open' : 'c-accordion--content'
          }
        >
          {content}
        </div>
      </div>
    </>
  )
}

export default Accordion
