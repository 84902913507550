import React from 'react'
import { Link, graphql } from 'gatsby'
import { getResourcePath } from '../routes'
import { getCategoryPath } from '../routes'
import { getTagPath } from '../routes'
import { DangerousHTML } from 'atoms/contentful/html'
import Block from 'molecules/block'
import Image from 'atoms/contentful/image'

const BlockPost = ({
  title,
  kicker,
  kickerLink,
  link,
  description,
  slug,
  type,
  image,
  categories,
  tags,
  postType,
}) => {
  return (
    <React.Fragment>
      <div
        className={
          type
            ? `${`c-block-post o-kicker__${type
                .toLowerCase()
                .replace(' ', '-')}`}`
            : `c-block-post`
        }
      >
        <Block
          title={title}
          kicker={categories && categories.name}
          kickerLink={categories && getCategoryPath(categories.key)}
          link={
            postType
              ? getResourcePath(`${categories.key}/${slug}`)
              : getResourcePath(slug)
          }
        >
          {image ? (
            <Block.Image>
              <Image {...image} />
            </Block.Image>
          ) : (
            <Block.Image />
          )}
          {description && <DangerousHTML>{description}</DangerousHTML>}
          {tags && (
            <Block.Tags>
              {tags.map(tag => {
                return (
                  <Link to={getTagPath(tag.key)} className="c-tags__item">
                    {tag.name}
                  </Link>
                )
              })}
            </Block.Tags>
          )}
        </Block>
      </div>
    </React.Fragment>
  )
}

export default BlockPost

export const query = graphql`
  fragment ContentfulPodcastFragment on ContentfulPodcastConnection {
    totalCount
    edges {
      node {
        name
        slug
        body {
          html: childMarkdownRemark {
            text: html
          }
        }
        heroImage {
          ...ContentfulImageFragment
        }
      }
    }
  }
  fragment ContentfulResourceFragment on ContentfulResourceConnection {
    totalCount
    edges {
      node {
        title
        description
        slug
        image {
          ...ContentfulImageFragment
        }
        type
        categories {
          name
          key
        }
        tags {
          name
          key
        }
        postType
        metadataTitle
        metadataDescription
        metadataKeywords
        metadataImage {
          ...ContentfulImageFragment
        }
      }
    }
  }
  fragment ContentfulResourceCategoryFragment on ContentfulResourceCategoryConnection {
    totalCount
    edges {
      node {
        name
        key
        description
        metadataTitle
        metadataDescription
        metadataKeywords
        metadataImage {
          ...ContentfulImageFragment
        }
        featuredResource {
          title
          description
          slug
          image {
            ...ContentfulImageFragment
          }
          type
          categories {
            name
            key
          }
          tags {
            name
            key
          }
          postType
          metadataTitle
          metadataDescription
          metadataKeywords
          metadataImage {
            ...ContentfulImageFragment
          }
        }
      }
    }
  }
  fragment ContentfulResourceTagFragment on ContentfulResourceTagConnection {
    edges {
      node {
        name
        key
      }
    }
  }
`
