import React from 'react'
import PropTypes from '../prop-types'
import cx from 'classnames'
import { oneByType, withoutTypes } from '../util/react'
import { Link } from 'gatsby'

const Card = ({
  align,
  title,
  titleLink,
  kicker,
  link,
  linkText,
  children,
  variant,
  className,
  backgroundHeader,
}) => {
  const image = oneByType(children, Card.Image)
  const description = withoutTypes(children, Card.Image)

  return (
    <PatternCard
      align={align}
      title={title}
      titleLink={titleLink}
      kicker={kicker}
      description={description}
      image={image.props.children}
      variant={variant}
      link={{ url: link, text: linkText }}
      className={className}
      backgroundHeader={backgroundHeader}
    />
  )
}

Card.propTypes = {
  align: PropTypes.oneOf['left, center'],
  title: PropTypes.string,
  kicker: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  variant: PropTypes.oneOf['bare'],
  children: PropTypes.children,
  className: PropTypes.string,
  backgroundHeader: PropTypes.string,
}

export default Card

Card.Image = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternCard = ({
  align,
  title,
  titleLink,
  kicker,
  description,
  variant,
  image,
  link,
  className,
  backgroundHeader,
}) => {
  const componentClassName = cx(className, {
    'c-card--bare': variant === 'bare',
    'u-text-align--center': align === 'center',
  })

  return (
    <React.Fragment>
      <div className={`c-card u-spacing ${componentClassName}`}>
        <div className="c-card__body u-spacing--half">
          {backgroundHeader ? (
            <React.Fragment>
              <div
                className="c-card__image c-card__image--bg lazyload"
                data-bgset={backgroundHeader ? backgroundHeader : null}
              >
                {title && <h4 className="c-card__title">{title}</h4>}
              </div>

              {kicker && <div className="c-card__kicker">{kicker}</div>}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {image && <div className="c-card__image">{image}</div>}
              {kicker && <div className="c-card__kicker">{kicker}</div>}
              {title && titleLink ? (
                <Link to={titleLink}>
                  <h4 className="c-card__title">{title}</h4>
                </Link>
              ) : (
                <h4 className="c-card__title">{title}</h4>
              )}
            </React.Fragment>
          )}

          {description && <p className="c-card__description">{description}</p>}
        </div>
        {link.url && (
          <a role="button" href={link.url} className="o-link">
            {link.text}
          </a>
        )}
      </div>
    </React.Fragment>
  )
}

PatternCard.propTypes = {
  title: PropTypes.string,
  kicker: PropTypes.string,
  description: PropTypes.node,
  image: PropTypes.node,
}
