import React from 'react'
import Metadata from '../layouts/metadata'
import Button from 'atoms/button'

// Sections
import Hero from 'organisms/hero'

import Layout from 'layouts'

// Images
import heroImage from '../images/illustrations/404.svg'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Page not found - Smartling"
          description=""
          keywords=""
        />
        <Hero title="Oops! Page not found" center>
          <Hero.Description>
            Here are a few links to help you find your way.
          </Hero.Description>
          <Hero.Image />
          <Hero.Buttons>
            <Button target="_blank" url="https://www.smartling.com/get-started">
              Get Started
            </Button>
            <Button outline url="/why-smartling">
              Learn More
            </Button>
          </Hero.Buttons>
        </Hero>
        <img loading="lazy" src={heroImage} alt="404" />
      </Layout>
    )
  }
}

export default NotFoundPage
