import React from 'react'

// Sections
import Hero from 'organisms/hero'
import Button from 'atoms/button'

import Metadata from '../layouts/metadata'
import Grid from 'atoms/grid'
import Section from 'atoms/section'
import Layout from 'layouts'
import Block from 'molecules/block'

import iconChecklist from '../images/icon-checklist.png'
import howSmartlingWorks from '../images/how-smartling-works.png'

class SampleTranslationRfpPage extends React.Component {
  render() {
    return (
      <Layout>
        <Metadata
          title="Sample Translation RFP | Smartling"
          description="Download the sample translation RFP to determine the translation requirements of your company and evaluate how potential translation technology and service vendors will work for you."
        />
        <Hero center curveBottom gradientPrimary title="Sample Translation RFP">
          <Hero.Description>
            <h5>Let’s build something together.</h5>
          </Hero.Description>
          <Hero.Image />
          <Hero.Buttons>
            <Button
              url="https://go.smartling.com/hubfs/Smartling_Sample_RFP_2.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Download RFP Template
            </Button>
            <Button
              url="mailto:rfp@smartling.com "
              target="_blank"
              rel="noopener noreferrer"
            >
              Submit an RFP
            </Button>
          </Hero.Buttons>
        </Hero>
        <Section>
          <Section.Inner l>
            <Grid twoUp>
              <Grid.Item>
                <h3 className="u-space--bottom">What is an RFP?</h3>
                <p className="u-space--bottom">
                  A Request for Proposal (RFP) of translation technology and
                  services is an important part of the process in determining
                  the translation requirements of your company, and evaluating
                  how well a particular translation vendor will work for you.
                </p>
                <p className="u-space--bottom">
                  Your RFP should give the translation vendor knowledge of your
                  current state as well as any longer term goals.
                </p>
                <p className="u-space--bottom">
                  This way, you can see all of the ways in which your brand can
                  benefit from translation <em>today and in the future</em>.
                </p>
              </Grid.Item>
              <Grid.Item>
                <img loading="lazy" src={iconChecklist} alt="Checklist" />
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>

        <Section className="u-padding--section">
          <Section.Header>
            <h3 className="u-space--bottom">
              You are the expert at what you do.
            </h3>
          </Section.Header>
          <Section.Inner s>
            <div className="u-text-align--center">
              <p>
                ... But it can be difficult to know the essential questions to
                ask when vetting a translation solution provider{' '}
                <span role="img" aria-label="Woman raising hand emoji">
                  🙋‍♀️
                </span>
              </p>
              <p className="u-space--double--top">
                You may not realize the info that is critical for configuring
                the best translation solution for your organization.
              </p>
            </div>
          </Section.Inner>
        </Section>
        <Section
          gradientSecondary
          className="c-section__grid-blocks c-grid-blocks u-padding--section"
        >
          <Section.Header>
            <h2 className="u-text-align--center">
              Smartling’s Sample Translation RFP provides:
            </h2>
          </Section.Header>
          <Section.Inner l className="u-padding--top">
            <Grid threeUp>
              <Grid.Item>
                <Block title="Framework For Growth">
                  Questions for companies to better understand what you need to
                  expand to global markets.
                </Block>
              </Grid.Item>
              <Grid.Item>
                <Block title="Technical Evaluation">
                  It’s not just about creating global content, technical
                  configurations need to be considered.
                </Block>
              </Grid.Item>
              <Grid.Item>
                <Block title="Service & Support Inquiry">
                  The contract is signed.. what does the relationship with the
                  TMS partner look like from here?
                </Block>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <Section className="u-padding--section">
          <Section.Inner l>
            <Grid twoUp>
              <Grid.Item>
                <img loading="lazy" src={howSmartlingWorks} alt="Checklist" />
              </Grid.Item>
              <Grid.Item>
                <h3 className="u-space--bottom">
                  Finding the Right Translation Solution
                </h3>
                <p className="u-space--bottom">
                  Whatever your company size and wherever your market, there are
                  opportunities to improve the process.
                </p>
                <p className="u-space--bottom">
                  If you’re just getting ready to move into a new market, the
                  implementation of a translation solution can seem{' '}
                  <span role="img" aria-label="sunglasses emoji">
                    🤯
                  </span>
                  . You have to consider every portion of your business, but in
                  another language!{' '}
                </p>
                <p className="u-space--bottom">
                  If you’re already global, there are likely some areas where
                  you can improve the process internally.
                </p>
              </Grid.Item>
            </Grid>
          </Section.Inner>
        </Section>
        <Section className="u-gradient--primary u-curve--top--right u-padding--section">
          <Section.Header>
            <div className="u-text-align--left">
              <h3>
                Interested in seeing how an end-to-end translation solution can
                accelerate your company’s growth?
              </h3>
              <div class="c-block__description  u-space--double--top">
                Download our sample RFP today and contact us to learn more.
              </div>
              <div className="u-space--double--top">
                <Button
                  url="https://go.smartling.com/hubfs/Smartling_Sample_RFP_2.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download RFP Template
                </Button>
                <Button
                  url="mailto:rfp@smartling.com "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Submit an RFP
                </Button>
              </div>
              <div class="c-block__description  u-space--double--top">
                Completed RFPs can be emailed to{' '}
                <a
                  href="mailto:rfp@smartling.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  rfp@smartling.com
                </a>
              </div>
            </div>
          </Section.Header>
        </Section>
      </Layout>
    )
  }
}

export default SampleTranslationRfpPage
