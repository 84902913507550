import React from 'react'
import { graphql } from 'gatsby'
import { getEventPath } from '../routes'
import { DangerousHTML } from 'atoms/contentful/html'
import Block from 'molecules/block'
import Image from 'atoms/contentful/image'

const moment = require('../../static/vendor/moment.min.js')

const EventBlockPost = ({
  name,
  kicker,
  kickerLink,
  link,
  shortDescription,
  slug,
  startDate,
  hideDate,
  eventLocation,
  heroImage,
  imageHeight,
  buttonURL,
  postType,
  eventCategory,
}) => {
  return (
    <React.Fragment>
      <div className="c-block-post">
        <Block
          title={name}
          link={getEventPath(slug, eventCategory ? eventCategory.name : null)}
        >
          {heroImage ? (
            <Block.Image>
              <Image height={imageHeight} {...heroImage} />
            </Block.Image>
          ) : (
            <Block.Image />
          )}
          {!hideDate && (
            <span
              className="o-event-date o-event-date--grey"
              data-date={startDate}
            >
              {startDate && moment.parseZone(startDate).format('MMMM D, YYYY')}
              {eventLocation?.city && ` | ${eventLocation.city}`}
              {eventLocation?.state && ` ${eventLocation.state}`}
            </span>
          )}

          {shortDescription && (
            <DangerousHTML>{shortDescription}</DangerousHTML>
          )}
        </Block>
      </div>
    </React.Fragment>
  )
}

export default EventBlockPost

export const query = graphql`
  fragment ContentfulEventFragment on ContentfulEventConnection {
    totalCount
    edges {
      node {
        name
        shortDescription
        slug
        startDate
        eventLocation {
          city
          state
        }
        heroImage {
          ...ContentfulImageFragment
        }
        event_category {
          name
          key
        }
        metadataTitle
        metadataDescription
        metadataKeywords
        metadataImage {
          ...ContentfulImageFragment
        }
      }
    }
  }
  fragment ContentfulEventCategoryFragment on ContentfulEventCategoryConnection {
    totalCount
    edges {
      node {
        name
        key
        description {
          html: childMarkdownRemark {
            text: html
          }
        }
        metadataTitle
        metadataDescription
        metadataKeywords
        metadataImage {
          ...ContentfulImageFragment
        }
        featuredEvent {
          name
          slug
          startDate
          eventLocation {
            city
            state
          }
          heroImage {
            ...ContentfulImageFragment
          }
          shortDescription
          event_category {
            name
            key
          }
          metadataTitle
          metadataDescription
          metadataKeywords
          metadataImage {
            ...ContentfulImageFragment
          }
        }
      }
    }
  }
`
