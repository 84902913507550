import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Metadata from '../layouts/metadata'
import Helmet from 'react-helmet'
import Section from 'atoms/section'
import Hero from 'organisms/hero'
import Grid from 'atoms/grid'
import LandingPageSection from 'components/sections/landing-page-section'
import Layout from 'layouts'
import { DangerousHTML } from 'atoms/contentful/html'
import { GatsbyImage } from 'gatsby-plugin-image'
import calendarIcon from '../images/icons/icon_calendar-light.png'
import grcLogo from '../images/grc-logo-crop.png'
import Block from '../01-molecules/block'
import Button from 'atoms/button'
import MetaDataImage from '../images/meta-image.png'
import PardotResponsive from '../02-organisms/pardot-responsive'
import PardotForm from 'components/forms/pardot'
import HubSpotForm from 'components/forms/HubSpotForm'

const ConferenceTemplate = ({ data }) => {
  const heroImage = data.page.heroImage
  const hubspotFormReference = data.page.hubspotFormReference || {}
  const { portalid, formid, campaignId } = hubspotFormReference

  return (
    <Layout>
      <Helmet>
        <body className="u-header--white" />
      </Helmet>
      <Metadata
        title={data.page.metadataTitle || data.page.title}
        description={data.page.metadataDescription}
        keywords={data.page.metadataKeywords?.join(`, `)}
        image={data.page.metadataImage.file.url || MetaDataImage}
      />
      <div className="c-page-professional-services l-page--grc c-page-conferences c-ppc-detail c-ppc-book-meeting">
        <Hero className="c-hero__resource o-hero--custom" backgroundPrimary>
          <Hero.Description>
            {heroImage &&
              (heroImage.gatsbyImageData ? (
                <GatsbyImage
                  image={heroImage.gatsbyImageData}
                  alt={heroImage.title}
                  objectFit="contain"
                />
              ) : (
                <img
                  loading="lazy"
                  src={heroImage.file.url}
                  alt={heroImage.title}
                />
              ))}
            {data.page.title && (
              <h1 className="c-hero__title o-section-title--heavy">
                {data.page.title}
              </h1>
            )}
            {data.page.heroDescription && (
              <p className="c-hero__sub-description">
                {data.page.heroDescription}
              </p>
            )}
          </Hero.Description>
          <Hero.Image>
            {data.page.hubspotFormReference &&
              (data.page?.pardotFormReferencePlacement === 'Hero' ? (
                <div className="c-ppc__hero--pardot">
                  <div
                    id="hubspot-form-container"
                    className="hubspot-form-container"
                  >
                    <HubSpotForm
                      portalId={portalid}
                      formId={formid}
                      campaignId={campaignId || ''}
                    />
                  </div>
                  <p>
                    For translators interested in translating for Smartling,{' '}
                    <a href="/translator_information">Apply here</a>.
                  </p>
                  <p>
                    By registering, you agree to the processing of your personal
                    data by Smartling as described in our{' '}
                    <a href="/privacy">Privacy Policy</a>.
                  </p>
                </div>
              ) : (
                ``
              ))}
          </Hero.Image>
          <Hero.Buttons>
            {data.page.heroButtons &&
              data.page.heroButtons.map((button) => (
                <Button
                  url={button.buttonLink}
                  target={button.newWindow ? `_blank` : `_self`}
                >
                  {button.buttonText ? button.buttonText : `Learn More`}
                </Button>
              ))}
          </Hero.Buttons>
        </Hero>
        <Section
          centerCTA
          className="c-conf-intro-container l-wrap l-container--xl"
        >
          {data.page.body.html.text && (
            <Section.Header>
              <Block>
                <div className="u-spacing u-padding--top c-conf-intro">
                  <DangerousHTML>{data.page.body.html.text}</DangerousHTML>
                </div>
              </Block>
            </Section.Header>
          )}
        </Section>
        {data.page.pageSections &&
          data.page.pageSections.map((section, index) => (
            <LandingPageSection
              {...section}
              disableLazy={index === 0 ? true : false}
              showExpanded={true}
            />
          ))}
        {data.page.hubspotFormReference &&
          (data.page?.pardotFormReferencePlacement === 'Sidebar' ||
          data.page?.pardotFormReferencePlacement === 'Below Body' ? (
            <div className="l-wrap">
              <div
                id="hubspot-form-container"
                className="hubspot-form-container"
              >
                <HubSpotForm
                  portalId={portalid}
                  formId={formid}
                  campaignId={campaignId || ''}
                />
              </div>
            </div>
          ) : (
            ``
          ))}
      </div>
    </Layout>
  )
}

export default ConferenceTemplate

export const ConferenceQuery = graphql`
  query ConferenceTemplateQuery {
    page: contentfulBasicPage(path: { eq: "conference" }) {
      title
      heroDescription
      heroImage {
        file {
          url
        }
        gatsbyImageData(width: 185, formats: [WEBP, AUTO], placeholder: BLURRED)
      }
      body {
        html: childMarkdownRemark {
          text: html
        }
      }
      pardotFormReferencePlacement
      metadataTitle
      metadataImage {
        file {
          url
        }
      }
      metadataDescription
      metadataKeywords
      heroButtons {
        buttonText
        buttonLink
        newWindow
      }
      pageSections {
        ...ContentfulLandingPageSectionGridFragment
        ...ContentfulLandingPageSectionAccordionFragment
        ...ContentfulLandingPageSectionRowFragment
      }
      hubspotFormReference {
        portalid
        formid
        campaignId
      }
    }
  }
`
