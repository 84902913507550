import React from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import PropTypes from '../prop-types'
import Section from 'atoms/section'
import { oneByType } from '../util/react'
import Helmet from 'react-helmet'
import classNames from 'classnames'

const Hero = ({
  title,
  renderTitleAsHtml,
  titleLink,
  className,
  center,
  flip,
  largeImage,
  largeLandingImage,
  backgroundPrimary,
  gradientPrimary,
  gradientPrimaryAlt,
  gradientSenery,
  gradientSecondary,
  gradientTertiary,
  gradientSeptenary,
  curveTop,
  curveRight,
  curveLeft,
  curveLeftReverse,
  curveBottom,
  curveCenter,
  curveCenterAlt,
  children,
  backgroundImage,
  backgroundPosition,
  kicker,
  id,
  disableLazy,
  disableShadow,
}) => {
  const classes = cx(className, {
    'c-hero--center': center,
    'c-hero--reverse': flip,
    'c-hero--large-image': largeImage,
    'c-hero--large-landing': largeLandingImage,
    'u-background--primary': backgroundPrimary,
    'u-gradient--senery': gradientSenery,
    'u-gradient--primary': gradientPrimary,
    'u-gradient--secondary': gradientSecondary,
    'u-gradient--tertiary': gradientTertiary,
    'u-gradient--septenary': gradientSeptenary,
    'u-disable-shadow': disableShadow,
  })

  // const kicker = oneByType(children, Hero.Kicker)
  const description = oneByType(children, Hero.Description)
  const image = oneByType(children, Hero.Image)
  const video = oneByType(children, Hero.Video)
  const buttons = oneByType(children, Hero.Buttons)

  var helmet
  if ((className = gradientPrimary || backgroundPrimary || gradientSenery)) {
    helmet = (
      <Helmet>
        <body className="u-header--white" />
      </Helmet>
    )
  }

  // if ((className = gradientEventCategory)) {
  //   helmet = (
  //     <Helmet>
  //       <body className="" />
  //     </Helmet>
  //   )
  // }

  return (
    <React.Fragment>
      {helmet}
      <PatternHero
        className={classes}
        id={id}
        title={title}
        renderTitleAsHtml={renderTitleAsHtml}
        titleLink={titleLink}
        kicker={kicker}
        description={description && description.props.children}
        image={image && image.props.children}
        video={video && video.props.children}
        buttons={buttons && buttons.props.children}
        backgroundImage={backgroundImage}
        backgroundPosition={backgroundPosition}
        disableLazy={disableLazy}
        disableShadow={disableShadow}
      />
    </React.Fragment>
  )
}

Hero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
}

export default Hero

Hero.Kicker = ({ children }) => <React.Fragment />

Hero.Description = ({ children }) => <React.Fragment />

Hero.Image = ({ children }) => <React.Fragment />

Hero.Video = ({ children }) => <React.Fragment />

Hero.Buttons = ({ children }) => <React.Fragment />

/* ----------------------------- PATTERN ----------------------------- */

export const PatternHero = ({
  className,
  title,
  renderTitleAsHtml,
  titleLink,
  kicker,
  description,
  image,
  video,
  buttons,
  backgroundImage,
  backgroundPosition,
  id,
  disableLazy,
  disableShadow,
}) => {

  if (titleLink) {
    title = (
      <Link to={titleLink} className="c-hero__title-link">
        <h1 className="c-hero__title">{title}</h1>
      </Link>
    )
  } else if (renderTitleAsHtml) {
    title = (
      <h1
        className="c-hero__title o-section-title--heavy"
        dangerouslySetInnerHTML={{ __html: title }}
      />
    )
  } else {
    title = <h1 className="c-hero__title o-section-title--heavy">{title}</h1>
  }

  if (backgroundImage) {
    var style = {}
    if (backgroundPosition) {
      style.backgroundPosition = backgroundPosition
    }
    if (disableLazy && backgroundImage) {
      style.backgroundImage = `url(${backgroundImage})`
    }
    var backgroundClassName = `has-background-image u-overlay ${
      !disableLazy ? 'lazyload' : ''
    }`
  }

  return (
    <Section
      style={style}
      databgset={backgroundImage && !disableLazy ? backgroundImage : ''}
      className={`c-section__hero c-hero ${className} ${backgroundClassName}`}
      id={id}
    >
      <Section.Inner xl>
        <div className="c-hero--left">
          <div className="c-hero__content u-spacing--double">
            {kicker && (
              <span className="c-hero__kicker o-kicker">{kicker}</span> // TODO: .u-color--primary sets the text to purple which is only useful on white backgrounds; set conditional here to choose kicker color based on chosen background
            )}
            {title}
            {description && (
              <p className="c-hero__description">{description}</p>
            )}
            {buttons && <div className="c-hero__buttons">{buttons}</div>}
          </div>
        </div>
        {image && (
          <div className="c-hero--right">
            {titleLink ? (
              <Link to={titleLink} className="c-hero__image-link">
                <div className="c-hero__image">{image}</div>
              </Link>
            ) : (
              <div
                className={classNames(
                  disableShadow && 'u-disable-shadow',
                  'c-hero__image'
                )}
              >
                {image}
              </div>
            )}
          </div>
        )}
        {video && <div className="c-hero__video">{video}</div>}
      </Section.Inner>
    </Section>
  )
}

PatternHero.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  kicker: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.node,
  video: PropTypes.children,
  buttons: PropTypes.children,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
}
